import React from "react";
import ReactDOM from "react-dom";
import "./styles/index.css";
import Main from "./Main";
import * as serviceWorker from "./serviceWorker";
import { createRoot } from "react-dom/client";

const main = (
  // <React.StrictMode>
  <Main />
  // </React.StrictMode>
);
const root = createRoot(document.getElementById("root"));
root.render(main);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
