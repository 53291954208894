import React from "react";
import { DropdownInput } from "../../generic/DropdownInput";
import { SingleLineInput } from "../../generic/SingleLineInput";
import { OrderFormAccordian } from "./OrderFormAccordian";

export const BroadbandOrderForm = ({
  packages,
  data,
  setData,
  checked,
  setChecked
}) => {
  return (
    <OrderFormAccordian
      title={"Broadband Order"}
      checked={checked}
      setChecked={setChecked}
    >
      <DropdownInput
        id={"bb-package-input"}
        required={true}
        title={"Package"}
        defaultValue={data.broadband_package}
        options={packages.map(pkg => {
          return {
            title: `${pkg.display_name} - ${pkg.retail_price.toLocaleString(
              "en-GB",
              {
                style: "currency",
                currency: "GBP"
              }
            )}`,
            value: pkg.id
          };
        })}
        onChange={e => {
          setData({
            ...data,
            broadband_package: !isNaN(parseInt(e.target.value))
              ? parseInt(e.target.value)
              : null
          });
        }}
      />

      <SingleLineInput
        id={"broadband-package-price-input"}
        required={true}
        type={"number"}
        title={"Price"}
        defaultValue={data.package_price}
        onChange={e => {
          setData({
            ...data,
            package_price: !isNaN(parseFloat(e.target.value))
              ? parseFloat(e.target.value)
              : null
          });
        }}
      />
      <a
        target={"_blank"}
        href={"https://daisypartners.co.uk/broadband-availability-checker/"}
      >
        Check Available Speeds Here
      </a>
      <DropdownInput
        id="connection-type-select"
        title={"Connection Type"}
        required={true}
        options={[
          { title: "SoGEA", value: "sogea" },
          { title: "FTTP", value: "fttp" }
        ]}
        defaultValue={data.connection_type}
        onChange={e => {
          setData({
            ...data,
            connection_type: e.target.value
          });
        }}
      />
      <SingleLineInput
        id="min-down-speed"
        type="number"
        title={"Min Download Speed"}
        required={true}
        defaultValue={data.min_down_speed}
        onChange={e => {
          setData({
            ...data,
            min_down_speed: e.target.value
          });
        }}
      />
      <SingleLineInput
        id="max-down-speed"
        type="number"
        title={"Max Download Speed"}
        required={true}
        defaultValue={data.max_down_speed}
        onChange={e => {
          setData({
            ...data,
            max_down_speed: e.target.value
          });
        }}
      />
      <SingleLineInput
        id="min-up-speed"
        type="number"
        title={"Min Upload Speed"}
        required={true}
        defaultValue={data.min_up_speed}
        onChange={e => {
          setData({
            ...data,
            min_up_speed: e.target.value
          });
        }}
      />
      <SingleLineInput
        id="max-up-speed"
        type="number"
        title={"Max Upload Speed"}
        required={true}
        defaultValue={data.max_up_speed}
        onChange={e => {
          setData({
            ...data,
            max_up_speed: e.target.value
          });
        }}
      />
      <SingleLineInput
        id="min-guar-down-speed"
        type="number"
        title={"Min Guaranteed Download Speed"}
        required={true}
        defaultValue={data.min_guaranteed_down_speed}
        onChange={e => {
          setData({
            ...data,
            min_guaranteed_down_speed: e.target.value
          });
        }}
      />
    </OrderFormAccordian>
  );
};
