import {
  getSelectedCompany,
  selectedCompanyID,
  switchedUserID
} from "./constants";
import { keysCamelToSnake, keysSnakeToCamel } from "./obj";
import { getTokens, refreshAccessToken } from "./tokens";

export async function request(url, method, body, headers, outputFormat) {
  var h = new Headers({
    "Content-Type": "application/json; charset=utf-8",
    Accept: "application/json",
    ...headers
  });

  const company = getSelectedCompany();

  // if (company && company.id != null) {
  //   h.append("X-Company-ID", company.id);
  // }

  h.append("X-Company-ID", 5);

  if (switchedUserID != null) {
    h.append("X-Switched-User-ID", switchedUserID);
  }

  if (url.includes("/profile_pic") && method === "POST") {
    h = new Headers({
      Accept: "application/json"
    });
  }

  const tokens = getTokens();

  if (tokens.accessToken) {
    if (tokens.expiresAt <= Date.now() / 1000) {
      try {
        await refreshAccessToken();
        return request(url, method, body, headers);
      } catch (err) {
        console.log(err);
        return;
      }
    }

    h.set("Authorization", `Bearer ${tokens.accessToken}`);
  }

  const opts = {
    method,
    headers: h
  };

  if (method !== "GET" && method !== "HEAD" && body) {
    opts.body = JSON.stringify(body);
  }

  if (url.includes("/profile_pic") && method === "POST") {
    opts.body = body;
  }

  const res = await fetch(url, opts);

  const json = await res.json();

  if (!json.ok) {
    const error = new Error(json.error || "Request error");
    error.code = res.status;
    throw error;
  }

  return json.data;
}

export function get(url, headers, outputFormat) {
  return request(url, "GET", undefined, headers, outputFormat);
}

export function post(url, body, headers, outputFormat) {
  return request(url, "POST", body, headers, outputFormat);
}

export function put(url, body, headers, outputFormat) {
  return request(url, "PUT", body, headers, outputFormat);
}

export function del(url, headers, outputFormat) {
  return request(url, "DELETE", undefined, headers, outputFormat);
}
