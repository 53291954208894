// Libraries
import React, { useEffect, useState, useContext } from "react";

// Functions

// Styles
import "../styles/outbound-calls/overview/OutboundCalls.css";
import "../styles/outbound-calls/overview/SalesLeaderboard.css";
import "../styles/outbound-calls/overview/SalesStatistics.css";

// Views
import TabViewBar from "../views/generic/TabViewBar";
import Screen from "./Screen";
import { OutboundCallsScreenHeader } from "../views/outbound-calls/overview/OutboundCallsScreenHeader";
import TabViewContainer from "../views/generic/TabViewContainer";
import OutboundCallsOverviewTab from "../views/outbound-calls/overview/OutboundCallsOverviewTab";
import OutboundCallsCallingSessionTab from "../views/outbound-calls/overview/OutboundCallsCallingSessionTab";
import OutboundCallsHistoryTab from "../views/outbound-calls/call-history/OutboundCallsHistoryTab";

// Contexts
import { UserContext } from "../Main";

const OutboundCallsScreenV2 = () => {
  const [selectedTabView, setSelectedTabView] = useState("calling-session");
  const { user, _ } = useContext(UserContext);
  const [userName, setUserName] = useState("-");
  const [userProfilePic, setUserProfilePic] = useState("");

  useEffect(() => {
    (async () => {
      // Load previously selected tab from local storage.
      const previouslySelectedTab = localStorage.getItem(
        "rscrm_outbound_calls_sel_tab"
      );
      if (previouslySelectedTab && previouslySelectedTab !== "") {
        console.log(previouslySelectedTab);
        setSelectedTabView(previouslySelectedTab);
      }
    })();
  }, []);

  useEffect(() => {
    if (user) {
      setUserName(`${user.first_name} ${user.last_name}`);
      setUserProfilePic(user.profile_pic);
    }
  }, [user]);

  return (
    <Screen>
      <div className="outbound-call-screen">
        <OutboundCallsScreenHeader
          userProfilePicture={userProfilePic}
          userName={userName}
        />
        <TabViewBar
          selectedTab={selectedTabView}
          tabs={[
            // { id: "overview", display_text: "Overview" },
            { id: "calling-session", display_text: "Calling Session" },
            // { id: "training", display_text: "Training" },
            { id: "call-history", display_text: "Call History" }
          ]}
          onSwitch={nextTabView => {
            localStorage.setItem("rscrm_outbound_calls_sel_tab", nextTabView);
            setSelectedTabView(nextTabView);
          }}
        />
        <TabViewContainer>
          {selectedTabView == "overview" && <OutboundCallsOverviewTab />}
          {selectedTabView == "calling-session" && (
            <OutboundCallsCallingSessionTab />
          )}
          {selectedTabView == "training" && <span>Training</span>}
          {selectedTabView == "call-history" && (
            <OutboundCallsHistoryTab
              setSelectedTab={tab => {
                localStorage.setItem("rscrm_outbound_calls_sel_tab", tab);
                setSelectedTabView(tab);
              }}
            />
          )}
        </TabViewContainer>
      </div>
    </Screen>
  );
};

export default OutboundCallsScreenV2;
