import React from "react";
import "../styles/Alert.css";

function Alert({ title, message, submitAction, cancelAction}) {
  return (
    <div className="alert-container">
      <h3>{title}</h3>
      <p className="alert-message-lbl">{message}</p>
      <div className="alert-bottom-row">
        <button className="alert-dismiss-btn" onClick={async () => {
          if (cancelAction) {
            cancelAction();
          } else {
            document.getElementById("alert-message").remove();
          }
        }}>Dismiss</button>
        <button className="alert-submit-btn" hidden={submitAction === null || submitAction === undefined} onClick={async () => {
          submitAction();
        }}>Dismiss</button>
      </div>
    </div>
  )
}

export default Alert;
