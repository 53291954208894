import React, { useContext, useEffect, useState } from "react";

// Styles
import "../../../styles/outbound-calls/history/OutboundCallsHistoryTab.css";
import { Table } from "../../generic/Table";
import { getUserCallHistory } from "../../../functions/outbound-calls/call-history-functions";
import {
  listCallbacks,
  openCallbackDetailForm
} from "../../../functions/outbound-calls/callback_functions";
import { showErrorToast } from "../../../utils/ErrorHandler";
import { SelectedRecordContext, UserContext } from "../../../Main";
import { getRecord } from "../../../functions/outbound-calls/calling_session_functions";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../../utils/LoadingHandler";
import { closeModal } from "../../generic/modal/Modal";

export const OutboundCallsHistoryTab = ({ setSelectedTab }) => {
  const [outboundCallHistory, setOutboundCallHistory] = useState([]);
  const [callbacks, setCallbacks] = useState([]);

  const { selectedRecord, setSelectedRecord } = useContext(
    SelectedRecordContext
  );
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const history = await getUserCallHistory();
      if (history) {
        setOutboundCallHistory(history);
      } else {
        setOutboundCallHistory([]);
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      const [callbacks, err] = await listCallbacks();
      if (err) {
        showErrorToast(
          "Failed to get callbacks",
          "There was an error whilst trying to get your callbacks, please try again later."
        );
        return;
      }
      if (callbacks) {
        setCallbacks(callbacks);
      } else {
        setCallbacks([]);
      }
    })();
  }, [user]);

  const openRecord = async cli => {
    showLoadingToast();
    const record = await getRecord(cli);
    if (record) {
      localStorage.setItem("rscrm_current_record", JSON.stringify(record));
      localStorage.removeItem("rscrm_current_call_cli");
      setSelectedRecord(record);
      setSelectedTab("calling-session");
    }
    removeLoadingToast();
  };

  return (
    <div className="outbound-calls-history-tab">
      <div className="col-1">
        <span>Today's Connected Calls</span>
        <Table
          headers={["Customer Name", "CLI", "Time"]}
          rowData={outboundCallHistory.map(dial => {
            return {
              customer_name: dial.person_name,
              cli: dial.cli,
              time: new Date(dial.called_at).toLocaleTimeString()
            };
          })}
          rowClick={async row => {
            localStorage.removeItem("rscrm_dealsheet_cache");
            await openRecord(row.cli);
          }}
        />
      </div>
      <div className="col-2">
        <span>Today's Callbacks</span>
        <Table
          headers={["Customer Name", "CLI", "Date", "Time"]}
          rowData={callbacks.map(callback => {
            return {
              customer_name: callback.customer_name,
              cli: callback.cli,
              date: new Date(callback.callback_on).toLocaleDateString(),
              time: new Date(callback.callback_on)
                .toLocaleTimeString()
                .slice(0, 5)
            };
          })}
          rowClick={data => {
            const filtered = callbacks.filter(callback => {
              return callback.cli == data.cli;
            });
            if (filtered.length == 0) {
              return;
            }
            openCallbackDetailForm(filtered[0], async () => {
              closeModal();
              localStorage.removeItem("rscrm_dealsheet_cache");
              await openRecord(data.cli);
            });
          }}
        />
      </div>
    </div>
  );
};

export default OutboundCallsHistoryTab;
