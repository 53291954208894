import React from "react";
import "../../styles/generic/DestructiveActionButton.css";

function DestructiveActionButton({ id, title, style = "filled", onClick }) {
  const className = `destructive-action-button ${style}`;

  return (
    <button className={className} id={id} onClick={onClick}>
      <span className="title-lbl">{title}</span>
    </button>
  );
}

export default DestructiveActionButton;
