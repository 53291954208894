import React from "react";
import { createRoot } from "react-dom/client";
import { SuccessToast } from "../views/generic/SuccessToast";
import { generateUUID } from "../functions/core_functions";

export function showSuccessToast(title, description, permanent = false) {
  /* title is an optional parameter */
  const id = generateUUID();
  const div = document.createElement("div");
  div.id = id;
  div.className = "success-popup";

  document.getElementById("root").appendChild(div);
  const successToast = (
    <SuccessToast
      title={title}
      description={description}
      onClose={async () => {
        removeSuccessToast(id);
      }}
    />
  );
  const root = createRoot(document.getElementById(id));
  root.render(successToast);

  if (!permanent) {
    setTimeout(() => {
      removeSuccessToast(id);
    }, 6000);
  }
}

function removeSuccessToast(id) {
  const mainDiv = document.getElementById(id);
  if (mainDiv) {
    mainDiv.classList.add("removing");
    const toast = mainDiv.querySelectorAll(".success-toast")[0];
    toast.classList.add("removing");
    setTimeout(() => {
      toast.remove();
      mainDiv.remove();
    }, 250);
  }
}
