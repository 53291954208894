import React from "react";
import ReactDOM from "react-dom";
import { serverDomain, selectedCompanyID } from "../utils/constants.js";
import * as req from "../utils/request";
import { showErrorToast } from "../utils/ErrorHandler";
import { showAlert } from "../functions/core_functions.js";
import TechnicalFaultDetailView from "../views/Customers/TechnicalFaults/TechnicalFaultDetailView.js";
import TechnicalFaultFurtherDetailView from "../views/Customers/TechnicalFaults/TechnicalFaultFurtherDetailView.js";
import TechnicalFaultNote from "../views/Customers/TechnicalFaults/TechnicalFaultNote.js";
import TechnicalFaultEngineerVisitDetailView from "../views/Customers/TechnicalFaults/TechnicalFaultEngineerVisitDetailView.js";
import OpenRecordTableRowButton from "../views/elements/OpenRecordTableRowButton.js";

import { switchToTechnicalFaultsTab } from "../functions/customer_functions.js";

export const listTechnicalFaultsLimit = 25;

export var listCustomerTechnicalFaultsOffset = 0;
export var listUnresolvedTechnicalFaultsOffset = 0;
export var listResolvedTechnicalFaultsOffset = 0;

export var reachedEndOfCustomersTechnicalFaults = false;
export var reachedEndOfUnresolvedTechnicalFaults = false;
export var reachedEndOfResolvedTechnicalFaults = false;

export function setListUnresolvedTechnicalFaultsOffset(newOffset) {
  if (newOffset <= 0) {
    listUnresolvedTechnicalFaultsOffset = 0;
  }
  listUnresolvedTechnicalFaultsOffset = newOffset;
}

export function setListResolvedTechnicalFaultsOffset(newOffset) {
  if (newOffset <= 0) {
    listResolvedTechnicalFaultsOffset = 0;
  }
  listResolvedTechnicalFaultsOffset = newOffset;
}

export function setListCustomerTechnicalFaultsOffset(newOffset) {
  if (newOffset <= 0) {
    listCustomerTechnicalFaultsOffset = 0;
  }
  listCustomerTechnicalFaultsOffset = newOffset;
}

export async function listTechnicalFaults(customerId, status, offset) {
  var url = `${serverDomain}/api/v1/technical_faults?limit=${listTechnicalFaultsLimit}&offset=${offset}`;
  if (customerId !== null) {
    url = `${url}&customer_id=${customerId}`;
  }
  if (status !== null) {
    url = `${url}&status=${status}`;
  }
  try {
    var table;
    if (customerId !== null) {
      table = document
        .getElementById("customer-technical-faults-table")
        .querySelector("tbody");
    } else if (status.toLowerCase() === "unresolved") {
      table = document
        .getElementById("technical-faults-unresolved-table")
        .querySelector("tbody");
    } else if (status.toLowerCase() === "resolved") {
      table = document
        .getElementById("technical-faults-resolved-table")
        .querySelector("tbody");
    }

    const faults = await req.get(url, null, "snake");
    if (!faults) {
      table.innerHTML = "";
      return;
    }

    if (offset === 0) {
      table.innerHTML = "";
    }

    for (var i = 0; i < faults.length; i++) {
      const fault = faults[i];
      const row = table.insertRow(i);
      row.className = "customer-technical-faults-row";

      var rowData;
      if (customerId === null) {
        if (status === "resolved") {
          rowData = [
            new Date(fault.resolved_at).toLocaleDateString(),
            fault.customer_name,
            fault.cli,
            `${fault.type.charAt(0).toUpperCase()}${fault.type.slice(1)}`
          ];
        } else {
          rowData = [
            new Date(fault.created_at).toLocaleDateString(),
            fault.customer_name,
            fault.cli,
            `${fault.type.charAt(0).toUpperCase()}${fault.type.slice(1)}`
          ];
        }
      } else {
        rowData = [
          new Date(fault.created_at).toLocaleDateString(),
          `${fault.type.charAt(0).toUpperCase()}${fault.type.slice(
            1
          )}`.replaceAll("_", " "),
          `${fault.status.charAt(0).toUpperCase()}${fault.status.slice(1)}`
        ];
      }

      rowData.forEach((val, indx) => {
        const cell = row.insertCell(indx);
        cell.textContent = val;
        if (val.toLowerCase() === "unresolved") {
          cell.style.color = "red";
          cell.style.fontFamily = "Satoshi-Medium";
        } else if (val.toLowerCase() === "resolved") {
          cell.style.color = "green";
          cell.style.fontFamily = "Satoshi-Medium";
        }
        if (indx === rowData.length - 1) {
          const div = document.createElement("div");
          div.className = "open-record-button-container";
          ReactDOM.render(
            <OpenRecordTableRowButton
              onClick={async () => {
                const div = document.createElement("div");
                if (status === "unresolved") {
                  div.id = "tech-fault-further-detail-form-parent-div";
                  ReactDOM.render(
                    <TechnicalFaultFurtherDetailView technicalFault={fault} />,
                    div
                  );
                } else if (status === "resolved") {
                  div.id = "tech-fault-detail-form-parent-div";
                  ReactDOM.render(
                    <TechnicalFaultDetailView technicalFault={fault} />,
                    div
                  );
                }
                document.getElementById("app-content-wrapper").appendChild(div);
              }}
            />,
            div
          );
          cell.appendChild(div);
        }
      });
    }

    return;
  } catch (err) {
    showErrorToast(
      "Failed to get technical faults",
      `There was an error that occurred whilst trying to list technical faults. ${err}`
    );

    console.log(err);
    return;
  }
}

export async function createTechnicalFault(customerId) {
  var url = `${serverDomain}/api/v1/technical_faults`;

  const description = document.getElementById(
    "new-tech-fault-form-description-input"
  ).value;
  if (description === null || description === "") {
    showAlert(
      "Missing fault description",
      "Please enter a fault description in the field provided."
    );
    return;
  }

  const faultType = document.getElementById(
    "new-tech-fault-form-type-dropdown"
  ).value;

  var data = {
    customer_id: customerId,
    description: description,
    type: faultType
  };

  try {
    await req.post(url, data);
  } catch (err) {
    showErrorToast(
      "Failed to raise technical fault",
      `There was an error that occurred whilst trying to raise this technical fault. ${err}`
    );

    console.log(err);
    return;
  }

  const tab = document.getElementById("technical-faults-tab");
  if (tab) {
    tab.className = "customer-detail-table-tab disabled-alert-tab";
  }

  await switchToTechnicalFaultsTab(customerId);

  document.getElementById("new-technical-fault-form-parent-div").remove();
}

export async function updateTechnicalFault(faultId, data) {
  try {
    await req.put(`${serverDomain}/api/v1/technical_faults/${faultId}`, data);

    return true;
  } catch (err) {
    showErrorToast(
      "Failed to update technical fault",
      `There was an error that occurred whilst trying to update the technical fault data on the database. ${err}`
    );
    return;
  }
}

export async function resolveTechnicalFault(faultId) {
  try {
    await req.post(
      `${serverDomain}/api/v1/technical_faults/${faultId}/resolve`,
      null
    );

    return true;
  } catch (err) {
    showErrorToast(
      "Failed to resolve technical fault",
      `There was an error that occurred whilst trying to resolve the technical fault data on the database. ${err}`
    );
    return;
  }
}

export async function deleteTechnicalFault(faultId) {
  try {
    await req.del(`${serverDomain}/api/v1/technical_faults/${faultId}`);

    return true;
  } catch (err) {
    showErrorToast(
      "Failed to delete technical fault",
      `There was an error that occurred whilst trying to delete the technical fault data on the database. ${err}`
    );
    return;
  }
}

export async function createTechnicalFaultNote(faultId) {
  var url = `${serverDomain}/api/v1/technical_faults/${faultId}/notes`;

  const notes = document.getElementById(
    "tech-fault-further-detail-notes-input-field"
  ).value;
  if (notes === null || notes === "") {
    return;
  }

  try {
    await req.post(url, { message: notes });
    await listTechnicalFaultNotes(faultId, 0);

    document.getElementById(
      "tech-fault-further-detail-notes-input-field"
    ).value = "";
  } catch (err) {
    showErrorToast(
      "Failed to create technical fault note",
      `There was an error that occurred whilst trying to create this technical fault note. ${err}`
    );

    console.log(err);
    return;
  }
}

export const listTechnicalFaultNotesLimit = 50;
export var listTechnicalFaultNotesOffset = 0;
export var reachedEndOfTechnicalFaultNotes = false;

export function setListTechnicalFaultNotesOffset(newOffset) {
  if (newOffset <= 0) {
    listTechnicalFaultNotesOffset = 0;
  }
  listTechnicalFaultNotesOffset = newOffset;
}

export async function listTechnicalFaultNotes(faultId, offset) {
  var url = `${serverDomain}/api/v1/technical_faults/${faultId}/notes?limit=${listTechnicalFaultNotesLimit}&offset=${offset}`;

  try {
    var table = document.getElementById("tech-fault-notes");

    const notes = await req.get(url, null, "snake");
    if (!notes) {
      table.innerHTML = "";
      return;
    }

    if (offset === 0) {
      table.innerHTML = "";
    }

    for (var i = 0; i < notes.length; i++) {
      const note = notes[i];
      const row = table.insertRow(0);
      row.className = "tech-fault-note-parent-row";
      ReactDOM.render(<TechnicalFaultNote note={note} />, row);
    }

    const container = document.getElementsByClassName(
      "tech-fault-further-detail-tabbed-tbody-container"
    )[0];
    container.scrollTop = container.scrollHeight;

    return;
  } catch (err) {
    showErrorToast(
      "Failed to get technical fault notes",
      `There was an error that occurred whilst trying to list technical fault notes. ${err}`
    );

    console.log(err);
    return;
  }
}

export async function createTechnicalFaultEngineerVisit(faultId) {
  var url = `${serverDomain}/api/v1/technical_faults/${faultId}/engineer_visits`;

  const reference = document.getElementById(
    "new-tech-fault-engineer-visit-reference-input"
  ).value;
  if (reference === null || reference === "") {
    showAlert(
      "Missing engineer appointment reference",
      "Please enter a engineer appointment reference in the field provided."
    );
    return;
  }

  const visitedOn = document.getElementById(
    "new-tech-fault-engineer-visit-visited-on-date-input"
  ).value;
  if (visitedOn === null || visitedOn === "") {
    showAlert(
      "Missing engineer appointment date",
      "Please enter a engineer appointment date in the field provided."
    );
    return;
  }

  var data = {
    visited_on: new Date(visitedOn).toISOString(),
    reference: reference
  };

  try {
    await req.post(url, data);
  } catch (err) {
    showErrorToast(
      "Failed to create technical fault engineer visit",
      `There was an error that occurred whilst trying to create this technical fault engineer visit. ${err}`
    );

    console.log(err);
    return;
  }

  document
    .getElementById("new-tech-fault-engineer-visit-form-parent-div")
    .remove();
}

export const listTechnicalFaultEngineerVisitsLimit = 50;
export var listTechnicalFaultEngineerVisitsOffset = 0;
export var reachedEndOfTechnicalFaultEngineerVisits = false;

export async function listTechnicalFaultEngineerVisits(faultId, offset) {
  var url = `${serverDomain}/api/v1/technical_faults/${faultId}/engineer_visits?limit=${listTechnicalFaultEngineerVisitsLimit}&offset=${offset}`;

  try {
    var table = document.getElementById("tech-fault-engineer-visits-body");

    const visits = await req.get(url, null, "snake");
    if (!visits) {
      table.innerHTML = "";
      return;
    }

    if (offset === 0) {
      table.innerHTML = "";
    }

    for (var i = 0; i < visits.length; i++) {
      const visit = visits[0];
      const row = table.insertRow(-1);
      row.className = "tech-fault-note-parent-row";
      var rowData = [
        visit.reference,
        new Date(visit.visited_on).toLocaleString()
      ];
      rowData.forEach((val, indx) => {
        const cell = row.insertCell(indx);
        cell.textContent = val;
      });
      row.addEventListener("click", async () => {
        const div = document.createElement("div");
        div.id = "tech-fault-engineer-visit-detail-view-parent-div";
        ReactDOM.render(
          <TechnicalFaultEngineerVisitDetailView visit={visit} />,
          div
        );
        document.getElementById("app-content-wrapper").appendChild(div);
      });
    }

    return;
  } catch (err) {
    showErrorToast(
      "Failed to get technical fault notes",
      `There was an error that occurred whilst trying to list technical fault notes. ${err}`
    );

    console.log(err);
    return;
  }
}

export async function updateTechnicalFaultEngineerVisit(faultId, visitId) {
  const reference = document.getElementById(
    "tech-fault-engineer-visit-detail-reference-input"
  ).value;
  if (reference === null || reference === "") {
    showAlert(
      "Missing engineer appointment reference",
      "Please enter a engineer appointment reference in the field provided."
    );
    return;
  }

  const visitedOn = document.getElementById(
    "tech-fault-engineer-visit-detail-visited-on-date-input"
  ).value;
  if (visitedOn === null || visitedOn === "") {
    showAlert(
      "Missing engineer appointment date",
      "Please enter a engineer appointment date in the field provided."
    );
    return;
  }

  var data = {
    reference: reference,
    visited_on: new Date(visitedOn).toISOString()
  };
  const closingNotes = document.getElementById(
    "tech-fault-engineer-visit-detail-closing-notes-input"
  ).value;
  if (closingNotes) {
    data["closing_notes"] = closingNotes;
  }

  try {
    await req.put(
      `${serverDomain}/api/v1/technical_faults/${faultId}/engineer_visits/${visitId}`,
      data
    );

    document
      .getElementById("tech-fault-engineer-visit-detail-view-parent-div")
      .remove();
    await listTechnicalFaultEngineerVisits(faultId, 0);

    return true;
  } catch (err) {
    showErrorToast(
      "Failed to update engineer visit",
      `There was an error that occurred whilst trying to update the engineer visit data on the database. ${err}`
    );
    return;
  }
}
