import React from "react";

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { request } from "../utils/request";
import Screen from "./Screen";

export const SpotifyCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      const searchParams = new URLSearchParams(window.location.search);
      const code = searchParams.get("code");
      console.log(code);
      var h = new Headers({
        "Content-Type": "application/x-www-form-urlencoded",
        Authorization:
          "Basic " +
          Buffer.from(
            "67be3358089f4793aceee85ce6a50780" +
              ":" +
              "ac3233bff31e4c39a927b2e4c733a640"
          ).toString("base64")
      });

      var rawBody = {
        code: code,
        redirect_uri: "https://crm.limeutility.co.uk/spotify/callback",
        grant_type: "authorization_code"
      };

      var formBody = [];
      for (var property in rawBody) {
        var encodedKey = encodeURIComponent(property);
        var encodedValue = encodeURIComponent(rawBody[property]);
        formBody.push(encodedKey + "=" + encodedValue);
      }
      formBody = formBody.join("&");

      const opts = {
        method: "post",
        headers: h,
        body: formBody
      };

      const res = await fetch("https://accounts.spotify.com/api/token", opts);
      const json = await res.json();
      var access_token = json.access_token;
      if (access_token) {
        var today = new Date();
        today.setHours(today.getHours() + 1);
        localStorage.setItem("rscrm_spotify_token_expiry", today.toISOString());
        localStorage.setItem("rscrm_spotify_access_token", access_token);
      }
      window.location = `/outbound-calls`;
    })();
  }, []);

  return (
    <Screen>
      <span>Redirecting...</span>
    </Screen>
  );
};
