import React from "react";
import "../styles/Confirmation.css";

function Confirmation({ title, message, yesAction, noAction }) {
  const dismiss = async () => {
    document.getElementById("confirmation-message").remove();
  };

  return (
    <div className="confirmation-container">
      <h3>{title}</h3>
      <p className="confirmation-message-lbl">{message}</p>
      <div className="confirmation-bottom-row">
        <button
          className="confirmation-no-btn"
          onClick={async () => {
            noAction();
            dismiss();
          }}
        >
          No
        </button>
        <button
          className="confirmation-yes-btn"
          hidden={yesAction === null || yesAction === undefined}
          onClick={async () => {
            yesAction();
            dismiss();
          }}
        >
          Yes
        </button>
      </div>
    </div>
  );
}

export default Confirmation;
