import React from "react";

// Styles
import "../../styles/home/AgentOfTheWeekCard.css";

// Assets
import TrophyIcon from "../../assets/icons/trophy.svg";
import HomeCard from "./HomeCard";

export default function AgentOfTheWeekCard({ team, agent }) {
  return (
    <HomeCard icon={TrophyIcon} header={team + " Agent of the week"}>
      <div className="agent-of-the-week-card">
        <img className="cover-image" src={agent.image} />
        <div className="text-wrapper">
          <span className="title-label">{agent.title}</span>
          <span className="body-label">{agent.body}</span>
        </div>
      </div>
    </HomeCard>
  );
}
