import React from "react";
import PropTypes from "prop-types";

// Styles
import "../../styles/generic/TabViewContainer.css";

export default function TabViewContainer({ id, children }) {
  return (
    <div className="tab-view-container" id={id}>
      {children}
    </div>
  );
}

TabViewContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
};
