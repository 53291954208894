import React from "react";
import "../../styles/home/HomeCard.css";

export default function HomeCard({ icon, header, children }) {
  return (
    <div className="home-card">
      <div className="header-row">
        <div className="icon-wrapper">
          <img src={icon} />
        </div>
        <span className="header-lbl">{header}</span>
      </div>
      <div className="card-wrapper">{children}</div>
    </div>
  );
}
