import React from "react";
// Styles
import "../../styles/generic/WarningToast.css";

// Assets
import WarningIcon from "../../assets/icons/warning.svg";
import CrossIcon from "../../assets/CrossIcon.svg";

export const WarningToast = ({ title, description, onClose }) => {
  return (
    <div id="warning-toast" className="warning-toast">
      <div className="timer-bar" />
      <div className="warning-content-wrapper">
        <img className="warning-icon" src={WarningIcon} />
        <div className="warning-details-wrapper">
          {title && <span className="warning-title-lbl">{title}</span>}
          {description && (
            <span className="warning-description-lbl">{description}</span>
          )}
        </div>
        <button className="close-button" onClick={onClose}>
          <img src={CrossIcon} />
        </button>
      </div>
    </div>
  );
};
