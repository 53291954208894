import React, { useContext, useEffect, useState } from "react";

// Styles
import "../../styles/outbound-calls/calling-session-script/CallingSessionDialOutView.css";
import DestructiveActionButton from "../generic/DestructiveActionButton";

// Views
import PrimaryActionButton from "../generic/PrimaryActionButton";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../utils/LoadingHandler";
import {
  dialOutNumber,
  openArchiveRecordForm,
  openNewCallbackForm,
  openRecordHandoverForm,
  recordNoAnswerAction
} from "../../functions/outbound-calls/calling_session_functions";
import { showErrorToast } from "../../utils/ErrorHandler";

import { SelectedRecordContext } from "../../Main";
import { showConfirmation } from "../../functions/core_functions";
import { DealsheetDataContext } from "./overview/OutboundCallsCallingSessionTab";

const CallingSessionDialOutView = () => {
  const { selectedRecord, setSelectedRecord } = useContext(
    SelectedRecordContext
  );
  const { dealsheetData, setDealsheetData } = useContext(DealsheetDataContext);

  const [currentCallCLI, setCurrentCallCLI] = useState(null);
  const [callbackCompleted, setCallbackCompleted] = useState(false);
  const [handoverRecorded, setHandoverRecorded] = useState(false);

  const [randomNumber, setRandomNumber] = useState(1);

  useEffect(() => {
    setRandomNumber(Math.round(Math.random() * 10));

    const cli = localStorage.getItem("rscrm_current_call_cli");
    if (cli && cli !== "") {
      setCurrentCallCLI(cli);
    }
  }, []);

  const showHandoverConfirmationIfNecessary = postAction => {
    if (!handoverRecorded) {
      showConfirmation(
        "Did a handover take place?",
        "If so, please record the handover so the CRM stats match your real-time outbound stats.",
        async () => {
          return;
        },
        () => {
          // Save to localstorage a flag that the allows skip for current record
          localStorage.setItem("rscrm_skip_handover_for", selectedRecord.cli);
          postAction();
        }
      );
    } else {
      postAction();
    }
  };

  return (
    <div className="calling-session-dial-out-view">
      <div className="customer-details-wrapper">
        <div className="cli-wrapper">
          <span className="cli-lbl">
            {selectedRecord ? selectedRecord.cli : "---"}
          </span>
          <PrimaryActionButton
            id="call-cli-btn"
            title="Call"
            style="bordered"
            onClick={() => {
              window.open(`tel:${selectedRecord.cli}`);
            }}
          />
        </div>
        <span className="name-lbl">
          {selectedRecord ? selectedRecord.person_name : "---"}
        </span>
        <span className="address-lbl">
          {selectedRecord ? selectedRecord.full_address : "---"}
        </span>
      </div>
      <div className="call-action-btns">
        {selectedRecord && currentCallCLI !== selectedRecord.cli && (
          <PrimaryActionButton
            id={"dial-out-btn"}
            title={"Answered"}
            style={"filled"}
            onClick={async () => {
              showLoadingToast();
              const err = await dialOutNumber(selectedRecord.id);
              if (err) {
                showErrorToast(
                  "Failed to record dial attempt on number.",
                  "There was an error that occurred whilst trying to record the dial attempt for this CLI."
                );
              } else {
                localStorage.setItem(
                  "rscrm_current_call_cli",
                  selectedRecord.cli
                );
                setCurrentCallCLI(selectedRecord.cli);
              }
              removeLoadingToast();
            }}
          />
        )}
        <DestructiveActionButton
          id={"no-answer-btn"}
          title={"No Answer"}
          style={"filled"}
          onClick={async () => {
            showLoadingToast();
            const err = await recordNoAnswerAction(selectedRecord.id);
            if (err) {
              showErrorToast(
                "Failed to record no answer",
                "It is important you take note of the number and pass onto technical support for a manual update."
              );
              removeLoadingToast();
            } else {
              showLoadingToast();
              localStorage.removeItem("rscrm_current_call_cli");
              setCurrentCallCLI(null);
              setSelectedRecord(null);
              setCallbackCompleted(false);
              setDealsheetData({});
              localStorage.removeItem("rscrm_dealsheet_cache");
            }
          }}
        />
        {!(selectedRecord && currentCallCLI !== selectedRecord.cli) && (
          <PrimaryActionButton
            id={"record-handover-btn"}
            title={"Handover"}
            style={"blurred"}
            onClick={async () => {
              openRecordHandoverForm(selectedRecord, () => {
                setHandoverRecorded(true);
              });
            }}
          />
        )}
        {!(selectedRecord && currentCallCLI !== selectedRecord.cli) &&
          !callbackCompleted && (
            <DestructiveActionButton
              id={"archive-record-btn"}
              title={"Archive"}
              style={"filled"}
              onClick={() => {
                const postAction = () => {
                  openArchiveRecordForm(selectedRecord, () => {
                    showLoadingToast();
                    localStorage.removeItem("rscrm_current_call_cli");
                    setCurrentCallCLI(null);
                    setSelectedRecord(null);
                    setCallbackCompleted(false);
                    setDealsheetData({});
                    localStorage.removeItem("rscrm_dealsheet_cache");
                  });
                };
                postAction();
                // showHandoverConfirmationIfNecessary(postAction);
              }}
            />
          )}
        {!(selectedRecord && currentCallCLI !== selectedRecord.cli) &&
          !callbackCompleted && (
            <PrimaryActionButton
              id={"callback-btn"}
              title={"Callback"}
              style={"blurred"}
              onClick={async () => {
                const postAction = () => {
                  openNewCallbackForm(selectedRecord, () => {
                    setCallbackCompleted(true);
                  });
                };
                postAction();
                // showHandoverConfirmationIfNecessary(postAction);
              }}
            />
          )}
        {callbackCompleted && (
          <PrimaryActionButton
            id={"next-record-btn"}
            title={"Get Next Record"}
            style={"blurred"}
            onClick={async () => {
              showLoadingToast();
              localStorage.removeItem("rscrm_current_call_cli");
              setCurrentCallCLI(null);
              setSelectedRecord(null);
              setCallbackCompleted(false);
              setDealsheetData({});
              localStorage.removeItem("rscrm_dealsheet_cache");
            }}
          />
        )}
      </div>
    </div>
  );
};

export default CallingSessionDialOutView;
