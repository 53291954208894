import React, { useEffect } from "react";

import "../styles/Customers.css";

import Screen from "./Screen.js";
import SearchBar from "../views/SearchBar.js";
import { Table } from "../views/generic/Table.js";

function Customers() {
  useEffect(() => {
    (async () => {})();
  });

  return (
    <Screen>
      <div className="customers-screen">
        <SearchBar
          id={"customer-search-bar"}
          placeholder={"Search customers by name, account number or CLI..."}
        />
        <Table
          headers={["Account #", "Customer Name", "CLI", "XXX"]}
          rowData={[
            {
              account_number: "PTNXXXXX",
              customer_name: "XX",
              cli: "XX",
              unknown: "XX"
            }
          ]}
          rowClick={async () => {
            window.location = "/customers/1";
          }}
        />
      </div>
    </Screen>
  );
}

export default Customers;
