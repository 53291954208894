import React, { useEffect } from "react";

// Styles
import "../../styles/generic/MultiLineInput.css";

export const MultiLineInput = ({
  id,
  required = false,
  title,
  placeholder,
  defaultValue,
  onChange = () => {}
}) => {
  useEffect(() => {
    (async () => {
      const el = document.getElementById(id);
      if (el) {
        el.addEventListener("wheel", event => {
          event.preventDefault();
        });
      }
    })();
  }, []);

  return (
    <div className="multi-line-input-wrapper">
      {title && (
        <span className="header-lbl">
          {title}
          {required && <p>*</p>}
        </span>
      )}

      <textarea
        id={id}
        title={title}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        onInput={onChange}
      ></textarea>
    </div>
  );
};
