import React from "react";
import ReactDOM from "react-dom";
import {
  serverDomain,
  getSelectedCompany,
  switchedUserID
} from "../utils/constants.js";
import {
  currentUser,
  hideLoadingOverlay,
  showAlert,
  showLoadingOverlay
} from "../functions/core_functions.js";
import { showErrorToast } from "../utils/ErrorHandler.js";
import OpenRecordTableRowButton from "../views/elements/OpenRecordTableRowButton.js";
import * as req from "../utils/request";
import NewTimeOffForm from "../views/account/NewTimeOffForm.js";
import TimeOffRequestsView from "../views/account/TimeOffRequestsView.js";

export async function getUsers() {
  try {
    const users = await req.get(
      `${serverDomain}/api/v1/users?select=id,first_name,last_name,work_email,teams,roles`,
      null,
      "snake"
    );
    const userRoles = await req.get(
      `${serverDomain}/api/v1/user_roles`,
      null,
      "snake"
    );
    const userTeams = await req.get(
      `${serverDomain}/api/v1/user_teams`,
      null,
      "snake"
    );

    const table = document.getElementById("users-data-table-body");
    if (!table) {
      return;
    }
    table.innerHTML = "";

    users.forEach(async (data, index) => {
      const row = table.insertRow(index);
      row.className = "users-data-table-row";

      var teamNames = [];
      if (data.teams && data.teams.length !== 0) {
        for (const team of data.teams) {
          const teamObj = await userTeams.find(t => t.id === team);
          if (teamObj) {
            teamNames.push(teamObj.name);
          }
        }
      }

      var roleNames = [];
      if (data.roles && data.roles.length !== 0) {
        for (const role of data.roles) {
          const roleObj = await userRoles.find(r => r.id === role);
          if (roleObj) {
            roleNames.push(roleObj.name);
          }
        }
      }

      const rowData = [
        data.first_name,
        data.last_name,
        data.work_email,
        roleNames.join(", "),
        teamNames.join(", ")
      ];

      rowData.forEach((val, indx) => {
        const cell = row.insertCell(indx);
        cell.textContent = val;
        if (indx === rowData.length - 1) {
          const div = document.createElement("div");
          div.className = "open-record-button-container";
          ReactDOM.render(
            <OpenRecordTableRowButton
              onClick={async () => {
                window.location.hash = `/user-detail/${data.id}`;
                return;
              }}
            />,
            div
          );
          cell.appendChild(div);
        }
      });
    });
  } catch (err) {
    console.log(err);
  }
}

export async function getUser(userId) {
  try {
    const user = await req.get(
      `${serverDomain}/api/v1/users/${userId}`,
      null,
      "snake"
    );
    return user;
  } catch (err) {
    console.log(err);
  }
}

export async function getUserActivityStatus(userId) {
  try {
    const user = await req.get(
      `${serverDomain}/api/v1/users/${userId}?select=activity_status`,
      null,
      "snake"
    );
    return user.activity_status;
  } catch (err) {
    console.log(err);
    return;
  }
}

export async function updateUserActivityStatus(userId, status) {
  try {
    console.log(`${userId} : ${status}`);
    await req.put(`${serverDomain}/api/v1/users/${userId}`, {
      activity_status: status
    });
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function displayUserDetail(userId) {
  try {
    const user = await getUser(userId);
    if (!user) {
      window.location.hash = "/users";
      return;
    }

    document.getElementById("user-detail-first-name").value = user.first_name;
    document.getElementById("user-detail-last-name").value = user.last_name;
    document.getElementById("user-detail-start-date").value = user.start_date
      ? new Date(user.start_date)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join("-")
      : "";
    document.getElementById("user-detail-birthdate").value = user.birthdate
      ? new Date(user.birthdate)
          .toLocaleDateString()
          .split("/")
          .reverse()
          .join("-")
      : "";
    document.getElementById("user-detail-extension").value = user.extension;
    document.getElementById("user-detail-work-mobile").value = user.work_mobile;
    document.getElementById("user-detail-work-email").value = user.work_email;
    document.getElementById("user-detail-personal-email").value =
      user.personal_email;
    document.getElementById("user-detail-personal-mobile").value =
      user.personal_mobile;

    if (user.profile_pic) {
      document.getElementById("user-detail-pp").src = user.profile_pic;
    }

    const users = await req.get(
      `${serverDomain}/api/v1/users?select=id,first_name,last_name`,
      null,
      "snake"
    );
    if (!users) {
      window.location.hash = "/users";
      return;
    }
    document.getElementById("user-managers-table").innerHTML = "";
    users.forEach((managerUser, indx) => {
      if (managerUser.id !== user.id) {
        const row = document.createElement("tr");
        const nameLabel = document.createElement("span");
        nameLabel.innerHTML = `${managerUser.first_name} ${managerUser.last_name}`;
        const checkbox = document.createElement("input");
        checkbox.type = "checkbox";
        checkbox.value = managerUser.id;
        if (user.managers && user.managers.includes(managerUser.id)) {
          checkbox.checked = true;
        }
        row.appendChild(nameLabel);
        row.appendChild(checkbox);
        document.getElementById("user-managers-table").appendChild(row);
      }
    });

    const userTeams = await req.get(
      `${serverDomain}/api/v1/user_teams`,
      null,
      "snake"
    );
    if (!userTeams) {
      window.location.hash = "/users";
      return;
    }
    document.getElementById("user-teams-table").innerHTML = "";
    userTeams.forEach((team, indx) => {
      const row = document.createElement("tr");
      const nameLabel = document.createElement("span");
      nameLabel.innerHTML = `${team.name}`;
      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.value = team.id;
      if (user.teams && user.teams.includes(team.id)) {
        checkbox.checked = true;
      }
      row.appendChild(nameLabel);
      row.appendChild(checkbox);
      document.getElementById("user-teams-table").appendChild(row);
    });

    const userRoles = await req.get(
      `${serverDomain}/api/v1/user_roles`,
      null,
      "snake"
    );
    if (!userRoles) {
      window.location.hash = "/users";
      return;
    }
    document.getElementById("user-roles-table").innerHTML = "";
    userRoles.forEach((role, indx) => {
      const row = document.createElement("tr");
      const nameLabel = document.createElement("span");
      nameLabel.innerHTML = `${role.name}`;
      const checkbox = document.createElement("input");
      checkbox.type = "checkbox";
      checkbox.value = role.id;
      if (user.roles && user.roles.includes(role.id)) {
        checkbox.checked = true;
      }
      row.appendChild(nameLabel);
      row.appendChild(checkbox);
      document.getElementById("user-roles-table").appendChild(row);
    });
  } catch (err) {
    console.log(err);
    window.location.hash = "/users";
  }
}

export async function discardChanges(userId) {
  const r = window.confirm(
    "Are you sure you wish to discard changes? All unsaved changes will be lost."
  );
  if (r) {
    displayUserDetail(userId);
  }
}

export async function saveChanges(userId) {
  try {
    const firstName = document.getElementById("user-detail-first-name").value;
    const lastName = document.getElementById("user-detail-last-name").value;
    const startDate = document.getElementById("user-detail-start-date").value;
    const birthdate = document.getElementById("user-detail-birthdate").value;
    const extension = document.getElementById("user-detail-extension").value;
    const workMobile = document.getElementById("user-detail-work-mobile").value;
    const workEmail = document.getElementById("user-detail-work-email").value;
    const personalEmail = document.getElementById(
      "user-detail-personal-email"
    ).value;
    const personalMobile = document.getElementById(
      "user-detail-personal-mobile"
    ).value;
    var managers = [];
    var teams = [];
    var roles = [];

    const teamBoxes = document
      .getElementById("user-teams-table")
      .querySelectorAll("input");
    const managerBoxes = document
      .getElementById("user-managers-table")
      .querySelectorAll("input");
    const roleBoxes = document
      .getElementById("user-roles-table")
      .querySelectorAll("input");

    teamBoxes.forEach((box, idx) => {
      if (box.checked && box.value) {
        teams.push(parseInt(box.value));
      }
    });
    managerBoxes.forEach((box, idx) => {
      if (box.checked && box.value) {
        managers.push(parseInt(box.value));
      }
    });
    roleBoxes.forEach((box, idx) => {
      if (box.checked && box.value) {
        roles.push(parseInt(box.value));
      }
    });

    const data = {
      first_name: firstName,
      last_name: lastName,
      extension: extension,
      work_mobile: workMobile,
      work_email: workEmail,
      personal_mobile: personalMobile,
      personal_email: personalEmail,
      managers: managers,
      teams: teams,
      roles: roles
    };
    if (startDate !== "" && startDate !== null) {
      data["start_date"] = new Date(startDate).toISOString();
    }
    if (birthdate !== "" && birthdate !== null) {
      data["birthdate"] = new Date(birthdate).toISOString();
    }

    await req.put(`${serverDomain}/api/v1/users/${userId}`, data);

    showAlert(
      "User changes saved.",
      "Ask the user to refresh their browser session in order for the changes to be activated."
    );
  } catch (err) {
    console.log(err);
    window.alert("Failed to save user changes. Please try again.");
    return;
  }
}

export async function deleteUser(history, userId) {
  const r = window.confirm("Are you sure you wish to delete this user?");
  if (r) {
    try {
      await req.del(`${serverDomain}/api/v1/users/${userId}`);
      history.push("/users");
      return;
    } catch (err) {
      console.log(err);
      window.alert("Failed to delete user. Please try again later.");
      return;
    }
  }
}

export async function createUser() {
  const firstName = document.getElementById("add-user-first-name-input").value;
  const lastName = document.getElementById("add-user-last-name-input").value;
  const workEmail = document.getElementById("add-user-work-email-input").value;
  const password = document.getElementById("add-user-password-input").value;

  if (!firstName || firstName === "") {
    window.alert("Missing first name.");
    return;
  } else if (!lastName || lastName === "") {
    window.alert("Missing last name.");
    return;
  } else if (!workEmail || workEmail === "") {
    window.alert("Missing work email.");
    return;
  } else if (!password || password === "") {
    window.alert("Missing password.");
    return;
  }

  try {
    const data = await req.post(`${serverDomain}/auth/register`, {
      first_name: firstName,
      last_name: lastName,
      work_email: workEmail,
      password: password
    });
    window.location.reload();
    return;
  } catch (err) {
    console.log(err);
    window.alert("Failed to create user. Please try again later.");
    return;
  }
}

export async function resetPassword(id) {
  try {
    const newPassword = document.getElementById(
      "reset-password-new-input"
    ).value;
    const confirmPassword = document.getElementById(
      "reset-password-confirm-input"
    ).value;

    if (newPassword === "") {
      window.alert("Missing new password.");
      return;
    } else if (confirmPassword === "") {
      window.alert("Please confirm your new password.");
      return;
    } else if (newPassword !== confirmPassword) {
      window.alert("Passwords non-matching. Please check and try again.");
      return;
    }

    const data = await req.post(`${serverDomain}/auth/reset_password`, {
      id: id,
      new_password: newPassword
    });
    window.alert("Password has been successfully reset.");
    document.getElementById("reset-password-popup-form").style.display = "none";
    return;
  } catch (err) {
    console.log(err);
    window.alert("Failed to reset password for user. Please try again later.");
    return;
  }
}

export async function getAttendancePercentage(userID) {
  try {
    const attendance = await req.get(
      `${serverDomain}/api/v1/users/${userID}/attendance`
    );
    return attendance;
  } catch (err) {
    showErrorToast(
      "Failed to get user attendance",
      `There was an error that occured whilst trying to calculate this month's attendance. ${err}`
    );
    return null;
  }
}

export async function getHolidayAllowance(userID) {
  try {
    const allowance = await req.get(
      `${serverDomain}/api/v1/users/${userID}/holiday_allowance`,
      null,
      "snake"
    );
    return allowance;
  } catch (err) {
    showErrorToast(
      "Failed to get user allowance",
      `There was an error that occured whilst trying to calculate this year's holiday allowance. ${err}`
    );
    return null;
  }
}

export function openNewTimeOffForm() {
  const newTimeOffFormContainer = document.createElement("div");
  newTimeOffFormContainer.className = "new-time-off-form-overlay";
  const closeAction = async () => {
    const form = document.getElementsByClassName("new-time-off-form")[0];
    const overlay = document.getElementsByClassName(
      "new-time-off-form-overlay"
    )[0];
    const container = document.getElementsByClassName(
      "new-time-off-form-container"
    )[0];

    form.classList.add("removing");
    overlay.classList.add("removing");

    setTimeout(function () {
      container.remove();
    }, 250);
  };
  ReactDOM.render(
    <NewTimeOffForm
      closeAction={closeAction}
      saveAction={async () => {
        const startDate = document.getElementById(
          "new-time-off-start-date"
        ).value;
        const endDate = document.getElementById("new-time-off-end-date").value;
        const reason = document.getElementById("new-time-off-notes").value;

        if (startDate === "") {
          showAlert("Cannot save request", "Please enter a valid start date.");
        } else if (endDate === "") {
          showAlert("Cannot save request", "Please enter a valid end date.");
        }

        showLoadingOverlay();

        try {
          await req.post(
            `${serverDomain}/api/v1/users/${switchedUserID}/holiday_requests`,
            {
              start_date: new Date(startDate).toISOString(),
              end_date: new Date(endDate).toISOString(),
              reason: reason
            }
          );
          hideLoadingOverlay();
          closeAction();
        } catch (err) {
          showErrorToast(
            "Failed to save request",
            "There was an error that occured whilst trying to save your time off request."
          );
        }
      }}
    />,
    newTimeOffFormContainer
  );
  document
    .getElementById("app-content-wrapper")
    .appendChild(newTimeOffFormContainer);
}

export function openPendingTimeOffRequestsView(admin) {
  const newTimeOffFormContainer = document.createElement("div");
  newTimeOffFormContainer.className = "time-off-requests-view-overlay";
  const closeAction = async () => {
    const form = document.getElementsByClassName("time-off-requests-view")[0];
    const overlay = document.getElementsByClassName(
      "time-off-requests-view-overlay"
    )[0];
    const container = document.getElementsByClassName(
      "time-off-requests-view-container"
    )[0];

    form.classList.add("removing");
    overlay.classList.add("removing");

    setTimeout(function () {
      container.remove();
    }, 250);
  };
  ReactDOM.render(
    <TimeOffRequestsView admin={admin} closeAction={closeAction} />,
    newTimeOffFormContainer
  );
  document
    .getElementById("app-content-wrapper")
    .appendChild(newTimeOffFormContainer);
}

export async function getTimeOffRequests() {
  try {
    const timeOffLogs = await req.get(
      `${serverDomain}/api/v1/users/${
        switchedUserID || currentUser.id
      }/holiday_requests`,
      null,
      "snake"
    );
    return timeOffLogs;
  } catch (err) {
    showErrorToast(
      "Failed to get time off logs",
      `There was an error that occured whilst trying to get your time off requests. ${err}`
    );
    return null;
  }
}

export async function getAllPendingTimeOffRequests() {
  try {
    const timeOffLogs = await req.get(
      `${serverDomain}/api/v1/pending_holiday_requests`,
      null,
      "snake"
    );
    return timeOffLogs;
  } catch (err) {
    showErrorToast(
      "Failed to get time off logs",
      `There was an error that occured whilst trying to get your time off requests. ${err}`
    );
    return null;
  }
}

export async function deleteTimeOffRequest(id) {
  try {
    await req.del(
      `${serverDomain}/api/v1/users/${
        switchedUserID || currentUser.id
      }/holiday_requests/${id}`
    );
    return null;
  } catch (err) {
    showErrorToast(
      "Failed to delete time off log",
      `There was an error that occured whilst trying to delete your time off request. ${err}`
    );
    return err;
  }
}

export async function saveTimeOffRequest(id, reason) {
  try {
    showLoadingOverlay();
    await req.put(
      `${serverDomain}/api/v1/users/${
        switchedUserID || currentUser.id
      }/holiday_requests/${id}`,
      { reason: reason }
    );
    hideLoadingOverlay();
    showAlert(
      "Request Updated",
      "Your time off request has been successfully updated."
    );
    return null;
  } catch (err) {
    showErrorToast(
      "Failed to update time off log",
      `There was an error that occured whilst trying to update your time off request. ${err}`
    );
    return err;
  }
}

export async function savePendingTimeOffRequest(id, status) {
  try {
    showLoadingOverlay();
    await req.put(`${serverDomain}/api/v1/pending_holiday_requests/${id}`, {
      status: status
    });
    hideLoadingOverlay();
    showAlert(
      "Request Updated",
      "This time off request has been successfully updated."
    );
    return null;
  } catch (err) {
    showErrorToast(
      "Failed to update time off log",
      `There was an error that occured whilst trying to update this time off request. ${err}`
    );
    return err;
  }
}
