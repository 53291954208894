import React, { useContext, useEffect, useState } from "react";

import "../../styles/outbound-calls/RecordHandoverForm.css";
import Modal, {
  ModalContent,
  ModalHeader,
  ModalInputGrid,
  ModalRow,
  ModalSection,
  closeModal
} from "../generic/modal/Modal";
import {
  getCloserAgents,
  recordHandover
} from "../../functions/outbound-calls/calling_session_functions";
import { showErrorToast } from "../../utils/ErrorHandler";
import { showSuccessToast } from "../../utils/SuccessHandler";
import DefaultProfileImage from "../../assets/DefaultProfileImage.svg";
import { SelectedRecordContext } from "../../Main";

export default function RecordHandoverForm({ record, onCompletion }) {
  const [agents, setAgents] = useState([]);
  useEffect(() => {
    (async () => {
      const [data, err] = await getCloserAgents();
      if (err) {
        showErrorToast(
          "Failed to get handover agents",
          "There was an issue that occurred whilst trying to fetch the list of handover agents."
        );
        return;
      }
      setAgents(data);
    })();
  }, []);

  return (
    <Modal id={"record-handover-form"}>
      <ModalHeader title={"Record Handover"} />
      <ModalContent>
        <ModalSection>
          <ModalRow>
            <span>
              Select the closing agent that is taking the handover from you.
            </span>
          </ModalRow>
          <ModalInputGrid>
            {agents &&
              agents.map(agent => {
                return (
                  <SelectAgentButton
                    agent={agent}
                    saveHandover={async () => {
                      const err = await recordHandover(record, agent.id);
                      if (err) {
                        showErrorToast(
                          "Failed to record handover",
                          "There was an issue that occurred when recording this handover. Please try again later or contact administrator."
                        );
                        return;
                      }
                      onCompletion();
                      closeModal();
                      localStorage.setItem(
                        "rscrm_skip_handover_for",
                        record.cli
                      );
                      showSuccessToast("Successfully recorded handover.");
                    }}
                  />
                );
              })}
          </ModalInputGrid>
        </ModalSection>
      </ModalContent>
    </Modal>
  );
}

const SelectAgentButton = ({ agent, saveHandover }) => {
  return (
    <button className="select-handover-agent-btn" onClick={saveHandover}>
      <img src={agent.profile_pic || DefaultProfileImage} />
      <span>
        {agent.first_name} {agent.last_name}
      </span>
    </button>
  );
};
