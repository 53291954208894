import { serverDomain } from "../../utils/constants";
import * as request from "../../utils/request";

export async function getTotalDealsCount(startDate, endDate, userID) {
  try {
    const count = await request.get(
      `${serverDomain}/api/v1/stats/deals_count?start_date=${startDate}&end_date=${endDate}&user_id=${userID}`,
      null,
      "snake"
    );
    return [count, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function getTotalClosesCount(startDate, endDate, userID) {
  try {
    const count = await request.get(
      `${serverDomain}/api/v1/stats/closes_count?start_date=${startDate}&end_date=${endDate}&user_id=${userID}`,
      null,
      "snake"
    );
    return [count, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function getTotalHandoversCount(startDate, endDate, userID) {
  try {
    const count = await request.get(
      `${serverDomain}/api/v1/stats/handovers_count?start_date=${startDate}&end_date=${endDate}&user_id=${userID}`,
      null,
      "snake"
    );
    return [count, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function getTotalYIADDCount(startDate, endDate, userID) {
  try {
    const count = await request.get(
      `${serverDomain}/api/v1/stats/yia_dd_count?start_date=${startDate}&end_date=${endDate}&user_id=${userID}`,
      null,
      "snake"
    );
    return [count, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function getTotalEstimatedConnectionsCount(
  startDate,
  endDate,
  userID
) {
  try {
    const count = await request.get(
      `${serverDomain}/api/v1/stats/estimated_connections?start_date=${startDate}&end_date=${endDate}&user_id=${userID}`,
      null,
      "snake"
    );
    return [count, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function getTotalEstimatedDialsCount(startDate, endDate, userID) {
  try {
    const count = await request.get(
      `${serverDomain}/api/v1/stats/estimated_dials?start_date=${startDate}&end_date=${endDate}&user_id=${userID}`,
      null,
      "snake"
    );
    return [count, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function checkIfUserIsPodManager(userID) {
  try {
    const isManager = await request.get(
      `${serverDomain}/api/v1/users/${userID}/pod_manager`,
      null,
      "snake"
    );
    return [isManager, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function sendPersonalStatsSMS(startDate, endDate, userID) {
  try {
    await request.post(
      `${serverDomain}/api/v1/stats/send_sales_personal_sms?start_date=${startDate}&end_date=${endDate}&user_id=${userID}`,
      null
    );
    return null;
  } catch (err) {
    return err;
  }
}

export async function sendPodStatsSMS(startDate, endDate, userID) {
  try {
    await request.post(
      `${serverDomain}/api/v1/stats/send_sales_pod_sms?start_date=${startDate}&end_date=${endDate}&user_id=${userID}`,
      null
    );
    return null;
  } catch (err) {
    return err;
  }
}

export async function getSalesStats() {
  try {
    const count = await request.get(
      `${serverDomain}/api/v1/sales_stats`,
      null,
      "snake"
    );
    return [count, null];
  } catch (err) {
    return [null, err];
  }
}
