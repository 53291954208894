import React, { useEffect } from "react";

import "../../styles/generic/TabViewBar.css";

const TabViewBar = ({ selectedTab = null, tabs, onSwitch }) => {
  return (
    <div className="tab-view-bar">
      {tabs.map(tab => {
        const className = `tab-view-btn ${
          selectedTab === tab.id ? "selected" : ""
        }`;
        return (
          <button
            className={className}
            id={tab.id}
            onClick={() => {
              onSwitch(tab.id);
            }}
          >
            {tab.display_text}
          </button>
        );
      })}
    </div>
  );
};

export default TabViewBar;
