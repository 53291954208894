// Libraries
import React from "react";

// Assets
import PencilIcon from "../../assets/interface-icons/attribution-pencil.svg";

// Styles
import "../../styles/generic/EditButton.css";

const EditButton = ({ onClick = () => {} }) => {
  return (
    <button className="edit-btn" onClick={onClick}>
      <img src={PencilIcon} />
    </button>
  );
};

export default EditButton;
