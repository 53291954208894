import React from "react";
import PropTypes from "prop-types";
import "../../../styles/generic/Modal.css";
import CrossIcon from "../../../assets/CrossIcon.svg";

export default function Modal({
  id,
  children,
  changePage = () => {},
  closableFromBackground = true
}) {
  return (
    <div
      className="modal-wrapper"
      id={id}
      onClick={async () => {
        if (closableFromBackground) {
          changePage();
          closeModal();
        }
      }}
    >
      <div
        className="modal-body-wrapper"
        id={id}
        onClick={e => e.stopPropagation()}
      >
        <div className="modal-body">{children}</div>
      </div>
    </div>
  );
}

Modal.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
};

export function closeModal() {
  const overlay = document.getElementsByClassName("modal-wrapper")[0];
  const content = document.getElementsByClassName("modal-body-wrapper")[0];
  overlay.classList.add("removing");
  content.classList.add("removing");

  setTimeout(function () {
    const divWrapper = document.getElementById("modal-div-wrapper");
    if (divWrapper) {
      divWrapper.remove();
    }
  }, 250);
}

export function ModalHeader({ title, changePage = () => {} }) {
  return (
    <div className="modal-header">
      <span>{title}</span>
      <div
        className="close-btn"
        onClick={async () => {
          changePage();
          closeModal();
        }}
      >
        <img src={CrossIcon} />
      </div>
    </div>
  );
}

export function ModalContent({ children }) {
  return <div className="modal-content">{children}</div>;
}
ModalContent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
};

const ModalTitle = ({ title }) => {
  return <span className="modal-section-title-lbl">{title}</span>;
};

export function ModalSection({ title, children }) {
  return (
    <div className="modal-section">
      {title && title !== "" && <ModalTitle title={title} />}
      {children}
    </div>
  );
}

ModalSection.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ]),
  title: PropTypes.string
};

export function ModalRow({ children }) {
  return <div className="modal-row">{children}</div>;
}

ModalRow.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
};

export function ModalFooter({ children }) {
  return <div className="modal-footer">{children}</div>;
}

ModalFooter.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
};

export function ModalInputGrid({ children }) {
  return <div className="modal-input-grid">{children}</div>;
}

ModalInputGrid.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
};

export function ModalRadio({
  id,
  name,
  img = null,
  title,
  value,
  defaultSelected
}) {
  return (
    <div className="modal-radio-wrapper">
      <input
        type="radio"
        name={name}
        id={id}
        value={value}
        defaultChecked={defaultSelected}
      />
      <label htmlFor={id} className="option">
        {img !== null && <img src={img} />}
        <span>{title}</span>
        <div className="dot"></div>
      </label>
    </div>
  );
}

export function ModalSubactionButton({ icon, title, onClick }) {
  return (
    <button className="modal-subaction-btn" onClick={onClick}>
      {(() => {
        if (icon) {
          return <img src={icon} />;
        }
      })()}
      {(() => {
        if (title) {
          return <span>{title}</span>;
        }
      })()}
    </button>
  );
}

export function ModalSubactionGroup({ buttonAttrs }) {
  return (
    <div className="modal-subaction-group">
      {buttonAttrs.map((button, index) =>
        (() => {
          return (
            <div className="sub-button">
              <ModalSubactionButton
                icon={button.icon}
                title={button.title}
                onClick={button.onClick}
              />
              <div className="sep" />
            </div>
          );
        })()
      )}
    </div>
  );
}
