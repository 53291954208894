// Libraries
import React, { useEffect, useState } from "react";

// Styles
import "../styles/Customers/CustomerDetail.css";

// Views
import { CustomerBillingTab } from "../views/Customers/CustomerBillingTab";
import { CustomerOverviewTab } from "../views/Customers/CustomerOverviewTab";
import { CustomerTariffPackagesTab } from "../views/Customers/CustomerTariffPackagesTab";
import PrimaryActionButton from "../views/generic/PrimaryActionButton";
import TabViewBar from "../views/generic/TabViewBar";
import TabViewContainer from "../views/generic/TabViewContainer";
import Screen from "./Screen";
import EditButton from "../views/generic/EditButton";

// Assets
import PhoneIcon from "../assets/interface-icons/call-outgoing.svg";
import SMSIcon from "../assets/interface-icons/comment.svg";
import MailIcon from "../assets/interface-icons/envelope-open-text.svg";
import EmailIcon from "../assets/interface-icons/at.svg";
import PaymentIcon from "../assets/interface-icons/pound.svg";
import LeftArrow from "../assets/interface-icons/left-arrow.svg";
import RightArrow from "../assets/interface-icons/right-arrow.svg";
import CustomerLineTestsTab from "../views/Customers/CustomerLineTestsTab";
import ChangeAddressForm from "../views/Customers/ChangeAddressForm";
import { createRoot } from "react-dom/client";
import SendEmailForm from "../views/Customers/SendEmailForm";
import SendLetterForm from "../views/Customers/SendLetterForm";
import SendSMSForm from "../views/Customers/SendSMSForm";
import TakePaymentForm from "../views/Customers/TakePaymentForm";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";

const stripePromise = loadStripe(
  "pk_test_51IEuiZA1ceQolqyGe2bNjF7Y8JFKBCJlqaN3dWBjkZdOrhRVjdi4XEXojwrqVVoUIKLDOpNJDDRQe73sWUaecpgO00VFZnuLEg"
);

function CustomerDetail() {
  const [selectedTabView, setSelectedTabView] = useState("overview");

  useEffect(() => {
    (async () => {})();
  });

  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    // Create PaymentIntent as soon as the page loads
    fetch("http://localhost:8080/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ items: [{ id: "xl-tshirt" }] })
    })
      .then(res => res.json())
      .then(data => setClientSecret(data.clientSecret));
  }, []);

  const appearance = {
    theme: "stripe"
  };
  const options = {
    clientSecret,
    appearance
  };

  return (
    <Screen>
      <div className="customer-detail-screen">
        <div className="customer-info-sidebar">
          <div className="primary-info-header">
            <span id="customer-name-lbl">Mr John Doe</span>
            <span id="account-id-cli-lbl">PTNXXXXXX · 0123456789</span>
          </div>
          <div className="account-alerts-wrapper">
            <AccountAlertCard
              header={"Missed Direct Debit TEST"}
              description={
                "The direct debit instruction scheduled for June was returned as unpaid. TEST"
              }
            />
            <div className="buttons-wrapper">
              <AccountAlertControlButton icon={LeftArrow} />
              <AccountAlertControlButton icon={RightArrow} />
            </div>
          </div>
          <div className="comm-actions-bar-wrapper">
            <PrimaryActionButton
              id={"call-button"}
              style="blurred"
              icon={PhoneIcon}
            />
            <PrimaryActionButton
              id={"sms-button"}
              style="blurred"
              icon={SMSIcon}
              onClick={() => {
                const div = document.createElement("div");
                div.id = "modal-div-wrapper";
                document.getElementById("app-content-wrapper").appendChild(div);
                const modal = <SendSMSForm />;
                const root = createRoot(
                  document.getElementById("modal-div-wrapper")
                );
                root.render(modal);
              }}
            />
            <PrimaryActionButton
              id={"mail-button"}
              style="blurred"
              icon={MailIcon}
              onClick={() => {
                const div = document.createElement("div");
                div.id = "modal-div-wrapper";
                document.getElementById("app-content-wrapper").appendChild(div);
                const modal = <SendLetterForm />;
                const root = createRoot(
                  document.getElementById("modal-div-wrapper")
                );
                root.render(modal);
              }}
            />
            <PrimaryActionButton
              id={"email-button"}
              style="blurred"
              icon={EmailIcon}
              onClick={() => {
                const div = document.createElement("div");
                div.id = "modal-div-wrapper";
                document.getElementById("app-content-wrapper").appendChild(div);
                const modal = <SendEmailForm />;
                const root = createRoot(
                  document.getElementById("modal-div-wrapper")
                );
                root.render(modal);
              }}
            />
            <PrimaryActionButton
              id={"payment-button"}
              style="blurred"
              icon={PaymentIcon}
              onClick={() => {
                if (clientSecret) {
                  const div = document.createElement("div");
                  div.id = "modal-div-wrapper";
                  document
                    .getElementById("app-content-wrapper")
                    .appendChild(div);
                  const modal = (
                    <Elements options={options} stripe={stripePromise}>
                      <TakePaymentForm />
                    </Elements>
                  );
                  const root = createRoot(
                    document.getElementById("modal-div-wrapper")
                  );
                  root.render(modal);
                }
              }}
            />
          </div>
          <div className="address-details-wrapper">
            <div className="text-wrapper">
              <span className="header-lbl">Address</span>
              <span className="value-lbl">Address Line 1</span>
              <span className="value-lbl">Address Line 2</span>
              <span className="value-lbl">Town/City</span>
              <span className="value-lbl">County, Postcode</span>
            </div>
            <EditButton
              onClick={async () => {
                const div = document.createElement("div");
                div.id = "modal-div-wrapper";
                document.getElementById("app-content-wrapper").appendChild(div);
                const modal = <ChangeAddressForm />;
                const root = createRoot(
                  document.getElementById("modal-div-wrapper")
                );
                root.render(modal);
              }}
            />
          </div>
          <div className="overview-wrapper">
            <span className="header-lbl">Overview</span>
            <div className="grid-wrapper">
              <OverviewStatCard header={"Statistic 1"} value={"123"} />
              <OverviewStatCard header={"Statistic 2"} value={"123"} />
              <OverviewStatCard header={"Statistic 3"} value={"123"} />
              <OverviewStatCard header={"Statistic 4"} value={"123"} />
            </div>
          </div>
        </div>

        <div className="main-content-wrapper">
          <TabViewBar
            selectedTab={selectedTabView}
            tabs={[
              { id: "overview", display_text: "Overview" },
              { id: "tariff_packages", display_text: "Tariff & Packages" },
              { id: "billing", display_text: "Billing" },
              { id: "line_tests", display_text: "Line Tests" }
            ]}
            onSwitch={tab => {
              setSelectedTabView(tab);
            }}
          />
          <TabViewContainer id="customer-detail-tab-view">
            {selectedTabView === "overview" && <CustomerOverviewTab />}
            {selectedTabView === "tariff_packages" && (
              <CustomerTariffPackagesTab />
            )}
            {selectedTabView === "billing" && <CustomerBillingTab />}
            {selectedTabView === "line_tests" && <CustomerLineTestsTab />}
          </TabViewContainer>
        </div>
      </div>
    </Screen>
  );
}

const AccountAlertCard = ({ header, description }) => {
  return (
    <div className="account-alert-card">
      <span className="title-lbl">{header}</span>
      <span className="description-lbl">{description}</span>
    </div>
  );
};

const AccountAlertControlButton = ({ icon, onClick = () => {} }) => {
  return (
    <button className="account-alert-control-btn" onClick={onClick}>
      <img src={icon} />
    </button>
  );
};

const OverviewStatCard = ({ header, value }) => {
  return (
    <div className="overview-stat-card">
      <span className="title-lbl">{header}</span>
      <span className="value-lbl">{value}</span>
    </div>
  );
};

export default CustomerDetail;
