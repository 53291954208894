import firebase from "../firebase.js";
import React from "react";
import ReactDOM from "react-dom";
// import { pageNumber } from "../containers/PhoneNumbersData.js";
import { sortObject } from "../utils/helpers.js";
import { getRadioInputValue } from "../utils/dom.js";
import {
  resolveCustomerData,
  resolveDate,
  resolveTariff,
  resolveBroadbandType
} from "../utils/customerDataResolver.js";
import {
  serverDomain,
  selectedCompanyID,
  switchedUserID
} from "../utils/constants.js";
import * as req from "../utils/request";
import { getTokens, clearTokens, refreshAccessToken } from "../utils/tokens.js";
import {
  selectedRecord,
  setCurrentRecord
} from "./outbound-calls/calling_session_functions.js";
import { searchCustomers } from "./customer_functions.js";
import Alert from "../views/Alert.js";
import ErrorAlert from "../views/ErrorAlert.js";
import Notification from "../views/Notification.js";
import { getUserRoles } from "./users_functions.js";
import Confirmation from "../views/Confirmation.js";
import { removeLoadingToast, showLoadingToast } from "../utils/LoadingHandler";
import { createRoot } from "react-dom/client.js";

const db = firebase.firestore();
let namesPaginationDoc = null;

export function timeToString(time) {
  let diffInHrs = time / 3600000;
  let hh = Math.floor(diffInHrs);

  let diffInMin = (diffInHrs - hh) * 60;
  let mm = Math.floor(diffInMin);

  let diffInSec = (diffInMin - mm) * 60;
  let ss = Math.floor(diffInSec);

  let formattedHH = hh.toString().padStart(2, "0");
  let formattedMM = mm.toString().padStart(2, "0");
  let formattedSS = ss.toString().padStart(2, "0");

  return `${formattedHH}:${formattedMM}:${formattedSS}`;
}

export async function getCompanies() {
  try {
    const data = await req.get(
      `${serverDomain}/api/v1/companies`,
      null,
      "snake"
    );
    return data;
  } catch (err) {
    console.log(err);
    return;
  }
}

export let authTokens = null;

export function getAuthTokens() {
  return authTokens;
}

export function setAuthTokens(tokens) {
  authTokens = tokens;
}

// DEPRECATE
export let currentUser = null;

// DEPRECATE
export function fetchCurrentUserData() {
  return currentUser;
}

export async function getLoggedInUser() {
  const tokens = getTokens();
  try {
    const user = await req.get(
      `${serverDomain}/api/v1/users/${tokens.userId}`,
      null,
      "snake"
    );
    return user;
  } catch (err) {
    console.log(err);
    if (window.location.pathname !== "/login") {
      window.location = "/";
    }
    return null;
  }
}

//Fetches the current user from the backend database.
export async function fetchCurrentUser() {
  if (currentUser !== null) {
    return currentUser;
  }

  const tokens = getTokens();

  //The email is hardcoded for now but will get it from the FirebaseAuth object when that is implemented.
  if (!tokens.userId || !tokens.accessToken) {
    window.location.hash = "/login";
    return;
  }

  try {
    const data = await req.get(
      `${serverDomain}/api/v1/users/${tokens.userId}`,
      null,
      "snake"
    );
    currentUser = data;
  } catch (err) {
    console.log(err);
    window.location.hash = "/login";
    return;
  }

  return currentUser;
}

export async function signOut() {
  clearTokens();
  window.location = "/login";
  localStorage.removeItem("rscrm_switched_user_id");
  return;
}

export async function getUserTeamNames(user) {
  const teams = await req.get(
    `${serverDomain}/api/v1/user_teams`,
    null,
    "snake"
  );
  const userTeams = [];

  teams.forEach((team, i) => {
    if (user.teams.includes(team.id)) {
      userTeams.push(team.name);
    }
  });

  return userTeams;
}

export async function getUserRoleNames(user) {
  const roles = await req.get(
    `${serverDomain}/api/v1/user_roles`,
    null,
    "snake"
  );
  const userRoles = [];

  roles.forEach((role, i) => {
    if (user.roles.includes(role.id)) {
      userRoles.push(role.name);
    }
  });

  return userRoles;
}

export async function showAlert(title, message, submitAction, cancelAction) {
  const alert = document.createElement("div");
  alert.id = "alert-message";
  const a = (
    <Alert
      title={title}
      message={message}
      submitAction={null}
      cancelAction={null}
    />
  );
  const root = createRoot(alert);
  root.render(a);
  document.getElementById("app-content-wrapper").appendChild(alert);
  return;
}

export async function showConfirmation(
  title,
  message,
  yesAction,
  noAction = () => {}
) {
  const alert = document.createElement("div");
  alert.id = "confirmation-message";
  ReactDOM.render(
    <Confirmation
      title={title}
      message={message}
      noAction={noAction}
      yesAction={yesAction}
    />,
    alert
  );
  document.getElementById("app-content-wrapper").appendChild(alert);
  return;
}

export async function showError(title, message, cancelAction) {
  hideLoadingOverlay();
  const error = document.createElement("div");
  error.id = "error-message";
  ReactDOM.render(
    <ErrorAlert title={title} message={message} cancelAction={null} />,
    error
  );
  document.getElementById("app-content-wrapper").appendChild(error);
  return;
}

export async function showNotification(type, title, message) {
  const notification = document.createElement("div");
  notification.className = "sys-notification";
  ReactDOM.render(
    <Notification type={type} title={title} message={message} />,
    notification
  );
  document.getElementById("app-content-wrapper").appendChild(notification);
  return;
}

export async function sidebarSearchAction(history) {
  var pass = false;
  if (!window.location.hash.includes("/customers")) {
    pass = true;
  }
  var r = window.confirm(
    "This search will take you to the customers section - all unsaved changes will be lost. Do you wish to proceed?"
  );
  if (r || pass) {
    history.push("/customers?sidebar_search=true");

    const sidebarSearchInput = await document
      .getElementById("sidebar-search-bar")
      .querySelectorAll("input")[0];
    const customersSearchInput = await document.getElementById(
      "customers-search-input"
    );

    await searchCustomers(0);

    customersSearchInput.value = sidebarSearchInput.value;
    sidebarSearchInput.value = "";
  }
}

export function generateUUID() {
  var dt = new Date().getTime();
  var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
    /[xy]/g,
    function (c) {
      var r = (dt + Math.random() * 16) % 16 | 0;
      dt = Math.floor(dt / 16);
      return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    }
  );
  return uuid;
}

export async function updateFCMToken(token) {
  if (!currentUser) {
    await fetchCurrentUser();
  }
  try {
    await req.put(
      `${serverDomain}/api/v1/users/${switchedUserID || currentUser.id}`,
      {
        fcm_token: token
      }
    );
    return;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function showLoadingOverlay(text = "Loading...") {
  showLoadingToast(text);
}

export async function hideLoadingOverlay() {
  removeLoadingToast();
}
