import React, { useContext, useEffect, useState } from "react";
import { createRoot } from "react-dom/client";

// Styles
import "../../styles/navbar/SwitchedUserIndicator.css";

// Assets
import DownArrow from "../../assets/interface-icons/down-arrow.svg";
import DefaultProfileImage from "../../assets/DefaultProfileImage.svg";

// Functions
import {
  getSwitchedUserData,
  openSwitchUser,
  switchUser
} from "../../functions/auth/switch-user-functions";
import { authChecker } from "../../functions/authChecker";
import { setSwitchedUserID } from "../../utils/constants";

// Contexts
import { UserContext } from "../../Main";

// Screens
import SwitchUser from "../../screens/SwitchUser";

export function SwitchedUserIndicator() {
  const { user, setUser } = useContext(UserContext);

  const [userName, setUserName] = useState("");
  const [profileImage, setProfileImage] = useState(null);

  useEffect(() => {
    (async () => {
      await authChecker();
      if (!user) {
        return;
      }

      const switchedUserIdFromStorage = JSON.parse(
        localStorage.getItem("rscrm_switched_user_id")
      );
      if (switchedUserIdFromStorage) {
        setSwitchedUserID(parseInt(switchedUserIdFromStorage));
        const switchedUser = await getSwitchedUserData();
        setProfileImage(switchedUser.profile_image);
        setUserName(`${switchedUser.first_name} ${switchedUser.last_name}`);
      } else if (user) {
        setUserName(`${user.first_name} ${user.last_name}`);
      }
    })();
  }, []);

  useEffect(() => {
    if (user) {
      setUserName(`${user.first_name} ${user.last_name}`);
      setProfileImage(user.profile_pic);
    }
  }, [user]);

  return (
    <button
      id="switched-user-indicator"
      onClick={async () => {
        const div = document.createElement("div");
        div.className = "switch-user-content";
        const root = createRoot(div);
        root.render(<SwitchUser user={user} setUser={setUser} />);
        document.getElementById("app-content-wrapper").appendChild(div);
      }}
    >
      <div className="user-details-container">
        <img src={profileImage || DefaultProfileImage} />
        <span className="name-label">{`${userName}`}</span>
        <div className="down-icon-wrapper">
          <img className="down-icon" src={DownArrow} />
        </div>
      </div>
    </button>
  );
}
