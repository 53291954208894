import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import "../../../styles/Customers/TechnicalFaults/TechnicalFaultFurtherDetailView.css";
import CrossIcon from "../../../assets/CrossIcon.svg";
import TrashIcon from "../../../assets/TrashIcon.svg";
import PlusIcon from "../../../assets/PlusIcon.svg";
import CustomersIcon from "../../../assets/sidebar-icons/CustomersIcon.svg";
import SendMessageButton from "../../../components/SendMessageButton.js";
import NewTechnicalFaultEngineerVisitForm from "../../../views/Customers/TechnicalFaults/NewTechnicalFaultEngineerVisitForm.js";

import {
  updateTechnicalFault,
  listTechnicalFaults,
  deleteTechnicalFault,
  createTechnicalFaultNote,
  listTechnicalFaultNotes,
  resolveTechnicalFault,
  listTechnicalFaultEngineerVisits
} from "../../../functions/technical_fault_functions.js";
import { createRoot } from "react-dom/client";

function TechnicalFaultFurtherDetailView({ technicalFault }) {
  useEffect(() => {
    (async () => {
      await listTechnicalFaultNotes(technicalFault.id, 0);

      document
        .getElementById("tech-fault-further-detail-notes-input-field")
        .addEventListener("keyup", async function (event) {
          if (event.keyCode === 13) {
            event.preventDefault();
            await createTechnicalFaultNote(technicalFault.id);
          }
        });
    })();
  });

  return (
    <div className="tech-fault-further-detail-form-container">
      <div className="tech-fault-further-detail-form-top-row">
        <span className="tech-fault-further-detail-form-title">
          Technical Fault (ID#{technicalFault.id})
        </span>
        <button
          title="Open Customer Record"
          className="concern-detail-form-open-customer-btn"
          onClick={async () => {
            const r = window.confirm(
              "Are you sure you would like to open the customers record? Any unsaved changes will be lost."
            );
            if (r) {
              document
                .getElementById("tech-fault-further-detail-form-parent-div")
                .remove();
              window.location.hash = `/customer-detail/${technicalFault.customer_id}`;
            }
          }}
        >
          <img src={CustomersIcon} />
        </button>
        <button
          className="tech-fault-further-detail-form-exit-btn"
          onClick={async () => {
            const r = window.confirm(
              "Are you sure you would like to exit? Any unsaved changes will be lost."
            );
            if (r) {
              document
                .getElementById("tech-fault-further-detail-form-parent-div")
                .remove();
            }
          }}
        >
          <img src={CrossIcon} />
        </button>
      </div>
      <div className="tech-fault-further-detail-form-content">
        <div className="tech-fault-further-detail-left-col">
          <select
            id="tech-fault-further-detail-form-type-dropdown"
            defaultValue={technicalFault.type}
          >
            <option value="line">Line Fault</option>
            <option value="broadband">Broadband Fault</option>
          </select>
          <span className="tech-fault-further-detail-form-description-header">
            Fault Description:
          </span>
          <textarea
            id="tech-fault-further-detail-form-description-input"
            defaultValue={technicalFault.description}
          ></textarea>
          <div className="tech-fault-further-detail-form-action-btns">
            <button
              className="tech-fault-further-detail-form-delete-btn"
              onClick={async () => {
                const res = await deleteTechnicalFault(technicalFault.id);
                if (res) {
                  if (window.location.hash.includes("customer-detail")) {
                    await listTechnicalFaults(
                      technicalFault.customer_id,
                      null,
                      0
                    );
                  } else {
                    await listTechnicalFaults(null, "unresolved", 0);
                    await listTechnicalFaults(null, "resolved", 0);
                  }
                  document
                    .getElementById("tech-fault-further-detail-form-parent-div")
                    .remove();
                }
              }}
            >
              <img src={TrashIcon} />
              <span>Delete Fault</span>
            </button>
            <button
              className="tech-fault-further-detail-form-submit-btn"
              onClick={async () => {
                const description = document.getElementById(
                  "tech-fault-further-detail-form-description-input"
                ).value;
                const type = document.getElementById(
                  "tech-fault-further-detail-form-type-dropdown"
                ).value;
                const res = await updateTechnicalFault(technicalFault.id, {
                  description: description,
                  type: type
                });
                if (res) {
                  if (window.location.hash.includes("customer-detail")) {
                    await listTechnicalFaults(
                      technicalFault.customer_id,
                      null,
                      0
                    );
                  } else {
                    await listTechnicalFaults(null, "unresolved", 0);
                    await listTechnicalFaults(null, "resolved", 0);
                  }
                  document
                    .getElementById("tech-fault-further-detail-form-parent-div")
                    .remove();
                }
              }}
            >
              Save Changes
            </button>
          </div>
          <button
            className="tech-fault-further-detail-form-resolve-btn"
            onClick={async () => {
              const res = await resolveTechnicalFault(technicalFault.id);
              if (res) {
                if (window.location.hash.includes("customer-detail")) {
                  await listTechnicalFaults(
                    technicalFault.customer_id,
                    null,
                    0
                  );
                } else {
                  await listTechnicalFaults(null, "unresolved", 0);
                  await listTechnicalFaults(null, "resolved", 0);
                }
                document
                  .getElementById("tech-fault-further-detail-form-parent-div")
                  .remove();
              }
            }}
          >
            Resolve Fault
          </button>
        </div>
        <div className="tech-fault-further-detail-right-col">
          <div className="tech-fault-further-detail-tabbed-table-container">
            <div className="tech-fault-further-detail-table-tabs">
              <button
                id="notes-tab"
                className="tech-fault-table-tab"
                onClick={async () => {
                  document.getElementById("notes-tab").className =
                    "tech-fault-table-tab";
                  document.getElementById("engineer-visits-tab").className =
                    "tech-fault-table-tab disabled-tab";
                  document.getElementById(
                    "tech-fault-engineer-visits"
                  ).style.display = "none";
                  document.getElementById("tech-fault-notes").style.display =
                    "table";
                  document.getElementById(
                    "tech-fault-further-detail-notes-input"
                  ).style.display = "flex";
                  document.getElementById(
                    "tech-fault-new-engineer-visit-btn"
                  ).style.display = "none";
                  const container = document.getElementsByClassName(
                    "tech-fault-further-detail-tabbed-tbody-container"
                  )[0];
                  container.scrollTop = container.scrollHeight;
                  await listTechnicalFaultNotes(technicalFault.id, 0);
                }}
              >
                <span>Notes</span>
              </button>
              <button
                id="engineer-visits-tab"
                className="tech-fault-table-tab disabled-tab"
                onClick={async () => {
                  document.getElementById("engineer-visits-tab").className =
                    "tech-fault-table-tab";
                  document.getElementById("notes-tab").className =
                    "tech-fault-table-tab disabled-tab";
                  document.getElementById(
                    "tech-fault-engineer-visits"
                  ).style.display = "table";
                  document.getElementById("tech-fault-notes").style.display =
                    "none";
                  document.getElementById(
                    "tech-fault-further-detail-notes-input"
                  ).style.display = "none";
                  document.getElementById(
                    "tech-fault-new-engineer-visit-btn"
                  ).style.display = "flex";
                  const container = document.getElementsByClassName(
                    "tech-fault-further-detail-tabbed-tbody-container"
                  )[0];
                  container.scrollTop = -container.scrollTop;
                  await listTechnicalFaultEngineerVisits(technicalFault.id, 0);
                }}
              >
                <span>Engineer Visits</span>
              </button>
            </div>
            <div className="tech-fault-further-detail-tabbed-tbody-container">
              <div className="table-container" id="tech-fault-engineer-visits">
                <table id="tech-fault-engineer-visits-header">
                  <thead>
                    <tr>
                      <th>Reference</th>
                      <th>Visit Date/Time</th>
                    </tr>
                  </thead>
                </table>
                <div className="table-flex-container">
                  <table id="tech-fault-engineer-visits-body">
                    <tbody></tbody>
                  </table>
                </div>
              </div>
              <table id="tech-fault-notes">
                <tbody></tbody>
              </table>
            </div>
            <div id="tech-fault-further-detail-notes-input">
              <input
                placeholder="Type your comment here..."
                id="tech-fault-further-detail-notes-input-field"
              />
              <SendMessageButton
                onClick={async () => {
                  await createTechnicalFaultNote(technicalFault.id);
                }}
              />
            </div>
            <button
              id="tech-fault-new-engineer-visit-btn"
              onClick={async () => {
                const div = document.createElement("div");
                div.id = "new-tech-fault-engineer-visit-form-parent-div";
                const root = createRoot(div);
                root.render(
                  <NewTechnicalFaultEngineerVisitForm
                    faultId={technicalFault.id}
                  />
                );
                document.getElementById("app-content-wrapper").appendChild(div);
              }}
            >
              Book New Engineer Visit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TechnicalFaultFurtherDetailView;
