import React from "react";
import "../../../styles/Sales/CallingSession/PackageAddOnDealsheetOption.css";
import { updateSalesCalculator } from "../../../functions/outbound-calls/calling_session_functions.js";

function PackageAddOnDealsheetOption({ addon, defaultChecked }) {
  return (
    <div className="package-add-on-dealsheet-card">
      <div className="package-add-on-dealsheet-card-input-row">
        <span>Does customer have {addon.name}?</span>
        <input
          type="checkbox"
          name={addon.name.toLowerCase().replaceAll(" ", "_")}
          defaultChecked={defaultChecked}
          onChange={async () => {
            updateSalesCalculator();
          }}
        />
      </div>
      <span className="advice">
        <i>
          Advise Customer {addon.name} will increase bill by{" "}
          {addon.price.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP"
          })}{" "}
          per month, a recurring {addon.billing_type} will be applied to their
          account.
        </i>
      </span>
    </div>
  );
}

export default PackageAddOnDealsheetOption;
