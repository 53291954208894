import React from "react";
import "../../../styles/outbound-calls/callbacks/DateSelector.css";
import LeftChevron from "../../../assets/LeftChevron.svg";
import RightChevron from "../../../assets/RightChevron.svg";

function DateSelector({ inputId, onClick }) {

    return (
        <div className="date-selector">
            {/* <button><img src={LeftChevron}/></button> */}
            <input id={inputId} className="date-text-input" type="datetime-local" defaultValue={new Date().toISOString().slice(0,16)} min={new Date().toISOString().slice(0,16)}/>
            {/* <button><img src={RightChevron}/></button> */}
        </div>
    )

}

export default DateSelector;