import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { changePathName } from "../functions/changePathName.js";
import { authChecker } from "../functions/authChecker";
import { currentUser } from "../functions/core_functions.js";
import SearchBar from "../views/SearchBar.js";
import TableIcon from "../assets/TableIcon.svg";
import CrossIcon from "../assets/CrossIcon.svg";
import "../styles/Retentions.css";
import {
  listCancellationRequests,
  updateCancellationRequest,
  setSelectedRetentionData
} from "../functions/retentions_functions.js";
import { Table } from "../views/generic/Table.js";
import Screen from "./Screen.js";
import { showErrorToast } from "../utils/ErrorHandler.js";

function Retentions() {
  const [reqs, setReqs] = useState([]);

  useEffect(() => {
    (async () => {
      const [requests, err] = await listCancellationRequests();
      if (requests) {
        setReqs(requests);
      } else {
        showErrorToast("Failed to get cancellation requests");
      }
    })();
  }, []);

  return (
    <Screen>
      <div className="retentions-screen">
        <div className="retentions-top-bar">
          <SearchBar
            id="retentions-search-bar"
            onSearch={async () => {
              return;
            }}
          />
          {/*<button id="retentions-export-csv-btn" onClick={async ()=>{return;}}>
            <img src={TableIcon}/>
            <span>Bulk Export To CSV</span>
          </button>*/}
        </div>
        <div className="table-wrapper">
          <Table
            headers={[
              "Date",
              "Customer ID",
              "Name",
              "Account ID",
              "CLI",
              "Live Date",
              "Outcome"
            ]}
            rowData={reqs.map(req => {
              const customer = JSON.parse(req.customer);
              const lineRentalOrder = JSON.parse(req.lr_order);
              const provisioningJob = JSON.parse(req.prov_job);

              return {
                date: new Date(req.created_at).toLocaleDateString(),
                id: req.customer_id,
                full_name: customer.full_name,
                account_id: customer.account_id,
                cli: lineRentalOrder.cli,
                live_date: provisioningJob
                  ? new Date(
                      provisioningJob.porting_live_date
                    ).toLocaleDateString()
                  : "N/A",
                outcome: req.outcome || "OPEN"
              };
            })}
            rowClick={async data => {
              // openProvisioningDetail(data.id, selectedCompany);
            }}
            state={reqs}
            paginationFunc={async () => {
              // if (!stopPagination) {
              //   showLoadingToast("Loading more deals...");
              //   const [ds, err] = await getProvisioningData(
              //     searchInput,
              //     deals.length
              //   );
              //   setDeals(deals.concat(ds));
              //   removeLoadingToast();
              // }
            }}
          />
        </div>
      </div>
    </Screen>
  );
}

export default Retentions;
