// Libraries
import React, { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Functions
import { setTokens } from "../utils/tokens";
import { getLoggedInUser } from "../functions/core_functions";
import { removeLoadingToast, showLoadingToast } from "../utils/LoadingHandler";
import { login, stayLoggedIn } from "../functions/authChecker";
import { showErrorToast } from "../utils/ErrorHandler";

// Styling
import "../styles/Login.css";

// Views
import PrimaryActionButton from "../views/generic/PrimaryActionButton";
import { SingleLineInput } from "../views/generic/SingleLineInput";
import Screen from "./Screen";

// Contexts
import { UserContext } from "../Main";

// Assets
import FullLimeLogo from "../assets/logos/Logo.svg";
import LoginBackground from "../assets/LoginBackground.svg";

function Login() {
  const { user, setUser } = useContext(UserContext);

  useEffect(() => {
    (async () => {
      const loggedIn = await stayLoggedIn();
      if (loggedIn) {
        showLoadingToast();
        const loggedInUser = await getLoggedInUser();
        removeLoadingToast();
        if (loggedInUser) {
          setUser(user);
        }
      }
    })();
  }, []);

  const [loginInfo, setLoginInfo] = useState({
    email: "",
    password: ""
  });
  const [rememberMe, setRememberMe] = useState(false);

  const handleChangeEmail = e => {
    setLoginInfo({ ...loginInfo, email: e.target.value });
  };
  const handleChangePassword = e => {
    setLoginInfo({ ...loginInfo, password: e.target.value });
  };

  //function handles logging in the user.
  const handleFormSubmit = async () => {
    showLoadingToast();
    const data = await login(loginInfo.email, loginInfo.password);
    removeLoadingToast();
    if (data) {
      setTokens(data);
      const user = await getLoggedInUser();
      if (user) {
        setUser(user);
      }
    } else {
      showErrorToast(
        "Failed to login",
        "There was an error whilst trying to login. Please check your email and password, then try again."
      );
    }
  };

  return (
    <Screen>
      <div id="login-screen">
        <div className="poster-wrapper">
          <img src={LoginBackground} />
          <div className="central-content-wrapper">
            <div className="container">
              <span className="title-lbl">
                Digital
                <br />
                sales & marketing
                <br />
                delivery at its
                <br />
                <span>best.</span>
              </span>
              <span className="subtitle-lbl">
                Easy to use, helping to deliver the best results.
              </span>
            </div>
          </div>
        </div>
        <div className="login-el-wrapper">
          <div className="content-wrapper">
            <div className="logo-wrapper">
              <img id="logo" src={FullLimeLogo} />
            </div>
            <div className="inputs-wrapper">
              <div className="text-wrapper">
                <span className="title-lbl">Welcome back 👋</span>
                <span className="subtitle-lbl">
                  Enter your unique login details provided to you by your
                  management team.
                </span>
              </div>
              <div className="input-fields-wrapper">
                <SingleLineInput
                  id={"email-address-input"}
                  type={"email"}
                  required={true}
                  title={"Email address"}
                  onChange={async e => {
                    handleChangeEmail(e);
                  }}
                  onEnter={handleFormSubmit}
                />
                <SingleLineInput
                  id={"password-input"}
                  type={"password"}
                  required={true}
                  title={"Password"}
                  onChange={async e => {
                    handleChangePassword(e);
                  }}
                  onEnter={handleFormSubmit}
                />
                <div className="action-btns-wrapper">
                  <button
                    id="remember-me-btn"
                    onClick={async () => {
                      setRememberMe(!rememberMe);
                    }}
                  >
                    {!rememberMe && <div className="remember-me-unselected" />}
                    {rememberMe && <div className="remember-me-selected" />}
                    <span>Remember me</span>
                  </button>
                  <button id="forgot-password-btn">
                    <span>Forgot password?</span>
                  </button>
                </div>
              </div>

              <PrimaryActionButton
                id={"login-btn"}
                title={"Login"}
                onClick={handleFormSubmit}
              />
            </div>
            <div className="bottom-wrapper">
              <span></span>
            </div>
          </div>
        </div>
      </div>
    </Screen>
  );
}

export default Login;
