import React from "react";
import "../../styles/generic/TableHeaderStat.css";

const TableHeaderStat = ({ title, description, value, label }) => {
  return (
    <div className="table-header-stat-wrapper">
      <div className="header-wrapper">
        <span className="title-lbl">{title}</span>
        <span className="description-lbl">{description}</span>
      </div>
      <div className="value-wrapper">
        <span className="value-lbl">{value}</span>
        <span className="desc-lbl">{label}</span>
      </div>
    </div>
  );
};

export default TableHeaderStat;
