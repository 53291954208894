import { getCompanies } from "../functions/core_functions";

export const serverDomain = "https://crm-server.limeutility.co.uk";

export var selectedCompany = null;
export var selectedCompanyID = 5;
export var selectedRecord = null;
export var switchedUserID = null;

export function setCompany(data) {
  if (data) {
    const sidebarCompanyName = document.getElementsByClassName(
      "company-switcher-name"
    );
    if (sidebarCompanyName.length > 0) {
      sidebarCompanyName[0].innerHTML = data.company_name;
    }

    const sidebarCompanyLogo = document.getElementsByClassName(
      "company-switcher-logo"
    );
    if (sidebarCompanyLogo.length > 0) {
      sidebarCompanyLogo[0].src = data.logo_ref;
      if (data.logo_ref) {
        sidebarCompanyName[0].innerHTML = "";
      }
    }
    localStorage.setItem("rscrm_selected_company", JSON.stringify(data));
    selectedCompanyID = parseInt(data.id);
    selectedCompany = data;
  }
}

export async function loadDefaultCompany(setSelectedCompany) {
  const selectedCompanyFromLocalStorage = JSON.parse(
    localStorage.getItem("rscrm_selected_company")
  );
  if (selectedCompanyFromLocalStorage) {
    setSelectedCompany(selectedCompanyFromLocalStorage);
  } else {
    const companies = await getCompanies();
    companies.forEach(company => {
      if (company.id === 5) {
        setSelectedCompany(company);
      }
    });
  }
}

export function getSelectedCompany() {
  const selectedCompanyFromLocalStorage = JSON.parse(
    localStorage.getItem("rscrm_selected_company")
  );
  if (selectedCompanyFromLocalStorage) {
    return selectedCompanyFromLocalStorage;
  } else if (selectedCompany) {
    return selectedCompany;
  } else {
    return null;
  }
}

export function setCurrentRecord(data) {
  selectedRecord = data;
}

export function setSwitchedUserID(id) {
  switchedUserID = id;
  if (id) {
    localStorage.setItem("rscrm_switched_user_id", id);
  } else {
    localStorage.removeItem("rscrm_switched_user_id");
  }
}

export function convertToCurrency(val) {
  return val.toLocaleString("en-GB", {
    style: "currency",
    currency: "GBP"
  });
}

export const defaultPagePostLogin = "outbound-calls";
