import React from "react";
import Screen from "./Screen";

function TechnicalFaults() {
  return (
    <Screen>
      <div className="technical-faults-screen">Tech Faults</div>
    </Screen>
  );
}

export default TechnicalFaults;
