import React, { useContext, useEffect, useState } from "react";
import {
  hideLoadingOverlay,
  showLoadingOverlay
} from "../../../functions/core_functions";
import {
  continueToCallingSessionScript,
  getRecord
} from "../../../functions/outbound-calls/calling_session_functions";
import { SelectedRecordContext } from "../../../Main";
import "../../../styles/outbound-calls/overview/CallInSection.css";
import PrimaryActionButton from "../../generic/PrimaryActionButton";
import { showErrorToast } from "../../../utils/ErrorHandler";
import SearchIcon from "../../../assets/interface-icons/search.svg";
import { SingleLineInput } from "../../generic/SingleLineInput";

function CallInSection() {
  const { _, setSelectedRecord } = useContext(SelectedRecordContext);

  const submit = async () => {
    const cli = document.getElementById("customer-call-in-phone-input").value;
    showLoadingOverlay();
    const record = await getRecord(cli);
    if (record) {
      setSelectedRecord(record ? record : { cli: cli });
      localStorage.setItem("rscrm_current_record", JSON.stringify(record));
      localStorage.removeItem("rscrm_dealsheet_cache");
      localStorage.removeItem("rscrm_current_call_cli");
    } else {
      showErrorToast(
        "Failed to find record",
        "There was an issue that occurred when trying to find this number. We may not hold data for this customer."
      );
    }
    hideLoadingOverlay();
  };

  return (
    <div className="call-in-section">
      <div className="customer-call-in-wrapper">
        <div className="text-wrapper">
          <span className="title-lbl">Record Search</span>
          <span className="subtitle-lbl">
            Enter the CLI for a record, if we hold the record in our system, it
            will open.
          </span>
        </div>

        <SingleLineInput
          id="customer-call-in-phone-input"
          placeholder="CLI"
          onEnter={submit}
        />
        <PrimaryActionButton
          id={"customer-call-in-submit-btn"}
          icon={SearchIcon}
          title={"Search"}
          onClick={submit}
        />
      </div>
    </div>
  );
}

export default CallInSection;
