import React from "react";
import { DropdownInput } from "../generic/DropdownInput";
import Modal, {
  ModalContent,
  ModalHeader,
  ModalInputGrid,
  ModalFooter,
  ModalSection
} from "../generic/modal/Modal";
import PrimaryActionButton from "../generic/PrimaryActionButton";
import { SingleLineInput } from "../generic/SingleLineInput";

const TariffPackageAmmendmentForm = () => {
  return (
    <Modal id={"tariff-package-ammendment-form"}>
      <ModalHeader title={"Tariff/Package Ammendment"} />
      <ModalContent>
        <ModalSection title={"Current"}>
          <ModalInputGrid>
            <SingleLineInput
              id={"current-name-input"}
              title={"Current Tariff/Package"}
              type={"text"}
              defaultValue={"TARIFF GOES HERE"}
              editable={false}
            />
            <SingleLineInput
              id={"current-price-input"}
              title={"Current Price"}
              type={"number"}
              defaultValue={"0.00"}
              editable={false}
            />
          </ModalInputGrid>
        </ModalSection>
        <ModalSection title={"New"}>
          <ModalInputGrid>
            <DropdownInput
              id={"new-package-input"}
              title={"New Package"}
              placeholder={"Select a package..."}
              options={[]}
            />
            <SingleLineInput
              id={"current-price-input"}
              title={"Current Price"}
              type={"number"}
              defaultValue={"0.00"}
              editable={false}
            />
          </ModalInputGrid>
        </ModalSection>
      </ModalContent>
      <ModalFooter>
        <span id="change-in-price-lbl">Change in price: £0.00</span>
        <PrimaryActionButton id={"save-ammendment-btn"} title={"Save"} />
      </ModalFooter>
    </Modal>
  );
};

export default TariffPackageAmmendmentForm;
