export function getRadioInputValue(name) {
  const radios = document.getElementsByName(name);

  if (radios.length === 0) {
    return "";
  }

  for (let i = 0; i < radios.length; i++) {
    if (radios[i].checked) {
      return radios[i].value;
    }
  }
}
