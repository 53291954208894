import React, { useContext, useEffect } from "react";
import ReactDOM from "react-dom";
import { calculateLeaderboard } from "../../../functions/dashboard_functions";

// Styles
import "../../../styles/outbound-calls/overview/SalesLeaderboard.css";

// Views
import DashboardLeaderboardPosition from "../../../views/Dashboard/DashboardLeaderboardPosition";
import { createRoot } from "react-dom/client";
import { SelectedCompanyContext } from "../../../Main";

function SalesLeaderboard() {
  const { selectedCompany, setSelectedCompany } = useContext(
    SelectedCompanyContext
  );

  useEffect(() => {
    (async () => {
      await getLatestLeaderboard(
        "total-deals-leaderboard",
        selectedCompany ? selectedCompany.id : null
      );
    })();
  }, [selectedCompany]);

  const getLatestLeaderboard = async (tableId, companyId) => {
    const leaderboardEls = document.getElementsByClassName("sales-leaderboard");

    leaderboardEls[0].innerHTML = "";

    const [leaderboard, err] = await calculateLeaderboard(
      tableId,
      companyId,
      "daily"
    );

    if (leaderboard && leaderboardEls.length > 0) {
      var i = 0;
      leaderboard.forEach(position => {
        i += 1;
        const div = document.createElement("div");
        div.className = "leaderboard-position-wrapper";
        const root = createRoot(div);
        root.render(
          <DashboardLeaderboardPosition
            pos={i}
            imgRef={position.profile_pic}
            name={position.name}
            score={position.amount}
          />
        );

        leaderboardEls[0].appendChild(div);
      });
    }
  };

  const getIncentiveLeaderboard = async tableId => {
    const [leaderboard, err] = await calculateLeaderboard(
      tableId,
      null,
      "daily"
    );
    document.getElementsByClassName("sales-leaderboard")[0].innerHTML = "";
    if (leaderboard) {
      var i = 0;
      console.log(leaderboard);
      leaderboard.closes.forEach(position => {
        i += 1;
        const div = document.createElement("div");
        div.className = "leaderboard-position-wrapper";
        const root = createRoot(div);
        root.render(
          <DashboardLeaderboardPosition
            pos={i}
            imgRef={position.profile_pic}
            name={position.name}
            score={`${position.amount} CL`}
          />
        );

        document
          .getElementsByClassName("sales-leaderboard")[0]
          .appendChild(div);
      });
      var j = 0;
      console.log(leaderboard);
      leaderboard.deals.forEach(position => {
        j += 1;
        const div = document.createElement("div");
        div.className = "leaderboard-position-wrapper";
        const root = createRoot(div);
        root.render(
          <DashboardLeaderboardPosition
            pos={j}
            imgRef={position.profile_pic}
            name={position.name}
            score={`${position.amount} D`}
          />
        );
        document
          .getElementsByClassName("sales-leaderboard")[0]
          .appendChild(div);
      });
    }
  };

  return (
    <div className="sales-leaderboard-wrapper">
      <div className="filter-wrapper">
        <button
          className="sales-leaderboard-filter"
          onClick={async () => {
            await getLatestLeaderboard("total-handovers-leaderboard");
          }}
        >
          <span>H</span>
        </button>
        <button
          className="sales-leaderboard-filter"
          onClick={async () => {
            await getLatestLeaderboard("total-handovers-taken-leaderboard");
          }}
        >
          <span>HT</span>
        </button>
        <button
          className="sales-leaderboard-filter"
          onClick={async () => {
            await getLatestLeaderboard("total-closes-leaderboard");
          }}
        >
          <span>CL</span>
        </button>
        <button
          className="sales-leaderboard-filter"
          onClick={async () => {
            await getLatestLeaderboard("total-deals-leaderboard");
          }}
        >
          <span>D</span>
        </button>
        <button
          className="sales-leaderboard-filter"
          onClick={async () => {
            await getIncentiveLeaderboard("sales-incentive-leaderboard");
          }}
        >
          <span>🏆</span>
        </button>
      </div>
      <div className="sales-leaderboard"></div>
    </div>
  );
}

export default SalesLeaderboard;
