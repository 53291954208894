import React from "react";
import ReactDOM from "react-dom";
import { serverDomain, selectedCompanyID } from "../utils/constants.js";
import * as req from "../utils/request";

import { listTechnicalFaults } from "../functions/technical_fault_functions.js";
import { listCustomerConcerns } from "../functions/customer_concerns_functions.js";
import { showErrorToast } from "../utils/ErrorHandler";
import { showAlert } from "../functions/core_functions.js";
import AddCustomerNotePopup from "../components/AddCustomerNotePopup.js";
import CustomerNoteRow from "../views/CustomerNoteRow.js";
import TariffInformationRow from "../views/TariffInformationRow.js";
import NewCancellationRequestForm from "../components/NewCancellationRequestForm.js";
import CreditsHistory from "../views/Customers/Credits/CreditsHistory.js";
import CreditHistoryRow from "../views/Customers/Credits/CreditHistoryRow.js";
import OpenRecordTableRowButton from "../views/elements/OpenRecordTableRowButton.js";
import { getDealsheetItems } from "./outbound-calls/calling_session_functions.js";

export async function searchCustomers(offset) {
  const customersSearchInput = document.getElementById(
    "customers-search-input"
  );
  const sidebarSearchInput = document
    .getElementById("sidebar-search-bar")
    .querySelectorAll("input")[0];
  var searchInput = null;

  if (customersSearchInput !== null && customersSearchInput.value !== "") {
    searchInput = customersSearchInput.value;
  } else {
    searchInput = sidebarSearchInput.value;
  }

  if (searchInput === null || searchInput === "") {
    await listCustomers(0);
    return;
  }

  if (!window.location.hash.includes("/customers")) {
    window.location.hash = "/customers";
  }

  try {
    const customers = await req.get(
      `${serverDomain}/api/v1/search_customers?search=${searchInput}&limit=${listCustomersLimit}&offset=${offset}`
    );

    const table = document.getElementById("customers-results-table-body");
    if (!table) {
      return;
    }
    if (offset === 0) {
      table.innerHTML = "";
    }

    if (!customers || !Array.isArray(customers)) {
      return;
    }

    customers.forEach((data, index) => {
      const row = table.insertRow(-1);
      row.className = "customers-results-table-row";

      row.addEventListener(
        "click",
        async () => {
          window.location.hash = `/customer-detail/${data.id}`;
        },
        false
      );

      const rowData = [
        data.account_id,
        data.full_name,
        data.landline,
        `${data.address1}, ${data.address_postcode}`
      ];

      rowData.forEach((val, indx) => {
        const cell = row.insertCell(indx);
        cell.textContent = val;
      });
    });

    if (sidebarSearchInput.value !== "") {
      customersSearchInput.value = sidebarSearchInput.value;
      sidebarSearchInput.value = "";
    }

    if (customers.length < listCustomersLimit) {
      reachedEndOfSearchCustomersRecords = true;
    }
  } catch (err) {
    console.log(err);
    showErrorToast(
      "Failed to search customers",
      `There was an error that occurred whilst trying to search customers. ${err}`
    );

    return;
  }
}

export async function getCustomerDetails(customerId) {
  try {
    const { customer, error } = await getCustomer(customerId);
    if (error) {
      throw error;
    }

    document.getElementById("customer-detail-full-name").value =
      customer.full_name;
    document.getElementById("customer-detail-account-number").value =
      customer.account_id;
    document.getElementById("customer-detail-landline").innerHTML =
      customer.landline;
    document.getElementById("customer-detail-email").value =
      customer.email || "";
    document.getElementById("customer-detail-mobile").value =
      customer.mobile || "";
    document.getElementById("customer-detail-address-1").value =
      customer.address1 || "";
    document.getElementById("customer-detail-address-2").value =
      customer.address2 || "";
    document.getElementById("customer-detail-address-city").value =
      customer.address_city || "";
    document.getElementById("customer-detail-address-county").value =
      customer.address_county || "";
    document.getElementById("customer-detail-address-postcode").value =
      customer.address_postcode || "";
    document.getElementById("customer-detail-dob").value =
      customer.birthdate.split("T")[0] || "";

    const { deal, dealError } = await getDeal(customer.deal_id);
    if (dealError) {
      throw dealError;
    }

    document.getElementById("customer-detail-add-account-holder").value =
      deal.add_account_holder || "";
    document.getElementById("customer-detail-soft-facts").innerHTML =
      deal.soft_facts || "";

    //Set the style of the icons...

    displayTariffInformationData(deal);

    if (customer.open_tech_faults > 0) {
      const tab = document.getElementById("technical-faults-tab");
      if (tab) {
        tab.className = "customer-detail-table-tab disabled-alert-tab";
      }
    }

    if (customer.open_concerns > 0) {
      const tab = document.getElementById("concerns-tab");
      if (tab) {
        tab.className = "customer-detail-table-tab disabled-alert-tab";
      }
    }
  } catch (err) {
    console.log(err);

    window.alert(
      "An error occurred whilst accessing customer data. Please try again later."
    );
    window.location.hash = "/customers";
    return;
  }
}

export async function getCustomer(customerId) {
  try {
    const customer = await req.get(
      `${serverDomain}/api/v1/customers/${customerId}`,
      null,
      "snake"
    );
    return [customer, null];
  } catch (err) {
    return [null, err];
  }
}

export async function getCustomerByDealID(dealId) {
  try {
    const customer = await req.get(
      `${serverDomain}/api/v1/deals/${dealId}/customer`,
      null,
      "snake"
    );
    return [customer, null];
  } catch (err) {
    return [null, err];
  }
}

export async function getDeal(dealId) {
  try {
    const deal = await req.get(
      `${serverDomain}/api/v1/deals/${dealId}`,
      null,
      "snake"
    );
    return [deal, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export function styleTariffInfoIcon(icon, description, active) {
  if (active) {
    icon.style.filter =
      "invert(87%) sepia(79%) saturate(3649%) hue-rotate(68deg) brightness(90%) contrast(80%)";
    icon.title = `Active ${description}`;
  } else {
    icon.style.filter =
      "invert(57%) sepia(51%) saturate(5569%) hue-rotate(328deg) brightness(102%) contrast(103%)";
    icon.title = `No ${description}`;
  }
}

export async function displayTariffInformationData(deal) {
  try {
    //Set the prices in the fields....
    document.getElementById("tariff-price-field-input").value =
      deal.tariff_price;
    document.getElementById("line-rental-price-field-input").value =
      deal.line_rental_price;
    document.getElementById("mobile-package-price-field-input").value =
      deal.mobile_package_price;
    document.getElementById("broadband-package-price-field-input").value =
      deal.broadband_package_price;
    document.getElementById("non-geo-package-price-field-input").value =
      deal.non_geo_package_price;
    // document.getElementById("bt-mobile-price-field").value = deal.package_add_ons.includes(1) ? packageAddOns.find(e => e.id === 1).price : null;
    // document.getElementById("bt-sport-price-field").value = deal.package_add_ons.includes(2) ? packageAddOns.find(e => e.id === 2).price : null;

    document.getElementById("bt-mobile-price-field").style.display = "none";
    document.getElementById("bt-sport-price-field").style.display = "none";

    const tariffIcon = document
      .getElementById("tariff-cell")
      .querySelector(".tariff-information-cell-icon");
    const lineRentalIcon = document
      .getElementById("line-rental-cell")
      .querySelector(".tariff-information-cell-icon");
    const bbIcon = document
      .getElementById("broadband-package-cell")
      .querySelector(".tariff-information-cell-icon");
    const mobIcon = document
      .getElementById("mobile-package-cell")
      .querySelector(".tariff-information-cell-icon");
    const nonGeoIcon = document
      .getElementById("non-geo-package-cell")
      .querySelector(".tariff-information-cell-icon");
    const btSportIcon = document
      .getElementById("bt-sport-cell")
      .querySelector(".tariff-information-cell-icon");
    const btMobileIcon = document
      .getElementById("bt-mobile-cell")
      .querySelector(".tariff-information-cell-icon");

    styleTariffInfoIcon(tariffIcon, "Tariff", deal.tariff !== null);
    styleTariffInfoIcon(
      lineRentalIcon,
      "Line Rental",
      deal.line_rental !== null
    );
    styleTariffInfoIcon(
      bbIcon,
      "Broadband Package",
      deal.broadband_package !== null
    );
    styleTariffInfoIcon(
      mobIcon,
      "Mobile Package",
      deal.mobile_package !== null
    );
    styleTariffInfoIcon(
      nonGeoIcon,
      "Non Geo Package",
      deal.non_geo_package !== null
    );
    styleTariffInfoIcon(
      btSportIcon,
      "BT Sport",
      deal.package_add_ons.includes(2)
    );
    styleTariffInfoIcon(
      btMobileIcon,
      "BT Mobile",
      deal.package_add_ons.includes(1)
    );

    function addNullOptionToDropdown(dropdown) {
      const option = document.createElement("option");
      option.value = null;
      option.innerHTML = "N/A";
      dropdown.appendChild(option);
    }

    function addBoolOptionsToDropdown(dropdown, price) {
      const falseOption = document.createElement("option");
      falseOption.value = false;
      falseOption.innerHTML = "No";
      dropdown.appendChild(falseOption);

      const trueOption = document.createElement("option");
      trueOption.value = true;
      trueOption.innerHTML = `Yes - ${price.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
      })}`;
      dropdown.appendChild(trueOption);
    }

    const [items, err] = await getDealsheetItems();
    if (!items) {
      return;
    }

    const tariffDropdown = document.getElementById(
      "tariff-select-dropdown-input"
    );
    items.tariffs.forEach((tariff, i) => {
      const option = document.createElement("option");
      option.value = tariff.id;
      option.innerHTML = `${tariff.name.replaceAll(
        "%COMPANY_NAME%",
        deal.company_name
      )} - ${tariff.price.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
      })}`;
      tariffDropdown.appendChild(option);
    });
    tariffDropdown.value = deal.tariff;

    const lineRentalDropdown = document.getElementById(
      "line-rental-select-dropdown-input"
    );
    items.line_rental_prices.forEach((pkg, i) => {
      const option = document.createElement("option");
      option.value = pkg.id;
      option.innerHTML = `${pkg.name.replaceAll(
        "%COMPANY_NAME%",
        deal.company_name
      )} - ${pkg.price.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
      })}`;
      lineRentalDropdown.appendChild(option);
    });
    lineRentalDropdown.value = deal.line_rental;

    const broadbandDropdown = document.getElementById(
      "broadband-package-select-dropdown-input"
    );
    addNullOptionToDropdown(broadbandDropdown);
    items.broadband_packages.forEach((pkg, i) => {
      const option = document.createElement("option");
      option.value = pkg.id;
      option.innerHTML = `${pkg.name.replaceAll(
        "%COMPANY_NAME%",
        deal.company_name
      )} - ${pkg.price.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
      })}`;
      broadbandDropdown.appendChild(option);
    });
    broadbandDropdown.value = deal.broadband_package;

    const mobileDropdown = document.getElementById(
      "mobile-package-select-dropdown-input"
    );
    addNullOptionToDropdown(mobileDropdown);
    items.mobile_packages.forEach((pkg, i) => {
      const option = document.createElement("option");
      option.value = pkg.id;
      option.innerHTML = `${pkg.name.replaceAll(
        "%COMPANY_NAME%",
        deal.company_name
      )} - ${pkg.price.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
      })}`;
      mobileDropdown.appendChild(option);
    });
    mobileDropdown.value = deal.mobile_package;

    const nonGeoDropdown = document.getElementById(
      "non-geo-package-select-dropdown-input"
    );
    addNullOptionToDropdown(nonGeoDropdown);
    items.non_geo_packages.forEach((pkg, i) => {
      const option = document.createElement("option");
      option.value = pkg.id;
      option.innerHTML = `${pkg.name.replaceAll(
        "%COMPANY_NAME%",
        deal.company_name
      )} - ${pkg.price.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
      })}`;
      nonGeoDropdown.appendChild(option);
    });
    nonGeoDropdown.value = deal.non_geo_package;

    const btMobileAddOn = items.package_add_ons.find(
      t => t.name === "BT Mobile"
    );
    const btMobileDropdown = document.getElementById(
      "bt-mobile-select-dropdown-input"
    );
    addBoolOptionsToDropdown(btMobileDropdown, btMobileAddOn.price);
    btMobileDropdown.value = deal.package_add_ons.includes(1);
    if (deal.bt_mobile) {
      document.getElementById("bt-mobile-price-field").value =
        btMobileAddOn.price;
    }

    const btSportAddOn = items.package_add_ons.find(t => t.name === "BT Sport");
    const btSportDropdown = document.getElementById(
      "bt-sport-select-dropdown-input"
    );
    addBoolOptionsToDropdown(btSportDropdown, btSportAddOn.price);
    btSportDropdown.value = deal.package_add_ons.includes(2);
    if (deal.bt_sport) {
      document.getElementById("bt-sport-price-field").value =
        btSportAddOn.price;
    }

    const matchingLineRental = deal.line_rental_prices.find(
      p => p.id === deal.line_rental
    );

    const totalLabel = document.getElementById(
      "customer-tariff-info-total-label"
    );
    const total =
      deal.tariff_price +
      (deal.broadband_package_price ?? 0) +
      (deal.mobile_package_price ?? 0) +
      (deal.non_geo_package_price ?? 0) +
      (deal.bt_sport ? btSportAddOn.price : 0) +
      (deal.bt_mobile ? btMobileAddOn.price : 0) +
      (matchingLineRental.billing_interval === "yearly"
        ? deal.line_rental_price / 12
        : deal.line_rental_price);

    totalLabel.innerHTML = `Total Monthly Price: ${total.toLocaleString(
      "en-GB",
      { style: "currency", currency: "GBP" }
    )}`;
  } catch (err) {
    showErrorToast(
      "Failed to display tariff information data",
      `There was an unexpected error that occurred whilst trying to display tariff information data. ${err}`
    );
    return;
  }
}

export async function updateDeal(dealID, data) {
  try {
    req.put(`${serverDomain}/api/v1/deals/${dealID}`, data);
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export var listCustomersOffset = 0;
export const listCustomersLimit = 25;
export var searchCustomersOffset = 0;

export var reachedEndOfCustomersRecords = false;
export var reachedEndOfSearchCustomersRecords = false;

export function setListCustomersOffset(newOffset) {
  if (newOffset <= 0) {
    listCustomersOffset = 0;
  }
  listCustomersOffset = newOffset;
}

export function setSearchCustomersOffset(newOffset) {
  if (newOffset <= 0) {
    searchCustomersOffset = 0;
  }
  searchCustomersOffset = newOffset;
}

export async function listCustomers(offset) {
  try {
    const customers = await req.get(
      `${serverDomain}/api/v1/customers?limit=${listCustomersLimit}&offset=${offset}`,
      null,
      "snake"
    );

    const table = document.getElementById("customers-results-table-body");
    if (!table) {
      return;
    }
    if (offset === 0) {
      table.innerHTML = "";
    }

    if (customers === null) {
      return;
    }

    customers.forEach(async (data, index) => {
      const row = table.insertRow(-1);
      row.className = "customers-results-table-row";

      const rowData = [
        data.account_id,
        data.full_name,
        data.landline,
        data.address1 + ", " + data.address_postcode
      ];

      rowData.forEach((val, indx) => {
        const cell = row.insertCell(indx);
        cell.textContent = val;

        if (indx === rowData.length - 1) {
          const div = document.createElement("div");
          div.className = "open-record-button-container";
          ReactDOM.render(
            <OpenRecordTableRowButton
              onClick={async () => {
                window.location.hash = `/customer-detail/${data.id}`;
              }}
            />,
            div
          );
          cell.appendChild(div);
        }
      });
    });

    if (customers.length < listCustomersLimit) {
      reachedEndOfCustomersRecords = true;
    }
  } catch (err) {
    showErrorToast(
      "Failed to get list customers",
      `There was an error that occurred whilst trying to list customers. ${err}`
    );

    console.log(err);
  }
}

export async function discardCustomerChanges(customerId) {
  const r = window.confirm(
    "Are you sure you wish to discard changes? All unsaved changes will be lost."
  );
  if (r) {
    getCustomerDetails(customerId);
  }
}

export async function saveCustomerChanges(customerId) {
  try {
    const customer = await req.get(
      `${serverDomain}/api/v1/customers/${customerId}`,
      null,
      "snake"
    );

    if (customer === null || customer.id === null || customer.id === 0) {
      window.alert("Failed to save user details. Please try again later.");
      return;
    }

    //customer data to update
    const fullName = document.getElementById("customer-detail-full-name").value;
    const email = document.getElementById("customer-detail-email").value;
    const mobile = document.getElementById("customer-detail-mobile").value;

    //deal data to update
    const additionalAccountHolder = document.getElementById(
      "customer-detail-add-account-holder"
    ).value;
    const accountId = document.getElementById(
      "customer-detail-account-number"
    ).value;
    const address1 = document.getElementById("customer-detail-address-1").value;
    const address2 = document.getElementById("customer-detail-address-2").value;
    const townCity = document.getElementById(
      "customer-detail-address-city"
    ).value;
    const county = document.getElementById(
      "customer-detail-address-county"
    ).value;
    const postcode = document.getElementById(
      "customer-detail-address-postcode"
    ).value;
    const dobString = document.getElementById("customer-detail-dob").value;
    const softFacts = document.getElementById(
      "customer-detail-soft-facts"
    ).value;

    const dateOfBirth = new Date(dobString).toISOString();

    const customerData = {
      full_name: fullName,
      email: email,
      mobile: mobile,
      birthdate: dateOfBirth
    };

    const dealData = {
      add_account_holder: additionalAccountHolder,
      account_id: accountId,
      full_name: fullName,
      mobile_number: mobile,
      email_address: email,
      address1: address1,
      address2: address2,
      address_city: townCity,
      address_county: county,
      address_postcode: postcode,
      soft_facts: softFacts
    };

    await req.put(
      `${serverDomain}/api/v1/customers/${customer.id}`,
      customerData
    );

    await req.put(`${serverDomain}/api/v1/deals/${customer.deal_id}`, dealData);

    showAlert(
      "Successfully saved changes.",
      "Your changes to this customer were successfully saved to the database."
    );
  } catch (err) {
    console.log(err);

    showErrorToast(
      "Failed to save changes.",
      `Your changes to this customer could not be saved to the database. ${err}`
    );
    return;
  }
}

export async function openAddCustomerNotePopup(customerId) {
  const popup = document.createElement("div");
  popup.id = "add-customer-note-popup";
  ReactDOM.render(<AddCustomerNotePopup customerId={customerId} />, popup);
  document.getElementById("app-content-wrapper").appendChild(popup);
  return;
}

export async function listCustomerNotes(customerId) {
  try {
    const notes = await req.get(
      `${serverDomain}/api/v1/customers/${customerId}/notes`,
      null,
      "snake"
    );
    if (notes === null || notes.length === 0) {
      return;
    }
    document.getElementById("customer-detail-notes").innerHTML = "";
    for (var i = 0; i < notes.length; i++) {
      const note = notes[i];
      const row = document.createElement("div");
      const noteCreatedAt = new Date(note.created_at);
      function checkZero(data) {
        if (data.length === 1) {
          data = "0" + data;
        }
        return data;
      }
      const formattedDate = `${checkZero(
        noteCreatedAt.getDate() + ""
      )}/${checkZero(noteCreatedAt.getMonth() + 1 + "")}/${checkZero(
        noteCreatedAt.getFullYear() + ""
      )}`;

      ReactDOM.render(
        <CustomerNoteRow
          dateTime={formattedDate}
          userName={note.user_name}
          note={note.note}
        />,
        row
      );
      document.getElementById("customer-detail-notes").appendChild(row);
    }
  } catch (err) {
    showErrorToast(
      "Failed to get customer notes",
      `There was an error that occurred whilst trying to get all customer notes. ${err}`
    );
    return;
  }
}

export async function addCustomerNote(customerId, notesData) {
  try {
    await req.post(
      `${serverDomain}/api/v1/customers/${customerId}/notes`,
      notesData
    );
  } catch (err) {
    showErrorToast(
      "Failed to save customer note",
      `There was an error that occurred whilst trying to create this customer note. ${err}`
    );
    return false;
  }

  return true;
}

export async function openNewCancellationRequestForm(customerId) {
  const popup = document.createElement("div");
  popup.id = "new-cancellation-request-form";
  ReactDOM.render(
    <NewCancellationRequestForm customerId={customerId} />,
    popup
  );
  document.getElementById("app-content-wrapper").appendChild(popup);
  return;
}

export async function createCustomerCancellation(id, data) {
  try {
    await req.post(
      `${serverDomain}/api/v1/customers/${id}/cancellation_request`,
      data
    );
  } catch (err) {
    showErrorToast(
      "Failed to save cancellation request",
      `There was an error that occurred whilst trying to create this cancellation request. ${err}`
    );
    return false;
  }

  return true;
}

export async function openCustomerCreditsTable(customerId) {
  const historyTable = document.createElement("div");
  historyTable.id = "credits-history-parent-div";
  ReactDOM.render(<CreditsHistory customerId={customerId} />, historyTable);
  document.getElementById("app-content-wrapper").appendChild(historyTable);
  return;
}

export async function raiseCredit(customerId) {
  const amountString = document.getElementById(
    "new-customer-credit-form-amount-input"
  ).value;
  if (!amountString || amountString === "") {
    showErrorToast(
      "No Credit Amount",
      "Please enter an amount for this credit in the field provided."
    );
    return;
  }

  const amount = parseFloat(amountString);
  if (!amount) {
    showErrorToast(
      "Invalid Credit Amount",
      "Please enter a valid decimal amount for this credit in the field provided."
    );
    return;
  }

  const reason = document.getElementById(
    "new-customer-credit-form-reason-input"
  ).value;
  if (!reason || reason === "") {
    showErrorToast(
      "No Credit Reason",
      "Please enter a reason for raising this credit in the field provided for future references."
    );
    return;
  }

  try {
    const res = await req.post(
      `${serverDomain}/api/v1/customers/${customerId}/credits`,
      { amount: amount, reason: reason }
    );
    if (res.approved === true) {
      showAlert(
        "Credit Applied",
        "Your credit request has been automatically approved and can now be found in Provisioning."
      );
    } else {
      showAlert(
        "Credit Submitted For Approval",
        "Your credit request has been submitted for approval by a manager."
      );
    }
  } catch (err) {
    showErrorToast(
      "Failed to save customer credit",
      `There was an error that occurred whilst trying to create this customer credit. ${err}`
    );
    return;
  }

  document.getElementById("new-customer-credit-form-parent-div").remove();

  return;
}

export async function listCredits(customerId) {
  try {
    const credits = await req.get(
      `${serverDomain}/api/v1/customers/${customerId}/credits`,
      null,
      "snake"
    );
    const tableBody = document
      .getElementById("credits-history-table-body")
      .querySelectorAll("tbody")[0];
    tableBody.innerHTML = "";

    if (credits === null || credits.length === 0) {
      return;
    }

    for (var i = 0; i < credits.length; i++) {
      const credit = credits[i];
      const row = document.createElement("div");

      const creditCreatedAt = new Date(credit.created_at);
      function checkZero(data) {
        if (data.length === 1) {
          data = "0" + data;
        }
        return data;
      }
      const formattedDate = `${checkZero(
        creditCreatedAt.getDate() + ""
      )}/${checkZero(creditCreatedAt.getMonth() + 1 + "")}/${checkZero(
        creditCreatedAt.getFullYear() + ""
      )}`;

      ReactDOM.render(
        <CreditHistoryRow
          createdAt={formattedDate}
          amount={credit.amount.toLocaleString("en-GB", {
            style: "currency",
            currency: "GBP"
          })}
          reason={credit.reason}
        />,
        row
      );
      tableBody.appendChild(row);
    }
  } catch (err) {
    showErrorToast(
      "Failed to get customer credits",
      `There was an error that occurred whilst trying to get all customer credits. ${err}`
    );
    return;
  }
}

//DETAIL TAB FUNCTIONS --------

export async function switchToTariffInformationTab() {
  document.getElementById("tariff-information-tab").className =
    "customer-detail-table-tab";
  const techFaultsTab = document.getElementById("technical-faults-tab");
  if (techFaultsTab.className.includes("alert-tab")) {
    techFaultsTab.className = "customer-detail-table-tab disabled-alert-tab";
  } else {
    techFaultsTab.className = "customer-detail-table-tab disabled-tab";
  }
  const concernsTab = document.getElementById("concerns-tab");
  if (concernsTab.className.includes("alert-tab")) {
    concernsTab.className = "customer-detail-table-tab disabled-alert-tab";
  } else {
    concernsTab.className = "customer-detail-table-tab disabled-tab";
  }
  document.getElementById("bills-tab").className =
    "customer-detail-table-tab disabled-tab";
  document.getElementById("credits-tab").className =
    "customer-detail-table-tab disabled-tab";
  document.getElementById("documents-tab").className =
    "customer-detail-table-tab disabled-tab";

  document.getElementById("customer-tariff-info-container").style.display =
    "flex";
  document.getElementById("customer-technical-faults-table").style.display =
    "none";
  document.getElementById("customer-concerns-table").style.display = "none";
  document.getElementById("customer-bills-table").style.display = "none";
  document.getElementById("customer-credits-table").style.display = "none";
  document.getElementById("customer-documents-table").style.display = "none";
}

export async function switchToTechnicalFaultsTab(customerId) {
  document.getElementById("tariff-information-tab").className =
    "customer-detail-table-tab disabled-tab";
  const techFaultsTab = document.getElementById("technical-faults-tab");
  if (techFaultsTab.className.includes("alert-tab")) {
    techFaultsTab.className = "customer-detail-table-tab enabled-alert-tab";
  } else {
    techFaultsTab.className = "customer-detail-table-tab";
  }
  const concernsTab = document.getElementById("concerns-tab");
  if (concernsTab.className.includes("alert-tab")) {
    concernsTab.className = "customer-detail-table-tab disabled-alert-tab";
  } else {
    concernsTab.className = "customer-detail-table-tab disabled-tab";
  }
  document.getElementById("bills-tab").className =
    "customer-detail-table-tab disabled-tab";
  document.getElementById("credits-tab").className =
    "customer-detail-table-tab disabled-tab";
  document.getElementById("documents-tab").className =
    "customer-detail-table-tab disabled-tab";

  document.getElementById("customer-tariff-info-container").style.display =
    "none";
  document.getElementById("customer-technical-faults-table").style.display =
    "flex";
  document.getElementById("customer-concerns-table").style.display = "none";
  document.getElementById("customer-bills-table").style.display = "none";
  document.getElementById("customer-credits-table").style.display = "none";
  document.getElementById("customer-documents-table").style.display = "none";

  await listTechnicalFaults(customerId, null, 0);
}

export async function switchToConcernsTab(customerId) {
  document.getElementById("tariff-information-tab").className =
    "customer-detail-table-tab disabled-tab";
  const techFaultsTab = document.getElementById("technical-faults-tab");
  if (techFaultsTab.className.includes("alert-tab")) {
    techFaultsTab.className = "customer-detail-table-tab disabled-alert-tab";
  } else {
    techFaultsTab.className = "customer-detail-table-tab disabled-tab";
  }
  const concernsTab = document.getElementById("concerns-tab");
  if (concernsTab.className.includes("alert-tab")) {
    concernsTab.className = "customer-detail-table-tab enabled-alert-tab";
  } else {
    concernsTab.className = "customer-detail-table-tab";
  }
  document.getElementById("bills-tab").className =
    "customer-detail-table-tab disabled-tab";
  document.getElementById("credits-tab").className =
    "customer-detail-table-tab disabled-tab";
  document.getElementById("documents-tab").className =
    "customer-detail-table-tab disabled-tab";

  document.getElementById("customer-tariff-info-container").style.display =
    "none";
  document.getElementById("customer-technical-faults-table").style.display =
    "none";
  document.getElementById("customer-concerns-table").style.display = "flex";
  document.getElementById("customer-bills-table").style.display = "none";
  document.getElementById("customer-credits-table").style.display = "none";
  document.getElementById("customer-documents-table").style.display = "none";

  await listCustomerConcerns(customerId, 0);
}

export async function switchToBillsTab() {
  window.alert("Bills coming soon");

  // document.getElementById("tariff-information-tab").className = "customer-detail-table-tab disabled-tab";
  // const techFaultsTab = document.getElementById("technical-faults-tab");
  // if (techFaultsTab.className.includes("alert-tab")) {
  //   techFaultsTab.className = "customer-detail-table-tab disabled-alert-tab";
  // } else {
  //   techFaultsTab.className = "customer-detail-table-tab disabled-tab";
  // }
  // const concernsTab = document.getElementById("concerns-tab");
  // if (concernsTab.className.includes("alert-tab")) {
  //   concernsTab.className = "customer-detail-table-tab disabled-alert-tab";
  // } else {
  //   concernsTab.className = "customer-detail-table-tab disabled-tab";
  // }
  // document.getElementById("bills-tab").className = "customer-detail-table-tab";
  // document.getElementById("credits-tab").className = "customer-detail-table-tab disabled-tab";
  // document.getElementById("documents-tab").className = "customer-detail-table-tab disabled-tab";
  //
  // document.getElementById("customer-tariff-info-container").style.display = "none";
  // document.getElementById("customer-technical-faults-table").style.display = "none";
  // document.getElementById("customer-concerns-table").style.display = "none";
  // document.getElementById("customer-bills-table").style.display = "flex";
  // document.getElementById("customer-credits-table").style.display = "none";
  // document.getElementById("customer-documents-table").style.display = "none";
}

export async function switchToCreditsTab() {
  document.getElementById("tariff-information-tab").className =
    "customer-detail-table-tab disabled-tab";
  const techFaultsTab = document.getElementById("technical-faults-tab");
  if (techFaultsTab.className.includes("alert-tab")) {
    techFaultsTab.className = "customer-detail-table-tab disabled-alert-tab";
  } else {
    techFaultsTab.className = "customer-detail-table-tab disabled-tab";
  }
  const concernsTab = document.getElementById("concerns-tab");
  if (concernsTab.className.includes("alert-tab")) {
    concernsTab.className = "customer-detail-table-tab disabled-alert-tab";
  } else {
    concernsTab.className = "customer-detail-table-tab disabled-tab";
  }
  document.getElementById("bills-tab").className =
    "customer-detail-table-tab disabled-tab";
  document.getElementById("credits-tab").className =
    "customer-detail-table-tab";
  document.getElementById("documents-tab").className =
    "customer-detail-table-tab disabled-tab";

  document.getElementById("customer-tariff-info-container").style.display =
    "none";
  document.getElementById("customer-technical-faults-table").style.display =
    "none";
  document.getElementById("customer-concerns-table").style.display = "none";
  document.getElementById("customer-bills-table").style.display = "none";
  document.getElementById("customer-credits-table").style.display = "flex";
  document.getElementById("customer-documents-table").style.display = "none";
}

export async function switchToDocumentsTab() {
  window.alert("Documents coming soon");

  // document.getElementById("tariff-information-tab").className = "customer-detail-table-tab disabled-tab";
  // const techFaultsTab = document.getElementById("technical-faults-tab");
  // if (techFaultsTab.className.includes("alert-tab")) {
  //   techFaultsTab.className = "customer-detail-table-tab disabled-alert-tab";
  // } else {
  //   techFaultsTab.className = "customer-detail-table-tab disabled-tab";
  // }
  // const concernsTab = document.getElementById("concerns-tab");
  // if (concernsTab.className.includes("alert-tab")) {
  //   concernsTab.className = "customer-detail-table-tab disabled-alert-tab";
  // } else {
  //   concernsTab.className = "customer-detail-table-tab disabled-tab";
  // }
  // document.getElementById("bills-tab").className = "customer-detail-table-tab disabled-tab";
  // document.getElementById("credits-tab").className = "customer-detail-table-tab disabled-tab";
  // document.getElementById("documents-tab").className = "customer-detail-table-tab";
  //
  // document.getElementById("customer-tariff-info-container").style.display = "none";
  // document.getElementById("customer-technical-faults-table").style.display = "none";
  // document.getElementById("customer-concerns-table").style.display = "none";
  // document.getElementById("customer-bills-table").style.display = "none";
  // document.getElementById("customer-credits-table").style.display = "none";
  // document.getElementById("customer-documents-table").style.display = "flex";
}

//END: DETAIL TAB FUNCTIONS ---------

export async function getLineRentalPrices() {
  try {
    const prices = await req.get(
      `${serverDomain}/api/v1/line_rental_prices`,
      null,
      "snake"
    );
    return [prices, null];
  } catch (err) {
    return [null, err];
  }
}

export async function getBroadbandPackages() {
  try {
    const packages = await req.get(
      `${serverDomain}/api/v1/broadband_packages`,
      null,
      "snake"
    );
    return [packages, null];
  } catch (err) {
    return [null, err];
  }
}
