import React, { useContext, useEffect, useState } from "react";

import { DropdownInput } from "../generic/DropdownInput";
import { MultiLineInput } from "../generic/MultiLineInput";
import PrimaryActionButton from "../generic/PrimaryActionButton";
import { ModalInputGrid, ModalRow } from "../generic/modal/Modal";
import {
  generateWelcomePack,
  getLiveRateAgents,
  getWelcomeCallsForCustomer,
  saveWelcomeCall
} from "../../functions/provisioning_functions";
import { showErrorToast } from "../../utils/ErrorHandler";

import "../../styles/Provisioning/WelcomeCallsSection.css";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../utils/LoadingHandler";
import { ProvisioningCustomerContext } from "./ProvisioningDetail";
import { showSuccessToast } from "../../utils/SuccessHandler";

export const WelcomeCallsSection = () => {
  const { customer, setCustomer } = useContext(ProvisioningCustomerContext);
  const [welcomeCalls, setWelcomeCalls] = useState([]);
  const [triggerUpdate, setTriggerUpdate] = useState(false);

  // Get any existing welcome call data using the customer ID
  useEffect(() => {
    (async () => {
      if (!customer || !customer.id) {
        return;
      }
      const [calls, err] = await getWelcomeCallsForCustomer(customer.id);
      if (err) {
        showErrorToast(
          "Failed to get welcome call data",
          "There was an error that occurred whilst trying to fetch the welcome call data for this deal. Please try again later."
        );
      } else if (calls) {
        console.log(calls);
        setWelcomeCalls(calls);
      }
    })();
  }, [customer, triggerUpdate]);

  return (
    <div className="welcome-calls-section">
      <ModalInputGrid>
        <NewWelcomeCallForm
          triggerUpdate={triggerUpdate}
          setTriggerUpdate={setTriggerUpdate}
        />
        <div className="welcome-call-log-list">
          {welcomeCalls.map(call => {
            return <WelcomeCallLog call={call} />;
          })}
        </div>
      </ModalInputGrid>
    </div>
  );
};

const WelcomeCallLog = ({ call }) => {
  return (
    <div className="welcome-call-log">
      <span className="call-rating-text">
        Rating:{" "}
        <p className={`call-rating ${call.rating}`}>
          {call.rating.toUpperCase()}
        </p>
      </span>
      <span>Called By: {call.agent_name}</span>
      <span>Called At: {new Date(call.created_at).toLocaleDateString()}</span>
      <span>Notes: {call.notes}</span>
    </div>
  );
};

const NewWelcomeCallForm = ({ triggerUpdate, setTriggerUpdate }) => {
  const { customer, setCustomer } = useContext(ProvisioningCustomerContext);
  const [newWCData, setNewWCData] = useState({});
  const [liveRateAgents, setLiveRateAgents] = useState([]);

  // Get closing agents
  useEffect(() => {
    (async () => {
      const [agents, err] = await getLiveRateAgents();
      if (err) {
        showErrorToast(
          "Failed to get live rate agents",
          "There was an error whilst trying to retrieve the live rate agents from the database. Please try again later."
        );
        return;
      } else {
        setLiveRateAgents(agents || []);
      }
    })();
  }, []);

  return (
    <div className="new-wc-form">
      <DropdownInput
        id={"nwc-rating-dropdown"}
        title={"Rating"}
        placeholder={"Select a rating..."}
        options={[
          { title: "Excellent", value: "excellent" },
          { title: "Good", value: "good" },
          { title: "Average", value: "average" },
          { title: "Poor", value: "poor" },
          { title: "No Answer", value: "no_answer" }
        ]}
        onChange={e => {
          setNewWCData({
            ...newWCData,
            rating:
              e.target.value !== "Select a rating..." ? e.target.value : null
          });
        }}
      />
      <MultiLineInput
        id={"nwc-notes-input"}
        title={"Notes"}
        placeholder={"Enter any feedback for the welcome call in here..."}
        onChange={e => {
          setNewWCData({
            ...newWCData,
            notes:
              e.target.value !==
              "Enter any feedback for the first welcome call in here..."
                ? e.target.value
                : null
          });
        }}
      />
      <DropdownInput
        id={"wc-agent-dropdown"}
        title={"Agent"}
        placeholder={"Select an agent..."}
        options={liveRateAgents.map(agent => {
          return {
            title: `${agent.first_name} ${agent.last_name}`,
            value: agent.id
          };
        })}
        onChange={e => {
          setNewWCData({
            ...newWCData,
            agent_id: !isNaN(parseInt(e.target.value))
              ? parseInt(e.target.value)
              : null
          });
        }}
      />
      <PrimaryActionButton
        id={"save-welcome-calls-btn"}
        title={"Log Welcome Call"}
        onClick={async () => {
          showLoadingToast();

          const err = await saveWelcomeCall(customer.id, newWCData);
          setTriggerUpdate(!triggerUpdate);
          removeLoadingToast();

          if (err) {
            showErrorToast("Failed to save welcome call");
            return;
          }

          showSuccessToast(
            "Successfully saved welcome call data",
            "The welcome call information for this deal has been successfully updated."
          );
        }}
      />
      <PrimaryActionButton
        id={"generate-welcome-pack-btn"}
        title={"Generate Welcome Pack"}
        onClick={async () => {
          showLoadingToast();

          const err = await generateWelcomePack(customer.account_id);
          removeLoadingToast();

          if (err) {
            showErrorToast("Failed to generate welcome pack");
            return;
          }

          showSuccessToast(
            "Successfully saved welcome pack",
            "The welcome call pack for this deal has been successfully generated."
          );
        }}
      />
    </div>
  );
};
