import React from "react";
import { func } from "prop-types";

function CrossIcon({ onClick }) {
  return (
    <button className="cross-icon" onClick={onClick}>
      <svg width="17px" height="17px" viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
        <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Dashboard---Calling-Session-Script" transform="translate(-611.000000, -161.000000)" fill="#FFFFFF">
                <g id="Group-4" transform="translate(296.000000, 130.000000)">
                    <g id="Group-4-Copy-7" transform="translate(301.000000, 17.000000)">
                        <path d="M14.6606854,29.2869397 L21.2436858,22.6932454 L14.6606854,16.1098375 C14.3109635,15.760094 14.3109635,15.1737592 14.6606854,14.8137291 C15.0104073,14.4434124 15.6172776,14.453699 15.9669995,14.8137291 L22.55,21.3971369 L29.1330005,14.8137291 C29.4827224,14.453699 30.0793068,14.453699 30.4393146,14.8137291 C30.7993225,15.1634727 30.7890365,15.760094 30.4393146,16.1098375 L23.8563142,22.6932454 L30.4393146,29.2869397 C30.7890365,29.6366833 30.7890365,30.223018 30.4393146,30.5830481 C30.0895927,30.9430783 29.4827224,30.9327917 29.1330005,30.5830481 L22.55,23.9996403 L15.9669995,30.5830481 C15.6172776,30.9327917 15.0206932,30.9327917 14.6606854,30.5830481 C14.3006775,30.2333046 14.3109635,29.6366833 14.6606854,29.2869397 Z" id="CrossIcon"></path>
                    </g>
                </g>
            </g>
        </g>
      </svg>
    </button>
  );
}

CrossIcon.propTypes = { onClick: func };

export default CrossIcon;
