import React from "react";

// Styles
import "../../styles/generic/SectionHeader.css";

export const SectionHeader = ({ label }) => {
  return (
    <div>
      <span className="section-title">{label}</span>
    </div>
  );
};
