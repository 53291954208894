import firebase from "../firebase.js";
import { getSelectedCompany } from "./constants.js";
import { capitalize } from "./helpers.js";

export function resolveBoolean(b) {
  if (typeof b !== "boolean") {
    return b;
  }

  return b ? "Yes" : "No";
}

export function resolveDate(d) {
  if (!(d instanceof firebase.firestore.Timestamp)) {
    return d;
  }

  return d.toDate().toLocaleString("en-US");
}

export function resolveCustomerData(customerData) {
  // final result
  const result = {};

  // used to avoid updating function paramter which is bad for optimization
  const updatedData = customerData;

  // updatedData.broadband_type = resolveBroadbandType(updatedData.broadband_type);

  // updatedData.broadband_price = resolveBroadbandPrice(
  //   updatedData.broadband_price
  // );
  // updatedData.package_price = resolvePackagePrice(updatedData.package_price);

  Object.keys(updatedData).forEach(k => {
    if (typeof updatedData[k] === "boolean") {
      updatedData[k] = resolveBoolean(updatedData[k]);
    }

    if (updatedData[k] instanceof firebase.firestore.Timestamp) {
      updatedData[k] = resolveDate(updatedData[k]);
    }

    result[capitalize(k.split("_"))] = updatedData[k];
  });

  return result;
}
