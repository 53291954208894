import React, { useState } from "react";
import { Table } from "../views/generic/Table";
import Screen from "./Screen";
import "../styles/broadband-orders/BroadbandOrders.css";
import TabViewBar from "../views/generic/TabViewBar";
import TabViewContainer from "../views/generic/TabViewContainer";

function BroadbandOrders() {
  const [tabView, setTabView] = useState("application");

  return (
    <Screen>
      <div className="broadband-orders-screen">
        <TabViewBar
          selectedTab={tabView}
          tabs={[
            { id: "application", display_text: "To Be Applied" },
            { id: "pending", display_text: "Pending" },
            { id: "committed", display_text: "Committed" },
            { id: "live", display_text: "Broadband Live" }
          ]}
          onSwitch={tab => {
            setTabView(tab);
          }}
        />
        <TabViewContainer id={"broadband-orders-tab-view"}>
          {tabView === "application" && (
            <Table
              headers={["Date", "Account Number", "Customer Name", "CLI"]}
              rowData={[]}
            />
          )}
          {tabView === "pending" && (
            <Table
              headers={[
                "Date",
                "Account Number",
                "Customer Name",
                "CLI",
                "Status"
              ]}
              rowData={[]}
            />
          )}
          {tabView === "committed" && (
            <Table
              headers={[
                "Date",
                "Account Number",
                "Customer Name",
                "CLI",
                "Router"
              ]}
              rowData={[]}
            />
          )}
          {tabView === "live" && (
            <Table
              headers={["Live Date", "Account Number", "Customer Name", "CLI"]}
              rowData={[]}
            />
          )}
        </TabViewContainer>
      </div>
    </Screen>
  );
}

export default BroadbandOrders;
