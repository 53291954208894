import { serverDomain } from "../utils/constants";
import * as req from "../utils/request";

export async function getCallRecordingsForCLI(cli) {
  try {
    const recordings = await req.get(
      `${serverDomain}/api/v1/call_recordings?cli=${cli}`
    );
    return [recordings, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function getWavData(id) {
  try {
    const wavData = await req.get(
      `${serverDomain}/api/v1/call_recordings/play?id=${id}`
    );
    return [wavData, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}
