import { getTokens } from "../../utils/tokens";
import { showErrorToast } from "../../utils/ErrorHandler";
import { currentUser, fetchCurrentUser, showAlert } from "../core_functions.js";
import {
  serverDomain,
  getSelectedCompany,
  selectedCompanyID
} from "../../utils/constants";
import {
  setMPFIndicatorState,
  setTPSIndicatorState,
  setTagsIndicatorState
} from "./calling_session_functions.js";
import {
  listCommentsRequest,
  displayRecordComments
} from "./record-comments-functions.js";

import * as request from "../../utils/request";
import RecordComment from "../../components/RecordComment.js";
import RescheduleCallbackPopup from "../../components/RescheduleCallbackPopup.js";
import React from "react";
import ReactDOM from "react-dom";

import NewCallbackForm from "../../views/outbound-calls/callbacks/NewCallbackForm";
import ScheduledCallbacks from "../../views/outbound-calls/callbacks/ScheduledCallbacks";
import ScheduledCallbackCell from "../../views/outbound-calls/callbacks/ScheduledCallbackCell";
import { capitalize } from "../../utils/helpers";
import { closeModal } from "../../views/generic/modal/Modal";
import { createRoot } from "react-dom/client";
import CallbackDetailView from "../../views/outbound-calls/call-history/CallbackDetailView";

//Create Callback - Used when the user wishes to schedule a callback with the customer at a later date.
export async function createCallback(number, dateTimeTextVal, notes) {
  //Gets the date/time from the input field that the user has selected.
  var d = new Date(dateTimeTextVal);
  try {
    await request.post(serverDomain + "/api/v1/callbacks", {
      cli: number,
      callback_on: d,
      notes: notes
    });
    return null;
  } catch (err) {
    console.error(err);
    return err;
  }
}

var setCallbacks;
export function assignSetCallbackFunction(func) {
  setCallbacks = func;
}

var setCountdownText;
export function assignSetCountdownTextFunction(func) {
  setCountdownText = func;
}

// Opens the scheduled callbacks popup
export async function openScheduledCallbacks(
  selectedRecord,
  setSelectedRecord
) {
  const div = document.createElement("div");
  div.id = "modal-div-wrapper";
  document.getElementById("app-content-wrapper").appendChild(div);
  const modal = (
    <ScheduledCallbacks
      selectedRecord={selectedRecord}
      setSelectedRecord={setSelectedRecord}
    />
  );
  const root = createRoot(document.getElementById("modal-div-wrapper"));
  root.render(modal);
}

// Opens the arrange callback popup
export function openArrangeCallbackPopup(selectedRecord) {
  const div = document.createElement("div");
  div.id = "modal-div-wrapper";
  document.getElementById("app-content-wrapper").appendChild(div);
  const modal = (
    <NewCallbackForm
      saveAction={async () => {
        const res = await newCallbackSaveButtonAction(selectedRecord);
        if (res && res === true) {
          closeModal();
        }
      }}
    />
  );
  const root = createRoot(document.getElementById("modal-div-wrapper"));
  root.render(modal);
}

// Closes the arrange callback popup
export function closeArrangeCallbackPopup() {
  //deprecated - needs replacing with new popup
  let popup = document.getElementById("arrange-cb-popup");
  if (popup !== null) {
    popup.style.display = "none";
  }
}

export async function newCallbackSaveButtonAction(selectedRecord) {
  const dateTimeInput = document.getElementById("new-callback-datetime");
  const notesInput = document.getElementById("new-callback-notes");

  if (dateTimeInput !== null && notesInput !== null) {
    const dateTimeTextVal = dateTimeInput.value;
    const notes = notesInput.value;

    if (dateTimeTextVal === "" || dateTimeTextVal === null) {
      window.alert("Missing Callback Date");
    } else {
      const res = await createCallback(
        selectedRecord.cli.replace(" ", ""),
        dateTimeTextVal,
        notes
      );

      const callbacks = await listCallbacks();
      setCallbacks(callbacks);
      setCountdownText(calcCountdownText(callbacks));
      return res;
    }
  }
}

export function calcCountdownText(callbacks) {
  if (callbacks.length > 0) {
    var firstCallbackDate = new Date(callbacks[0].callback_on);

    const today = new Date();
    var userTimezoneOffset = today.getTimezoneOffset() * 60000;
    const todayWithoutTZ = new Date(today.getTime() - userTimezoneOffset);

    const isToday = someDate => {
      return (
        someDate.getDate() === today.getDate() &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    };
    const isTomorrow = someDate => {
      const today = new Date();
      return (
        someDate.getDate() === today.getDate() + 1 &&
        someDate.getMonth() === today.getMonth() &&
        someDate.getFullYear() === today.getFullYear()
      );
    };

    if (firstCallbackDate < today) {
      return `You have no callbacks scheduled in the near future.`;
    } else if (isToday(firstCallbackDate)) {
      return `Your next callback is scheduled for Today at ${firstCallbackDate
        .toLocaleTimeString()
        .slice(0, 5)}`;
    } else if (isTomorrow(firstCallbackDate)) {
      return `Your next callback is scheduled for Tomorrow at ${firstCallbackDate
        .toLocaleTimeString()
        .slice(0, 5)}`;
    } else {
      return `Your next callback is scheduled for the ${firstCallbackDate.toLocaleDateString()}`;
    }
  } else {
    return `You have no scheduled callbacks.`;
  }
}

export async function openCallbackRecord(phone, setSelectedRecord) {
  try {
    const record = await request.get(
      serverDomain + "/api/v1/records/" + phone,
      null,
      "snake"
    );

    // const fullAddressFormatted = capitalize(record.ful;address.split(" "));
    // const address1Formatted = capitalize(record.address_line1.split(" "));

    // var address2Formatted;
    // if (record.address_line2) {
    //   address2Formatted = capitalize(record.address_line2.split(" "));
    // }
    // const townCityFormatted = capitalize(record.address_towncity.split(" "));

    // var countyFormatted;
    // if (record.address_county) {
    //   countyFormatted = capitalize(record.address_county.split(" "));
    // }

    // record.address =
    //   fullAddressFormatted.slice(
    //     0,
    //     fullAddressFormatted.length - record.address_postcode.length
    //   ) + record.address_postcode;
    // record.address_line1 = address1Formatted;
    // record.address_line2 = address2Formatted;
    // record.address_towncity = townCityFormatted;
    // record.address_county = countyFormatted;

    // setCurrentRecord(record);

    // const [comments, error] = await listCommentsRequest(record.cli);
    // if (comments) {
    //   await displayRecordComments(comments);
    // }

    // document.getElementsByClassName(
    //   "empty-record-state-container"
    // )[0].style.display = "none";
    // document.getElementsByClassName(
    //   "active-record-container"
    // )[0].style.display = "flex";

    return record;

    // document.getElementById("active-record-cli-lbl").textContent =
    //   selectedRecord.cli.replace(" ", "");
    // document.getElementById(
    //   "active-record-name-lbl"
    // ).textContent = `${selectedRecord.person_name}`;
    // document.getElementById(
    //   "active-record-address-lbl"
    // ).textContent = `${selectedRecord.full_address}`;

    setTPSIndicatorState(true);

    // if (selectedRecord.check_completed === "mpf") {
    //   setMPFIndicatorState(true);
    //   setTagsIndicatorState(false);
    // } else {
    //   setTagsIndicatorState(true);
    //   setMPFIndicatorState(false);
    // }
  } catch (err) {
    console.error(err);
    window.alert(
      "Failed to get record related to callback. Please contact technical support for further assistance."
    );
    return null;
  }
}

export async function listCallbacks() {
  try {
    const callbacks = await request.get(serverDomain + "/api/v1/callbacks");
    return [callbacks, null];
  } catch (err) {
    console.error(err);
    return [null, err];
  }
}

export async function displayCallbacks(callbacks) {
  const tableBody = document.getElementsByClassName("callbacks-wrapper")[0];

  if (tableBody && callbacks) {
    tableBody.innerHTML = "";

    if (callbacks.length > 0) {
      callbacks.forEach((callback, i) => {
        const id = Math.random() * 16;
        const d = document.createElement("div");
        d.id = id;
        tableBody.appendChild(d);

        const callbackDateTime = new Date(callback.callback_on);

        function checkZero(data) {
          if (data.length === 1) {
            data = "0" + data;
          }
          return data;
        }

        const formattedDate = `${checkZero(
          callbackDateTime.getDate() + ""
        )}/${checkZero(callbackDateTime.getMonth() + 1 + "")}/${checkZero(
          callbackDateTime.getFullYear() + ""
        )} ${checkZero(callbackDateTime.getHours() + "")}:${checkZero(
          callbackDateTime.getMinutes() + ""
        )}`;

        // ReactDOM.render(
        //   <ScheduledCallbackCell containerId={id} callback={callback} />,
        //   document.getElementById(id)
        // );
      });
    }
  }
}

export async function openRescheduleCallbackForm(phone) {
  const popup = document.createElement("div");
  popup.id = "reschedule-callback-popup";
  ReactDOM.render(
    <RescheduleCallbackPopup
      rescheduleCallback={async () => {
        const dateTimeString = document.getElementById(
          "reschedule-cb-datetime"
        ).value;
        rescheduleCallback(phone, dateTimeString);
      }}
    />,
    popup
  );
  document.getElementById("app-content-wrapper").appendChild(popup);
  return;
}

export async function rescheduleCallback(phone, newTime) {
  if (!phone || phone === "") {
    showErrorToast(
      "CLI empty",
      "Cannot proceed when the CLI cannot be found. Please try refreshing the page and rescheduling."
    );
    return;
  }

  if (!newTime || newTime === "") {
    showErrorToast(
      "Reschedule time empty",
      "The time/date field cannot be empty, please insert a value into the field in order to proceed."
    );
    return;
  }

  try {
    await request.put(`${serverDomain}/api/v1/callbacks/${phone}`, {
      callback_on: new Date(newTime).toISOString()
    });
  } catch (err) {
    console.log(err);

    showErrorToast(
      "Failed to reschedule callback",
      `There was an error that occurred when trying to update the callback date/time.\n\nError: ${err}`
    );
    return;
  }

  const callbacks = await listCallbacks();
  await displayCallbacks(callbacks);
  setCountdownText(calcCountdownText(callbacks));
}

export async function deleteScheduledCallback(
  phone,
  selectedRecord,
  setSelectedRecord
) {
  if (!phone) {
    showErrorToast(
      "Missing phone",
      "Function was expecting the callback phone number to be passed, but is missing."
    );
    return;
  }

  try {
    await request.del(serverDomain + "/api/v1/callbacks/" + phone);
  } catch (err) {
    showErrorToast(
      "Failed to delete callback",
      `There was an error that occurred whilst trying to delete this callback from the database.\n\nError: ${err}`
    );
    return;
  }

  if (selectedRecord && selectedRecord.cli === phone) {
    setSelectedRecord({});
    window.location.reload();
    return;
  }

  const callbacks = await listCallbacks();
  await displayCallbacks(callbacks);
  setCallbacks(callbacks);
  setCountdownText(calcCountdownText(callbacks));
}

export async function updateScheduledCallbackNotes(phone, notes) {
  if (!phone || phone === "") {
    showErrorToast(
      "CLI empty",
      "Cannot proceed when the CLI cannot be found. Please try refreshing the page and rescheduling."
    );
    return;
  }

  if (!notes || notes === "") {
    showErrorToast(
      "Notes empty",
      "Cannot proceed when the notes cannot be found. Please try refreshing the page and updating the notes."
    );
    return;
  }

  try {
    await request.put(`${serverDomain}/api/v1/callbacks/${phone}`, {
      notes: notes
    });
  } catch (err) {
    console.log(err);

    showErrorToast(
      "Failed to update callback",
      `There was an error that occurred when trying to update the callback notes.\n\nError: ${err}`
    );
    return;
  }

  const callbacks = await listCallbacks();
  await displayCallbacks(callbacks);
  setCountdownText(calcCountdownText(callbacks));
}

export function openCallbackDetailForm(callback, openRecord) {
  const div = document.createElement("div");
  div.id = "modal-div-wrapper";
  document.getElementById("app-content-wrapper").appendChild(div);
  const modal = (
    <CallbackDetailView callback={callback} openRecord={openRecord} />
  );
  const root = createRoot(document.getElementById("modal-div-wrapper"));
  root.render(modal);
}
