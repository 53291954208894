import React from "react";
import { func } from "prop-types";

function SendMessageButton({ onClick }) {
  return (
    <button className="send-message-icon" onClick={onClick}>
      <svg width="41px" height="41px" viewBox="0 0 41 41" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
          <g id="Page-1" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
              <g id="Dashboard---Calling-Session" transform="translate(-1339.000000, -925.000000)">
                  <g id="Send-button" transform="translate(1339.000000, 925.000000)">
                      <rect id="Rectangle" fill="black" x="0" y="0" width="41" height="41" rx="15"></rect>
                      <path d="M20.0797915,12.503194 L17.119873,15.7919922 L14.7053223,18.1728516 C14.607991,18.2701828 14.4938158,18.3450518 14.362793,18.3974609 C14.2317702,18.4498701 14.0913907,18.4760742 13.9416504,18.4760742 C13.6421697,18.4760742 13.3951019,18.3768727 13.2004395,18.1784668 C13.005777,17.9800609 12.9084473,17.7311213 12.9084473,17.4316406 C12.9084473,17.147134 13.0207508,16.8888358 13.2453613,16.6567383 L20.2644043,9.62646484 C20.3767095,9.50667258 20.5021152,9.41682973 20.640625,9.35693359 C20.7791348,9.29703746 20.9195143,9.26708984 21.0617676,9.26708984 C21.2115079,9.26708984 21.3556308,9.29703746 21.4941406,9.35693359 C21.6326504,9.41682973 21.7580561,9.50667258 21.8703613,9.62646484 L28.8894043,16.6567383 C29.1140148,16.8888358 29.2263184,17.147134 29.2263184,17.4316406 C29.2263184,17.7311213 29.1271169,17.9800609 28.9287109,18.1784668 C28.730305,18.3768727 28.4851089,18.4760742 28.1931152,18.4760742 C28.0433749,18.4760742 27.9029954,18.4498701 27.7719727,18.3974609 C27.6409499,18.3450518 27.5267746,18.2701828 27.4294434,18.1728516 L25.0036621,15.7919922 L22.0558424,12.526715 L22.1398926,14.8037109 L22.1398926,28.3251953 C22.1398926,28.647137 22.0388194,28.907307 21.8366699,29.1057129 C21.6345205,29.3041188 21.3762223,29.4033203 21.0617676,29.4033203 C20.7473129,29.4033203 20.4908864,29.3041188 20.2924805,29.1057129 C20.0940745,28.907307 19.994873,28.647137 19.994873,28.3251953 L19.994873,14.8037109 L20.0797915,12.503194 Z" id="UpArrow" fill="#FFFFFF"></path>
                  </g>
              </g>
          </g>
      </svg>
    </button>
  );
}

SendMessageButton.propTypes = { onClick: func };

export default SendMessageButton;
