import React from "react";

import "../../styles/generic/DataPoint.css";

export const DataPoint = ({ title, value }) => {
  return (
    <div className="data-point-wrapper">
      <span className="title-lbl">{title}</span>
      <button
        className="value-lbl"
        onClick={() => {
          navigator.clipboard.writeText(value);
        }}
      >
        {value}
      </button>
    </div>
  );
};
