import React from "react";
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalRow,
  ModalSection
} from "../../generic/modal/Modal";
import PrimaryActionButton from "../../generic/PrimaryActionButton";

export const CallbackDetailView = ({ callback, openRecord }) => {
  return (
    <Modal id={"callback-detail-view"}>
      <ModalHeader title={"Callback Detail"} />
      <ModalContent>
        <ModalSection title={"Notes"}>
          <ModalRow>
            <span>{callback.notes || "No callback notes recorded."}</span>
          </ModalRow>
        </ModalSection>
      </ModalContent>
      <ModalFooter>
        <PrimaryActionButton
          id={"open-record-btn"}
          title={"Open Record"}
          onClick={openRecord}
        />
      </ModalFooter>
    </Modal>
  );
};

export default CallbackDetailView;
