import React, { useContext, useEffect, useRef, useState } from "react";

// Styles
import "../../../styles/outbound-calls/calling-session-script/CallingSessionScriptView.css";
import { SelectedCompanyContext } from "../../../Main";
import PrimaryActionButton from "../../generic/PrimaryActionButton";

// Assets
import HandoverIcon from "../../../assets/interface-icons/hand-paper.svg";
import ObjectionIcon from "../../../assets/interface-icons/ban.svg";
import DDIcon from "../../../assets/interface-icons/bank.svg";
import CloseIcon from "../../../assets/interface-icons/handshake.svg";

const CallingSessionScriptView = () => {
  const [selectedScriptTab, setSelectedScriptTab] = useState("handover");
  const [pdfLink, setPdfLink] = useState("");

  const { selectedCompany, setSelectedCompany } = useContext(
    SelectedCompanyContext
  );

  useEffect(() => {
    if (selectedCompany) {
      setPdfLink(pdfLinkForTab());
    }
  }, [selectedScriptTab, selectedCompany]);

  function pdfLinkForTab() {
    switch (selectedScriptTab) {
      case "handover":
        return (() => {
          switch (selectedCompany.id) {
            default:
              return "https://firebasestorage.googleapis.com/v0/b/transparent-telecom.appspot.com/o/scripts%2Fpremier-talk-handover-script.pdf?alt=media&token=ce4bda54-f8d9-4284-81be-aed89366b02f";
            case 5:
              return "https://firebasestorage.googleapis.com/v0/b/transparent-telecom.appspot.com/o/scripts%2FLime%20Handover%20Script.pdf?alt=media&token=f759032d-8f53-4375-9385-760071054ec0";
          }
        })();
      case "closing":
        return (() => {
          switch (selectedCompany.id) {
            default:
              return "https://firebasestorage.googleapis.com/v0/b/transparent-telecom.appspot.com/o/scripts%2Fpremier-talk-closing-script.pdf?alt=media&token=df259473-af02-4b70-9228-a96de59d9624";
            case 5:
              return "https://firebasestorage.googleapis.com/v0/b/lime-29de9.appspot.com/o/docs%2Fscripts%2FLime%20Closing%20Script.pdf?alt=media&token=c867509f-5e64-4b4d-a140-c997aa006fdc";
          }
        })();
      case "dd-objections":
        return "https://firebasestorage.googleapis.com/v0/b/transparent-telecom.appspot.com/o/scripts%2FDD%20Objections.pdf?alt=media&token=b5896cb9-81dd-4e58-a86d-224d75101e3b";
      case "handover-objections":
        return "https://firebasestorage.googleapis.com/v0/b/lime-29de9.appspot.com/o/docs%2Fscripts%2FHandover%20Objections.pdf?alt=media&token=d8eef578-bc02-4a70-bec8-0082cef594da";
    }
  }

  return (
    <div className="calling-session-script-view">
      <div className="tabs">
        <PrimaryActionButton
          id="handover"
          icon={HandoverIcon}
          title={"Handover"}
          style={"blurred"}
          onClick={async () => {
            setSelectedScriptTab("handover");
          }}
        />
        <PrimaryActionButton
          id="handover-objections"
          icon={ObjectionIcon}
          title={"H. Objections"}
          style={"blurred"}
          onClick={async () => {
            setSelectedScriptTab("handover-objections");
          }}
        />
        <PrimaryActionButton
          id="closing"
          icon={CloseIcon}
          title={"Closing"}
          style={"blurred"}
          onClick={async () => {
            setSelectedScriptTab("closing");
          }}
        />
        <PrimaryActionButton
          id="dd-objections"
          icon={DDIcon}
          title={"DD Objections"}
          style={"blurred"}
          onClick={async () => {
            setSelectedScriptTab("dd-objections");
          }}
        />
      </div>
      <div className="script-wrapper">
        <div className="script-text">
          {pdfLink !== "" && (
            <iframe className="pdf-viewer" src={`${pdfLink}#toolbar=0`} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CallingSessionScriptView;
