import React, { useState } from "react";
import Modal, {
  ModalContent,
  ModalHeader,
  ModalInputGrid,
  ModalRow,
  ModalSection,
  closeModal
} from "../generic/modal/Modal";
import { DropdownInput } from "../generic/DropdownInput";
import PrimaryActionButton from "../generic/PrimaryActionButton";
import { archiveNumber } from "../../functions/outbound-calls/calling_session_functions";
import { showWarningToast } from "../../utils/WarningHandler";
import { MultiLineInput } from "../generic/MultiLineInput";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../utils/LoadingHandler";
import { showErrorToast } from "../../utils/ErrorHandler";
import { showSuccessToast } from "../../utils/SuccessHandler";

export const ArchiveRecordForm = ({ record, onCompletion = () => {} }) => {
  const notInterestedReasons = [
    { title: "Intro", value: "intro" },
    { title: "BT Story", value: "bt_story" },
    { title: "Confirming Package", value: "confirming_package" },
    { title: "Bill Breakdown", value: "bill_breakdown" },
    { title: "YIA", value: "yia" },
    { title: "Qualifying Questions", value: "qualifying_questions" },
    { title: "Setting Agenda", value: "setting_agenda" },
    { title: "Direct Debit", value: "direct_debit" },
    { title: "Consolidation", value: "consolidation" },
    { title: "Reco", value: "reco" },
    { title: "Other", value: "other" }
  ];

  const [notInterestedReason, setNotInterestedReason] = useState(null);
  const [notInterestedNotes, setNotInterestedNotes] = useState(null);

  return (
    <Modal id={"archive-record-form"}>
      <ModalHeader title={"Archive Record"} />
      <ModalContent>
        <ModalSection title={"Not Interested"}>
          <ModalRow>
            <span className="desc-lbl">
              If the customer was lost in the script because they were not
              interested after battling with objections, then please select one
              of the following reasons why.
            </span>
          </ModalRow>
          <ModalInputGrid>
            <DropdownInput
              id={"archive-record-not-interested-dropdown"}
              placeholder={"Select a reason..."}
              options={notInterestedReasons}
              onChange={e => {
                if (e.target.value && e.target.value !== "Select a reason...") {
                  setNotInterestedReason(e.target.value);
                } else {
                  setNotInterestedReason(null);
                }
              }}
            />
            <div />
            <MultiLineInput
              id={"archive-not-interested-notes-input"}
              placeholder={"Any additional notes..."}
              onChange={e => {
                if (
                  e.target.value &&
                  e.target.value !== "Any additional notes..."
                ) {
                  setNotInterestedNotes(e.target.value);
                } else {
                  setNotInterestedNotes(null);
                }
              }}
            />
          </ModalInputGrid>
          <ModalRow>
            <PrimaryActionButton
              id={"archive-record-not-interested-btn"}
              title={"Confirm Archive"}
              onClick={async () => {
                if (!notInterestedReason) {
                  showWarningToast(
                    "Missing not interested reason",
                    "Please provide a location from the dropdown to which the point the customer because uninterested."
                  );
                  return;
                }
                showLoadingToast();
                const err = await archiveNumber(
                  record.id,
                  "not_interested",
                  notInterestedReason,
                  notInterestedNotes
                );
                if (err) {
                  showErrorToast(
                    "Failed to archive record",
                    "There was an error that occurred whilst trying to archive this record."
                  );
                  removeLoadingToast();
                  return;
                }
                showSuccessToast(
                  "Successfully archive record",
                  "This record has now been archived as not interested."
                );

                closeModal();
                onCompletion();
              }}
            />
          </ModalRow>
        </ModalSection>
        <ModalSection title={"POA"}>
          <ModalRow>
            <span className="desc-lbl">
              If the customer has confirmed they have an active POA, please
              confirm below to archive this record.
            </span>
          </ModalRow>
          <ModalRow>
            <PrimaryActionButton
              id={"archive-record-poa-btn"}
              title={"Customer Has POA"}
              onClick={async () => {
                showLoadingToast();
                const err = await archiveNumber(record.id, "poa", null, null);
                if (err) {
                  showErrorToast(
                    "Failed to archive record",
                    "There was an error that occurred whilst trying to archive this record."
                  );
                  removeLoadingToast();
                  return;
                }
                showSuccessToast(
                  "Successfully archive record",
                  "This record has now been archived as having a POA."
                );
                closeModal();
                onCompletion();
              }}
            />
          </ModalRow>
        </ModalSection>
      </ModalContent>
    </Modal>
  );
};
