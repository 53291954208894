import React, { useContext, useEffect, useState } from "react";
import {
  getCloserAgents,
  getHandoverAgents,
  getRandomRecordToDialOut,
  submitDealsheet
} from "../../../functions/outbound-calls/calling_session_functions";

// Contexts
import { SelectedRecordContext } from "../../../Main";

// Styles
import "../../../styles/outbound-calls/overview/OutboundCallsCallingSessionTab.css";

import CallingSessionScriptView from "../calling-session-script/CallingSessionScriptView";

// Functions
import { showErrorToast } from "../../../utils/ErrorHandler";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../../utils/LoadingHandler";

// Views
import CallingSessionDialOutView from "../CallingSessionDialOutView";
import CallInSection from "./CallInSection";
import { BroadbandOrderForm } from "../order-forms/BroadbandOrderForm";
import { LandlineOrderForm } from "../order-forms/LandlineOrderForm";
import { CustomerDetailsForm } from "../order-forms/CustomerDetailsForm";
import GetNewRecordCard from "./GetNewRecordCard";
import { showLoadingOverlay } from "../../../functions/core_functions";
import { DropdownInput } from "../../generic/DropdownInput";
import PrimaryActionButton from "../../generic/PrimaryActionButton";
import {
  getBroadbandPackages,
  getLineRentalPrices
} from "../../../functions/customer_functions";

// Context that holds the dealsheet data.
export const DealsheetDataContext = React.createContext();

export default function OutboundCallsCallingSessionTab() {
  // The current record that the user is handling.
  const { selectedRecord, setSelectedRecord } = useContext(
    SelectedRecordContext
  );

  // Map that holds the dealsheet data.
  const [dealsheetData, setDealsheetData] = useState({});

  const [dealsheetDataUpdated, setDealsheetDataUpdated] = useState(false);

  const [firstStateRun, setFirstStateRun] = useState(false);

  const getNewRecord = async () => {
    showLoadingToast("Fetching new record...");
    const [record, err] = await getRandomRecordToDialOut();
    if (record) {
      setSelectedRecord(record);
    }
    removeLoadingToast();
  };

  // useEffect(() => {
  //   if (dealsheetDataUpdated && Object.keys(dealsheetData).length == 0) {
  //     const ds = document.getElementsByClassName("calling-session-dealsheet");
  //     if (!ds || ds.length === 0) {
  //       return;
  //     }
  //     const inputs = ds[0].querySelectorAll("input");
  //     inputs.forEach(input => {
  //       if (input.type == "checkbox") {
  //         input.checked = false;
  //       } else {
  //         input.value = "";
  //       }
  //     });
  //     const dropdowns = ds[0].querySelectorAll("select");
  //     dropdowns.forEach(dropdown => {
  //       dropdown.value = null;
  //     });
  //   } else if (!dealsheetDataUpdated) {
  //     const cachedDealsheet = localStorage.getItem("rscrm_dealsheet_cache");
  //     setDealsheetData(JSON.parse(cachedDealsheet) || {});
  //     setDealsheetDataUpdated(true);
  //   }
  // }, [dealsheetData]);

  useEffect(() => {
    (async () => {
      setFirstStateRun(true);

      const recordFromLocalStorage = JSON.parse(
        localStorage.getItem("rscrm_current_record")
      );
      if (!selectedRecord && !recordFromLocalStorage) {
        showLoadingToast();
        const [record, err] = await getRandomRecordToDialOut();
        removeLoadingToast();
        if (record) {
          localStorage.setItem("rscrm_current_record", JSON.stringify(record));
          setSelectedRecord(record);
        } else {
          showErrorToast(
            "Failed to get number",
            "There was an error that occurred whilst trying to get a random number from the database. Please contact an administrator.",
            true
          );
          return;
        }
      } else {
        if (recordFromLocalStorage) {
          setSelectedRecord(recordFromLocalStorage);
        }
      }

      setCustomerDetailsData(dealsheetData.customer_details_data || {});
    })();
  }, []);

  useEffect(() => {
    (async () => {
      getHAgentsDropdownValues();
      getCAgentsDropdownValues();
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const [prices, err] = await getLineRentalPrices();
      if (prices) {
        setLineRentalPrices(prices);
      }
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const [packages, err] = await getBroadbandPackages();
      console.log(err);
      if (packages) {
        setBroadbandPackages(packages);
      }
    })();
  }, []);

  const getHAgentsDropdownValues = async () => {
    const [agents, err] = await getHandoverAgents();
    if (err) {
      showErrorToast(
        "Failed to get handover agents",
        "Please refresh the CRM and try again. If the issue persists then speak to administrator."
      );
    }
    if (agents) {
      const mappedAgents = agents.map(agent => {
        return {
          title: `${agent.first_name} ${agent.last_name}`,
          value: agent.id
        };
      });
      setHandoverAgents(mappedAgents);
    }
  };

  const getCAgentsDropdownValues = async () => {
    const [agents, err] = await getCloserAgents();
    if (err) {
      showErrorToast(
        "Failed to get closing agents",
        "Please refresh the CRM and try again. If the issue persists then speak to administrator."
      );
    }
    if (agents) {
      const mappedAgents = agents.map(agent => {
        return {
          title: `${agent.first_name} ${agent.last_name}`,
          value: agent.id
        };
      });
      setClosingAgents(mappedAgents);
    }
  };

  useEffect(() => {
    (async () => {
      if (!selectedRecord && firstStateRun) {
        showLoadingToast();
        const [record, err] = await getRandomRecordToDialOut();
        removeLoadingToast();
        if (record) {
          localStorage.setItem("rscrm_current_record", JSON.stringify(record));
          setSelectedRecord(record);
        } else {
          showErrorToast(
            "Failed to get number",
            "There was an error that occurred whilst trying to get a random number from the database. Please contact an administrator.",
            true
          );
          return;
        }
      }
    })();
  }, [selectedRecord]);

  const [customerDetailsData, setCustomerDetailsData] = useState({});
  const [landlineOrderData, setLandlineOrderData] = useState({});
  const [broadbandOrderData, setBroadbandOrderData] = useState({});

  const [handoverAgents, setHandoverAgents] = useState([]);
  const [closingAgents, setClosingAgents] = useState([]);

  const [selectedHandoverAgent, setSelectedHandoverAgent] = useState(null);
  const [selectedClosingAgent, setSelectedClosingAgent] = useState(null);

  const [lineRentalPrices, setLineRentalPrices] = useState([]);
  const [broadbandPackages, setBroadbandPackages] = useState([]);

  useEffect(() => {
    setDealsheetData({
      ...dealsheetData,
      customer_details_data: customerDetailsData
    });
  }, [customerDetailsData]);

  const saveDealAction = async () => {
    //TODO: validation here...

    const customerData = {
      full_name:
        customerDetailsData.title &&
        customerDetailsData.first_name &&
        customerDetailsData.last_name
          ? `${customerDetailsData.title} ${customerDetailsData.first_name} ${customerDetailsData.last_name}`
          : null,
      add_account_holder: customerDetailsData.add_account_holder || null,
      email: customerDetailsData.email || null,
      mobile: customerDetailsData.mobile || null,
      soft_facts: customerDetailsData.soft_facts || null,
      billing_type:
        customerDetailsData.email === null || customerDetailsData.email === ""
          ? "paper"
          : "email",
      invoice_name: customerDetailsData.invoice_name || null,
      pbom: customerDetailsData.pbom
    };

    const ddMandateData = {
      bank_branch: customerDetailsData.bank_name,
      sort_code: customerDetailsData.sort_code,
      account_number: customerDetailsData.account_number,
      iban_check: customerDetailsData.iban_check
    };

    const field = document.getElementById("address-county-field");

    const billingAddressData = {
      building_name_number: customerDetailsData.address1,
      street: customerDetailsData.address2,
      town_city: customerDetailsData.address_city,
      county: field.value,
      postcode: customerDetailsData.address_postcode
    };

    const speedStatsData = {
      min_down_speed: parseFloat(broadbandOrderData.min_down_speed),
      max_down_speed: parseFloat(broadbandOrderData.max_down_speed),
      min_up_speed: parseFloat(broadbandOrderData.min_up_speed),
      max_up_speed: parseFloat(broadbandOrderData.max_up_speed),
      min_guaranteed_down_speed: parseFloat(
        broadbandOrderData.min_guaranteed_down_speed
      ),
      connection_type: broadbandOrderData.connection_type
    };

    const broadbandData = {
      package: parseInt(broadbandOrderData.broadband_package),
      package_price: parseFloat(broadbandOrderData.package_price),
      contract_length_months: parseInt(
        customerDetailsData.contract_length_months
      )
    };

    var data = {
      customer: customerData,
      handover_agent_id: parseInt(selectedHandoverAgent),
      closing_agent_id: parseInt(selectedClosingAgent),
      raw_record_data: parseInt(selectedRecord.id),
      billing_address: billingAddressData
    };

    if (
      ddMandateData.sort_code !== "" &&
      ddMandateData.sort_code !== undefined &&
      ddMandateData.account_number !== "" &&
      ddMandateData.account_number !== undefined
    ) {
      data["dd_mandate"] = ddMandateData;
    }

    if (lineRentalOrderEnabled) {
      data["landline"] = {
        ...landlineOrderData,
        contract_length_months: parseInt(
          customerDetailsData.contract_length_months
        )
      };
    }

    if (broadbandOrderEnabled) {
      data["broadband"] = broadbandData;
      data["speed_stats"] = speedStatsData;
    }

    console.log(JSON.stringify(data));

    const resp = await submitDealsheet(data);

    if (resp) {
      setDealsheetData(null);
      setCustomerDetailsData({});
      setBroadbandOrderData({});
      setLandlineOrderData({});
      await getNewRecord();
    }
  };

  const [broadbandOrderEnabled, setBroadbandOrderEnabled] = useState(false);
  const [lineRentalOrderEnabled, setLineRentalOrderEnabled] = useState(false);

  return (
    <DealsheetDataContext.Provider
      value={{
        dealsheetData: dealsheetData,
        setDealsheetData: setDealsheetData
      }}
    >
      <div className="outbound-calls-calling-session-tab">
        <div className="grid-wrapper">
          <div className="col-1">
            {!selectedRecord && (
              <GetNewRecordCard
                action={async () => {
                  await getNewRecord();
                }}
              />
            )}
            {selectedRecord && <CallingSessionDialOutView />}
            <CallInSection />
          </div>
          <div className="col-2">
            <CallingSessionScriptView />
          </div>
          <div className="col-3">
            <div className="ds-accordians-wrapper">
              <CustomerDetailsForm
                data={customerDetailsData}
                setData={setCustomerDetailsData}
                checked={true}
                setChecked={() => {}}
              />
              <LandlineOrderForm
                lineRentalPrices={lineRentalPrices}
                data={landlineOrderData}
                setData={setLandlineOrderData}
                checked={lineRentalOrderEnabled}
                setChecked={setLineRentalOrderEnabled}
              />
              <BroadbandOrderForm
                packages={broadbandPackages}
                data={broadbandOrderData}
                setData={setBroadbandOrderData}
                checked={broadbandOrderEnabled}
                setChecked={setBroadbandOrderEnabled}
              />
            </div>

            <div className="ds-submission-wrapper">
              <DropdownInput
                id={"handover-agent-dropdown"}
                title={"Handover Agent"}
                options={handoverAgents.map(val => {
                  return { title: val.title, value: val.value };
                })}
                onChange={e => {
                  setSelectedHandoverAgent(e.target.value);
                }}
              />
              <DropdownInput
                id={"closing-agent-dropdown"}
                title={"Closing Agent"}
                options={closingAgents.map(val => {
                  return { title: val.title, value: val.value };
                })}
                onChange={e => {
                  setSelectedClosingAgent(e.target.value);
                }}
              />
              <PrimaryActionButton
                id={"submit-deal-btn"}
                title={"Submit Deal"}
                onClick={async () => {
                  await saveDealAction();
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </DealsheetDataContext.Provider>
  );
}
