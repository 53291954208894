import React from "react";
import "../../../styles/Customers/TechnicalFaults/NewTechnicalFaultEngineerVisitForm.css";
import CrossIcon from "../../../assets/CrossIcon.svg";
import { createTechnicalFaultEngineerVisit } from "../../../functions/technical_fault_functions.js";

function NewTechnicalFaultEngineerVisitForm({faultId}) {
  return (
    <div className="new-tech-fault-engineer-visit-form-container">
    <div className="new-tech-fault-engineer-visit-form-top-row">
      <span className="new-tech-fault-engineer-visit-form-title">New Engineer Visit</span>
      <button className="new-tech-fault-engineer-visit-form-exit-btn" onClick={ async () => {
        document.getElementById("new-tech-fault-engineer-visit-form-parent-div").remove();
      }}>
        <img src={CrossIcon}/>
      </button>
    </div>
      <span className="new-tech-fault-engineer-visit-form-description">Enter the reference of engineer booking and the date they will be visiting the customer.</span>
      <input id="new-tech-fault-engineer-visit-reference-input" type="text" placeholder="Appointment Reference"/>
      <input id="new-tech-fault-engineer-visit-visited-on-date-input" type="datetime-local"/>
      <button className="new-tech-fault-engineer-visit-form-submit-btn" onClick={async ()=>{await createTechnicalFaultEngineerVisit(faultId)}}>Submit</button>
    </div>
  )
}

export default NewTechnicalFaultEngineerVisitForm;
