import React from "react";
import "../../../styles/Customers/TechnicalFaults/TechnicalFaultNote.css";
import DefaultProfileImage from "../../../assets/DefaultProfileImage.svg";

function TechnicalFaultNote({note}) {
  return (
    <div className="tech-fault-note">
      <div className="tech-fault-note-top-row">
        <span className="tech-fault-note-name-lbl">{note.user_name}</span>
        <span className="tech-fault-note-time-lbl">{new Date(note.created_at).toLocaleString()}</span>
      </div>
      <div className="tech-fault-note-message-row">
        <img className="tech-fault-note-user-pp" src={note.user_profile_pic ? note.user_profile_pic : DefaultProfileImage}/>
        <div className="tech-fault-note-message-container">
          <span className="tech-fault-note-message-lbl">{note.message}</span>
        </div>
      </div>
    </div>
  )
}

export default TechnicalFaultNote;
