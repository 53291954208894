import React from "react";
import "../../../styles/Customers/TechnicalFaults/TechnicalFaultDetailView.css";
import CrossIcon from "../../../assets/CrossIcon.svg";
import { updateTechnicalFault, listTechnicalFaults } from "../../../functions/technical_fault_functions.js";

function TechnicalFaultDetailView({technicalFault}) {
  return (
    <div className="tech-fault-detail-form-container">
    <div className="tech-fault-detail-form-top-row">
      <span className="tech-fault-detail-form-title">Technical Fault (ID#{technicalFault.id})</span>
      <button className="tech-fault-detail-form-exit-btn" onClick={ async () => {
        document.getElementById("tech-fault-detail-form-parent-div").remove();
      }}>
        <img src={CrossIcon}/>
      </button>
    </div>
    <select id="tech-fault-detail-form-type-dropdown" defaultValue={technicalFault.type}>
      <option value="line">Line Fault</option>
      <option value="broadband">Broadband Fault</option>
    </select>
    <span className="tech-fault-detail-form-description-header">Fault Description:</span>
    <textarea id="tech-fault-detail-form-description-input" defaultValue={technicalFault.description}></textarea>
    <button className="tech-fault-detail-form-submit-btn" onClick={async ()=>{
      const description = document.getElementById("tech-fault-detail-form-description-input").value;
      const type = document.getElementById("tech-fault-detail-form-type-dropdown").value;
      const res = await updateTechnicalFault(technicalFault.id, {"description":description, "type":type});
      if (res) {
        await listTechnicalFaults(technicalFault.customer_id, 0, 0);
        document.getElementById("tech-fault-detail-form-parent-div").remove();
      }
    }}>Save Changes</button>
    </div>
  )
}

export default TechnicalFaultDetailView;
