import React, { useState } from "react";
import "../../../styles/outbound-calls/callbacks/NewCallbackForm.css";
import RightChevron from "../../../assets/RightChevron.svg";
import DateSelector from "./DateSelector";
import Modal, {
  ModalContent,
  ModalHeader,
  ModalInputGrid,
  ModalSection,
  ModalRow,
  ModalFooter,
  closeModal
} from "../../generic/modal/Modal";
import PrimaryActionButton from "../../generic/PrimaryActionButton";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../../utils/LoadingHandler";
import { createCallback } from "../../../functions/outbound-calls/callback_functions";
import { showWarningToast } from "../../../utils/WarningHandler";
import { showErrorToast } from "../../../utils/ErrorHandler";
import { showSuccessToast } from "../../../utils/SuccessHandler";
import { SingleLineInput } from "../../generic/SingleLineInput";
import { MultiLineInput } from "../../generic/MultiLineInput";

export default function NewCallbackForm({ record, onCompletion = () => {} }) {
  const [dateTime, setDateTime] = useState(null);
  const [notes, setNotes] = useState("");

  return (
    <Modal
      id="new-callback-form"
      changePage={() => {
        return;
      }}
    >
      <ModalHeader title={"New Callback"} />
      <ModalContent>
        <ModalSection title={"Select a date & time"}>
          <ModalInputGrid>
            <SingleLineInput
              type="datetime-local"
              id="new-callback-datetime"
              onChange={e => {
                if (e.target.value == "") {
                  setDateTime(null);
                } else {
                  setDateTime(e.target.value);
                }
              }}
            />
          </ModalInputGrid>
        </ModalSection>
        <ModalSection title={"Notes"}>
          <ModalRow>
            <MultiLineInput
              id="new-callback-notes"
              onChange={e => {
                setNotes(e.target.value);
              }}
            />
          </ModalRow>
        </ModalSection>
      </ModalContent>
      <ModalFooter>
        <PrimaryActionButton
          id={"schedule-callback-button"}
          title={"Schedule Callback"}
          onClick={async () => {
            if (!dateTime) {
              showWarningToast(
                "Missing date & time",
                "The date & time is missing from the required field."
              );
              return;
            }

            showLoadingToast();
            const err = await createCallback(record.cli, dateTime, notes);
            if (err) {
              showErrorToast(
                "Failed to save callback",
                "There was an issue that occurred whilst trying to save this callback. Please try again later or contact administrator."
              );
              return;
            }
            onCompletion();
            removeLoadingToast();
            closeModal();
            showSuccessToast(
              "Successfully created callback",
              "Your callback has been scheduled."
            );
            return;
          }}
        />
      </ModalFooter>
    </Modal>
  );
}
