import React from "react";
import ReactDOM from "react-dom";
import SwitchUser from "../../screens/SwitchUser";
import {
  serverDomain,
  setSwitchedUserID,
  switchedUserID
} from "../../utils/constants";
import {
  currentUser,
  hideLoadingOverlay,
  showLoadingOverlay
} from "../core_functions";
import { showErrorToast } from "../../utils/ErrorHandler";
import * as request from "../../utils/request";
import { updateUserActivityStatus } from "../users_functions";

export async function closeSwitchUser() {
  const switchUserEl = document.getElementsByClassName(
    "switch-user-content"
  )[0];
  switchUserEl.remove();
}

export async function switchUser(user, setUser) {
  //remove the popover
  const switchUserInput = document.getElementById("switch-user-pin-code");
  if (switchUserInput.value === "") {
    return;
  }

  const switchUserEl = document.getElementsByClassName(
    "switch-user-content"
  )[0];
  switchUserEl.remove();

  showLoadingOverlay("Getting user...");

  try {
    await updateUserActivityStatus(user.id, "away");
    setSwitchedUserID(switchUserInput.value);
    await updateUserActivityStatus(switchUserInput.value, "active");

    //get the data of the switched user
    const newUser = await getSwitchedUserData();
    if (!newUser) {
      throw Error("No user exists for this ID");
    }

    setUser(newUser);

    //set the data of the indicator
    // document
    //   .getElementById("switched-user-indicator")
    //   .querySelectorAll(
    //     ".name-label"
    //   )[0].innerHTML = `${user.first_name} ${user.last_name}`;

    hideLoadingOverlay();

    if (window.location.hash.includes("/my-account")) {
      window.location.reload();
    }

    return;
  } catch (err) {
    setSwitchedUserID(null);
    hideLoadingOverlay();
    showErrorToast(
      "Failed to get user.",
      `There was an error that occured whilst trying to get this user's data. Error: ${err}`
    );
    return;
  }
}

export async function getSwitchedUserData() {
  try {
    const user = await request.get(
      `${serverDomain}/api/v1/users/${switchedUserID}`,
      null,
      "snake"
    );
    return user;
  } catch (err) {
    console.log(err);
    return null;
  }
}
