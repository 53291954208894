import React from "react";

// Styles
import "../../../styles/outbound-calls/overview/action-cards/GetNewRecordCard.css";

// Assets
import CloudDownloadIcon from "../../../assets/CloudDownloadIcon.svg";
import PrimaryActionButton from "../../generic/PrimaryActionButton";

// Card that allows the user to fetch a new number if no record is present in the context.
function GetNewRecordCard({ action }) {
  return (
    <div className="get-new-record-card">
      <div className="get-new-record-main-content">
        <div className="get-new-record-text">
          <span className="get-new-record-title">Start Dialling Out</span>
          <span className="get-new-record-subtitle">
            Pull a record from the database to dial out.
          </span>
        </div>
      </div>
      <PrimaryActionButton
        id={"fetch-number-btn"}
        icon={CloudDownloadIcon}
        title={"Fetch Number"}
        onClick={action}
      />
    </div>
  );
}

export default GetNewRecordCard;
