import React, { useState } from "react";

// Styles
import "../../../styles/outbound-calls/dealsheet/OrderFormAccordian.css";

// Assets
import RightArrow from "../../../assets/interface-icons/right-arrow.svg";
import DownArrow from "../../../assets/interface-icons/down-arrow.svg";

export const OrderFormAccordian = props => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <div className="order-form-accordian">
      <div className="header-row">
        <span>{props.title}</span>

        <label for="myCheckbox">
          <input
            id="myCheckbox"
            type="checkbox"
            className="checkbox"
            defaultChecked={props.checked}
            disabled={props.title == "Customer Information"}
            onChange={e => {
              props.setChecked(e.target.checked);
              if (!e.target.checked) {
                setIsOpen(false);
              }
            }}
          />
        </label>
        {props.checked && (
          <button
            onClick={() => {
              setIsOpen(!isOpen);
            }}
          >
            <img src={!isOpen ? RightArrow : DownArrow} />
          </button>
        )}
      </div>

      {isOpen && props.children}
    </div>
  );
};
