import React from "react";
import ReactDOM from "react-dom";
import {
  serverDomain,
  getSelectedCompany,
  selectedCompanyID
} from "../utils/constants.js";
import * as req from "../utils/request";
import {
  resolveCustomerData,
  resolveDate,
  resolveTariff,
  resolveBroadbandType
} from "../utils/customerDataResolver.js";
import { sortObject } from "../utils/helpers.js";

import {
  showAlert,
  hideLoadingOverlay,
  showLoadingOverlay
} from "../functions/core_functions.js";
import { showErrorToast } from "../utils/ErrorHandler.js";
import ApprovalCreditRow from "../views/Provisioning/Credits/ApprovalCreditRow.js";
import PendingCreditRow from "../views/Provisioning/Credits/PendingCreditRow.js";
import AppliedCreditRow from "../views/Provisioning/Credits/AppliedCreditRow.js";
import CreditDetailView from "../views/Provisioning/Credits/CreditDetailView.js";
import ProvisioningDealPopup from "../views/Provisioning/Line Takeovers/LineTakeoverDetail.js";
import OpenRecordTableRowButton from "../views/elements/OpenRecordTableRowButton.js";
import { ProvisioningDetail } from "../views/Provisioning/ProvisioningDetail.js";
import { createRoot } from "react-dom/client.js";

export let selectedPROVDealData = null;

export const listProvisioningDataLimit = 100;
export var listProvisioningDataOffset = 0;
export var reachedEndOfProvisioningData = false;

// --- NEW STUFF ---

export async function getProvisioningStat(stat) {
  try {
    const count = await req.get(
      `${serverDomain}/api/v1/provisioning_stats?stat=${stat}`,
      null,
      "snake"
    );
    return [count.count, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function getVerbalAgreementForCustomer(id) {
  try {
    const va = await req.get(
      `${serverDomain}/api/v1/customers/${id}/verbal_agreement`,
      null,
      "snake"
    );
    return [va, null];
  } catch (err) {
    return [null, err];
  }
}

export async function saveVerbalAgreement(customerId, notes, status) {
  try {
    await req.post(
      `${serverDomain}/api/v1/customers/${customerId}/verbal_agreement`,
      {
        notes: notes,
        status: status
      }
    );
    return null;
  } catch (err) {
    showErrorToast(
      "Failed to save customer verbal agreement",
      `There was an error that occurred whilst trying to create this verbal agreement. ${err}`
    );
    return err;
  }
}

// --- OLD ----

export function setListProvisioningDataOffset(newOffset) {
  if (newOffset <= 0) {
    listProvisioningDataOffset = 0;
  }
  listProvisioningDataOffset = newOffset;
}

export var activeProvisioningFilters = {};

export function setActiveProvisioningFilters(filters) {
  activeProvisioningFilters = filters;

  var count = 0;
  Object.keys(filters).forEach((key, i) => {
    if (filters[key] !== null || filters[key] !== undefined) {
      count += 1;
    }
  });

  const label = document
    .getElementById("prov-filter-btn")
    .querySelector("span");
  label.innerHTML = `Filters (${count})`;
}

//get the deals that have status 'prov' and display in table.
export async function getProvisioningData(search, offset, filters) {
  try {
    var url = `${serverDomain}/api/v1/deals?limit=${listProvisioningDataLimit}&offset=${offset}`;
    if (search != null && search != "") {
      url += `&search=${search}`;
    }
    if (filters != null) {
      Object.keys(filters).forEach((key, i) => {
        if (filters[key] !== null || filters[key] !== undefined) {
          url += `&${key}=${filters[key]}`;
        }
      });
    }

    const deals = await req.get(url);
    if (deals.length < listProvisioningDataLimit) {
      reachedEndOfProvisioningData = true;
    }

    return [deals, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function handleSaveDealPROV() {
  if (!selectedPROVDealData) {
    window.alert("Please select a deal to continue.");
    return;
  }

  const statusNotes = document.getElementById("prov-status-notes").value;
  const openreachReference = document.getElementById(
    "prov-openreach-reference"
  ).value;
  const liveDate = document.getElementById("prov-live-date").value;

  const data = {};
  data["status_notes"] = statusNotes;
  data["openreach_reference"] = openreachReference;
  if (liveDate !== "" && liveDate !== null) {
    data["live_date"] = new Date(liveDate).toISOString();
  }

  if (data["openreach_reference"] && data["live_date"]) {
    data["status"] = "onboard";
  }

  try {
    const resp = await req.put(
      `${serverDomain}/api/v1/deals/${selectedPROVDealData.id}`,
      data
    );
  } catch (error) {
    console.log(error);

    window.alert("Failed to save/provision deal. Please try again");
    return;
  }

  if (data["status"] === "onboard") {
    // if (customerId === null) {
    //   window.alert("Failed to create customer, deal has been provisioned. Please contact technical support.");
    //   return
    // }

    //1. Get customer...
    //2. If customer exists then continue...
    //3. If customer does not exist then create customer...
    //4. If customer created successfully then continue...

    var customer;
    const welcomePackData = {};

    try {
      const c = await req.get(
        `${serverDomain}/api/v1/deals/${selectedPROVDealData.id}/customer`
      );
      customer = c;
    } catch (err) {
      console.log(err);

      window.alert("Failed to get customer data for this deal.");
      return;
    }

    if (customer === null || customer === undefined) {
      const customerData = {};
      customerData["full_name"] = selectedPROVDealData.full_name;
      customerData["email"] = selectedPROVDealData.email_address;
      customerData["mobile"] = selectedPROVDealData.mobile_number;
      customerData["birthdate"] = selectedPROVDealData.dob;
      customerData["deal_id"] = selectedPROVDealData.id;
      customerData["company_id"] = selectedCompanyID;

      try {
        const resp = await req.post(
          `${serverDomain}/api/v1/customers`,
          customerData
        );
      } catch (err) {
        console.log(err);

        window.alert(
          "Failed to create customer, the deal however has been provisioned. Please contact technical support."
        );
        return;
      }

      try {
        const customer = await req.get(
          `${serverDomain}/api/v1/deals/${selectedPROVDealData.id}/customer`
        );
        welcomePackData["customer_id"] = customer.id;
      } catch (err) {
        console.log(err);

        window.alert(
          "Failed to get created customer, both the deal and customer have been successfully created however the welcome pack has not been generated. Please contact technical support."
        );
        return;
      }
    } else {
      welcomePackData["customer_id"] = customer.id;
    }

    welcomePackData["deal_id"] = selectedPROVDealData.id;

    try {
      const resp = await req.post(
        `${serverDomain}/api/v1/welcome_pack`,
        welcomePackData
      );
    } catch (err) {
      console.log(err);
      window.alert(
        "Failed to generate welcome pack, deal has been provisioned and customer created. Please contact technical support."
      );
      return;
    }

    window.alert(
      "Successfully finalised deal. This deal can now be found in the Onboarding section."
    );

    await getProvisioningData(null, 0);

    selectedPROVDealData = null;
    const statusNotesInput = document.getElementById("prov-status-notes");
    const saveBtn = document.getElementById("prov-save-deal-btn");
    const liveDateInput = document.getElementById("prov-live-date");
    statusNotesInput.style.display = "none";
    liveDateInput.style.display = "none";
    saveBtn.style.display = "none";

    const leftCol = document.getElementById("prov-left-column");
    const rightCol = document.getElementById("prov-right-column");

    leftCol.style.width = "100%";
    rightCol.style.width = "0%";
    rightCol.style.opacity = 0;
    rightCol.style.display = "none";

    const elems = document.getElementsByTagName("h4");
    for (let i = 0; i < elems.length; i += 1) {
      elems[i].style.display = "none";
    }
  } else {
    window.alert("Successfully saved.");
  }
}

export const creditLimit = 10;
export var currentApprovalCreditsOffset = 0;
export var currentPendingCreditsOffset = 0;
export var currentAppliedCreditsOffset = 0;

export function setCurrentAppliedCreditsOffset(newOffset) {
  if (newOffset <= 0) {
    currentAppliedCreditsOffset = 0;
    document.getElementById("prov-credits-completed-prev-btn").innerHTML = "";
    return;
  }
  currentAppliedCreditsOffset = newOffset;
  document.getElementById("prov-credits-completed-prev-btn").innerHTML =
    "< Previous Page";
}

export function setCurrentPendingCreditsOffset(newOffset) {
  if (newOffset <= 0) {
    currentPendingCreditsOffset = 0;
    document.getElementById("prov-credits-pending-prev-btn").innerHTML = "";
    return;
  }
  currentPendingCreditsOffset = newOffset;
  document.getElementById("prov-credits-pending-prev-btn").innerHTML =
    "< Previous Page";
}

export function setCurrentApprovalCreditsOffset(newOffset) {
  if (newOffset <= 0) {
    currentApprovalCreditsOffset = 0;
    document.getElementById("prov-credits-approval-prev-btn").innerHTML = "";
  } else {
    currentApprovalCreditsOffset = newOffset;
    document.getElementById("prov-credits-approval-prev-btn").innerHTML =
      "< Previous Page";
  }
}

export async function listApprovalCredits(offset) {
  try {
    const credits = await req.get(
      `${serverDomain}/api/v1/customer_credits?status=approval&limit=${creditLimit}&offset=${offset}`,
      null,
      "snake"
    );
    const approvalTableBody = document
      .getElementById("prov-credits-approval-table-body")
      .querySelectorAll("tbody")[0];
    approvalTableBody.innerHTML = "";

    if (credits === null) {
      document.getElementById("prov-credits-approval-next-btn").innerHTML = "";

      document.getElementById(
        "prov-credits-approval-table-header-lbl"
      ).innerHTML = `0 Approval Credits`;
      return;
    }

    for (var i = 0; i < credits.length; i++) {
      const credit = credits[i];
      const row = document.createElement("div");

      if (credit.status === "approval") {
        ReactDOM.render(<ApprovalCreditRow credit={credit} />, row);
        approvalTableBody.appendChild(row);
      }
    }

    document.getElementById(
      "prov-credits-approval-table-header-lbl"
    ).innerHTML = `Showing ${currentApprovalCreditsOffset + 1} - ${
      currentApprovalCreditsOffset + credits.length
    } Approval Credits`;

    if (credits.length < 1) {
      document.getElementById("prov-credits-approval-next-btn").innerHTML = "";
    } else {
      document.getElementById("prov-credits-approval-next-btn").innerHTML =
        "Next Page >";
    }
  } catch (err) {
    showErrorToast(
      "Failed to get customer approval credits",
      `There was an error that occurred whilst trying to get customer approval credits. ${err}`
    );

    return;
  }
}

export async function listPendingCredits(offset) {
  try {
    const credits = await req.get(
      `${serverDomain}/api/v1/customer_credits?status=pending&limit=${creditLimit}&offset=${offset}`,
      null,
      "snake"
    );
    const pendingTableBody = document
      .getElementById("prov-credits-pending-table-body")
      .querySelectorAll("tbody")[0];
    pendingTableBody.innerHTML = "";
    if (credits === null) {
      document.getElementById("prov-credits-pending-next-btn").innerHTML = "";

      document.getElementById(
        "prov-credits-pending-table-header-lbl"
      ).innerHTML = `0 Pending Credits`;
      return;
    }
    for (var i = 0; i < credits.length; i++) {
      const credit = credits[i];
      const row = document.createElement("div");

      if (credit.status === "pending") {
        ReactDOM.render(<PendingCreditRow credit={credit} />, row);
        pendingTableBody.appendChild(row);
      }
    }

    document.getElementById(
      "prov-credits-pending-table-header-lbl"
    ).innerHTML = `Showing ${currentPendingCreditsOffset + 1} - ${
      currentPendingCreditsOffset + credits.length
    } Pending Credits`;

    if (credits.length < 1) {
      document.getElementById("prov-credits-pending-next-btn").innerHTML = "";
    } else {
      document.getElementById("prov-credits-pending-next-btn").innerHTML =
        "Next Page >";
    }
  } catch (err) {
    showErrorToast(
      "Failed to get customer pending credits",
      `There was an error that occurred whilst trying to get customer pending credits. ${err}`
    );

    return;
  }
}

export async function listAppliedCredits(offset) {
  try {
    const credits = await req.get(
      `${serverDomain}/api/v1/customer_credits?status=applied&limit=${creditLimit}&offset=${offset}`,
      null,
      "snake"
    );
    const appliedTableBody = await document
      .getElementById("prov-credits-completed-table-body")
      .querySelectorAll("tbody")[0];
    appliedTableBody.innerHTML = "";
    if (credits === null) {
      document.getElementById("prov-credits-completed-next-btn").innerHTML = "";

      document.getElementById(
        "prov-credits-completed-table-header-lbl"
      ).innerHTML = `0 Applied Credits`;
      return;
    }
    for (var i = 0; i < credits.length; i++) {
      const credit = credits[i];
      const row = document.createElement("div");

      if (credit.status === "applied") {
        ReactDOM.render(<AppliedCreditRow credit={credit} />, row);
        appliedTableBody.appendChild(row);
      }
    }
    document.getElementById(
      "prov-credits-completed-table-header-lbl"
    ).innerHTML = `Showing ${currentAppliedCreditsOffset + 1} - ${
      currentAppliedCreditsOffset + credits.length
    } Applied Credits`;

    if (credits.length < 1) {
      document.getElementById("prov-credits-completed-next-btn").innerHTML = "";
    } else {
      document.getElementById("prov-credits-completed-next-btn").innerHTML =
        "Next Page >";
    }
  } catch (err) {
    showErrorToast(
      "Failed to get customer applied credits",
      `There was an error that occurred whilst trying to get customer applied credits. ${err}`
    );

    return;
  }
}

export async function approveCredit(creditId) {
  if (!creditId) {
    showErrorToast(
      "No Credit ID",
      "Please try refreshing the page and try approve the credit again."
    );
    return;
  }

  try {
    await req.put(
      `${serverDomain}/api/v1/customer_credits/${creditId}/approve`,
      null
    );
  } catch (err) {
    showErrorToast(
      "Failed to approve customer credit",
      `There was an error that occurred whilst trying to approve this customer credit. ${err}`
    );
    return;
  }
  await listPendingCredits();
  showAlert(
    "Credit Approved",
    "This credit was successfully approved and can now be found in pending section."
  );

  return;
}

export async function rejectCredit(creditId) {
  if (!creditId) {
    showErrorToast(
      "No Credit ID",
      "Please try refreshing the page and try approve the credit again."
    );
    return;
  }

  try {
    await req.put(
      `${serverDomain}/api/v1/customer_credits/${creditId}/reject`,
      null
    );
  } catch (err) {
    showErrorToast(
      "Failed to reject customer credit",
      `There was an error that occurred whilst trying to reject this customer credit. ${err}`
    );
    return;
  }
  await listApprovalCredits();
  showAlert(
    "Credit Rejected",
    "This credit was successfully rejected and referenced in the customers credit history."
  );

  return;
}

export async function applyCredit(creditId) {
  if (!creditId) {
    showErrorToast(
      "No Credit ID",
      "Please try refreshing the page and try approve the credit again."
    );
    return;
  }

  try {
    await req.put(
      `${serverDomain}/api/v1/customer_credits/${creditId}/apply`,
      null
    );
  } catch (err) {
    showErrorToast(
      "Failed to apply customer credit",
      `There was an error that occurred whilst trying to apply this customer credit. ${err}`
    );
    return;
  }
  await listAppliedCredits();
  showAlert(
    "Credit Applied",
    "Your credit has now been sent for approval. Please check back later to see if this has been approved for the customer."
  );

  return;
}

export async function openCreditDetailView(credit) {
  const div = document.createElement("div");
  div.id = "credit-detail-view-parent-div";
  ReactDOM.render(<CreditDetailView credit={credit} />, div);
  document.getElementById("app-content-wrapper").appendChild(div);
}

export async function openProvisioningDetail(data, selectedCompany) {
  const div = document.createElement("div");
  div.id = "modal-div-wrapper";
  document.getElementById("app-content-wrapper").appendChild(div);
  const modal = (
    <ProvisioningDetail dealId={data} selectedCompany={selectedCompany} />
  );
  const root = createRoot(document.getElementById("modal-div-wrapper"));
  root.render(modal);
}

export async function getRecordDataFromCLI(cli) {
  try {
    const data = await req.get(
      `${serverDomain}/api/v1/records/${cli}`,
      null,
      "snake"
    );
    return [data, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function getLiveRateAgents() {
  try {
    const agents = await req.get(
      `${serverDomain}/api/v1/live_rate_agents`,
      null,
      "snake"
    );
    return [agents, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function sendManagementOverviewText() {
  try {
    await req.post(
      `${serverDomain}/api/v1/stats/send_management_overview_sms`,
      null,
      "snake"
    );
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// Welcome Call Functions

export async function getWelcomeCallsForCustomer(id) {
  try {
    const calls = await req.get(
      `${serverDomain}/api/v1/customers/${id}/welcome_calls`,
      null,
      "snake"
    );
    return [calls, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function saveWelcomeCall(customerId, newWCData) {
  try {
    await req.post(
      `${serverDomain}/api/v1/customers/${customerId}/welcome_call`,
      newWCData
    );
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function generateWelcomePack(accountID) {
  try {
    await req.post(
      `${serverDomain}/api/v1/customers/${accountID}/welcome_pack`
    );
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function updateWelcomeCall(id, rating, notes, agentId) {
  try {
    await req.put(`${serverDomain}/api/v1/welcome_calls/${id}`, {
      rating: rating,
      notes: notes,
      agent_id: agentId
    });
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// Connection Charge Functions

export async function getConnectionCharges() {
  try {
    const charges = await req.get(
      `${serverDomain}/api/v1/connection_charges`,
      null,
      "snake"
    );
    return [charges, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

// Winback/Cancel Functions

export async function saveWinback(dealId, date, agentId, notes) {
  try {
    await req.post(`${serverDomain}/api/v1/deals/${dealId}/winback`, {
      date: date,
      agent_id: agentId,
      notes: notes
    });
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getWinbackAgents() {
  try {
    const agents = await req.get(
      `${serverDomain}/api/v1/winback_agents`,
      null,
      "snake"
    );
    return [agents, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function getWinbacksForDeal(id) {
  try {
    const wbs = await req.get(
      `${serverDomain}/api/v1/deals/${id}/winbacks`,
      null,
      "snake"
    );
    return [wbs, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function deleteWinback(id) {
  try {
    await req.del(`${serverDomain}/api/v1/winbacks/${id}`, null);
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function saveCustomerCancellationRequest(
  customerId,
  reason,
  notes
) {
  try {
    await req.post(
      `${serverDomain}/api/v1/customers/${customerId}/cancellation_request`,
      {
        reason: reason,
        notes: notes
      }
    );
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function saveCancellation(dealId, reason, notes) {
  try {
    await req.post(`${serverDomain}/api/v1/deals/${dealId}/cancel`, {
      reason: reason,
      notes: notes
    });
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getProvisioningJobsForDeal(id) {
  try {
    const lps = await req.get(
      `${serverDomain}/api/v1/deals/${id}/provisioning_jobs`,
      null,
      "snake"
    );
    return [lps || [], null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function saveLineProvision(dealId, data) {
  try {
    await req.post(`${serverDomain}/api/v1/deals/${dealId}/provision`, data);
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

export async function getProvisioningStatusForDeal(id) {
  try {
    const status = await req.get(
      `${serverDomain}/api/v1/deals/${id}/provisioning_status`,
      null,
      "snake"
    );
    return [status, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}

export async function singleAutoProvision(dealId, portal, accountId) {
  try {
    const lp = await req.post(
      `${serverDomain}/api/v1/deals/${dealId}/auto_provision`,
      {
        portal: portal,
        account_id: accountId
      }
    );
    return [lp, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}
