import React from "react";
import "../../../styles/Customers/Credits/CreditsHistory.css";

function CreditHistoryRow({createdAt, amount, reason}) {
  return (
    <tr>
      <td>{createdAt}</td>
      <td>{amount}</td>
      <td>{reason}</td>
    </tr>
  )
}

export default CreditHistoryRow;
