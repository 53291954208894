// Libraries
import React, { useContext, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

// Assets
import Logo from "../assets/logos/Logo.svg";
import DownArrow from "../assets/interface-icons/down-arrow.svg";
import HomeIcon from "../assets/interface-icons/home.svg";
import SupportIcon from "../assets/interface-icons/life-ring.svg";
import ChartIcon from "../assets/interface-icons/chart-line-up.svg";
import HeadsetIcon from "../assets/interface-icons/headset.svg";
import PhoneIcon from "../assets/interface-icons/call-outgoing.svg";
import WallboardIcon from "../assets/interface-icons/screen.svg";
import BroadbandIcon from "../assets/interface-icons/wifi.svg";
import CustomersIcon from "../assets/interface-icons/users.svg";
import FaultsIcon from "../assets/interface-icons/fault.svg";
import RetentionsIcon from "../assets/interface-icons/lock.svg";

// Styles
import "../styles/NavBar.css";

// Constants
import { switchedUserID } from "../utils/constants.js";

// Views
import { SwitchedUserIndicator } from "./navbar/SwitchedUserIndicator.js";

// Context
import { OpenNavMenuDropdownContext } from "../Main";
import CompanySwitcher from "./sidebar/CompanySwitcher";

const NavBarButton = ({
  active = false,
  icon,
  title,
  items = [],
  onClick = () => {}
}) => {
  const className = `${active ? "nav-menu-button active" : "nav-menu-button"}`;

  const { openNavMenuDropdown, setOpenNavMenuDropdown } = useContext(
    OpenNavMenuDropdownContext
  );

  return (
    <div className="nav-menu-item-wrapper">
      <button
        className={className}
        onMouseEnter={async () => {
          if (items.length > 0) {
            setOpenNavMenuDropdown(
              openNavMenuDropdown === title ? null : title
            );
          } else {
            setOpenNavMenuDropdown(null);
          }
        }}
        onClick={async () => {
          if (items.length === 0) {
            onClick();
          }
        }}
      >
        <img className="icon" src={icon} />
        <span>{title}</span>
        {items.length > 0 && (
          <img className="down-handle-icon" src={DownArrow} />
        )}
      </button>
      {items.length > 0 && openNavMenuDropdown === title && (
        <div
          className="dropdown-menu"
          onMouseLeave={() => {
            setOpenNavMenuDropdown(null);
          }}
        >
          {items.map(item => {
            return (
              <button className="dropdown-item" onClick={item.click}>
                <img src={item.icon} />
                <span>{item.title}</span>
              </button>
            );
          })}
        </div>
      )}
    </div>
  );
};

function NavBar({
  darkBack = false,
  backButtonTitle = null,
  backButtonAction = () => {}
}) {
  const navigate = useNavigate();

  return (
    <div className="nav-bar">
      <img src={Logo} className="logo" />

      {/* Disabled unless required to switch brands. */}
      {/* <CompanySwitcher /> */}

      {backButtonTitle && (
        <button className="back-btn" onClick={backButtonAction}>
          <span>{backButtonTitle}</span>
        </button>
      )}

      <div className="nav-menu">
        {/* <NavBarButton
          icon={HomeIcon}
          title={"Dashboard"}
          onClick={() => {
            navigate("home");
          }}
        /> */}
        <NavBarButton
          icon={ChartIcon}
          title={"Sales"}
          items={[
            {
              icon: PhoneIcon,
              title: "Outbound Calls",
              click: () => {
                navigate("/outbound-calls");
              }
            },
            {
              icon: WallboardIcon,
              title: "Sales Wallboard",
              click: () => {
                navigate("/sales-wallboard");
              }
            }
          ]}
        />
        <NavBarButton
          icon={HeadsetIcon}
          title={"Operations"}
          items={[
            {
              icon: PhoneIcon,
              title: "Provisioning",
              click: () => {
                navigate("/provisioning");
              }
            },
            {
              icon: PhoneIcon,
              title: "Welcome Calls",
              click: () => {
                navigate("/welcome-calls");
              }
            },
            {
              icon: CustomersIcon,
              title: "Customers",
              click: () => {
                navigate("/customers");
              }
            },
            {
              icon: FaultsIcon,
              title: "Tech Faults",
              click: () => {
                navigate("/technical-faults");
              }
            },
            {
              icon: RetentionsIcon,
              title: "Retentions",
              click: () => {
                navigate("/retentions");
              }
            },
            {
              icon: RetentionsIcon,
              title: "Billing",
              click: () => {
                navigate("/billing");
              }
            }
          ]}
        />
        {/* <NavBarButton
          icon={SupportIcon}
          title={"Support"}
          onClick={() => {
            navigate("support");
          }}
        />*/}
      </div>

      <SwitchedUserIndicator user={switchedUserID} />
    </div>
  );
}

export default NavBar;
