import React, { useContext, useEffect, useState } from "react";

// Views
import { ModalSubactionButton } from "../../generic/modal/Modal";

// Assets
import DefaultProfileImage from "../../../assets/DefaultProfileImage.svg";
import SendIcon from "../../../assets/interface-icons/paper-plane.svg";

// Styles
import "../../../styles/outbound-calls/overview/OutboundCallsScreenHeader.css";

// Contexts
import { UserContext } from "../../../Main";

// Functions
import {
  checkIfUserIsPodManager,
  getSalesStats,
  sendPersonalStatsSMS,
  sendPodStatsSMS,
  setStats
} from "../../../functions/outbound-calls/outbound-call-functions";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../../utils/LoadingHandler";
import { showErrorToast } from "../../../utils/ErrorHandler";

export const OutboundCallsScreenHeader = ({ userProfilePicture, userName }) => {
  const [showPodStats, setShowPodStats] = useState(false);
  const { user, _ } = useContext(UserContext);

  const [stats, setStats] = useState(null);

  useEffect(() => {
    (async () => {
      if (user) {
        const [isManager, err] = await checkIfUserIsPodManager(user.id);
        if (!err) {
          setShowPodStats(isManager);
        }
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      const [s, err] = await getSalesStats();
      if (err) {
        showErrorToast("Failed to get stats");
        return;
      }
      setStats(s);
      return;
    })();
  }, []);

  return (
    <div className="outbound-calls-screen-header">
      <div className="profile-content-wrapper">
        <div className="profile-picture-wrapper">
          <img src={userProfilePicture || DefaultProfileImage} />
        </div>
        <div className="text-wrapper">
          <span className="user-name-lbl">{userName}</span>
          <span className="subheader-lbl">
            Here is your sales summary for today.
          </span>
        </div>
        {stats && (
          <div className="stats-wrapper">
            <span>{stats.individual_deals} My Deals</span>
            <span>{stats.floor_deals} Floor Deals</span>
            <span>{stats.cph} Floor CPH</span>
          </div>
        )}

        {/* <div className="buttons-wrapper">
          <ModalSubactionButton
            icon={SendIcon}
            title={"Send Personal Stats"}
            onClick={async () => {
              if (user) {
                showLoadingToast();
                let date = new Date();
                date.setDate(date.getDate() + 1);
                const err = await sendPersonalStatsSMS(
                  new Date().toISOString().slice(0, 10),
                  date.toISOString().slice(0, 10),
                  user.id
                );
                if (err) {
                  console.log(err);
                  showErrorToast(
                    "Failed to send SMS",
                    "Could not send sms, please refresh page and try again."
                  );
                }
                removeLoadingToast();
              } else {
                showErrorToast(
                  "Failed to send SMS",
                  "Could not send sms, please refresh page and try again."
                );
              }
            }}
          />
          {showPodStats && (
            <ModalSubactionButton
              icon={SendIcon}
              title={"Send Pod Stats"}
              onClick={async () => {
                if (user) {
                  showLoadingToast();
                  let date = new Date();
                  date.setDate(date.getDate() + 1);
                  const err = await sendPodStatsSMS(
                    new Date().toISOString().slice(0, 10),
                    date.toISOString().slice(0, 10),
                    user.id
                  );
                  if (err) {
                    showErrorToast(
                      "Failed to send SMS",
                      "Could not send sms, please refresh page and try again."
                    );
                  }
                  removeLoadingToast();
                } else {
                  showErrorToast(
                    "Failed to send SMS",
                    "Could not send sms, please refresh page and try again."
                  );
                }
              }}
            />
          )}
        </div> */}
      </div>
    </div>
  );
};
