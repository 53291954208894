import React from "react";
import { createRoot } from "react-dom/client";
import { WarningToast } from "../views/generic/WarningToast";
import { generateUUID } from "../functions/core_functions";

export function showWarningToast(title, err, permanent = false) {
  /* title is an optional parameter */

  const id = generateUUID();

  const div = document.createElement("div");
  div.id = id;
  div.className = "warning-popup";
  document.getElementById("root").appendChild(div);
  const toast = (
    <WarningToast
      title={title}
      description={err}
      onClose={async () => {
        removeWarningToast(id);
      }}
    />
  );
  const root = createRoot(document.getElementById(id));
  root.render(toast);

  if (!permanent) {
    setTimeout(() => {
      removeWarningToast(id);
    }, 6000);
  }
}

function removeWarningToast(id) {
  const mainDiv = document.getElementById("warning-toast");
  if (mainDiv) {
    mainDiv.classList.add("removing");
    const toast = mainDiv.querySelectorAll(".warning-toast")[0];
    if (toast) {
      toast.classList.add("removing");
    }
    setTimeout(() => {
      mainDiv.remove();
    }, 250);
  }
}
