import React, { useState } from "react";
import PropTypes from "prop-types";
import { func } from "prop-types";
import "../styles/generic/SearchBar.css";
import SearchIcon from "../assets/SearchIcon.png";
import { SingleLineInput } from "./generic/SingleLineInput";

function SearchBar({ id, placeholder = "Search...", onSearch = text => {} }) {
  const [searchText, setSearchText] = useState("");

  return (
    <div id={id} className="search-bar">
      <SingleLineInput
        id="search-bar-input"
        type="text"
        placeholder={placeholder}
        onChange={e => {
          setSearchText(e.target.value);
        }}
        onEnter={e => {
          onSearch(searchText);
        }}
      />
      <button
        onClick={async () => {
          onSearch(searchText);
        }}
      >
        <img src={SearchIcon} />
      </button>
    </div>
  );
}

SearchBar.propTypes = { id: PropTypes.number, onSearch: func };

export default SearchBar;
