import React from "react";
import "../../styles/generic/PageTitle.css";

export const PageTitle = ({ label }) => {
  return (
    <div>
      <span className="page-title">{label}</span>
    </div>
  );
};
