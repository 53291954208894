import React from "react";
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalInputGrid,
  ModalRow,
  ModalSection
} from "../generic/modal/Modal";
import { MultiLineInput } from "../generic/MultiLineInput";
import PrimaryActionButton from "../generic/PrimaryActionButton";
import { SingleLineInput } from "../generic/SingleLineInput";

const SendLetterForm = () => {
  return (
    <Modal id={"send-customer-letter-form"}>
      <ModalHeader title={"Send Letter To Customer"} />
      <ModalContent>
        <ModalSection>
          <ModalInputGrid>
            <SingleLineInput
              id={"address-line-1-input"}
              title={"Address Line 1"}
              required={true}
            />
            <SingleLineInput
              id={"address-line-2-input"}
              title={"Address Line 2"}
            />
            <SingleLineInput
              id={"town-city-input"}
              title={"Town/City"}
              required={true}
            />
            <SingleLineInput id={"county-input"} title={"County"} />
            <SingleLineInput
              id={"postcode-input"}
              title={"Postcode"}
              required={true}
            />

            <div></div>
            <MultiLineInput
              id={"message-input"}
              title={"Message"}
              placeholder={"Write a message to send to the customer..."}
            />
          </ModalInputGrid>
        </ModalSection>
      </ModalContent>
      <ModalFooter>
        <PrimaryActionButton id={"send-button"} title={"Send"} />
      </ModalFooter>
    </Modal>
  );
};

export default SendLetterForm;
