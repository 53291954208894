import React from "react";
import "../../styles/Dashboard/DashboardLeaderboardPosition.css";
import DefaultProfileImage from "../../assets/DefaultProfileImage.svg";

function DashboardLeaderboardPosition({ pos, imgRef, name, score }) {
  return (
    <div className="dashboard-leaderboard-position">
      <span className="ldb-pos-number">{pos}</span>
      <div className="ldb-pos-pp">
        <img src={imgRef ? imgRef : DefaultProfileImage} />
      </div>
      <span className="ldb-pos-name">{name}</span>
      <span className="ldb-pos-score">{score}</span>
    </div>
  );
}

export default DashboardLeaderboardPosition;
