import React, { useEffect, useState } from "react";

// Views
import { Table } from "../views/generic/Table";
import Screen from "./Screen";

// Styles
import "../styles/Sales/SalesWallboard.css";
import { getSalesWallboardIndividualStats } from "../functions/sales_wallboard_functions";
import { showErrorToast } from "../utils/ErrorHandler";

const SalesWallboard = () => {
  const [individualStats, setIndividualStats] = useState([]);
  const [podStats, setPodStats] = useState({});

  useEffect(() => {
    (async () => {
      await getStats();
    })();
  }, []);

  async function getStats() {
    const [stats, err] = await getSalesWallboardIndividualStats();
    if (err) {
      showErrorToast("Failed to get wallboard stats");
      return;
    } else {
      setIndividualStats(stats || []);
      var ps = {};
      stats.forEach(stat => {
        var map = ps[stat.pod_name] || {};
        map["session_1"] = (map["session_1"] || 0) + stat.session_1;
        map["session_2"] = (map["session_2"] || 0) + stat.session_2;
        map["session_3"] = (map["session_3"] || 0) + stat.session_3;
        map["session_4"] = (map["session_4"] || 0) + stat.session_4;
        map["total"] = (map["total"] || 0) + stat.total;
        ps[stat.pod_name] = map;
      });
      setPodStats(ps);
      setTimeout(async () => {
        await getStats();
      }, 10000);
    }
  }

  return (
    <Screen>
      <div className="sales-wallboard">
        <Table
          headers={[
            "Pod",
            "Session 1",
            "Session 2",
            "Session 3",
            "Session 4",
            "Total",
            "CPH"
          ]}
          rowData={Object.keys(podStats).map(stat => {
            return {
              pod: stat,
              session_1: podStats[stat].session_1,
              session_2: podStats[stat].session_2,
              session_3: podStats[stat].session_3,
              session_4: podStats[stat].session_4,
              total: podStats[stat].total,
              cph: ""
            };
          })}
        />
        <Table
          headers={[
            "Agent",
            "Session 1",
            "Session 2",
            "Session 3",
            "Session 4",
            "Total"
          ]}
          rowData={individualStats.map(stat => {
            return {
              agent: stat.agent_name,
              session_1: stat.session_1 !== 0 ? stat.session_1 : "",
              session_2: stat.session_2 !== 0 ? stat.session_2 : "",
              session_3: stat.session_3 !== 0 ? stat.session_3 : "",
              session_4: stat.session_4 !== 0 ? stat.session_4 : "",
              total: stat.total !== 0 ? stat.total : ""
            };
          })}
        />
      </div>
    </Screen>
  );
};

export default SalesWallboard;
