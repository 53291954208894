import React, { useContext, useEffect, useState } from "react";
import { BrowserRouter, Route, Routes, Navigate } from "react-router-dom";

// Screens
import Login from "./screens/Login";
import Home from "./screens/Home";
import OutboundCallsScreenV2 from "./screens/OutboundCalls";
import NavBar from "./views/NavBar";
import { UserContext } from "./Main";
import { getSwitchedUserData } from "./functions/auth/switch-user-functions";
import { getLoggedInUser } from "./functions/core_functions";
import { defaultPagePostLogin, setSwitchedUserID } from "./utils/constants";
import Provisioning from "./screens/Provisioning";
import { SpotifyCallback } from "./screens/SpotifyCallback";
import Customers from "./screens/Customers";
import CustomerDetail from "./screens/CustomerDetail";
import BroadbandOrders from "./screens/BroadbandOrders";
import TechnicalFaults from "./screens/TechnicalFaults";
import SalesWallboard from "./screens/SalesWallboard";
import Retentions from "./screens/Retentions";
import Billing from "./screens/Billing";
import WelcomeCalls from "./screens/WelcomeCalls";

function PageRouter() {
  const { user, setUser } = useContext(UserContext);
  const [firstState, setFirstState] = useState(false);

  useEffect(() => {
    (async () => {
      setFirstState(true);

      const switchedUserIdFromStorage = localStorage.getItem(
        "rscrm_switched_user_id"
      );
      if (switchedUserIdFromStorage) {
        const switchedUser = await getSwitchedUserData();
        if (switchedUser) {
          setUser(switchedUser);
        }
      } else {
        const u = await getLoggedInUser();
        setUser(u);
      }
    })();
  }, []);

  useEffect(() => {
    if (firstState && !user && window.location.pathname !== "/login") {
      window.location.pathname = "/login";
    } else if (firstState && user && window.location.pathname === "/login") {
      window.location.pathname = defaultPagePostLogin;
    }
  }, [user]);

  return (
    <BrowserRouter>
      {!user && (
        <Routes>
          <Route path="/" element={<Navigate to="login" />} />
          <Route exact path="/login" element={<Login />} />
        </Routes>
      )}

      {user && (
        <div id="app-content-wrapper">
          <div className="app-content">
            <NavBar breakAction={async () => {}} />

            <div className="view-container">
              <Routes>
                <Route path="/spotify/callback" element={<SpotifyCallback />} />

                <Route exact path="/home" element={<Home />} />
                {/* <Route exact path="/my-account" component={Account} /> */}
                <Route
                  path="/outbound-calls"
                  element={<OutboundCallsScreenV2 />}
                />
                <Route path="/sales-wallboard" element={<SalesWallboard />} />
                <Route path="/provisioning" element={<Provisioning />} />
                <Route path="/welcome-calls" element={<WelcomeCalls />} />
                <Route path="/customers" element={<Customers />} />
                <Route path="/customers/:id" element={<CustomerDetail />} />
                <Route path="/broadband-orders" element={<BroadbandOrders />} />
                <Route path="/technical-faults" element={<TechnicalFaults />} />
                <Route path="/retentions" element={<Retentions />} />
                <Route path="/billing" element={<Billing />} />
                {/*<Route path="/handovers" component={Handovers} />*/}
                {/* <Route
                path="/outbound-calls-script"
                component={CallingSessionScript}
              />
              <Route path="/punted-records" component={PuntedRecords} />
              
              <Route path="/pending-credits" component={ProvisioningCredits} />
              <Route path="/onboarding" component={Onboarding} />
              <Route path="/customers" component={Customers} />
              <Route path="/concerns" component={CustomerConcerns} />
              <Route path="/technical-faults" component={TechnicalFaults} />

              

              <Route path="/customer-detail/:id" component={CustomerDetail} />
              <Route path="/users" component={Users} />
              <Route path="/user-detail/:id" component={UserDetail} />
              <Route path="/script-settings" component={ScriptSettings} />
              <Route path="/session-management" component={SessionManagement} />
              <Route path="/committed-deals" component={CommittedDeals} />
              <Route path="/record-settings" component={RecordSettings} />
              <Route path="/price-settings" component={PriceSettings} /> */}
              </Routes>
            </div>
          </div>
        </div>
      )}
    </BrowserRouter>
  );
}

export default PageRouter;
