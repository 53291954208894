import React from "react";
import "../../../styles/Customers/TechnicalFaults/TechnicalFaultEngineerVisitDetailView.css";
import CrossIcon from "../../../assets/CrossIcon.svg";
import { updateTechnicalFaultEngineerVisit } from "../../../functions/technical_fault_functions.js";

function TechnicalFaultEngineerVisitDetailView({visit}) {
  return (
    <div className="tech-fault-engineer-visit-detail-view-container">
    <div className="tech-fault-engineer-visit-detail-view-top-row">
      <span className="tech-fault-engineer-visit-detail-view-title">Engineer Visit (ID#{visit.id})</span>
      <button className="tech-fault-engineer-visit-detail-view-exit-btn" onClick={ async () => {
        document.getElementById("tech-fault-engineer-visit-detail-view-parent-div").remove();
      }}>
        <img src={CrossIcon}/>
      </button>
    </div>
      <span className="tech-fault-engineer-visit-detail-view-description"></span>
      <input id="tech-fault-engineer-visit-detail-reference-input" type="text" placeholder="Appointment Reference" defaultValue={visit.reference}/>
      <input id="tech-fault-engineer-visit-detail-visited-on-date-input" type="datetime-local" defaultValue={new Date(visit.visited_on).toISOString().slice(0,16)}/>
      <span className="tech-fault-engineer-visit-detail-view-input-header">Closing Notes:</span>
      <textarea id="tech-fault-engineer-visit-detail-closing-notes-input" defaultValue={visit.closing_notes}></textarea>
      <button className="tech-fault-engineer-visit-detail-view-submit-btn" onClick={async ()=>{await updateTechnicalFaultEngineerVisit(visit.fault_id, visit.id)}}>Save Changes</button>
    </div>
  )
}

export default TechnicalFaultEngineerVisitDetailView;
