import React from "react";
import { Table } from "../generic/Table";

import "../../styles/Customers/CustomerBillingTab.css";

export const CustomerBillingTab = () => {
  return (
    <div className="customer-billing-tab">
      <Table headers={["Type", "Date/Time", "Amount", "Balance"]} />
    </div>
  );
};
