import React, { useState } from "react";
import { checkBankDetails } from "../../../functions/outbound-calls/calling_session_functions";
import { showErrorToast } from "../../../utils/ErrorHandler";
import { DropdownInput } from "../../generic/DropdownInput";
import { MultiLineInput } from "../../generic/MultiLineInput";
import PrimaryActionButton from "../../generic/PrimaryActionButton";
import { SingleLineInput } from "../../generic/SingleLineInput";
import { OrderFormAccordian } from "./OrderFormAccordian";

export const CustomerDetailsForm = ({ data, setData }) => {
  const [validBankDetailsProvided, setValidBankDetailsProvided] =
    useState(null);

  const [sortCode, setSortCode] = useState(null);
  const [accountNumber, setAccountNumber] = useState(null);

  const countyLookup = async postcode => {
    fetch(`https://api.postcodes.io/postcodes/${postcode}`, {
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(resp => resp.json())
      .then(data => {
        const field = document.getElementById("address-county-field");
        field.value = data.result.pfa;
        // setData({ ...data, address_county: data.result.pfa });
        return data.result.pfa;
      })
      .catch(err => {
        return;
      });
  };

  //01924259469

  return (
    <OrderFormAccordian
      title={"Customer Information"}
      checked={true}
      setChecked={() => {}}
    >
      <DropdownInput
        id={"acccount-name-title-field"}
        required={true}
        title={"Title"}
        options={[
          { title: "Mr", value: "Mr" },
          { title: "Mrs", value: "Mrs" },
          { title: "Miss", value: "Miss" },
          { title: "Ms", value: "Ms" }
        ]}
        placeholder={"Select a title..."}
        onChange={e => {
          setData({
            ...data,
            title: e.target.value == "Select a title..." ? null : e.target.value
          });
        }}
        defaultValue={data.title}
      />
      <SingleLineInput
        id={"account-first-name-field"}
        type={"text"}
        required={true}
        title={"First Name"}
        placeholder={"John"}
        onChange={e => {
          setData({ ...data, first_name: e.target.value });
        }}
        defaultValue={data.first_name}
      />
      <SingleLineInput
        id={"account-last-name-field"}
        type={"text"}
        required={true}
        title={"Last Name"}
        placeholder={"Doe"}
        onChange={e => {
          setData({ ...data, last_name: e.target.value });
        }}
        defaultValue={data.last_name}
      />
      <SingleLineInput
        id={"additional-account-holder-field"}
        type={"text"}
        required={false}
        title={"Additional Account Holder"}
        placeholder={"Mrs Jane Doe"}
        onChange={e => {
          setData({ ...data, add_account_holder: e.target.value });
        }}
        defaultValue={data.add_account_holder}
      />
      <SingleLineInput
        id={"mobile-number-field"}
        type={"number"}
        required={false}
        title={"Mobile Number"}
        placeholder={"07123456789"}
        onChange={e => {
          setData({ ...data, mobile: e.target.value });
        }}
        defaultValue={data.mobile}
      />
      <SingleLineInput
        id={"email-address-field"}
        type={"text"}
        required={false}
        title={"Email Address"}
        placeholder={"john.doe@mail.com"}
        onChange={e => {
          setData({ ...data, email: e.target.value });
        }}
        defaultValue={data.email}
      />
      <SingleLineInput
        id={"address-line-1-field"}
        type={"text"}
        required={true}
        title={"Address Line 1"}
        placeholder={"4a Castle View Mews"}
        onChange={e => {
          setData({ ...data, address1: e.target.value });
        }}
        defaultValue={data.address1}
      />
      <SingleLineInput
        id={"address-line-2-field"}
        type={"text"}
        required={true}
        title={"Address Line 2"}
        placeholder={"Castle Hill"}
        onChange={e => {
          setData({ ...data, address2: e.target.value });
        }}
        defaultValue={data.address2}
      />
      <SingleLineInput
        id={"town-city-field"}
        type={"text"}
        required={true}
        title={"Town/City"}
        placeholder={"Rochester"}
        onChange={e => {
          setData({ ...data, address_city: e.target.value });
        }}
        defaultValue={data.address_city}
      />
      <SingleLineInput
        id={"address-county-field"}
        type={"text"}
        required={true}
        title={"County"}
        placeholder={"Kent"}
        onChange={e => {
          setData({ ...data, address_county: e.target.value });
        }}
        defaultValue={data.address_county}
      />
      <SingleLineInput
        id={"address-postcode-field"}
        type={"text"}
        required={true}
        title={"Postcode"}
        placeholder={"ME1 1LA"}
        onChange={e => {
          (async () => {
            await countyLookup(e.target.value);
          })();
          setData({ ...data, address_postcode: e.target.value });
        }}
        defaultValue={data.address_postcode}
      />
      <DropdownInput
        id={"contract-length-field"}
        required={true}
        title={"Contract Length"}
        options={[
          { title: "24 Months", value: 24 },
          { title: "36 Months", value: 36 }
        ]}
        onChange={e => {
          setData({
            ...data,
            contract_length_months: !isNaN(parseInt(e.target.value))
              ? parseInt(e.target.value)
              : null
          });
        }}
        defaultValue={data.contract_length_months}
      />
      <MultiLineInput
        id={"soft-facts-input"}
        title={"Soft Facts"}
        placeholder={"Any additional notes..."}
        onChange={e => {
          setData({ ...data, soft_facts: e.target.value });
        }}
      />
      <SingleLineInput
        id={"invoice-name-field"}
        type={"text"}
        required={true}
        title={"Invoice Name"}
        placeholder={"Mr John Doe"}
        onChange={e => {
          setData({ ...data, invoice_name: e.target.value });
        }}
        defaultValue={data.invoice_name}
      />
      <DropdownInput
        id={"pbom-field"}
        required={true}
        title={"Payment Method"}
        placeholder={"Select a payment method..."}
        defaultValue={data.pbom}
        options={["DD", "Card", "Cheque", "BACS"].map(method => {
          return {
            title: method,
            value: method.toLowerCase()
          };
        })}
        onChange={async e => {
          setData({ ...data, pbom: e.target.value });
        }}
      />
      {data.pbom === "dd" && (
        <>
          <SingleLineInput
            id={"sort-code-field"}
            type={"number"}
            required={false}
            title={"Sort Code"}
            placeholder={"123456"}
            onChange={e => {
              setData({
                ...data,
                sort_code: e.target.value,
                iban_check: false
              });
              setSortCode(e.target.value);
              setValidBankDetailsProvided(null);
            }}
            defaultValue={data.sort_code}
          />
          <SingleLineInput
            id={"account-number-field"}
            type={"number"}
            required={false}
            title={"Account Number"}
            placeholder={"12345678"}
            onChange={e => {
              setData({
                ...data,
                account_number: e.target.value,
                iban_check: false
              });
              setAccountNumber(e.target.value);
              setValidBankDetailsProvided(null);
            }}
            defaultValue={data.account_number}
          />
          {!validBankDetailsProvided && (
            <PrimaryActionButton
              id={"iban-check-btn"}
              title={"Check Bank Details"}
              onClick={async () => {
                const bankDetails = await checkBankDetails(
                  sortCode,
                  accountNumber
                );
                if (bankDetails) {
                  setValidBankDetailsProvided(bankDetails.bank_name);
                  setData({
                    ...data,
                    iban_check: true,
                    bank_name: bankDetails.bank_name
                  });
                } else {
                  showErrorToast(
                    "Bank Details Invalid",
                    "The bank details given by the customer are being returned as invalid. Please verify the details with them."
                  );
                  return;
                }
              }}
            />
          )}
          {validBankDetailsProvided && (
            <span className="bank-details-confirmation">
              The bank details provided by the customer have come back as valid
              details with {validBankDetailsProvided}
            </span>
          )}
        </>
      )}
    </OrderFormAccordian>
  );
};
