import React, { useEffect } from "react";

// Styles
import "../../../styles/Provisioning/Line Takeovers/LineTakeoverDetail.css";
import "../../../styles/Provisioning/Line Takeovers/ProvisioningDealPopup.css";

// Assets
import ExitIcon from "../../../assets/buttons/ExitIcon.svg";
import ChessIcon from "../../../assets/ChessIcon.svg";

// Views
import HeaderedInput from "../../../views/elements/HeaderedInput.js";
import HeaderedSelect from "../../../views/elements/HeaderedSelect.js";
import HeaderedTextView from "../../../views/elements/HeaderedTextView.js";
import PrimaryActionButton from "../../../views/generic/PrimaryActionButton.js";

// function LineTakeoverDetail({ deal }) {
//   useEffect(() => {
//     (async () => {
//       const options = [
//         "pending",
//         "live",
//         "same-day-cancel",
//         "rejected",
//         "cancelled",
//         "invalid"
//       ];
//       const select = await document.getElementById(
//         "line-takeover-detail-status-field"
//       );

//       options.forEach((option, i) => {
//         const optElement = document.createElement("option");
//         optElement.value = option;
//         optElement.innerHTML = `${option.charAt(0).toUpperCase()}${option.slice(
//           1
//         )}`.replaceAll("-", " ");
//         select.appendChild(optElement);
//       });

//       select.value = deal.line_status;

//       document.getElementById(
//         "line-takeover-detail-openreach-ref-field"
//       ).value = deal.openreach_reference;

//       document.getElementById("line-takeover-detail-live-date-field").type =
//         "date";
//       document.getElementById("line-takeover-detail-live-date-field").value =
//         new Date(deal.live_date).toISOString().substring(0, 10);

//       document.getElementById("line-takeover-detail-status-notes-field").value =
//         deal.status_notes;
//     })();
//   });

//   return (
//     <div className="line-takeover-detail-container">
//       <div className="line-takeover-detail-top-row">
//         <span className="line-takeover-detail-title">
//           Line Takeover - {deal.account_id}
//         </span>
//         <button
//           className="line-takeover-detail-exit-btn"
//           onClick={async () => {
//             document.getElementById("line-takeover-detail-parent-div").remove();
//           }}
//         >
//           <img src={ExitIcon} />
//         </button>
//       </div>

//       <div className="line-takeover-detail-data-list">
//         <span>CLI: {deal.landline}</span>
//         <span>
//           Address: {deal.address1},{" "}
//           {deal.address2 !== "" ? `${deal.address2}, ` : ""}
//           {deal.address_city},{" "}
//           {deal.address_county !== "" ? `${deal.address_county}, ` : ""}
//           {deal.address_postcode}
//         </span>
//         <span>Broadband Package: {deal.broadband_package_name || "N/A"}</span>
//         <span>Current Provider: {deal.phone_provider.toUpperCase()}</span>
//       </div>

//       <div className="line-takeover-detail-input-row-1">
//         <HeaderedInput
//           id={"line-takeover-detail-live-date-input"}
//           title={"Live Date"}
//           fieldId={"line-takeover-detail-live-date-field"}
//           placeholder={"DD/MM/YYYY"}
//           type={"date"}
//         />
//         <HeaderedInput
//           id={"line-takeover-detail-openreach-ref-input"}
//           title={"Openreach Reference"}
//           fieldId={"line-takeover-detail-openreach-ref-field"}
//           placeholder={"Openreach ref..."}
//           type={"text"}
//         />
//       </div>

//       <HeaderedSelect
//         id={"line-takeover-detail-status-dropdown"}
//         title={"Status"}
//         fieldId={"line-takeover-detail-status-field"}
//       />

//       <HeaderedTextView
//         id={"line-takeover-detail-status-notes-input"}
//         title={"Status Notes"}
//         fieldId={"line-takeover-detail-status-notes-field"}
//         placeholder={"Please type the latest line takeover status notes here…"}
//       />

//       <div className="line-takeover-detail-button-row">
//         <button
//           id="line-takeover-detail-open-chess-btn"
//           onClick={async () => {
//             window.open("https://chess.imperatives.co.uk");
//           }}
//         >
//           <img src={ChessIcon} />
//           <span>Open Chess</span>
//         </button>
//         <PrimaryActionButton
//           id={"line-takeover-detail-submit-button"}
//           title={"Save Changes"}
//           onClick={async () => {
//             return;
//           }}
//         />
//       </div>
//     </div>
//   );
// }

// export default LineTakeoverDetail;

export default function ProvisioningDealPopup() {
  return (
    <div className="provisioning-deal-popup-wrapper">
      <div className="provisioning-deal-popup">
        <div className="top-row-wrapper">
          <button
            className="exit-btn"
            onClick={async () => {
              document
                .getElementById("provisioning-deal-popup-parent")
                .remove();
            }}
          >
            <img src={ExitIcon} />
          </button>
        </div>
        <div className="content-wrapper">
          <div className="col-1-wrapper">
            <CustomerDetailSection />
            <AddressSection />
          </div>
          <div className="col-2-wrapper">
            <DealPackagesSection />
          </div>
          <div className="col-3-wrapper">
            <ReckoStatusSection />
            <ProvisionSection />
          </div>
        </div>
      </div>
    </div>
  );
}

const CustomerDetailSection = () => {
  return (
    <div className="customer-details-wrapper">
      <HeaderedInput
        fieldId={"customer-name-input"}
        title={"Customer Name"}
        placeholder={"John Doe"}
      />
      <HeaderedInput
        fieldId={"full-name-input"}
        title={"Full Name"}
        placeholder={"John Doe"}
      />
      <HeaderedInput
        fieldId={"add-acc-holder-input"}
        title={"Additional Account Holder"}
        placeholder={"Jane Doe"}
      />
      <HeaderedInput
        fieldId={"poa-details-input"}
        title={"POA Details"}
        placeholder={""}
      />
      <HeaderedInput
        fieldId={"landline-input"}
        title={"Landline"}
        placeholder={""}
      />
      <HeaderedInput
        fieldId={"mobile-input"}
        title={"Mobile"}
        placeholder={""}
      />
      <HeaderedInput fieldId={"email-input"} title={"Email"} placeholder={""} />
    </div>
  );
};

const DealPackagesSection = () => {
  return (
    <div className="deal-packages-wrapper">
      <div className="package-wrapper">
        <HeaderedSelect fieldId={"tariff-input"} title={"Tariff"} />
        <input
          className="price-override-input"
          id="tariff-po-input"
          placeholder="Price Override"
        ></input>
      </div>
      <div className="package-wrapper">
        <HeaderedSelect
          fieldId={"line-rental-price-input"}
          title={"Line Rental Price"}
        />
        <input
          className="price-override-input"
          id="line-rental-price-po-input"
          placeholder="Price Override"
        ></input>
      </div>
      <div className="package-wrapper">
        <HeaderedSelect
          fieldId={"broadband-package-input"}
          title={"Broadband Package"}
        />
        <input
          className="price-override-input"
          id="broadband-package-po-input"
          placeholder="Price Override"
        ></input>
      </div>
      <div className="package-wrapper">
        <HeaderedSelect
          fieldId={"call-broadband-bundle-input"}
          title={"Call+Broadband Bundle"}
        />
        <input
          className="price-override-input"
          id="call-broadband-bundle-po-input"
          placeholder="Price Override"
        ></input>
      </div>
      <div className="package-wrapper">
        <HeaderedSelect
          fieldId={"mobile-package-input"}
          title={"Mobile Package"}
        />
        <input
          className="price-override-input"
          id="mobile-package-po-input"
          placeholder="Price Override"
        ></input>
      </div>
      <div className="package-wrapper">
        <HeaderedSelect
          fieldId={"non-geo-package-input"}
          title={"Non-Geographic Package"}
        />
        <input
          className="price-override-input"
          id="non-geo-package-po-input"
          placeholder="Price Override"
        ></input>
      </div>
      <div className="package-wrapper">
        <HeaderedSelect
          fieldId={"international-call-package-input"}
          title={"International Call Package"}
        />
        <input
          className="price-override-input"
          id="international-call-package-po-input"
          placeholder="Price Override"
        ></input>
      </div>
      <HeaderedInput
        fieldId={"international-call-location-input"}
        title={"International Call Location"}
      />
    </div>
  );
};

const ReckoStatusSection = () => {
  return (
    <div className="recorded-line-section-wrapper">
      <div className="top-row">
        <span className="header-lbl">Recorded Line</span>
        <select className="status-dropdown">
          <option value=""></option>
          <option value="clean">Clean</option>
          <option value="invalid">Invalid</option>
          <option value="questionable">Questionable</option>
        </select>
      </div>
      <div className="call-recording-wrapper">
        <span className="agent-name-lbl">John Doe</span>
        <audio controls src="">
          <a href="/media/cc0-audio/t-rex-roar.mp3">Download audio</a>
        </audio>
      </div>
      <span className="call-clipping-header">RECKO Start Time:</span>
      <div className="call-clipping-wrapper">
        <input type="time"></input>
        <PrimaryActionButton
          id={"clip-recording-btn"}
          title={"Clip Recording"}
          onClick={() => {}}
        />
      </div>
      <span className="call-clipping-header">RECKO Notes:</span>
      <textarea className="recko-notes-input"></textarea>
      <PrimaryActionButton
        id={"save-recko-notes-btn"}
        title={"Save Notes"}
        onClick={() => {}}
      />
    </div>
  );
};

const ProvisionSection = () => {
  return <div>Test</div>;
};

const AddressSection = () => {
  return (
    <div className="address-details-wrapper">
      <HeaderedInput
        fieldId={"address-1-input"}
        title={"Address Line 1"}
        placeholder={""}
      />
      <HeaderedInput
        fieldId={"address-2-input"}
        title={"Address Line 2"}
        placeholder={""}
      />
      <HeaderedInput
        fieldId={"address-town-city-input"}
        title={"Town/City"}
        placeholder={""}
      />
      <HeaderedInput
        fieldId={"address-county-input"}
        title={"County"}
        placeholder={""}
      />
      <HeaderedInput
        fieldId={"address-postcode-input"}
        title={"Postcode"}
        placeholder={""}
      />
    </div>
  );
};
