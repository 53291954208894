import React from "react";
import { createRoot } from "react-dom/client";

import "../../styles/Customers/CustomerTariffPackagesTab.css";
import EditButton from "../generic/EditButton";
import { Table } from "../generic/Table";
import TariffPackageAmmendmentForm from "./TariffPackageAmmendmentForm";

export const CustomerTariffPackagesTab = () => {
  return (
    <div className="customer-tariff-packages-tab">
      <div className="packages-grid-wrapper">
        <div className="packages-grid">
          <TariffPackageCard
            title={"Tariff"}
            name={"XXX Package Name"}
            price={"£0.00"}
          />
          <TariffPackageCard
            title={"Line Rental"}
            name={"XXX Package Name"}
            price={"£0.00"}
          />
          <TariffPackageCard
            title={"Broadband Package"}
            name={"XXX Package Name"}
            price={"£0.00"}
          />
          <TariffPackageCard
            title={"Call+Broadband Bundle"}
            name={"XXX Package Name"}
            price={"£0.00"}
          />
          <TariffPackageCard
            title={"Mobile Package"}
            name={"XXX Package Name"}
            price={"£0.00"}
          />
          <TariffPackageCard
            title={"Non-Geographic Package"}
            name={"XXX Package Name"}
            price={"£0.00"}
          />
          <TariffPackageCard
            title={"International Call Package"}
            name={"XXX Package Name"}
            price={"£0.00"}
          />
          <TariffPackageCard
            title={"Package Add-ons"}
            name={"0 Add-ons"}
            price={"£0.00"}
          />
          <TariffPackageCard
            title={"Select Services"}
            name={"0 Services"}
            price={"£0.00"}
          />
          <TariffPackageCard
            title={"Call Bolt-ons"}
            name={"0 Bolt-ons"}
            price={"£0.00"}
          />
        </div>
      </div>
      <div className="ammendments-wrapper">
        <span>Ammendments</span>
        <Table headers={["Change Date", "Description", "Actioned By"]} />
      </div>
    </div>
  );
};

const TariffPackageCard = ({ title, name, price }) => {
  return (
    <div className="tariff-package-card">
      <div className="content-wrapper">
        <span>{title}</span>
        <span>{name}</span>
        <span id={"price-lbl"}>{price}</span>
      </div>
      <EditButton
        onClick={async () => {
          const div = document.createElement("div");
          div.id = "modal-div-wrapper";
          document.getElementById("app-content-wrapper").appendChild(div);
          const modal = <TariffPackageAmmendmentForm />;
          const root = createRoot(document.getElementById("modal-div-wrapper"));
          root.render(modal);
        }}
      />
    </div>
  );
};
