import React from "react";
import { func } from "prop-types";
import "../styles/RescheduleCallbackPopup.css";
import CrossIcon from "./CrossIcon.js";

function RescheduleCallbackPopup({ rescheduleCallback }) {
  return (
    <div id="reschedule-cb-container">
      <div id="reschedule-cb-top-row">
        <span>Reschedule Customer Callback</span>
        <CrossIcon onClick={async () => {
          document.getElementById("reschedule-callback-popup").remove();
        }}/>
      </div>
      <span id="reschedule-cb-desc">
        If the customer wishes to be called back at a later date then simply select a date and time below and click reschedule. This will alter the time for the callback to a time and date of your choice.
      </span>
      <input id="reschedule-cb-datetime" type="datetime-local"/>
      <button className="reschedule-cb-submit" onClick={rescheduleCallback}>Reschedule</button>
    </div>
  );
}

RescheduleCallbackPopup.propTypes = { rescheduleCallback: func };

export default RescheduleCallbackPopup;
