import React from "react";

// Styles
import "../../styles/generic/DropdownInput.css";
import { SingleLineInput } from "./SingleLineInput";

export const DropdownInput = ({
  id,
  required = false,
  title,
  placeholder = "Select an option...",
  defaultValue,
  options = [],
  onChange = () => {},
  overrideChange = () => {},
  override = false,
  defaultOverride
}) => {
  return (
    <div className="dropdown-input-wrapper">
      {title && (
        <span className="header-lbl">
          {title}
          {required && <p>*</p>}
        </span>
      )}
      <select id={id} onChange={onChange}>
        {[{ title: placeholder, value: null }, ...options].map(option => {
          return (
            <option
              value={option.value}
              selected={defaultValue ? defaultValue == option.value : false}
            >
              {option.title}
            </option>
          );
        })}
      </select>
      {override && (
        <SingleLineInput
          id={`override-${id}`}
          type={"number"}
          required={false}
          placeholder={"£0.00"}
          defaultValue={defaultOverride}
          onChange={overrideChange}
        />
      )}
    </div>
  );
};
