import React, { useState, useEffect, useContext } from "react";
import SalesLeaderboard from "./SalesLeaderboard";
import "../../../styles/outbound-calls/overview/OutboundCallsOverviewTab.css";
import {
  getTotalClosesCount,
  getTotalDealsCount,
  getTotalEstimatedConnectionsCount,
  getTotalEstimatedDialsCount,
  getTotalHandoversCount,
  getTotalYIADDCount
} from "../../../functions/outbound-calls/outbound-call-functions";

// Contexts
import { SelectedCompanyContext, UserContext } from "../../../Main";
import WebPlayback, {
  SpotifyPlayerCard
} from "../../generic/SpotifyPlayerCard";
import { showSuccessToast } from "../../../utils/SuccessHandler";

export default function OutboundCallsOverviewTab() {
  const { user, _ } = useContext(UserContext);
  const { selectedCompany, setSelectedCompany } = useContext(
    SelectedCompanyContext
  );

  const [spotifyToken, setSpotifyToken] = useState("");

  useEffect(() => {
    const token = localStorage.getItem("rscrm_spotify_access_token");
    const expiryStr = localStorage.getItem("rscrm_spotify_token_expiry");
    if (expiryStr) {
      const expiryDate = new Date(expiryStr);
      if (token && token !== "" && expiryDate > new Date()) {
        setSpotifyToken(token);
      }
    }
  }, []);

  return (
    <div className="outbound-calls-overview-tab">
      <div className="grid-wrapper">
        <div className="stats-grid-wrapper">
          {/* <SpotifyPlayerCard token={spotifyToken} /> */}
          <GenericStatsSingleView
            title={"Deals"}
            description={"Number of deals you have done today."}
            state={[user, selectedCompany]}
            dataCallback={async () => {
              if (user) {
                let date = new Date();
                date.setDate(date.getDate() + 1);
                const [count, err] = await getTotalDealsCount(
                  new Date().toISOString().slice(0, 10),
                  date.toISOString().slice(0, 10),
                  user.id
                );
                if (err) {
                  return "-";
                } else {
                  return `${count}`;
                }
              } else {
                return "-";
              }
            }}
            dataDescription={"Deals"}
          />
          <GenericStatsSingleView
            title={"Closes"}
            description={"Number of closes you have done today."}
            state={[user, selectedCompany]}
            dataCallback={async () => {
              if (user) {
                let date = new Date();
                date.setDate(date.getDate() + 1);
                const [count, err] = await getTotalClosesCount(
                  new Date().toISOString().slice(0, 10),
                  date.toISOString().slice(0, 10),
                  user.id
                );
                if (err) {
                  return "-";
                } else {
                  return `${count}`;
                }
              } else {
                return "-";
              }
            }}
            dataDescription={"Closes"}
          />
          <GenericStatsSingleView
            title={"Handovers"}
            description={"Number of handovers you have done today."}
            state={[user, selectedCompany]}
            dataCallback={async () => {
              if (user) {
                let date = new Date();
                date.setDate(date.getDate() + 1);
                const [count, err] = await getTotalHandoversCount(
                  new Date().toISOString().slice(0, 10),
                  date.toISOString().slice(0, 10),
                  user.id
                );
                if (err) {
                  return "-";
                } else {
                  return `${count}`;
                }
              } else {
                return "-";
              }
            }}
            dataDescription={"Handovers"}
          />
          <GenericStatsSingleView
            title={"Estimated Connections"}
            description={
              "Number of dials you have done today that have connected."
            }
            state={[user, selectedCompany]}
            dataCallback={async () => {
              if (user) {
                let date = new Date();
                date.setDate(date.getDate() + 1);
                const [count, err] = await getTotalEstimatedConnectionsCount(
                  new Date().toISOString().slice(0, 10),
                  date.toISOString().slice(0, 10),
                  user.id
                );
                if (err) {
                  return "-";
                } else {
                  return `${count}`;
                }
              } else {
                return "-";
              }
            }}
            dataDescription={"Conns."}
          />
          <GenericStatsSingleView
            title={"Estimated Dials"}
            description={
              "Number of dials you have done today that you have attempted today."
            }
            state={[user, selectedCompany]}
            dataCallback={async () => {
              if (user) {
                let date = new Date();
                date.setDate(date.getDate() + 1);
                const [count, err] = await getTotalEstimatedDialsCount(
                  new Date().toISOString().slice(0, 10),
                  date.toISOString().slice(0, 10),
                  user.id
                );
                if (err) {
                  return "-";
                } else {
                  return `${count}`;
                }
              } else {
                return "-";
              }
            }}
            dataDescription={"Dials"}
          />
        </div>
        <SalesLeaderboard />
        <div className="stats-grid-wrapper">
          <GenericStatsSingleView
            title={"YIA DD"}
            description={
              "Number of deals you have completed today that were both YIA and DD."
            }
            state={[user, selectedCompany]}
            dataCallback={async () => {
              if (user) {
                let date = new Date();
                date.setDate(date.getDate() + 1);
                const [count, err] = await getTotalYIADDCount(
                  new Date().toISOString().slice(0, 10),
                  date.toISOString().slice(0, 10),
                  user.id
                );
                if (err) {
                  return "-";
                } else {
                  return `${count}`;
                }
              } else {
                return "-";
              }
            }}
            dataDescription={"Deals"}
          />
          <GenericStatsSingleView
            title={"Floor Deals"}
            description={"Number of deals the floor has done today."}
            state={[user, selectedCompany]}
            dataCallback={async () => {
              let date = new Date();
              date.setDate(date.getDate() + 1);
              const [count, err] = await getTotalDealsCount(
                new Date().toISOString().slice(0, 10),
                date.toISOString().slice(0, 10),
                null
              );
              if (count) {
                return count;
              } else {
                return "-";
              }
            }}
            dataDescription={"Deals"}
          />
          <GenericStatsSingleView
            title={"Performance"}
            description={
              "Rating of where the floor should be performing based off previous metrics."
            }
            state={[user, selectedCompany]}
            dataCallback={async () => {
              return "1.0";
            }}
          />
        </div>
        {/* <div className="sales-loc-wrapper">Sales Locations</div> */}
      </div>
    </div>
  );
}

const GenericStatsSingleView = ({
  icon,
  title,
  description,
  state,
  dataCallback,
  dataDescription
}) => {
  const [value, setValue] = useState(null);

  useEffect(() => {
    (async () => {
      const val = await dataCallback();
      setValue(val);
    })();
  }, state);

  return (
    <div className="stats-widget-single-view">
      <div className="content-wrapper">
        <div className="header-wrapper">
          <span className="widget-title-lbl">{title}</span>
          {description && description != "" && (
            <span className="widget-desc-lbl">{description}</span>
          )}
        </div>
        <div className="stat-value-wrapper">
          {value && <span className="widget-data-value-lbl">{value}</span>}
          <span className="widget-data-desc-lbl">{dataDescription}</span>
        </div>
      </div>
    </div>
  );
};
