import ReactDOM from "react-dom";
import React from "react";
import { serverDomain } from "../../utils/constants";
import * as request from "../../utils/request";
import {
  currentUser,
  fetchCurrentUser,
  hideLoadingOverlay
} from "../core_functions";
import { showErrorToast } from "../../utils/ErrorHandler";
import CallHistoryRow from "../../views/outbound-calls/overview/CallHistoryRow";

export async function getUserCallHistory() {
  if (currentUser === null) {
    await fetchCurrentUser();
  }

  var data;
  try {
    data = await request.get(
      `${serverDomain}/api/v1/outbound_call_history`,
      null,
      "snake"
    );
    return data;
  } catch (err) {
    hideLoadingOverlay();
    showErrorToast(
      "Failed to get call history",
      `There was an error that occurred whilst trying to retrieve user call history from the database.\n\nError: ${err}`
    );
    return null;
  }
}
