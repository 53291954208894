import React from "react";
import ReactDOM from "react-dom";
import { serverDomain, selectedCompanyID } from "../utils/constants.js";
import * as req from "../utils/request";

import { showErrorToast } from "../utils/ErrorHandler";
import { showAlert } from "../functions/core_functions.js";
import OpenRecordTableRowButton from "../views/elements/OpenRecordTableRowButton.js";
export var selectedRetentionData;

export async function setSelectedRetentionData(data) {
  selectedRetentionData = data;
}

// export async function searchCustomers() {
//
//   try {
//
//     const customersSearchInput = document.getElementById("customers-search-input");
//     const sidebarSearchInput = document.getElementById("sidebar-search-bar").querySelectorAll("input")[0];
//     var searchInput = null;
//
//     if (customersSearchInput !== null && customersSearchInput.value !== '') {
//       searchInput = customersSearchInput;
//     } else {
//       searchInput = sidebarSearchInput.value;
//     }
//
//     if (searchInput === null || searchInput === "") {
//       window.alert("Please enter a search query in order to search database from customers.");
//       return
//     }
//
//     if (!window.location.hash.includes("/customers")) {
//       window.location.hash = "/customers";
//     }
//
//     const table = document.getElementById("customers-results-table-body");
//     if (!table) {
//       return;
//     }
//     table.innerHTML = '';
//
//     const customers = await req.get(`${serverDomain}/api/v1/search_customers?search=${searchInput}`);
//     if (!customers || !Array.isArray(customers)) {
//       window.alert("No Search Results");
//       document.getElementById("customers-search-input").value = "";
//       return
//     }
//
//     customers.forEach((data, index) => {
//
//       const row = table.insertRow(index);
//       row.className = "customers-results-table-row";
//
//       row.addEventListener(
//         "click",
//         async () => {
//             window.location.hash = `/customer-detail/${data.id}`;
//           },
//           false
//       );
//
//       const rowData = [
//         data.account_id,
//         data.full_name,
//         data.landline,
//         `${data.address1}, ${data.address_postcode}`
//       ];
//
//       rowData.forEach((val, indx) => {
//         const cell = row.insertCell(indx);
//         cell.textContent = val;
//       });
//     });
//
//     customersSearchInput.value = sidebarSearchInput.value;
//     sidebarSearchInput.value = '';
//
//   } catch (err) {
//     console.log(err)
//   }
// }

export var listCancellationRequestsOffset = 0;

export function setListCancellationRequestsOffset(number) {
  listCancellationRequestsOffset = number;
}

export async function listCancellationRequests() {
  try {
    var requests = await req.get(
      `${serverDomain}/api/v1/open_cancellation_requests`,
      null,
      "snake"
    );
    return [requests, null];
  } catch (err) {
    return [null, err];
  }
}

export async function updateCancellationRequest(data) {
  if (selectedRetentionData === null) {
    showErrorToast(
      "Failed to save cancellation request",
      "There was an unexpected error that occurred whilst trying to save this request. Please refresh the page."
    );
    return;
  }

  try {
    await req.put(
      `${serverDomain}/api/v1/cancellation_requests/${selectedRetentionData.id}`,
      data
    );
  } catch (err) {
    showErrorToast(
      "Failed to save cancellation request",
      `There was an unexpected error that occurred whilst trying to save this request. ${err}`
    );
  }
}
