import React, { useEffect, useState } from "react";
import Modal, {
  closeModal,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalInputGrid,
  ModalRow,
  ModalSection
} from "../generic/modal/Modal";
import {
  getCustomer,
  getCustomerByDealID,
  getDeal,
  updateDeal
} from "../../functions/customer_functions";
import { DataPoint } from "../generic/DataPoint";
import {
  deleteWinback,
  getConnectionCharges,
  getProvisioningJobsForDeal,
  getProvisioningStatusForDeal,
  getRecordDataFromCLI,
  getVerbalAgreementForCustomer,
  getWelcomeCallsForCustomer,
  getWinbackAgents,
  getWinbacksForDeal,
  saveCancellation,
  saveCustomerCancellationRequest,
  saveLineProvision,
  saveVerbalAgreement,
  saveWelcomeCall,
  saveWinback,
  singleAutoProvision,
  updateWelcomeCall
} from "../../functions/provisioning_functions";
import { showErrorToast } from "../../utils/ErrorHandler";
import { getCallRecordingsForCLI } from "../../functions/recko_functions";
import { VerbalAgreementSection } from "./VerbalAgreementSection";
import { WelcomeCallsSection } from "./WelcomeCallsSection";
import { DropdownInput } from "../generic/DropdownInput";
import { MultiLineInput } from "../generic/MultiLineInput";
import PrimaryActionButton from "../generic/PrimaryActionButton";
import { SingleLineInput } from "../generic/SingleLineInput";
import { CheckboxInputList } from "../generic/CheckboxInputList";
import {
  checkBankDetails,
  getCloserAgents,
  getDealsheetItems,
  getHandoverAgents,
  tpsCheckNumber
} from "../../functions/outbound-calls/calling_session_functions";
import { renderPrice } from "../../utils/helpers";
import { getLatestTPSCheckLog } from "../../functions/tps_functions";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../utils/LoadingHandler";
import { showSuccessToast } from "../../utils/SuccessHandler";
import { showWarningToast } from "../../utils/WarningHandler";
import DestructiveActionButton from "../generic/DestructiveActionButton";

import "../../styles/Provisioning/ProvisioningDetail.css";
import { showConfirmation } from "../../functions/core_functions";
import { Table } from "../generic/Table";
import { LoadingWheel } from "../generic/LoadingToast";
import { convertToCurrency } from "../../utils/constants";

export const ProvisioningCustomerContext = React.createContext();
export const ProvisioningDealContext = React.createContext();

export const ProvisioningDetail = ({ dealId, selectedCompany }) => {
  const [customer, setCustomer] = useState(null);
  const [customerAddress, setCustomerAddress] = useState({});
  const [deal, setDeal] = useState({});
  const [lineRentalOrder, setLineRentalOrder] = useState(null);
  const [bbOrder, setBBOrder] = useState(null);

  const [latestTPSCheck, setLatestTPSCheck] = useState(null);
  const [isTPS, setIsTPS] = useState(null);

  const [changesMade, setChangesMade] = useState(false);

  const [welcomeCalls, setWelcomeCalls] = useState([]);

  const [connectionCharge, setConnectionCharge] = useState(null);
  const [connectionCharges, setConnectionCharges] = useState([]);

  const [newProvisionData, setNewProvisionData] = useState({});

  const [lineProvisions, setLineProvisions] = useState([]);

  const [existingWinbacks, setExistingWinbacks] = useState([]);
  const [winbackAgents, setWinbackAgents] = useState([]);
  const [winbackDate, setWinbackDate] = useState(new Date());
  const [winbackAgent, setWinbackAgent] = useState(null);
  const [winbackNotes, setWinbackNotes] = useState(null);

  const [cancellationReason, setCancellationReason] = useState(null);
  const [cancellationNotes, setCancellationNotes] = useState(null);

  useEffect(() => {
    (async () => {
      const [customer, err] = await getCustomerByDealID(dealId);
      if (err) {
        showErrorToast("Failed to get customer");
        return;
      }
      setCustomer(customer);
      setCustomerAddress(customer.address[0]);
    })();
  }, []);

  useEffect(() => {
    (async () => {
      const [deal, err] = await getDeal(dealId);
      if (err) {
        showErrorToast("Failed to get deal");
        return;
      }
      setDeal(deal);
      setBBOrder(deal.bb_order ? JSON.parse(deal.bb_order) : null);
      setLineRentalOrder(deal.lr_order ? JSON.parse(deal.lr_order) : null);
    })();
  }, []);

  // Get Latest TPS Check log
  useEffect(() => {
    (async () => {
      if (!customer || !customer.cli_list || customer.cli_list.length == 0) {
        return;
      }
      const [rec, err] = await getRecordDataFromCLI(customer.cli_list[0]);
      if (err) {
        showErrorToast(
          "Failed to TPS check number",
          "There was an error that occurred whilst trying to check this number on the TPS register. Please try again later."
        );
      } else {
        setLatestTPSCheck(rec.last_tps_cleansed_at);
      }
    })();
  }, [customer]);

  // Get connection charges - disabled for now as just using the default 39.99 for every install
  // useEffect(() => {
  //   (async () => {
  //     const [charges, err] = await getConnectionCharges();
  //     if (err) {
  //       showErrorToast("Failed to get connection charges");
  //       return;
  //     } else if (charges) {
  //       setConnectionCharges(charges);
  //     }
  //   })();
  // }, []);

  // // Winback agents
  // useEffect(() => {
  //   (async () => {
  //     const [agents, err] = await getWinbackAgents();
  //     if (agents) {
  //       setWinbackAgents(agents);
  //     }
  //   })();
  // }, []);

  // // Get winbacks for deal
  // useEffect(() => {
  //   (async () => {
  //     const [wbs, err] = await getWinbacksForDeal(dealId);
  //     if (wbs) {
  //       setExistingWinbacks(wbs);
  //     }
  //   })();
  // }, []);

  // Get line provisions for deal
  useEffect(() => {
    (async () => {
      const [lps, err] = await getProvisioningJobsForDeal(dealId);
      setLineProvisions(lps || []);
    })();
  }, []);

  return (
    <ProvisioningCustomerContext.Provider
      value={{ customer: customer, setCustomer: setCustomer }}
    >
      <Modal id={"provisioning-detail-modal"} closableFromBackground={false}>
        <ModalHeader
          title={`Provisioning - ${customer ? customer.full_name : ""}`}
        />
        {customer && (
          <ModalContent>
            <ModalSection title={"Core Information"}>
              <ModalInputGrid>
                <DataPoint
                  title={"Date/Time"}
                  value={`${new Date(
                    customer.created_at
                  ).toLocaleDateString()} ${new Date(
                    customer.created_at
                  ).toLocaleTimeString()}`}
                />
                <DataPoint title={"CLI"} value={customer.cli_list} />
                <DataPoint title={"Person Name"} value={customer.full_name} />
                <DataPoint title={"Account ID"} value={customer.account_id} />
                <DataPoint title={"Mobile"} value={customer.mobile || "---"} />
                <DataPoint title={"Email"} value={customer.email || "---"} />
                <DataPoint
                  title={"Address Line 1"}
                  value={customerAddress.building_name_number || "---"}
                />
                <DataPoint
                  title={"Address Line 2"}
                  value={customerAddress.street || "---"}
                />
                <DataPoint
                  title={"Town / City"}
                  value={customerAddress.town_city || "---"}
                />
                <DataPoint
                  title={"County"}
                  value={customerAddress.county || "---"}
                />
                <DataPoint
                  title={"Postcode"}
                  value={customerAddress.postcode || "---"}
                />
                <DataPoint
                  title={"Payment Method"}
                  value={(() => {
                    if (customer.dd_mandate) {
                      return "DIRECT DEBIT";
                    } else if (customer.pbom === "dd") {
                      return "MANDATE";
                    } else {
                      return (customer.pbom || "N/A").toUpperCase();
                    }
                  })()}
                />
                <DataPoint
                  title={"Soft Facts"}
                  value={customer.soft_facts || "---"}
                />
                <div></div>
                <DataPoint
                  title={"Handover Agent"}
                  value={deal.handover_agent_name || "---"}
                />
                <DataPoint
                  title={"Closing Agent"}
                  value={deal.closing_agent_name || "---"}
                />
              </ModalInputGrid>
            </ModalSection>
            {lineRentalOrder && lineRentalOrder.line_rental_order && (
              <ModalSection title={"Landline Order"}>
                <ModalInputGrid>
                  <DataPoint
                    title={"Line Rental Package"}
                    value={lineRentalOrder.line_rental_price}
                  />
                  <DataPoint
                    title={"Line Rental Price"}
                    value={convertToCurrency(
                      lineRentalOrder.line_rental_order.package_price || 0
                    )}
                  />
                  {lineRentalOrder.line_rental_order
                    .international_call_package_price && (
                    <DataPoint
                      title={"Call Package Price"}
                      value={convertToCurrency(
                        lineRentalOrder.line_rental_order
                          .international_call_package_price || 0
                      )}
                    />
                  )}
                </ModalInputGrid>
              </ModalSection>
            )}
            {bbOrder && bbOrder.broadband_order && (
              <ModalSection title={"Broadband Order"}>
                <ModalInputGrid>
                  <DataPoint
                    title={"Broadband Package"}
                    value={bbOrder.broadband_price}
                  />
                  <DataPoint
                    title={"Broadband Price"}
                    value={convertToCurrency(
                      bbOrder.broadband_order.package_price || 0
                    )}
                  />
                  <DataPoint
                    title={"Min. Down. Speed"}
                    value={bbOrder.broadband_speeds.min_down_speed}
                  />
                  <DataPoint
                    title={"Max Down. Speed"}
                    value={bbOrder.broadband_speeds.max_down_speed}
                  />
                  <DataPoint
                    title={"Min. Up. Speed"}
                    value={bbOrder.broadband_speeds.min_up_speed}
                  />
                  <DataPoint
                    title={"Max Up. Speed"}
                    value={bbOrder.broadband_speeds.max_up_speed}
                  />
                  <DataPoint
                    title={"Min. Guaranteed Down. Speed"}
                    value={bbOrder.broadband_speeds.min_guaranteed_down_speed}
                  />
                </ModalInputGrid>
              </ModalSection>
            )}
            <ModalSection title={"TPS Check"}>
              <ModalRow>
                <span>
                  Check whether the customer is registered on the TPS register.
                </span>
              </ModalRow>
              {latestTPSCheck && (
                <ModalRow>
                  <span>
                    Latest Check:{" "}
                    {new Date(latestTPSCheck).toLocaleDateString()}
                  </span>
                </ModalRow>
              )}
              {!latestTPSCheck && (
                <ModalRow>
                  <LoadingWheel />
                  <span>No previous checks found.</span>
                </ModalRow>
              )}
              <ModalRow>
                <PrimaryActionButton
                  id={"tps-check-btn"}
                  title={"TPS Check"}
                  onClick={async () => {
                    if (!customer.cli_list || customer.cli_list.length === 0) {
                      return;
                    }
                    showLoadingToast();
                    const [tps, err] = await tpsCheckNumber(
                      customer.cli_list[0]
                    );
                    if (err) {
                      showErrorToast(
                        "Failed to TPS check number",
                        "There was an error that occurred whilst trying to check this number on the TPS register. Please try again later."
                      );
                      return;
                    } else {
                      if (tps) {
                        showWarningToast(
                          "Number is TPS registered",
                          "This number is registered on the TPS register, please speak to management before proceeding."
                        );
                      } else {
                        showSuccessToast("Number is not on the TPS register");
                      }
                      setIsTPS(tps);
                    }
                    const [rec, tpsErr] = await getRecordDataFromCLI(
                      customer.cli_list[0]
                    );
                    if (tpsErr) {
                      showErrorToast(
                        "Failed to TPS check number",
                        "There was an error that occurred whilst trying to check this number on the TPS register. Please try again later."
                      );
                    } else {
                      setLatestTPSCheck(rec.last_tps_cleansed_at);
                    }
                    removeLoadingToast();
                    return;
                  }}
                />
              </ModalRow>
              {isTPS && (
                <ModalRow>
                  <span>Is Registered: {isTPS}</span>
                </ModalRow>
              )}
            </ModalSection>
            <ModalSection title={"Verbal Agreement"}>
              <VerbalAgreementSection />
            </ModalSection>
            <ModalSection title={"Welcome Calls"}>
              <WelcomeCallsSection
                change={() => {
                  setChangesMade(true);
                }}
                calls={welcomeCalls}
              />
            </ModalSection>
            <ModalSection title={"Provisioning Jobs"}>
              {lineProvisions.length > 0 && (
                <ModalRow>
                  <Table
                    headers={[
                      "BB Live Date",
                      "Port Live Date",
                      "Ref.",
                      "BB User.",
                      "BB Pass.",
                      "Trunk Pass.",
                      "Portal",
                      "Status"
                    ]}
                    rowData={lineProvisions.map(lp => {
                      return {
                        bb_live_date: new Date(
                          lp.bb_live_date
                        ).toLocaleDateString(),
                        porting_live_date: new Date(
                          lp.porting_live_date
                        ).toLocaleDateString(),
                        ref: lp.network_reference,
                        bb_user: lp.bb_username,
                        bb_pass: lp.bb_password,
                        trunk_pass: lp.trunk_password,
                        portal: lp.portal,
                        status: lp.status.toUpperCase()
                      };
                    })}
                    rowClick={async lp => {
                      showLoadingToast("Checking provisioning status...");
                      const [status, err] = await getProvisioningStatusForDeal(
                        dealId
                      );
                      if (err) {
                        removeLoadingToast();
                        showErrorToast("Failed to check provisioning status");
                        return;
                      } else if (status !== lp.status) {
                        showSuccessToast(
                          "Provisioning status has changed from last check"
                        );
                        const [lps, err] = await getProvisioningJobsForDeal(
                          dealId
                        );
                        removeLoadingToast();
                        if (err) {
                          showErrorToast("Failed to check provisioning status");
                          return;
                        }
                        setLineProvisions(lps || []);
                      } else {
                        removeLoadingToast();
                      }
                    }}
                  />
                </ModalRow>
              )}
              {lineProvisions.length === 0 && (
                <>
                  <ModalInputGrid>
                    <div className="auto-provision-wrapper">
                      <DropdownInput
                        id={"prov-portal-dropdown"}
                        title={"Provisioning Portal"}
                        placeholder={"Select a portal..."}
                        options={[
                          { title: "Eclipse", value: "eclipse" },
                          { title: "Giacom", value: "giacom" }
                        ]}
                        onChange={async e => {
                          setNewProvisionData({
                            ...newProvisionData,
                            portal:
                              e.target.value !== "Select a portal..."
                                ? e.target.value
                                : null
                          });
                        }}
                      />
                      {/* <PrimaryActionButton
                    id={"auto-provision-btn"}
                    title={"Auto Prov."}
                    onClick={async () => {
                      // showLoadingToast();
                      // const [lp, err] = await singleAutoProvision(
                      //   dealId,
                      //   provisioningPortal,
                      //   accountID
                      // );
                      // removeLoadingToast();
                      // if (err !== null) {
                      //   showErrorToast(
                      //     "Failed to auto provision line",
                      //     err,
                      //     true
                      //   );
                      //   return;
                      // }
                      // showSuccessToast("Successfully provisioned line.");
                      // setNetworkReference(lp.network_reference);
                      // const [lps, _] = await getLineProvisionsForDeal(dealId);
                      // setLineProvisions(lps || []);
                      // return;
                    }}
                  /> */}
                    </div>
                    <SingleLineInput
                      id={"broadband-username-input"}
                      title={"BB Username"}
                      placeholder={"Username"}
                      value={newProvisionData.bb_username}
                      onChange={e => {
                        setNewProvisionData({
                          ...newProvisionData,
                          bb_username: e.target.value
                        });
                      }}
                    />
                    <SingleLineInput
                      id={"broadband-password-input"}
                      title={"BB Password"}
                      placeholder={"Password"}
                      value={newProvisionData.bb_password}
                      onChange={e => {
                        setNewProvisionData({
                          ...newProvisionData,
                          bb_password: e.target.value
                        });
                      }}
                    />
                    <SingleLineInput
                      id={"trunk-password-input"}
                      title={"SIP Trunk Password"}
                      placeholder={"SIP Credential Password"}
                      value={newProvisionData.trunk_password}
                      onChange={e => {
                        setNewProvisionData({
                          ...newProvisionData,
                          trunk_password: e.target.value
                        });
                      }}
                    />
                    <SingleLineInput
                      id={"porting-live-date-input"}
                      title={"Porting Live Date"}
                      type={"date"}
                      value={newProvisionData.porting_live_date}
                      onChange={e => {
                        setNewProvisionData({
                          ...newProvisionData,
                          porting_live_date: new Date(e.target.value)
                        });
                      }}
                    />
                    <SingleLineInput
                      id={"broadband-live-date-input"}
                      title={"Broadband Live Date"}
                      type={"date"}
                      value={newProvisionData.bb_live_date}
                      onChange={e => {
                        setNewProvisionData({
                          ...newProvisionData,
                          bb_live_date: new Date(e.target.value)
                        });
                      }}
                    />
                    <SingleLineInput
                      id={"network-reference-input"}
                      title={"Network Reference (OR/BBEU)"}
                      placeholder={"Network Reference"}
                      value={newProvisionData.network_reference}
                      onChange={e => {
                        setNewProvisionData({
                          ...newProvisionData,
                          network_reference: e.target.value
                        });
                      }}
                    />
                    <MultiLineInput
                      id={"prov-notes-input"}
                      title={"Notes"}
                      placeholder={
                        "Enter any notes for provisioning in here..."
                      }
                      value={newProvisionData.notes}
                      onChange={e => {
                        setNewProvisionData({
                          ...newProvisionData,
                          notes: e.target.value
                        });
                      }}
                    />
                  </ModalInputGrid>
                  <ModalRow>
                    <PrimaryActionButton
                      id={"save-prov-btn"}
                      title={"Save"}
                      onClick={async () => {
                        if (!newProvisionData.portal) {
                          showWarningToast("Missing provisioning portal");
                          return;
                        }

                        if (!newProvisionData.network_reference) {
                          showWarningToast("Missing network reference");
                          return;
                        }

                        if (!newProvisionData.porting_live_date) {
                          showWarningToast("Missing port live date");
                          return;
                        }

                        if (!newProvisionData.bb_live_date) {
                          showWarningToast("Missing broadband live date");
                          return;
                        }

                        if (!newProvisionData.bb_username) {
                          showWarningToast("Missing broadband username");
                          return;
                        }

                        if (!newProvisionData.bb_password) {
                          showWarningToast("Missing broadband password");
                          return;
                        }

                        if (!newProvisionData.trunk_password) {
                          showWarningToast("Missing trunk password");
                          return;
                        }

                        showLoadingToast();

                        const [lps, lpErr] = await getProvisioningJobsForDeal(
                          dealId
                        );
                        if (lpErr) {
                          removeLoadingToast();
                          showErrorToast(
                            "Failed to check for existing provisions"
                          );
                          return;
                        }

                        var orderInPlace = false;
                        lps.forEach(lp => {
                          console.log(lp.status);
                          if (lp.status === "pending") {
                            orderInPlace = true;
                          }
                        });

                        if (orderInPlace) {
                          removeLoadingToast();
                          showWarningToast(
                            "There is an active order in place."
                          );
                          return;
                        }

                        const err = await saveLineProvision(
                          dealId,
                          newProvisionData
                        );
                        removeLoadingToast();

                        if (err) {
                          showErrorToast("Failed to save line provision");
                          return;
                        }

                        setNewProvisionData({});

                        const [provisions, pErr] =
                          await getProvisioningJobsForDeal(dealId);
                        setLineProvisions(provisions);

                        showSuccessToast("Successfully saved line provision");
                      }}
                    />
                  </ModalRow>
                </>
              )}
            </ModalSection>
            <ModalSection title={"Winback"}>
              {existingWinbacks.length > 0 &&
                existingWinbacks.map(wb => {
                  return (
                    <ModalInputGrid>
                      <div className="existing-winback-wrapper">
                        <span>
                          Wonback by {wb.agent_name} on{" "}
                          {new Date(wb.date).toLocaleDateString()}
                        </span>
                        {wb.notes && <span>{wb.notes}</span>}
                        <DestructiveActionButton
                          id={"delete-winback-button"}
                          title={"Delete Winback"}
                          onClick={async () => {
                            showLoadingToast();
                            const err = await deleteWinback(wb.id);
                            if (err) {
                              removeLoadingToast();
                              showErrorToast("Failed to delete winback");
                              return;
                            }
                            const [wbs, _] = await getWinbacksForDeal(dealId);
                            setExistingWinbacks(wbs || []);
                            removeLoadingToast();

                            showSuccessToast("Successfully deleted winback");
                          }}
                        />
                      </div>
                      <div />
                    </ModalInputGrid>
                  );
                })}

              <ModalInputGrid>
                <SingleLineInput
                  id={"winback-date-input"}
                  title={"Date"}
                  type={"date"}
                  defaultValue={new Date().toISOString().slice(0, 10)}
                  onChange={e => {
                    setWinbackDate(e.target.value);
                  }}
                />
                <DropdownInput
                  id={"winback-agent-dropdown"}
                  title={"Agent"}
                  placeholder={"Select an agent..."}
                  options={winbackAgents.map(agent => {
                    return {
                      title: `${agent.first_name} ${agent.last_name}`,
                      value: agent.id
                    };
                  })}
                  onChange={e => {
                    setWinbackAgent(
                      e.target.value !== "Select an agent..."
                        ? e.target.value
                        : null
                    );
                  }}
                />
                <MultiLineInput
                  id={"winback-notes-input"}
                  title={"Notes"}
                  placeholder={"Any additional notes..."}
                  onChange={e => {
                    setWinbackNotes(e.target.value);
                  }}
                />
              </ModalInputGrid>
              <ModalRow>
                <PrimaryActionButton
                  id={"winback-btn"}
                  title={"Winback"}
                  onClick={async () => {
                    if (!winbackDate) {
                      showWarningToast("Missing winback date");
                      return;
                    }

                    if (!winbackAgent) {
                      showWarningToast("Missing winback agent");
                      return;
                    }

                    showLoadingToast();

                    const err = await saveWinback(
                      dealId,
                      new Date(winbackDate).toISOString(),
                      parseInt(winbackAgent),
                      winbackNotes
                    );
                    removeLoadingToast();
                    if (err) {
                      showErrorToast("Failed to save winback.");
                      return;
                    }
                    const [wbs, _] = await getWinbacksForDeal(dealId);
                    setExistingWinbacks(wbs || []);
                    setWinbackDate(new Date());
                    setWinbackAgent(null);
                    setWinbackNotes(null);
                    document.getElementById("winback-notes-input").value = "";
                    document.getElementById("winback-agent-dropdown").value =
                      null;
                    document.getElementById("winback-date-input").value =
                      new Date().toISOString().slice(0, 10);

                    showSuccessToast(
                      "Successfully saved winback",
                      "This winback has been recorded for the selected agent."
                    );
                  }}
                />
              </ModalRow>
            </ModalSection>
            <ModalSection title={"Cancel"}>
              <ModalInputGrid>
                <DropdownInput
                  id={"cancel-reason-input"}
                  title={"Reason"}
                  placeholder={"Select a reason..."}
                  onChange={e => {
                    setCancellationReason(
                      e.target.value !== "Select a reason..."
                        ? e.target.value
                        : null
                    );
                  }}
                  options={[
                    { title: "Additional Services", value: "add_services" },
                    { title: "Answer Centre Request", value: "ans_centre_req" },
                    { title: "BT Counter Offer", value: "bt_counter_offer" },
                    { title: "BT Halo", value: "bt_halo" },
                    { title: "BT Mobile", value: "bt_mobile" },
                    { title: "BT Said we are a scam", value: "bt_scam" },
                    { title: "BT Sport", value: "bt_sport" },
                    { title: "BT TV", value: "bt_tv" },
                    { title: "Cancellation Fee", value: "cancel_fee" },
                    {
                      title: "Confusion/Vulnerable",
                      value: "confusion_vulnerable"
                    },
                    { title: "Email Request", value: "email_req" },
                    {
                      title: "Family/Friends said we are a scam",
                      value: "family_friend_scam"
                    },
                    {
                      title: "Family/Friends Advice",
                      value: "family_friend_advice"
                    },
                    { title: "Google Reviews", value: "google_reviews" },
                    {
                      title: "Mislead On Openreach Relationship",
                      value: "mislead_openreach"
                    },
                    {
                      title: "Mislead - Sold on Paperwork",
                      value: "mislead_paperwork"
                    },
                    {
                      title: "Mislead - Thought we were BT",
                      value: "mislead_bt"
                    },
                    {
                      title: "Mislead - Thought BT were ceasing",
                      value: "mislead_bt_cease"
                    },
                    { title: "Moving house soon", value: "moving_house" },
                    {
                      title: "Never heard of us / not online",
                      value: "never_heard"
                    },
                    { title: "Non BT Line", value: "non_bt_line" },
                    { title: "Non Changer", value: "non_changer" },
                    { title: "Personal Problems", value: "personal_problems" },
                    { title: "Power of Attorney", value: "poa" },
                    {
                      title: "Rejected Call / Didn't wish to discuss",
                      value: "rejected_call"
                    },
                    { title: "Trustpilot Reviews", value: "trustpilot" },
                    {
                      title: "Unhappy with our service or process",
                      value: "unhappy_service"
                    },
                    { title: "Welcome Pack", value: "welcome_pack" },
                    { title: "Who Called Me Reviews", value: "who_called_me" }
                  ]}
                />
                <div />
                <MultiLineInput
                  id={"cancel-notes-input"}
                  title={"Notes"}
                  placeholder={
                    "Any additional notes for why the customer is cancelling..."
                  }
                  onChange={e => {
                    setCancellationNotes(e.target.value);
                  }}
                />
              </ModalInputGrid>
              <ModalRow>
                <PrimaryActionButton
                  id={"raise-request-btn"}
                  title={"Raise Request"}
                  onClick={async () => {
                    if (!cancellationReason) {
                      showWarningToast("Missing cancellation reason");
                      return;
                    }
                    if (!cancellationNotes) {
                      showWarningToast("Missing cancellation notes");
                      return;
                    }
                    showLoadingToast();
                    const err = await saveCustomerCancellationRequest(
                      customer.id,
                      cancellationReason,
                      cancellationNotes
                    );
                    removeLoadingToast();
                    if (err) {
                      showErrorToast("Failed to raise cancellation request");
                      return;
                    }
                    closeModal();
                    showSuccessToast(
                      "Successfully raised cancellation request",
                      "This request can now be found in the retentions section."
                    );
                  }}
                />
                <DestructiveActionButton
                  id={"cancel-btn"}
                  title={"Cancel"}
                  onClick={async () => {
                    showConfirmation(
                      "Cancel",
                      "Are you sure you wish to cancel this customer?",
                      async () => {
                        const err = await saveCancellation(
                          dealId,
                          cancellationReason,
                          cancellationNotes
                        );
                        if (err) {
                          showErrorToast("Failed to cancel deal");
                          return;
                        }
                        closeModal();
                        showSuccessToast(
                          "Successfully cancelled deal",
                          "This cancellation can now be found in the cancellations section."
                        );
                      }
                    );
                  }}
                />
              </ModalRow>
            </ModalSection>
            {/* <Dealsheet
          data={data}
          selectedCompany={selectedCompany}
          setChangesMade={setChangesMade}
        /> */}
          </ModalContent>
        )}
        {/* <ModalFooter>
        <PrimaryActionButton id={"save-changes-btn"} title={"Save Changes"} />
      </ModalFooter> */}
      </Modal>
    </ProvisioningCustomerContext.Provider>
  );
};

const Dealsheet = ({ data, selectedCompany, setChangesMade }) => {
  const [lineRentalPrices, setLineRentalPrices] = useState([]);
  const [tariffs, setTariffs] = useState([]);
  const [broadbandPackages, setBroadbandPackages] = useState([]);
  const [callBroadbandBundles, setCallBroadbandBundles] = useState([]);
  const [nonGeographicPackages, setNonGeographicPackages] = useState([]);
  const [internationalCallPackages, setInternationalCallPackages] = useState(
    []
  );
  const [mobilePackages, setMobilePackages] = useState([]);
  const [selectServices, setSelectServices] = useState([]);
  const [callBoltOns, setCallBoltOns] = useState([]);
  const [packageAddOns, setPackageAddOns] = useState([]);

  const [handoverAgents, setHandoverAgents] = useState([]);
  const [closingAgents, setClosingAgents] = useState([]);

  const [dealsheetData, setDealsheetData] = useState({});

  const [validBankDetailsProvided, setValidBankDetailsProvided] =
    useState(null);

  useEffect(() => {
    setValidBankDetailsProvided(data.bank_branch);
    removeNonDBFields();
    (async () => {
      const [items, err] = await getDealsheetItems();
      if (err) {
        showErrorToast(
          "Failed to get dealsheet packages",
          "There was an error whilst trying to retrieve the dealsheet packages from the database. Please try again later."
        );
        return;
      } else {
        setLineRentalPrices(items.line_rental_prices);
        setTariffs(items.tariffs);
        setBroadbandPackages(items.broadband_packages);
        setCallBroadbandBundles(items.call_broadband_bundles);
        setNonGeographicPackages(items.non_geo_packages);
        setInternationalCallPackages(items.international_call_packages);
        setMobilePackages(items.mobile_packages);
        setSelectServices(items.select_services);
        setPackageAddOns(items.package_add_ons);
        setCallBoltOns(items.call_bolt_ons);
      }
    })();
  }, []);

  // Get handover agents
  useEffect(() => {
    (async () => {
      const [agents, err] = await getHandoverAgents();
      if (err) {
        showErrorToast(
          "Failed to get handover agents",
          "There was an error whilst trying to retrieve the handover agents from the database. Please try again later."
        );
        return;
      } else {
        setHandoverAgents(agents || []);
      }
    })();
  }, []);

  // Get closing agents
  useEffect(() => {
    (async () => {
      const [agents, err] = await getCloserAgents();
      if (err) {
        showErrorToast(
          "Failed to get closing agents",
          "There was an error whilst trying to retrieve the closing agents from the database. Please try again later."
        );
        return;
      } else {
        setClosingAgents(agents || []);
      }
    })();
  }, []);

  const updateDealsheetFieldValue = (id, value) => {
    setChangesMade(true);
    var data = dealsheetData;
    if (value === "") {
      data[id] = null;
    } else {
      data[id] = value;
    }
    setDealsheetData(data);
  };

  const requiredFields = [
    "customer_name",
    "full_name",
    "address1",
    "address_city",
    "address_postcode",
    "line_rental",
    "tariff",
    "billing_type",
    "phone_provider",
    "average_bill_amount",
    "contract_length_months",
    "invoice_name",
    "handover_agent_id",
    "closing_agent_id"
  ];

  const [requiredFieldsCount, setRequiredFieldsCount] = useState(0);

  const checkRequiredFields = () => {
    var ret = true;
    Object.keys(dealsheetData).forEach(id => {
      if (
        (dealsheetData[id] === null || dealsheetData[id] === "") &&
        requiredFields.includes(id)
      ) {
        ret = false;
      }
    });
    return ret;
  };

  const checkDealData = () => {
    if (
      dealsheetData.billing_type === "email" &&
      (!dealsheetData.email_address || dealsheetData.email_address === "")
    ) {
      showWarningToast(
        "No email provided",
        "You have marked the billing type as email but no email address has been provided."
      );
      return false;
    } else if (
      (dealsheetData.sort_code || dealsheetData.account_number) &&
      !validBankDetailsProvided
    ) {
      showWarningToast(
        "Require IBAN check",
        "You have provided the customer's bank details but have not completed a successful IBAN check."
      );
      return false;
    } else {
      return true;
    }
  };

  const removeNonDBFields = () => {
    var d = dealsheetData;
    delete d["handover_ids"];
    delete d["handover_agent_name"];
    delete d["closing_agent_name"];
    delete d["line_rental_name"];
    delete d["tariff_name"];
    delete d["broadband_package_name"];
    delete d["non_geo_package_name"];
    delete d["mobile_package_name"];
    delete d["international_call_package_name"];
    delete d["premium_rate_package_name"];
    delete d["call_broadband_bundle_name"];
    delete d["company_name"];
    delete d["account_id"];
    delete d["company_id"];
    delete d["created_at"];
    delete d["dob"];
    delete d["landline"];
    delete d["line_status"];
    delete d["openreach_reference"];
    delete d["recko_notes"];
    delete d["recko_status"];
    delete d["status"];
    delete d["status_notes"];
    delete d["welcome_call_1_agent_id"];
    delete d["welcome_call_2_agent_id"];
    delete d["welcome_call_1_notes"];
    delete d["welcome_call_2_notes"];
    delete d["welcome_call_1_rating"];
    delete d["welcome_call_2_rating"];
    setDealsheetData(d);
  };

  return (
    <ModalSection title={"Dealsheet"}>
      <ModalRow>
        <span>Customer Details</span>
      </ModalRow>
      <ModalInputGrid>
        <SingleLineInput
          id={"prov-ds-customer-name-input"}
          required={true}
          title={"Customer Name"}
          placeholder={"John Doe"}
          defaultValue={data.customer_name}
          onChange={async e => {
            updateDealsheetFieldValue("customer_name", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-full-name-input"}
          required={true}
          title={"Full Name"}
          placeholder={"Mr John Doe"}
          defaultValue={data.full_name}
          onChange={async e => {
            updateDealsheetFieldValue("full_name", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-add-account-holder-input"}
          title={"Additional Account Holder"}
          placeholder={"Jane Doe"}
          defaultValue={data.add_account_holder}
          onChange={async e => {
            updateDealsheetFieldValue("add_account_holder", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-poa-details-input"}
          title={"POA Details"}
          placeholder={"James Doe"}
          defaultValue={data.poa_details}
          onChange={async e => {
            updateDealsheetFieldValue("poa_details", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-mobile-number-input"}
          title={"Mobile Number"}
          placeholder={"07123456789"}
          defaultValue={data.mobile_number}
          onChange={async e => {
            updateDealsheetFieldValue("mobile_number", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-email-address-input"}
          title={"Email Address"}
          placeholder={"john.doe@mail.com"}
          defaultValue={data.email_address}
          onChange={async e => {
            updateDealsheetFieldValue("email_address", e.target.value);
          }}
        />
      </ModalInputGrid>

      <ModalRow>
        <span>Billing Address</span>
      </ModalRow>

      <ModalInputGrid>
        <SingleLineInput
          id={"prov-ds-address-1-input"}
          required={true}
          title={"Address Line 1"}
          placeholder={"4a Castle View Mews"}
          defaultValue={data.address1}
          onChange={async e => {
            updateDealsheetFieldValue("address1", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-address-2-input"}
          title={"Address Line 2"}
          placeholder={"Castle Hill"}
          defaultValue={data.address2}
          onChange={async e => {
            updateDealsheetFieldValue("address2", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-address-city-input"}
          required={true}
          title={"Town/City"}
          placeholder={"Rochester"}
          defaultValue={data.address_city}
          onChange={async e => {
            updateDealsheetFieldValue("address_city", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-address-county-input"}
          title={"County"}
          placeholder={"Kent"}
          defaultValue={data.address_county}
          onChange={async e => {
            updateDealsheetFieldValue("address_county", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-address-postcode-input"}
          required={true}
          title={"Postcode"}
          placeholder={"ME1 1LA"}
          defaultValue={data.address_postcode}
          onChange={async e => {
            updateDealsheetFieldValue("address_postcode", e.target.value);
          }}
        />
      </ModalInputGrid>

      <ModalRow>
        <span>Package Information</span>
      </ModalRow>

      <ModalInputGrid>
        <DropdownInput
          id={"prov-ds-tariff-dropdown"}
          required={true}
          title={"Tariff"}
          placeholder={"Select a tariff..."}
          options={tariffs.map(tariff => {
            return {
              title: `${tariff.name} - ${renderPrice(tariff.price)}`,
              value: tariff.id
            };
          })}
          onChange={async e => {
            updateDealsheetFieldValue(
              "tariff",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
          defaultValue={data.tariff}
          override={true}
          defaultOverride={data.tariff_price}
          overrideChange={async e => {
            updateDealsheetFieldValue(
              "tariff_price",
              !isNaN(parseFloat(e.target.value))
                ? parseFloat(e.target.value)
                : null
            );
          }}
        />

        <DropdownInput
          id={"prov-ds-line-rental-dropdown"}
          required={true}
          title={"Line Rental"}
          placeholder={"Select line rental..."}
          options={lineRentalPrices.map(lrp => {
            return {
              title: `${lrp.name.replaceAll(
                "%COMPANY_NAME%",
                selectedCompany ? selectedCompany.company_name : "Company Name"
              )} - ${renderPrice(lrp.price)}`,
              value: lrp.id
            };
          })}
          onChange={async e => {
            updateDealsheetFieldValue(
              "line_rental",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
          defaultValue={data.line_rental}
          override={true}
          defaultOverride={data.line_rental_price}
          overrideChange={async e => {
            console.log(parseFloat(e.target.value));
            updateDealsheetFieldValue(
              "line_rental_price",
              !isNaN(parseFloat(e.target.value))
                ? parseFloat(e.target.value)
                : null
            );
          }}
        />

        <DropdownInput
          id={"prov-ds-broadband-package-dropdown"}
          title={"Broadband Package"}
          placeholder={"Select broadband package..."}
          options={broadbandPackages.map(pkg => {
            return {
              title: `${pkg.name} - ${renderPrice(pkg.price)}`,
              value: pkg.id
            };
          })}
          onChange={async e => {
            updateDealsheetFieldValue(
              "broadband_package",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
          defaultValue={data.broadband_package}
          override={true}
          defaultOverride={data.broadband_package_price}
          overrideChange={async e => {
            updateDealsheetFieldValue(
              "broadband_package_price",
              !isNaN(parseFloat(e.target.value))
                ? parseFloat(e.target.value)
                : null
            );
          }}
        />

        <DropdownInput
          id={"prov-ds-call-broadband-bundles-dropdown"}
          title={"Call & Broadband Bundle"}
          placeholder={"Select bundle..."}
          options={callBroadbandBundles.map(bundle => {
            return {
              title: `${bundle.name} - ${renderPrice(bundle.price)}`,
              value: bundle.id
            };
          })}
          onChange={async e => {
            updateDealsheetFieldValue(
              "call_broadband_bundle",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
          defaultValue={data.call_broadband_bundle}
          override={true}
          defaultOverride={data.call_broadband_bundle_price}
          overrideChange={async e => {
            updateDealsheetFieldValue(
              "call_broadband_bundle_price",
              !isNaN(parseFloat(e.target.value))
                ? parseFloat(e.target.value)
                : null
            );
          }}
        />

        <DropdownInput
          id={"prov-ds-mobile-package-dropdown"}
          title={"Mobile Package"}
          placeholder={"Select mobile package..."}
          options={mobilePackages.map(pkg => {
            return {
              title: `${pkg.name} - ${renderPrice(pkg.price)}`,
              value: pkg.id
            };
          })}
          onChange={async e => {
            updateDealsheetFieldValue(
              "mobile_package",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
          defaultValue={data.mobile_package}
          override={true}
          defaultOverride={data.mobile_package_price}
          overrideChange={async e => {
            updateDealsheetFieldValue(
              "mobile_package_price",
              !isNaN(parseFloat(e.target.value))
                ? parseFloat(e.target.value)
                : null
            );
          }}
        />

        <DropdownInput
          id={"prov-ds-non-geo-package-dropdown"}
          title={"Non Geo Package"}
          placeholder={"Select non geo package..."}
          options={nonGeographicPackages.map(pkg => {
            return {
              title: `${pkg.name} - ${renderPrice(pkg.price)}`,
              value: pkg.id
            };
          })}
          onChange={async e => {
            updateDealsheetFieldValue(
              "non_geo_package",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
          defaultValue={data.non_geo_package}
          override={true}
          defaultOverride={data.non_geo_package_price}
          overrideChange={async e => {
            updateDealsheetFieldValue(
              "non_geo_package_price",
              !isNaN(parseFloat(e.target.value))
                ? parseFloat(e.target.value)
                : null
            );
          }}
        />

        <DropdownInput
          id={"prov-ds-international-call-package-dropdown"}
          title={"International Call Package"}
          placeholder={"Select international call package..."}
          options={internationalCallPackages.map(pkg => {
            return {
              title: `${pkg.name} - ${renderPrice(pkg.price)}`,
              value: pkg.id
            };
          })}
          onChange={async e => {
            updateDealsheetFieldValue(
              "international_call_package",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
          defaultValue={data.international_call_package}
          override={true}
          defaultOverride={data.international_call_package_price}
          overrideChange={async e => {
            updateDealsheetFieldValue(
              "international_call_package_price",
              !isNaN(parseFloat(e.target.value))
                ? parseFloat(e.target.value)
                : null
            );
          }}
        />

        <SingleLineInput
          id={"prov-ds-international-call-location-input"}
          title={"International Call Location"}
          placeholder={"Australia, Europe, etc."}
          defaultValue={data.international_call_location}
          onChange={async e => {
            updateDealsheetFieldValue(
              "international_call_location",
              e.target.value
            );
          }}
        />

        <CheckboxInputList
          id={"select-services-list"}
          title={"Select Services"}
          options={selectServices.map(service => {
            return {
              name: service.name,
              price: renderPrice(service.price),
              value: service.id
            };
          })}
          defaultValue={data.select_services}
          onChange={async e => {
            var arr = dealsheetData.select_services || [];
            if (e.target.checked) {
              arr.push(parseInt(e.target.value));
            } else {
              arr = arr.filter(v => v !== parseInt(e.target.value));
            }
            updateDealsheetFieldValue("select_services", arr);
          }}
        />

        <CheckboxInputList
          id={"package-add-ons-list"}
          title={"Package Addons"}
          options={packageAddOns.map(addon => {
            return {
              name: addon.name,
              price: renderPrice(addon.price),
              value: addon.id
            };
          })}
          defaultValue={data.package_add_ons}
          onChange={async e => {
            var arr = dealsheetData.package_add_ons || [];
            if (e.target.checked) {
              arr.push(parseInt(e.target.value));
            } else {
              arr = arr.filter(v => v !== parseInt(e.target.value));
            }
            updateDealsheetFieldValue("package_add_ons", arr);
          }}
        />

        <CheckboxInputList
          id={"call-bolt-ons-list"}
          title={"Call Bolt Ons"}
          options={callBoltOns.map(bolton => {
            return {
              name: bolton.name,
              price: renderPrice(bolton.price),
              value: bolton.id
            };
          })}
          defaultValue={data.call_bolt_ons}
          onChange={async e => {
            var arr = dealsheetData.call_bolt_ons || [];
            if (e.target.checked) {
              arr.push(parseInt(e.target.value));
            } else {
              arr = arr.filter(v => v !== parseInt(e.target.value));
            }
            updateDealsheetFieldValue("call_bolt_ons", arr);
          }}
        />

        <div />

        <SingleLineInput
          id={"prov-ds-phone-provider-input"}
          required={true}
          title={"Phone Provider"}
          placeholder={"BT, EE, Vodafone etc."}
          defaultValue={data.phone_provider}
          onChange={async e => {
            updateDealsheetFieldValue("phone_provider", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-broadband-provider-input"}
          title={"Broadband Provider"}
          placeholder={"BT, EE, Vodafone etc."}
          defaultValue={data.broadband_provider}
          onChange={async e => {
            updateDealsheetFieldValue("broadband_provider", e.target.value);
          }}
        />

        <DropdownInput
          id={"prov-ds-billing-type-dropdown"}
          required={true}
          title={"Billing Type"}
          placeholder={"Select billing type..."}
          options={[
            { title: "Paper", value: "paper" },
            { title: "Email", value: "email" }
          ]}
          defaultValue={data.billing_type}
          onChange={async e => {
            updateDealsheetFieldValue("billing_type", e.target.value);
          }}
        />

        <SingleLineInput
          id={"prov-ds-average-bill-amount-input"}
          required={true}
          title={"Average Bill Amount"}
          placeholder={"£40.00"}
          defaultValue={data.average_bill_amount}
          onChange={async e => {
            updateDealsheetFieldValue("average_bill_amount", e.target.value);
          }}
        />

        <DropdownInput
          id={"prov-ds-contract-length-dropdown"}
          required={true}
          title={"Contract Length"}
          placeholder={"Select contract length.."}
          options={[
            { title: "24 Months", value: 24 },
            { title: "36 Months", value: 36 }
          ]}
          defaultValue={data.contract_length_months}
          onChange={async e => {
            updateDealsheetFieldValue(
              "contract_length_months",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
        />

        <div />
      </ModalInputGrid>

      <ModalRow>
        <span>Billing Details</span>
      </ModalRow>

      <ModalInputGrid>
        <SingleLineInput
          id={"prov-ds-invoice-name-input"}
          required={true}
          title={"Invoice Name"}
          placeholder={"John Doe"}
          defaultValue={data.invoice_name}
          onChange={async e => {
            updateDealsheetFieldValue("invoice_name", e.target.value);
          }}
        />
        <div />
        <SingleLineInput
          id={"prov-ds-account-number-input"}
          title={"Account Number"}
          placeholder={"12345678"}
          defaultValue={data.account_number}
          onChange={async e => {
            updateDealsheetFieldValue("iban_check", false);
            setValidBankDetailsProvided(null);
            updateDealsheetFieldValue("account_number", e.target.value);
          }}
        />
        <SingleLineInput
          id={"prov-ds-sort-code-input"}
          title={"Sort Code"}
          placeholder={"123456"}
          defaultValue={data.sort_code}
          onChange={async e => {
            updateDealsheetFieldValue("iban_check", false);
            setValidBankDetailsProvided(null);
            updateDealsheetFieldValue("sort_code", e.target.value);
          }}
        />

        {!validBankDetailsProvided && (
          <PrimaryActionButton
            id={"iban-check-btn"}
            title={"Check Bank Details"}
            onClick={async () => {
              const bankDetails = await checkBankDetails(
                dealsheetData.sort_code,
                dealsheetData.account_number
              );
              if (bankDetails) {
                setValidBankDetailsProvided(bankDetails.bank_name);
                updateDealsheetFieldValue("iban_check", true);
                updateDealsheetFieldValue("bank_branch", bankDetails.bank_name);
              } else {
                showErrorToast(
                  "Bank Details Invalid",
                  "The bank details given by the customer are being returned as invalid. Please verify the details with them."
                );
                return;
              }
            }}
          />
        )}

        {validBankDetailsProvided && (
          <span className="bank-details-confirmation">
            The bank details provided by the customer have come back as valid
            details with {validBankDetailsProvided}
          </span>
        )}
      </ModalInputGrid>

      <ModalInputGrid>
        <MultiLineInput
          id={"soft-facts-input"}
          title={"Soft Facts"}
          placeholder={"Any additional notes..."}
          defaultValue={data.soft_facts}
          onChange={async e => {
            updateDealsheetFieldValue("soft_facts", e.target.value);
          }}
        />
      </ModalInputGrid>

      <ModalRow>
        <span>Agents</span>
      </ModalRow>
      <ModalInputGrid>
        <DropdownInput
          id={"prov-ds-handover-agent-dropdown"}
          required={true}
          title={"Handover Agent"}
          placeholder={"Select handover agent..."}
          options={handoverAgents.map(agent => {
            return {
              title: `${agent.first_name} ${agent.last_name}`,
              value: agent.id
            };
          })}
          defaultValue={data.handover_agent_id}
          onChange={async e => {
            updateDealsheetFieldValue(
              "handover_agent_id",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
        />

        <DropdownInput
          id={"prov-ds-closing-agent-dropdown"}
          required={true}
          title={"Closing Agent"}
          placeholder={"Select closing agent..."}
          options={closingAgents.map(agent => {
            return {
              title: `${agent.first_name} ${agent.last_name}`,
              value: agent.id
            };
          })}
          defaultValue={data.closing_agent_id}
          onChange={async e => {
            updateDealsheetFieldValue(
              "closing_agent_id",
              !isNaN(parseInt(e.target.value)) ? parseInt(e.target.value) : null
            );
          }}
        />
      </ModalInputGrid>
      <ModalRow>
        {Object.keys(dealsheetData).length > 0 && (
          <PrimaryActionButton
            id={"save-dealsheet-btn"}
            title={"Save Dealsheet"}
            onClick={async () => {
              const ok = checkDealData();
              const reqs = checkRequiredFields();
              if (!reqs) {
                showWarningToast("Missing required values");
                return;
              }
              if (ok) {
                showLoadingToast();
                console.log(dealsheetData);
                const err = await updateDeal(data.id, dealsheetData);
                removeLoadingToast();
                if (err) {
                  showErrorToast(
                    "Failed to update deal",
                    "There was an error that occurred whilst trying to update this deal's data. Please try again later."
                  );
                  return;
                } else {
                  showSuccessToast(
                    "Successfully updated deal",
                    "This deal has been successfully updated with the latest data."
                  );
                }
              }
            }}
          />
        )}
      </ModalRow>
    </ModalSection>
  );
};
