export function capitalize(str) {
  if (Array.isArray(str)) {
    return str.map(s => capitalize(s.toLowerCase())).join(" ");
  }

  return str.charAt(0).toUpperCase() + str.slice(1);
}

export function sortObject(obj) {
  const orderd = {};

  Object.keys(obj)
    .sort()
    .forEach(k => {
      orderd[k] = obj[k];
    });

  return orderd;
}

var SI_SYMBOL = ["", "k", "M", "G", "T", "P", "E"];

export function abbreviateNumber(number) {
  // what tier? (determines SI symbol)
  var tier = (Math.log10(Math.abs(number)) / 3) | 0;

  // if zero, we don't need a suffix
  if (tier === 0) return number;

  // get suffix and determine scale
  var suffix = SI_SYMBOL[tier];
  var scale = Math.pow(10, tier * 3);

  // scale the number
  var scaled = number / scale;

  // format number and add suffix
  return scaled.toFixed(1) + suffix;
}

export function renderPrice(price) {
  if (price !== undefined && price !== null) {
    return price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    });
  } else {
    return "";
  }
}
