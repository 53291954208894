import React from "react";
import ScreenSection, {
  ScreenSectionRow
} from "../views/generic/section/ScreenSection";
import { SectionHeader } from "../views/generic/SectionHeader";
import AgentOfTheWeekCard from "../views/home/AgentOfTheWeekCard";
import BusinessNewsCard from "../views/home/BusinessNewsCard";
import Screen, { ScreenHeader } from "./Screen";

export default function Home() {
  return (
    <Screen>
      <ScreenHeader title="Roaring Success CRM" />
      <ScreenSection>
        <SectionHeader label="Business Announcements" />
        <ScreenSectionRow>
          <BusinessNewsCard
            header={"Team Bonding"}
            image={
              "https://dynamic-media-cdn.tripadvisor.com/media/photo-o/21/6a/69/02/american-pool.jpg?w=1200&h=-1&s=1"
            }
            title={"Monthly Team Bonding"}
            body={
              "Our upcoming team bonding exercise will be taking place this Friday at Boom Battle Bar, Lakeside."
            }
          />
        </ScreenSectionRow>
        <SectionHeader label="Awards" />
        <ScreenSectionRow>
          <AgentOfTheWeekCard
            team={"Sales"}
            agent={{
              image:
                "https://firebasestorage.googleapis.com/v0/b/transparent-telecom.appspot.com/o/user_profile_pics%2FWhatsApp%20Image%202023-01-24%20at%2017.16.08.jpeg?alt=media&token=215f7416-1eea-412b-aed0-e8ddabbc60ff",
              title: "Daniel Simmonds",
              body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            }}
          />
          <AgentOfTheWeekCard
            team={"Ops"}
            agent={{
              image:
                "https://firebasestorage.googleapis.com/v0/b/transparent-telecom.appspot.com/o/user_profile_pics%2FWhatsApp%20Image%202023-01-24%20at%2017.16.08.jpeg?alt=media&token=215f7416-1eea-412b-aed0-e8ddabbc60ff",
              title: "Daniel Simmonds",
              body: "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat."
            }}
          />
        </ScreenSectionRow>
      </ScreenSection>
    </Screen>
  );
}
