import React from "react";

// Styles
import "../../styles/generic/SuccessToast.css";

// Assets
import CheckmarkIcon from "../../assets/icons/checkmark.svg";
import CrossIcon from "../../assets/CrossIcon.svg";

export const SuccessToast = ({ title, description, onClose }) => {
  return (
    <div className="success-toast">
      <div className="timer-bar" />
      <div className="success-content-wrapper">
        <img className="success-icon" src={CheckmarkIcon} />
        <div className="success-details-wrapper">
          {(() => {
            if (title) {
              return <span className="success-title-lbl">{title}</span>;
            }
          })()}
          <span className="success-description-lbl">{description}</span>
        </div>
        <button className="close-button" onClick={onClose}>
          <img src={CrossIcon} />
        </button>
      </div>
    </div>
  );
};
