import React from "react";

import { createRoot } from "react-dom/client";
import { LoadingToast } from "../views/generic/LoadingToast";

export function showLoadingToast(
  title = "Loading...",
  description = "Please wait a moment, whilst we process your request."
) {
  const mainDivs = document.getElementsByClassName("loading-popup");
  if (mainDivs.length > 0) {
    return;
  }
  /* description is an optional parameter */
  const div = document.createElement("div");
  div.id = "loading-toast";
  div.className = "loading-popup";
  const appContent = document.getElementById("app-content-wrapper");
  if (appContent) {
    appContent.appendChild(div);
    const toast = <LoadingToast title={title} description={description} />;
    const root = createRoot(document.getElementById("loading-toast"));
    root.render(toast);
  }
}

export function removeLoadingToast() {
  const mainDiv = document.getElementById("loading-toast");
  if (mainDiv) {
    mainDiv.classList.add("removing");
    const toast = mainDiv.querySelectorAll(".loading-toast")[0];
    if (toast) {
      toast.classList.add("removing");
    }
    setTimeout(() => {
      mainDiv.remove();
    }, 250);
  }
}
