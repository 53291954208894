import React, { useContext, useEffect } from "react";
import PropTypes from "prop-types";

// Styles
import "../styles/generic/Screen.css";
import { PageTitle } from "../views/generic/PageTitle";
import { useNavigate } from "react-router-dom";
import { getLoggedInUser } from "../functions/core_functions";
import { stayLoggedIn } from "../functions/authChecker";
import { SelectedCompanyContext, UserContext } from "../Main";
import { getSwitchedUserData } from "../functions/auth/switch-user-functions";
import {
  getSelectedCompany,
  loadDefaultCompany,
  setSwitchedUserID,
  switchedUserID
} from "../utils/constants";

export default Screen = ({ children }) => {
  const navigate = useNavigate();
  const { user, setUser } = useContext(UserContext);
  const { selectedCompany, setSelectedCompany } = useContext(
    SelectedCompanyContext
  );

  useEffect(() => {
    (async () => {
      const loggedIn = await stayLoggedIn();
      if (!loggedIn) {
        navigate("/login");
        return;
      } else {
        const switchedUserIdFromStorage = localStorage.getItem(
          "rscrm_switched_user_id"
        );
        if (switchedUserIdFromStorage) {
          setSwitchedUserID(switchedUserIdFromStorage);
          const switchedUser = await getSwitchedUserData();
          setUser(switchedUser);
        } else {
          const user = await getLoggedInUser();
          setUser(user);
        }

        await loadDefaultCompany(company => {
          localStorage.setItem(
            "rscrm_selected_company",
            JSON.stringify(company)
          );
          setSelectedCompany(company);
        });
      }
    })();
  }, []);

  return <div className="screen">{children}</div>;
};

Screen.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
};

export const ScreenHeader = ({ title, subtitle = null, children }) => {
  return (
    <div className="screen-header">
      <div className="header-text-wrapper">
        <PageTitle label={title} />
        {subtitle && <span className="header-subtitle-lbl">{subtitle}</span>}
      </div>
      <div className="action-buttons-wrapper">{children}</div>
    </div>
  );
};

ScreenHeader.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.element),
    PropTypes.element.isRequired
  ])
};
