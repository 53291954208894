import { serverDomain } from "../utils/constants";
import * as request from "../utils/request";
import {
  clearTokens,
  getTokens,
  refreshAccessToken,
  setTokens
} from "../utils/tokens";
import { fetchCurrentUserData, fetchCurrentUser } from "./core_functions";

export async function authChecker() {
  const userData = fetchCurrentUserData();

  if (userData === null) {
    const tokens = getTokens();

    if (!tokens.userId || !tokens.refreshToken) {
      console.log("empty refresh token or user id");
      return false;
    }

    try {
      if (tokens.expiresAt <= Date.now() / 1000) {
        console.log("expired");
        await refreshAccessToken();
      }
      await fetchCurrentUser();
    } catch (err) {
      console.log(err);
      return false;
    }

    return true;
  }

  return true;
}

export async function login(email, password) {
  try {
    const data = await request.post(
      `${serverDomain}/auth/login`,
      {
        email: email,
        password: password
      },
      false,
      null,
      "snake"
    );
    console.log(data);
    setTokens(data);
    return data;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function stayLoggedIn() {
  const tokens = getTokens();

  try {
    await refreshAccessToken(true);
  } catch (err) {
    console.log(err);
    clearTokens();
    if (window.location.pathname !== "/login") {
      window.location = "/login";
    }
    return false;
  }

  return tokens.userId && tokens.userId !== 0;
}
