import React from "react";
import "../../../styles/generic/screen/section/ScreenSection.css";

export default function ScreenSection({ children }) {
  return <div className="screen-section">{children}</div>;
}

export const ScreenSectionGrid = ({ children }) => {
  return <div className="screen-section-grid">{children}</div>;
};

export const ScreenSectionRow = ({ children }) => {
  return <div className="screen-section-row">{children}</div>;
};
