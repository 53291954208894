// Libraries
import React from "react";

// Views
import { Table } from "../generic/Table";

// Styles
import "../../styles/Customers/CustomerLineTestsTab.css";
import { DropdownInput } from "../generic/DropdownInput";
import PrimaryActionButton from "../generic/PrimaryActionButton";

const CustomerLineTestsTab = () => {
  const portal = "Chess WLR";

  return (
    <div className="customer-line-tests-tab">
      <div className="new-line-test-form">
        <div className="text-wrapper">
          <span className="title-lbl">Run Line Test</span>
          <span className="description-lbl">
            Perform a new line test on the customer's line, through the {portal}{" "}
            portal. Line tests may take up to a minute to complete.
          </span>
        </div>
        <DropdownInput
          id={"reason-dropdown"}
          title={"Fault Reason:"}
          placeholder={"Select a fault reason from list..."}
          options={[
            { title: "Bell Ringing", value: "bell_ringing" },
            {
              title: "Calling/Network Feature Fault",
              value: "calling_network_feature_fault"
            },
            { title: "Continuous Dial Tone", value: "continuous_dial_tone" },
            { title: "Crossed lines", value: "crossed_lines" },
            { title: "Cutting Off", value: "cutting_off" },
            {
              title: "Damage Report Overhead",
              value: "damage_report_overhead"
            },
            {
              title: "Damage Report Underground",
              value: "damage_report_underground"
            },
            { title: "Damage Report Wiring", value: "damage_report_wiring" },
            {
              title: "Disconnect Clear/Gain Balance",
              value: "disconnect_clear_gain_balance"
            },
            { title: "Faint Transmission", value: "faint_transmission" },
            {
              title: "Messaging Service Fault (i.e. 1571)",
              value: "messaging_service_fault"
            },
            { title: "No Dial Tone", value: "no_dial_tone" },
            { title: "No Reply", value: "no_reply" },
            { title: "No Tones", value: "no_tones" },
            { title: "Noisy", value: "noisy" },
            { title: "Number Unobtainable", value: "number_unobtainable" },
            { title: "One Way Transmission", value: "one_way_transmission" },
            { title: "Overhearing", value: "overhearing" },
            {
              title: "Permanent Engaged Tone",
              value: "permanent_engaged_tone"
            },
            { title: "Ring Trip", value: "ring_trip" },
            { title: "Unable To Ring Trip", value: "unable_to_ring_trip" },
            { title: "Wrong Numbers Outgoing", value: "wrong_numbers_outgoing" }
          ]}
        />
        <PrimaryActionButton id={"start-test-btn"} title={"Start Test"} />
      </div>
      <div className="line-tests-history-wrapper">
        <Table headers={["Date/Time", "Result"]} rowData={[]} />
      </div>
    </div>
  );
};

export default CustomerLineTestsTab;
