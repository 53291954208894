import React, { useContext, useEffect, useState } from "react";
import { ModalInputGrid, ModalRow } from "../generic/modal/Modal";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../utils/LoadingHandler";
import {
  getCallRecordingsForCLI,
  getWavData
} from "../../functions/recko_functions";
import { showErrorToast } from "../../utils/ErrorHandler";
import PrimaryActionButton from "../generic/PrimaryActionButton";

import "../../styles/Provisioning/ReckoSection.css";
import { DropdownInput } from "../generic/DropdownInput";
import { MultiLineInput } from "../generic/MultiLineInput";
import { ProvisioningCustomerContext } from "./ProvisioningDetail";
import {
  getVerbalAgreementForCustomer,
  saveVerbalAgreement
} from "../../functions/provisioning_functions";
import { showSuccessToast } from "../../utils/SuccessHandler";

export const VerbalAgreementSection = () => {
  const { customer, setCustomer } = useContext(ProvisioningCustomerContext);
  const [callRecordings, setCallRecordings] = useState(null);
  const [verbalAgreement, setVerbalAgreement] = useState(null);
  const [newVerbalAgreement, setNewVerbalAgreement] = useState({});

  // Get Call Recordings
  useEffect(() => {
    (async () => {
      if (!customer.cli_list || customer.cli_list.length == 0) {
        return;
      }
      const [recordings, err] = await getCallRecordingsForCLI(
        customer.cli_list[0]
      );
      if (err) {
        showErrorToast(
          "Failed to get call recordings",
          "There was an error that occurred whilst trying to fetch the call recordings for this number. Please try again later.",
          true
        );
        return;
      } else if (recordings) {
        setCallRecordings(recordings);
      }
    })();
  }, [customer]);

  // Get verbal agreement
  useEffect(() => {
    (async () => {
      if (!customer || !customer.id) {
        return;
      }
      const [va, err] = await getVerbalAgreementForCustomer(customer.id);
      if (err) {
        console.log(err);
        showErrorToast("Failed to get verbal agreement");
        return;
      }
      setVerbalAgreement(va);
    })();
  }, [customer]);

  return (
    <div className="recko-section">
      <ModalInputGrid>
        {callRecordings &&
          callRecordings.map(recording => {
            return <CallRecording recording={recording} />;
          })}
      </ModalInputGrid>
      {!verbalAgreement && (
        <ModalInputGrid>
          <MultiLineInput
            id={"recko-notes-input"}
            title={"Notes"}
            placeholder={"Enter any feedback for the RECKO in here..."}
            onChange={async e => {
              setNewVerbalAgreement({
                ...newVerbalAgreement,
                notes: e.target.value !== "" ? e.target.value : null
              });
            }}
          />
          <DropdownInput
            id={"recko-status-dropdown"}
            title={"Status"}
            placeholder={"Select a status..."}
            options={[
              { title: "Invalid", value: "invalid" },
              { title: "Questionable", value: "questionable" },
              { title: "Clean", value: "clean" }
            ]}
            onChange={async e => {
              setNewVerbalAgreement({
                ...newVerbalAgreement,
                status:
                  e.target.value !== "Select a status..."
                    ? e.target.value
                    : null
              });
            }}
          />
          <PrimaryActionButton
            id={"save-recko-btn"}
            title={"Save Recko"}
            onClick={async () => {
              showLoadingToast();
              const err = await saveVerbalAgreement(customer.id);
              if (err) {
                showErrorToast(
                  "Failed to save verbal agreement",
                  `There was an unexpected error that occurred whilst trying to update this customer's verbal agreement. Please try again later.`
                );
                return;
              }
              const [va, getErr] = await getVerbalAgreementForCustomer(
                customer.id
              );
              if (getErr) {
                console.log(getErr);
                showErrorToast("Failed to get verbal agreement");
                return;
              }
              setVerbalAgreement(va);

              removeLoadingToast();
              showSuccessToast(
                "Successfully saved verbal agreement",
                "The verbal agreement for this customer has been successfully saved."
              );
            }}
          />
        </ModalInputGrid>
      )}
      {verbalAgreement && (
        <>
          <ModalRow>
            <VerbalAgreementStatusPill status={verbalAgreement.status} />
            <span>
              Reviewed by: {verbalAgreement.reviewed_by_name} (
              {new Date(verbalAgreement.reviewed_at).toLocaleDateString()})
            </span>
          </ModalRow>
          {verbalAgreement.notes && <span>{verbalAgreement.notes}</span>}
        </>
      )}
    </div>
  );
};

const VerbalAgreementStatusPill = ({ status }) => {
  return (
    <span className={`verbal-agreement-status-pill ${status}`}>
      {status.toUpperCase()}
    </span>
  );
};

const CallRecording = ({ recording }) => {
  const [download, setDownload] = useState(null);

  const downloadData = async () => {
    showLoadingToast(
      "Getting audio data",
      "Please wait a moment, whilst we download the audio data for this recording..."
    );
    const [wavData, err] = await getWavData(recording.internal_call_id);
    if (err) {
      removeLoadingToast();
      showErrorToast(
        "Failed to get audio data",
        "There was an issue that occurred whilst trying to download the audio data for this recording."
      );
      return;
    } else if (wavData) {
      const blob = new Blob([new Uint8Array(wavData).buffer], {
        type: "audio/wav"
      });
      const url = URL.createObjectURL(blob);
      setDownload(url);
      removeLoadingToast();
    }
  };

  return (
    <div className="call-recording-wrapper">
      <span>
        {recording.external_user} - {recording.connect_time}
      </span>
      <span>{recording.contact_center_queue}</span>
      {recording.status === "P" && (
        <span className="processing-lbl">Processing...</span>
      )}
      {!download && recording.status === "A" && (
        <PrimaryActionButton
          id={"download-recording-btn"}
          title={"Download"}
          onClick={async () => {
            await downloadData();
          }}
        />
      )}
      {download && (
        <audio controls autoPlay={true}>
          <source src={download} type="audio/wav" />
        </audio>
      )}
    </div>
  );
};
