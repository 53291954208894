import React from "react";

// Styles
import "../../styles/home/BusinessNewsCard.css";

// Assets
import PartyIcon from "../../assets/icons/party.svg";
import HomeCard from "./HomeCard";

export default function BusinessNewsCard({ header, image, title, body }) {
  return (
    <HomeCard icon={PartyIcon} header={header}>
      <div className="business-news-card">
        {(() => {
          if (image) {
            return <img className="cover-image" src={image} />;
          } else {
            return;
          }
        })()}
        <div className="content-wrapper">
          <div className="text-wrapper">
            <span className="title-label">{title}</span>
            <span className="body-label">{body}</span>
          </div>
          <button className="read-more-btn">Read More...</button>
        </div>
      </div>
    </HomeCard>
  );
}
