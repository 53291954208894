import { serverDomain } from "./constants";
import * as cookies from "./cookies";

export const tokens = {
  userId: +cookies.get("rscrm_user_id"),
  sessionToken: cookies.get("rscrm_session_token") || "",
  accessToken: cookies.get("rscrm_access_token") || "",
  expiresAt: cookies.get("rscrm_token_expiry") || 0
};

export function getTokens() {
  return tokens;
}

export function setTokens(t) {

  if (t.user_id) {
    tokens.userId = t.user_id;
  }
  if (t.refresh_token) {
    tokens.sessionToken = t.refresh_token;
  }
  tokens.accessToken = t.access_token;
  tokens.expiresAt = t.expires_at;

  cookies.set("rscrm_user_id", tokens.userId, 365);
  cookies.set("rscrm_session_token", tokens.sessionToken, 365);
  cookies.set("rscrm_access_token", tokens.accessToken, 365);
  cookies.set("rscrm_token_expiry", tokens.expiresAt, 365);
}

export function isLoggedIn() {
  const tokens = getTokens();
  if (
    tokens.accessToken === "" ||
    tokens.sessionToken === "" ||
    tokens.userId === ""
  ) {
    return false;
  } else {
    return true;
  }
}

export function clearTokens() {
  tokens.userId = null;
  tokens.sessionToken = null;
  tokens.accessToken = null;
  tokens.expiresAt = null;

  cookies.del("rscrm_user_id");
  cookies.del("rscrm_session_token");
  cookies.del("rscrm_access_token");
  cookies.del("rscrm_token_expiry");
}

export async function refreshAccessToken(force = false) {
  if (!tokens.userId) {
    throw new Error("user id not set");
  }

  if (!tokens.sessionToken) {
    throw new Error("refresh token not set");
  }

  const expiresAtDate = Date.parse(tokens.expiresAt);
  if (expiresAtDate > new Date() && !force) {
    return;
  }

  const res = await fetch(`${serverDomain}/auth/refresh_access_token`, {
    method: "POST",
    body: JSON.stringify({
      user_id: tokens.userId,
      refresh_token: tokens.sessionToken
    })
  });

  const json = await res.json();

  if (!json.ok) {
    throw new Error(json.error);
  }

  tokens.accessToken = json.data.access_token;
  tokens.expiresAt = json.data.expires_at;

  setTokens(json.data);
}
