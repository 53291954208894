import firebase from "firebase";

const firebaseConfig = {
  apiKey: "AIzaSyC25wD3ha4wlZnMCTb8jjeHF0AFJ9-gQms",
  authDomain: "transparent-telecom.firebaseapp.com",
  databaseURL: "https://transparent-telecom.firebaseio.com",
  projectId: "transparent-telecom",
  storageBucket: "transparent-telecom.appspot.com",
  messagingSenderId: "365986524175",
  appId: "1:365986524175:web:8abd28391a68e66c2ec77a",
  measurementId: "G-ZK5Z2HXR9D"
};

firebase.initializeApp(firebaseConfig);
firebase.analytics();
if (window.location.protocol === "https:") {
  firebase.messaging();
}

export default firebase;
