import React from "react";
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalInputGrid,
  ModalSection
} from "../generic/modal/Modal";
import PrimaryActionButton from "../generic/PrimaryActionButton";
import { SingleLineInput } from "../generic/SingleLineInput";

const ChangeAddressForm = () => {
  return (
    <Modal id={"change-address-modal"}>
      <ModalHeader title={"Change Address"} />
      <ModalContent>
        <ModalSection>
          <ModalInputGrid>
            <SingleLineInput
              id={"address-line-1-input"}
              required={true}
              title={"Address Line 1"}
            />
            <SingleLineInput
              id={"address-line-2-input"}
              required={false}
              title={"Address Line 2"}
            />
            <SingleLineInput
              id={"town-city-input"}
              required={true}
              title={"Town / City"}
            />
            <SingleLineInput
              id={"county-input"}
              required={false}
              title={"County"}
            />
            <SingleLineInput
              id={"postcode-input"}
              required={true}
              title={"Postcode"}
            />
          </ModalInputGrid>
        </ModalSection>
      </ModalContent>

      <ModalFooter>
        <PrimaryActionButton id={"save-address-form"} title={"Save Address"} />
      </ModalFooter>
    </Modal>
  );
};

export default ChangeAddressForm;
