import React from "react";

import { createRoot } from "react-dom/client";
import { ErrorToast } from "../views/generic/ErrorToast";
import { generateUUID } from "../functions/core_functions";

export function showErrorToast(
  title,
  err = "There was an unexpected error that occurred, please try again later.",
  permanent = false
) {
  /* title is an optional parameter */

  const id = generateUUID();

  const div = document.createElement("div");
  div.id = id;
  div.className = "error-popup";
  document.getElementById("root").appendChild(div);
  const errorToast = (
    <ErrorToast
      title={title}
      description={Error(err).message}
      onClose={async () => {
        removeErrorToast(id);
      }}
    />
  );
  const root = createRoot(document.getElementById(id));
  root.render(errorToast);

  if (!permanent) {
    setTimeout(() => {
      removeErrorToast(id);
    }, 4000);
  }
}

function removeErrorToast(id) {
  const mainDiv = document.getElementById(id);
  if (!mainDiv) {
    return;
  }
  mainDiv.classList.add("removing");
  const toast = mainDiv.querySelectorAll(".error-toast")[0];
  toast.classList.add("removing");
  setTimeout(() => {
    toast.remove();
    mainDiv.remove();
  }, 250);
}
