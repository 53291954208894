import React, { useContext, useEffect, useState } from "react";

// Styles
import "../../../styles/outbound-calls/callbacks/ScheduledCallbacks.css";

// Functions
import {
  displayCallbacks,
  listCallbacks
} from "../../../functions/outbound-calls/callback_functions";
import { generateUUID } from "../../../functions/core_functions.js";

// Views
import Modal, { ModalContent, ModalHeader } from "../../generic/modal/Modal";
import ScheduledCallbackCell from "./ScheduledCallbackCell";

function ScheduledCallbacks({ selectedRecord, setSelectedRecord }) {
  const [callbacks, setCallbacks] = useState([]);

  useEffect(() => {
    (async () => {
      const [cbs, err] = await listCallbacks();
      if (cbs) {
        setCallbacks(cbs);
      }
    })();
  }, []);

  return (
    <Modal>
      <ModalHeader title={"Scheduled Callbacks"} />
      <ModalContent>
        <div className="callbacks-wrapper">
          {callbacks.map(callback => (
            <ScheduledCallbackCell
              containerId={generateUUID()}
              callback={callback}
              selectedRecord={selectedRecord}
              setSelectedRecord={setSelectedRecord}
            />
          ))}
        </div>
      </ModalContent>
    </Modal>
  );
}

export default ScheduledCallbacks;
