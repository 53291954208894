import React, { useContext, useEffect } from "react";
import "../styles/navbar/SwitchUser.css";
import CrossIcon from "../assets/CrossIcon.svg";
import {
  closeSwitchUser,
  switchUser
} from "../functions/auth/switch-user-functions";
import { UserContext } from "../Main";
import PrimaryActionButton from "../views/generic/PrimaryActionButton";
import { SingleLineInput } from "../views/generic/SingleLineInput";

function SwitchUser({ user, setUser }) {
  return (
    <div className="switch-user-container">
      <div className="switch-user-top-row">
        <span className="switch-user-title">Switch User</span>
        <button
          className="switch-user-close-button"
          onClick={async () => {
            await closeSwitchUser();
          }}
        >
          <img src={CrossIcon} />
        </button>
      </div>
      <span className="switch-user-description">
        Enter your unique User ID to switch to your account.
      </span>
      <SingleLineInput
        id={"switch-user-pin-code"}
        onEnter={async () => {
          await switchUser(user, setUser);
        }}
      />
      <PrimaryActionButton
        id={"switch-user-login-button"}
        title={"Switch"}
        onClick={async () => {
          await switchUser(user, setUser);
        }}
      />
    </div>
  );
}

export default SwitchUser;
