import React from "react";
import { DropdownInput } from "../../generic/DropdownInput";
import { SingleLineInput } from "../../generic/SingleLineInput";
import { OrderFormAccordian } from "./OrderFormAccordian";

export const LandlineOrderForm = ({
  lineRentalPrices,
  data,
  setData,
  checked,
  setChecked
}) => {
  return (
    <OrderFormAccordian
      title={"Landline Order"}
      checked={checked}
      setChecked={setChecked}
    >
      <SingleLineInput
        id={"cli-input"}
        required={true}
        title={"CLI"}
        defaultValue={data.cli}
        onChange={e => {
          setData({
            ...data,
            cli: e.target.value
          });
        }}
      />
      <DropdownInput
        id={"landline-package-input"}
        required={true}
        title={"Package"}
        defaultValue={data.package}
        options={lineRentalPrices.map(price => {
          return {
            title: `${price.name} - ${price.price.toLocaleString("en-GB", {
              style: "currency",
              currency: "GBP"
            })}`,
            value: price.id
          };
        })}
        onChange={e => {
          setData({
            ...data,
            package: !isNaN(parseInt(e.target.value))
              ? parseInt(e.target.value)
              : null
          });
        }}
      />
      <SingleLineInput
        id={"landline-package-price-input"}
        required={true}
        type={"number"}
        title={"Price"}
        defaultValue={data.package_price}
        onChange={e => {
          setData({
            ...data,
            package_price: !isNaN(parseFloat(e.target.value))
              ? parseFloat(e.target.value)
              : null
          });
        }}
      />
      <SingleLineInput
        id={"international-call-package-price-input"}
        required={false}
        type={"number"}
        title={"International Call Package Price"}
        defaultValue={data.international_call_package_price}
        onChange={e => {
          setData({
            ...data,
            international_call_package_price: !isNaN(parseFloat(e.target.value))
              ? parseFloat(e.target.value)
              : null
          });
        }}
      />
    </OrderFormAccordian>
  );
};
