import { serverDomain } from "../utils/constants";
import * as req from "../utils/request";

export async function getSalesWallboardIndividualStats() {
  try {
    const stats = await req.get(
      `${serverDomain}/api/v1/stats/sales_wallboard_individual`,
      null,
      "snake"
    );
    return [stats, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}
