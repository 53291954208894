import React from "react";
import "../../styles/generic/PrimaryActionButton.css";

function PrimaryActionButton({
  id,
  icon = null,
  title = null,
  style = "filled",
  onClick
}) {
  const className = `primary-action-button ${style}`;

  return (
    <button className={className} id={id} onClick={onClick}>
      {icon && <img src={icon} />}
      {title && <span>{title}</span>}
    </button>
  );
}

export default PrimaryActionButton;
