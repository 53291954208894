import React, { useEffect } from "react";

// Styles
import "../../styles/generic/SingleLineInput.css";

export const SingleLineInput = ({
  id,
  type = "text",
  required = false,
  title,
  placeholder,
  defaultValue,
  onChange = () => {},
  onEnter = () => {},
  editable = true
}) => {
  useEffect(() => {
    (async () => {
      document.getElementById(id).addEventListener("wheel", event => {
        event.preventDefault();
      });
    })();
  }, []);

  return (
    <div className="single-line-input-wrapper">
      {title && (
        <span className="header-lbl">
          {title}
          {required && <p>*</p>}
        </span>
      )}
      <input
        id={id}
        type={type}
        title={title}
        placeholder={placeholder}
        defaultValue={defaultValue}
        onChange={onChange}
        onInput={onChange}
        disabled={editable ? "" : "disabled"}
        onKeyDown={async e => {
          if (e.code == "Enter") {
            onEnter();
          }
        }}
      />
    </div>
  );
};
