import React from "react";
import { getTokens } from "../../utils/tokens";
import { currentUser, fetchCurrentUser, showAlert } from "../core_functions.js";
import { showErrorToast } from "../../utils/ErrorHandler";
import { showWarningToast } from "../../utils/WarningHandler";
import {
  serverDomain,
  getSelectedCompany,
  selectedCompanyID
} from "../../utils/constants";
import * as request from "../../utils/request";
import ReactDOM from "react-dom";
import TickIcon from "../../assets/TickIcon.svg";
import CrossIcon from "../../assets/CrossIcon.svg";
import PackageAddOnDealsheetOption from "../../views/Sales/CallingSession/PackageAddOnDealsheetOption.js";
import { showLoadingOverlay, hideLoadingOverlay } from "../core_functions.js";
import {
  listCommentsRequest,
  displayRecordComments
} from "./record-comments-functions.js";
import { getRadioInputValue } from "../../utils/dom.js";
import { capitalize } from "../../utils/helpers";
import {
  removeLoadingToast,
  showLoadingToast
} from "../../utils/LoadingHandler";
import { AdditionalLineForm } from "../../views/outbound-calls/AdditionalLineForm";
import { createRoot } from "react-dom/client";
import RecordHandoverForm from "../../views/outbound-calls/RecordHandoverForm";
import NewCallbackForm from "../../views/outbound-calls/callbacks/NewCallbackForm";
import { ArchiveRecordForm } from "../../views/outbound-calls/ArchiveRecordForm.js";

var csUserSpecifiedName = null;

export var setTPSIndicatorState;
export var setTagsIndicatorState;
export var setMPFIndicatorState;

export function assignIndiciatorStateFunctions(tps, tags, mpf) {
  setTPSIndicatorState = tps;
  setTagsIndicatorState = tags;
  setMPFIndicatorState = mpf;
}

export async function getRandomRecordToDialOut() {
  try {
    const data = await request.get(
      `${serverDomain}/api/v1/records`,
      null,
      "snake"
    );
    return [data, null];
  } catch (err) {
    switch (err.code) {
      case 401:
        showErrorToast(
          "Login failed",
          `Please close this tab and reopen the CRM in order to pull a fresh record.`
        );
        break;
      case 402:
        showErrorToast(
          "No company selected",
          `Please select a company from the sidebar switcher, and then try again.`
        );
        break;
      case 501:
        showErrorToast(
          "No fresh records left",
          `There are no more fresh records left in the database. Please speak to management as soon as possible.`
        );
        break;
      case 502:
        showErrorToast(
          "Failed to occupy number",
          `Please try logging out and back in, then pull another record. If the error persists, contact system administrator.`
        );
        break;
      default:
        showErrorToast(
          "Failed to get number",
          `There was an error that occurred whilst trying to retrieve a random record from the database.`
        );
    }
    return [null, err];
  }
}

export async function getRecord(cli) {
  try {
    const data = await request.get(
      `${serverDomain}/api/v1/records/${cli}`,
      null,
      "snake"
    );
    return data;
  } catch (err) {
    return null;
  }
}

export async function checkFTTP() {
  try {
    //
  } catch (err) {
    return err;
  }
}

// Checks a given number on the WLR portal.
export async function tagsCheckNumber(number, postcode) {
  const timer = setTimeout(() => {
    tagsCheckNumber(number, postcode);
    return;
  }, 10000);
  try {
    const res = await request.post(
      `${serverDomain}/api/v1/number/${number}/tags_check?postcode=${postcode}`
    );
    clearTimeout(timer);
    return [res.bt, null];
  } catch (err) {
    return [null, err];
  }
}

// TPS checks a number on the TPS online website.
export async function tpsCheckNumber(number) {
  try {
    const res = await request.post(`${serverDomain}/api/v1/tps_check`, {
      cli: number
    });
    return [res.is_tps, null];
  } catch (err) {
    return [null, err];
  }
}

// Removes user occupation from a record allowing another user to take the record.
// Is used when the user wants to skip over a record.
export async function vacateCurrentRecord(recordId) {
  try {
    const res = await request.put(
      `${serverDomain}/api/v1/records/${recordId}/vacate`
    );
  } catch (err) {
    return err;
  }
}

// Reopens a call from the history log.
export async function reopenOutboundCallRecord(rec) {
  // try {
  //   const data = await request.get(
  //     `${serverDomain}/api/v1/records/${rec.phone}`,
  //     null,
  //     "snake"
  //   );
  //   const fullAddressFormatted = capitalize(data.address.split(" "));
  //   const address1Formatted = capitalize(data.address_line1.split(" "));
  //   var address2Formatted;
  //   if (data.address_line2) {
  //     address2Formatted = capitalize(data.address_line2.split(" "));
  //   }
  //   const townCityFormatted = capitalize(data.address_towncity.split(" "));
  //   var countyFormatted;
  //   if (data.address_county) {
  //     countyFormatted = capitalize(data.address_county.split(" "));
  //   }
  //   data.address =
  //     fullAddressFormatted.slice(
  //       0,
  //       fullAddressFormatted.length - data.address_postcode.length
  //     ) + data.address_postcode;
  //   data.address_line1 = address1Formatted;
  //   data.address_line2 = address2Formatted;
  //   data.address_towncity = townCityFormatted;
  //   data.address_county = countyFormatted;
  //   setCurrentRecord(data);
  //   document.getElementById("active-record-cli-lbl").textContent =
  //     selectedRecord.cli.replace(" ", "");
  //   document.getElementById(
  //     "active-record-name-lbl"
  //   ).textContent = `${selectedRecord.person_name}`;
  //   document.getElementById(
  //     "active-record-address-lbl"
  //   ).textContent = `${selectedRecord.full_address}`;
  //   try {
  //     const [comments, err] = await listCommentsRequest(
  //       selectedRecord.cli.replace(" ", "")
  //     );
  //     if (err) {
  //       throw err;
  //     } else {
  //       displayRecordComments(comments);
  //     }
  //   } catch (err) {
  //     hideLoadingOverlay();
  //     showErrorToast(
  //       "Failed to get record comments",
  //       `There was an error that occurred whilst trying to retrieve this record's comments.\n\nError: ${err}`
  //     );
  //     return;
  //   }
  //   setTPSIndicatorState(true);
  //   //Sets the states of the indicators based on record provider type.
  //   if (selectedRecord.check_completed === "mpf") {
  //     setMPFIndicatorState(true);
  //     setTagsIndicatorState(false);
  //   } else {
  //     setMPFIndicatorState(false);
  //     setTagsIndicatorState(true);
  //   }
  //   hideLoadingOverlay();
  //   if (selectedRecord === null || selectedRecord === undefined) {
  //     hideLoadingOverlay();
  //     showErrorToast(
  //       "Failed to get record",
  //       "There was a problem that occurred when opening this record. Please try again."
  //     );
  //     return;
  //   } else {
  //     document.getElementsByClassName(
  //       "active-record-container"
  //     )[0].style.display = "flex";
  //     document.getElementsByClassName(
  //       "empty-record-state-container"
  //     )[0].style.display = "none";
  //   }
  // } catch (err) {
  //   hideLoadingOverlay();
  //   showErrorToast(
  //     "Failed to get record",
  //     `There was a problem that occurred when opening this record. Please try again.\n\nErr: ${err}`
  //   );
  //   return;
  // }
}

//If the person does not pick up the phone, then the no answer function is called.
//Add one to missed call count on record and then get a new record for the user.
//TODO: This needs to be called automatically when Gamma API is implemented.
export async function recordNoAnswerAction(recordId) {
  try {
    await request.put(`${serverDomain}/api/v1/records/${recordId}/no_answer`);
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// Opens the archive record form for users to select a reason for archiving the record.
export async function archiveNumberButtonAction(recordId) {
  const div = document.createElement("div");
  div.id = "archive-record-form-parent-div";
  ReactDOM.render(<ArchiveRecordForm phone={recordId} />, div);
  document.getElementById("app-content-wrapper").appendChild(div);
}

// Archives the record on the database.
export async function archiveNumber(
  recordId,
  action = "not_interested",
  reason = null,
  notes = null
) {
  try {
    console.log({
      reason: reason,
      notes: notes
    });
    await request.put(`${serverDomain}/api/v1/records/${recordId}/${action}`, {
      reason: reason,
      notes: notes
    });
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// Punt number essentially archives a number marking the status as punt.
// Not currently used, but useful to keep.
export async function puntNumber(phone) {
  try {
    await request.post(serverDomain + "/api/v1/number/" + phone + "/punt");
    return true;
  } catch (err) {
    console.log(err);
    window.alert(
      "Failed to punt number in database. It is important you take note of the number and pass onto technical support for a manual archive."
    );
    return false;
  }
}

export async function dialOutNumber(recordId) {
  try {
    await request.put(`${serverDomain}/api/v1/records/${recordId}/answered`);
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// Validates an address
export async function detectAddress(fullAddress) {
  try {
    const data = await request.post(serverDomain + "/api/v1/detect_address", {
      address: fullAddress
    });
    return data;
  } catch (err) {
    console.log(err);
    window.alert(
      "Failed to validate address, please ensure the address is correctly formatted."
    );
    return null;
  }
}

// Updates the sales calculator with latest values from dealsheet
export async function updateSalesCalculator() {
  // document.getElementById("ds-sales-calculator").style.display = "flex";
  // var amount = 0; //total monthly price
  // var btPrice = 0; //bt price for comparison
  // var lrAmount = 0; //line rental amount
  // var inputElements = document.getElementsByTagName("input");
  // for (var i = 0; i < inputElements.length; i++) {
  //   const input = inputElements[i];
  //   if (
  //     (input.name === "tariff" ||
  //       input.name === "broadband_package" ||
  //       input.name === "broadband_charge" ||
  //       input.name === "non_geo_package" ||
  //       input.name === "mobile_package") &&
  //     input.checked
  //   ) {
  //     let valueJSONParsed = JSON.parse(input.value);
  //     amount += valueJSONParsed.price;
  //     btPrice += valueJSONParsed.bt_price;
  //   }
  //   if (input.name === "line_rental" && input.checked) {
  //     let valueJSONParsed = JSON.parse(input.value);
  //     document.getElementById(
  //       "ds-sales-calculator-line-rental"
  //     ).textContent = `Line Rental: ${valueJSONParsed.price.toLocaleString(
  //       "en-GB",
  //       { style: "currency", currency: "GBP" }
  //     )} ${valueJSONParsed.billing_interval}`;
  //     if (valueJSONParsed.billing_interval === "monthly") {
  //       btPrice += valueJSONParsed.bt_price;
  //       lrAmount = valueJSONParsed.price;
  //     } else if (valueJSONParsed.billing_interval === "yearly") {
  //       btPrice += valueJSONParsed.bt_price / 12;
  //       lrAmount = valueJSONParsed.price / 12;
  //     }
  //   }
  //   if (input.name === "bt_sport" && input.checked) {
  //     const packageAddOns = await getPackageAddOns(selectedCompanyID);
  //     const btSportAddOn = packageAddOns.find(e => e.id === 2);
  //     const newBTCharge = btSportAddOn.bt_price;
  //     amount += newBTCharge - btSportAddOn.price;
  //     btPrice += newBTCharge;
  //   } else if (input.name === "bt_mobile" && input.checked) {
  //     const packageAddOns = await getPackageAddOns(selectedCompanyID);
  //     const btMobileAddOn = packageAddOns.find(e => e.id === 2);
  //     const newBTCharge = btMobileAddOn.bt_price;
  //     amount += newBTCharge - btMobileAddOn.price;
  //     btPrice += newBTCharge;
  //   }
  //   if (input.name === "select_service" && input.checked) {
  //     let valueJSONParsed = JSON.parse(input.value);
  //     amount += valueJSONParsed.price;
  //     btPrice += valueJSONParsed.price;
  //   }
  // }
  // document.getElementById(
  //   "ds-sales-calculator-amount-lbl"
  // ).textContent = `${amount.toLocaleString("en-GB", {
  //   style: "currency",
  //   currency: "GBP"
  // })} per month`;
  // const savingsLabel = document.getElementById(
  //   "ds-sales-calculator-savings-amount"
  // );
  // const savingsAmount = btPrice - (amount + lrAmount);
  // if (savingsAmount < 0) {
  //   savingsLabel.style.color = "red";
  // } else {
  //   savingsLabel.style.color = "#3bd464";
  // }
  // savingsLabel.textContent = `${savingsAmount.toLocaleString("en-GB", {
  //   style: "currency",
  //   currency: "GBP"
  // })}`;
}

// Calculates the number of remaining fields in the dealsheet
export async function calculateRemainingRequiredFields() {
  const inputElements = document
    .getElementById("dealsheet-container")
    .querySelectorAll("textarea,input");
  document.getElementById("css-fields-rem-lbl").style.display = "block";

  var count = 0;
  for (let i = 0; i < inputElements.length; i++) {
    let { type, name, value, required } = inputElements[i];

    if (type === "radio") {
      value = getRadioInputValue(name);
    } else if (type === "checkbox") {
      value = inputElements[i].checked;
    } else {
      value = value.trim();
    }

    // checking required fields to make sure all have been filled.
    if (required && !value) {
      count++;
    }
  }

  if (count > 0) {
    document.getElementById(
      "css-fields-rem-lbl"
    ).textContent = `${count} required fields remaining`;
  } else {
    document.getElementById("css-fields-rem-lbl").style.display = "none";
  }
}

//Gets all the items to be included in the dealsheet in one API.
export async function getDealsheetItems() {
  try {
    const items = await request.get(
      `${serverDomain}/api/v1/dealsheet_items?company_id=${selectedCompanyID}`,
      null,
      "snake"
    );
    return [items, null];
  } catch (err) {
    return [null, err];
  }
}

export async function displayTariffs(tariffs, defaultValue, defaultOverride) {
  const tariffsCard = document.getElementById("tariffs-dealsheet-card");
  if (tariffsCard === null || tariffsCard === undefined) {
    return;
  }

  var existingElements = tariffsCard.getElementsByClassName(
    "dealsheet-radio-group"
  );
  while (existingElements[0]) {
    tariffsCard.removeChild(existingElements[0]);
  }

  tariffs.forEach((tariff, i) => {
    const newRadioGroup = document.createElement("div");
    newRadioGroup.className = "dealsheet-radio-group";

    const radioLabel = document.createElement("label");
    radioLabel.htmlFor = tariff.id;
    radioLabel.innerHTML = `${tariff.name.replace(
      "%COMPANY_NAME%",
      getSelectedCompany().company_name
    )} - ${tariff.price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    })}`;
    newRadioGroup.appendChild(radioLabel);

    const radioInput = document.createElement("input");
    radioInput.type = "radio";
    radioInput.name = "tariff";
    radioInput.value = JSON.stringify(tariff);
    if (i === 1) {
      radioInput.required = true;
    }
    if (tariff.id === defaultValue) {
      radioInput.defaultChecked = true;
    }
    radioInput.onchange = async function () {
      updateSalesCalculator();
      calculateRemainingRequiredFields();

      const cssScript = document.getElementsByClassName("css-script")[0];
      document.getElementById("selected-tariff-script-lbl").innerHTML = `${
        tariff.name
      } - ${tariff.price.toLocaleString("en-GB", {
        style: "currency",
        currency: "GBP"
      })}`;
    };
    newRadioGroup.appendChild(radioInput);

    tariffsCard.appendChild(newRadioGroup);
  });

  const separator = document.createElement("div");
  separator.className = "dealsheet-separator";
  tariffsCard.appendChild(separator);

  const overrideField = document.createElement("input");
  overrideField.placeholder = "Price Override";
  overrideField.id = "tariff-price-override-field";
  overrideField.name = "tariff_price";
  overrideField.type = "number";
  overrideField.onwheel = function (e) {
    e.target.blur();
  };

  if (defaultOverride !== null) {
    overrideField.value = defaultOverride;
  }

  tariffsCard.appendChild(overrideField);
}

export async function displayBroadbandPackages(
  broadbandPackages,
  defaultValue,
  defaultOverride
) {
  const bpsCard = document.getElementById("broadband-packages-dealsheet-card");
  if (bpsCard === null || bpsCard === undefined) {
    return;
  }
  broadbandPackages.forEach((bp, i) => {
    const newRadioGroup = document.createElement("div");
    newRadioGroup.className = "dealsheet-radio-group";

    const radioLabel = document.createElement("label");
    radioLabel.htmlFor = bp.id;
    radioLabel.innerHTML = `${bp.name.replace(
      "%COMPANY_NAME%",
      getSelectedCompany().company_name
    )} - ${bp.price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    })}`;
    newRadioGroup.appendChild(radioLabel);

    const radioInput = document.createElement("input");
    radioInput.type = "radio";
    radioInput.name = "broadband_package";
    radioInput.value = JSON.stringify(bp);
    if (bp.id === defaultValue) {
      radioInput.defaultChecked = true;
    }
    radioInput.onchange = async function () {
      updateSalesCalculator();
      calculateRemainingRequiredFields();
    };
    newRadioGroup.appendChild(radioInput);

    bpsCard.appendChild(newRadioGroup);
  });

  const separator = document.createElement("div");
  separator.className = "dealsheet-separator";
  bpsCard.appendChild(separator);

  const overrideField = document.createElement("input");
  overrideField.placeholder = "Price Override";
  overrideField.name = "broadband_package_price";
  overrideField.id = "broadband-package-price-override-field";
  overrideField.type = "number";
  if (defaultOverride !== null) {
    overrideField.value = defaultOverride;
  }
  overrideField.onwheel = function (e) {
    e.target.blur();
  };
  bpsCard.appendChild(overrideField);
}

export async function displayCallBroadbandBundles(
  bundles,
  defaultValue,
  defaultOverride
) {
  const card = document.getElementById("call-broadband-bundles-dealsheet-card");
  if (card === null || card === undefined) {
    return;
  }
  bundles.forEach((pkg, i) => {
    const newRadioGroup = document.createElement("div");
    newRadioGroup.className = "dealsheet-radio-group";

    const radioLabel = document.createElement("label");
    radioLabel.htmlFor = pkg.id;
    radioLabel.innerHTML = `${pkg.name.replace(
      "%COMPANY_NAME%",
      getSelectedCompany().company_name
    )} - ${pkg.price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    })}`;
    newRadioGroup.appendChild(radioLabel);

    const radioInput = document.createElement("input");
    radioInput.type = "radio";
    radioInput.name = "call_broadband_bundle";
    radioInput.value = JSON.stringify(pkg);

    if (pkg.id === defaultValue) {
      radioInput.defaultChecked = true;
    }
    radioInput.onchange = async function () {
      updateSalesCalculator();
      calculateRemainingRequiredFields();
    };
    newRadioGroup.appendChild(radioInput);

    card.appendChild(newRadioGroup);
  });

  const separator = document.createElement("div");
  separator.className = "dealsheet-separator";
  card.appendChild(separator);

  const overrideField = document.createElement("input");
  overrideField.placeholder = "Price Override";
  overrideField.id = "call-broadband-bundle-price-override-field";
  overrideField.name = "call_broadband_bundle_price";
  overrideField.type = "number";
  if (defaultOverride !== null) {
    overrideField.value = defaultOverride;
  }
  overrideField.onwheel = function (e) {
    e.target.blur();
  };
  card.appendChild(overrideField);
}

export async function displayNonGeoPackages(
  nonGeoPackages,
  defaultValue,
  defaultOverride
) {
  const card = document.getElementById("non-geo-packages-dealsheet-card");
  if (card === null || card === undefined) {
    return;
  }
  nonGeoPackages.forEach((pkg, i) => {
    const newRadioGroup = document.createElement("div");
    newRadioGroup.className = "dealsheet-radio-group";

    const radioLabel = document.createElement("label");
    radioLabel.htmlFor = pkg.id;
    radioLabel.innerHTML = `${pkg.name.replace(
      "%COMPANY_NAME%",
      getSelectedCompany().company_name
    )} - ${pkg.price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    })}`;
    newRadioGroup.appendChild(radioLabel);

    const radioInput = document.createElement("input");
    radioInput.type = "radio";
    radioInput.name = "non_geo_package";
    radioInput.value = JSON.stringify(pkg);

    if (pkg.id === defaultValue) {
      radioInput.defaultChecked = true;
    }
    radioInput.onchange = async function () {
      updateSalesCalculator();
      calculateRemainingRequiredFields();
    };
    newRadioGroup.appendChild(radioInput);

    card.appendChild(newRadioGroup);
  });

  const separator = document.createElement("div");
  separator.className = "dealsheet-separator";
  card.appendChild(separator);

  const overrideField = document.createElement("input");
  overrideField.placeholder = "Price Override";
  overrideField.id = "non-geo-package-price-override-field";
  overrideField.name = "non_geo_package_price";
  overrideField.type = "number";
  if (defaultOverride !== null) {
    overrideField.value = defaultOverride;
  }
  overrideField.onwheel = function (e) {
    e.target.blur();
  };
  card.appendChild(overrideField);
}

export async function displayMobilePackages(
  mobilePackages,
  defaultValue,
  defaultOverride
) {
  const card = document.getElementById("mobile-packages-dealsheet-card");
  if (card === null || card === undefined) {
    return;
  }
  mobilePackages.forEach((pkg, i) => {
    const newRadioGroup = document.createElement("div");
    newRadioGroup.className = "dealsheet-radio-group";

    const radioLabel = document.createElement("label");
    radioLabel.htmlFor = pkg.id;
    radioLabel.innerHTML = `${pkg.name.replace(
      "%COMPANY_NAME%",
      getSelectedCompany().company_name
    )} - ${pkg.price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    })}`;
    newRadioGroup.appendChild(radioLabel);

    const radioInput = document.createElement("input");
    radioInput.type = "radio";
    radioInput.name = "mobile_package";
    radioInput.value = JSON.stringify(pkg);

    if (pkg.id === defaultValue) {
      radioInput.defaultChecked = true;
    }
    radioInput.onchange = async function () {
      updateSalesCalculator();
      calculateRemainingRequiredFields();
    };
    newRadioGroup.appendChild(radioInput);

    card.appendChild(newRadioGroup);
  });

  const separator = document.createElement("div");
  separator.className = "dealsheet-separator";
  card.appendChild(separator);

  const overrideField = document.createElement("input");
  overrideField.placeholder = "Price Override";
  overrideField.id = "mobile-package-price-override-field";
  overrideField.name = "mobile_package_price";
  overrideField.type = "number";
  if (defaultOverride !== null) {
    overrideField.value = defaultOverride;
  }
  overrideField.onwheel = function (e) {
    e.target.blur();
  };
  card.appendChild(overrideField);
}

export async function displayLineRentalPrices(
  lineRentalPrices,
  defaultValue,
  defaultOverride
) {
  const card = document.getElementById("line-rental-prices-dealsheet-card");
  if (card === null || card === undefined) {
    return;
  }
  lineRentalPrices.forEach((price, i) => {
    const newRadioGroup = document.createElement("div");
    newRadioGroup.className = "dealsheet-radio-group";

    const radioLabel = document.createElement("label");
    radioLabel.htmlFor = price.id;
    radioLabel.innerHTML = `${price.name.replace(
      "%COMPANY_NAME%",
      getSelectedCompany().company_name
    )} - ${price.price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    })}`;
    newRadioGroup.appendChild(radioLabel);

    const radioInput = document.createElement("input");
    radioInput.type = "radio";
    radioInput.name = "line_rental";
    radioInput.value = JSON.stringify(price);
    if (i === 1) {
      radioInput.required = true;
    }
    if (price.id === defaultValue) {
      radioInput.defaultChecked = true;
    }
    radioInput.onchange = async function () {
      updateSalesCalculator();
      calculateRemainingRequiredFields();
    };
    newRadioGroup.appendChild(radioInput);

    card.appendChild(newRadioGroup);
  });

  const separator = document.createElement("div");
  separator.className = "dealsheet-separator";
  card.appendChild(separator);

  const overrideField = document.createElement("input");
  overrideField.placeholder = "Price Override";
  overrideField.id = "line-rental-price-override-field";
  overrideField.name = "line_rental_price";
  overrideField.type = "number";
  if (defaultOverride !== null) {
    overrideField.value = defaultOverride;
  }
  overrideField.onwheel = function (e) {
    e.target.blur();
  };
  card.appendChild(overrideField);
}

export async function displayPackageAddOns(packageAddOns) {
  const dscard = document.getElementById("package-add-ons-card");
  if (!dscard) {
    return;
  }

  packageAddOns.forEach((addon, i) => {
    const div = document.createElement("div");
    ReactDOM.render(<PackageAddOnDealsheetOption addon={addon} />, div);
    dscard.appendChild(div);
  });
}

export async function displaySelectServices(selectServices) {
  const card = document.getElementById("select-services-dealsheet-card");
  if (card === null || card === undefined) {
    return;
  }
  selectServices.forEach((selectService, i) => {
    const newRadioGroup = document.createElement("div");
    newRadioGroup.className = "dealsheet-radio-group";

    const radioLabel = document.createElement("label");
    radioLabel.htmlFor = selectService.id;
    radioLabel.innerHTML = `${selectService.name.replace(
      "%COMPANY_NAME%",
      getSelectedCompany().company_name
    )} - ${selectService.price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    })}`;
    newRadioGroup.appendChild(radioLabel);

    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.name = "select_service";
    checkbox.value = JSON.stringify(selectService);

    checkbox.onchange = async function () {
      updateSalesCalculator();
      calculateRemainingRequiredFields();
    };
    newRadioGroup.appendChild(checkbox);

    card.appendChild(newRadioGroup);
  });
}

export async function displayInternationalCallPackages(
  internationalCallPackages,
  defaultValue,
  defaultOverride
) {
  const card = document.getElementById(
    "international-call-packages-dealsheet-card"
  );
  if (card === null || card === undefined) {
    return;
  }
  internationalCallPackages.forEach((pkg, i) => {
    const newRadioGroup = document.createElement("div");
    newRadioGroup.className = "dealsheet-radio-group";

    const radioLabel = document.createElement("label");
    radioLabel.htmlFor = pkg.id;
    radioLabel.innerHTML = `${pkg.name.replace(
      "%COMPANY_NAME%",
      getSelectedCompany().company_name
    )} - ${pkg.price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    })}`;
    newRadioGroup.appendChild(radioLabel);

    const radioInput = document.createElement("input");
    radioInput.type = "radio";
    radioInput.name = "international_call_package";
    radioInput.value = JSON.stringify(pkg);

    if (pkg.id === defaultValue) {
      radioInput.defaultChecked = true;
    }
    radioInput.onchange = async function () {
      updateSalesCalculator();
      calculateRemainingRequiredFields();
    };
    newRadioGroup.appendChild(radioInput);

    card.appendChild(newRadioGroup);
  });

  const separator = document.createElement("div");
  separator.className = "dealsheet-separator";
  card.appendChild(separator);

  const overrideField = document.createElement("input");
  overrideField.placeholder = "Price Override";
  overrideField.id = "international-call-package-price-override-field";
  overrideField.name = "international_call_package_price";
  overrideField.type = "number";
  if (defaultOverride !== null) {
    overrideField.value = defaultOverride;
  }
  overrideField.onwheel = function (e) {
    e.target.blur();
  };
  card.appendChild(overrideField);
}

export async function displayCallBoltOns(
  callBoltOns,
  defaultValue,
  defaultOverride
) {
  const card = document.getElementById("call-bolt-ons-dealsheet-card");
  if (card === null || card === undefined) {
    return;
  }
  callBoltOns.forEach((boltOn, i) => {
    const newRadioGroup = document.createElement("div");
    newRadioGroup.className = "dealsheet-radio-group";

    const radioLabel = document.createElement("label");
    radioLabel.htmlFor = boltOn.id;
    radioLabel.innerHTML = `${boltOn.name.replace(
      "%COMPANY_NAME%",
      getSelectedCompany().company_name
    )} - ${boltOn.price.toLocaleString("en-GB", {
      style: "currency",
      currency: "GBP"
    })}`;
    newRadioGroup.appendChild(radioLabel);

    const checkbox = document.createElement("input");
    checkbox.type = "checkbox";
    checkbox.name = "call_bolt_on";
    checkbox.value = JSON.stringify(boltOn);

    checkbox.onchange = async function () {
      updateSalesCalculator();
      calculateRemainingRequiredFields();
    };
    newRadioGroup.appendChild(checkbox);

    card.appendChild(newRadioGroup);
  });
}

// Submit Dealsheet extracts the data from the deal sheet
export async function submitDealsheet(data) {
  try {
    // const fullAddress = data.address;
    // delete data.address;

    // const addressComps = await detectAddress(fullAddress + ", United Kingdom");
    // if (addressComps === null) {
    //   return;
    // }

    console.log(data);

    showLoadingToast();
    const resp = await request.post(`${serverDomain}/api/v1/deals`, data);
    removeLoadingToast();
    showAlert(
      "Successfully submitted dealsheet",
      "You successfully submitted this deal through to the operations team to be provisioned."
    );
    return resp;
  } catch (err) {
    console.log(err);
    removeLoadingToast();
    showErrorToast(
      "Failed to save deal",
      `There was an error that occurred whilst trying to save your deal in the database.\n\nError:${err}`
    );

    return null;
  }
}

export async function saveDeal(data) {
  try {
    const resp = await request.post(`${serverDomain}/api/v1/deals`, data);
    return null;
  } catch (err) {
    console.log(err);
    return err;
  }
}

// Checks the sort code and account number provided are valid.
export async function checkBankDetails(sortCode, accountNumber) {
  if (sortCode === "") {
    showWarningToast(
      "Missing sort code",
      "Cannot check details without the sort code, please enter a value in the field provided."
    );
    return;
  } else if (accountNumber === "") {
    showWarningToast(
      "Missing account number",
      "Cannot check details without the account number, please enter a value in the field provided."
    );
    return;
  }

  try {
    showLoadingToast();
    const bankDetails = await request.post(
      serverDomain + "/api/v1/bank_details_check",
      {
        country_code: "GB",
        branch_code: sortCode,
        account_number: accountNumber
      }
    );
    removeLoadingToast();
    return bankDetails;
  } catch (err) {
    removeLoadingToast();
    return null;
  }
}

export async function mobileProviderButtonAction(p) {
  const postcode = document.getElementById(
    "mobile-coverage-postcode-input"
  ).value;
  if (postcode === "") {
    showAlert(
      "Postcode empty",
      "Please fill in the postcode from the adddress field above, or specified by the customer."
    );
    document.getElementById("mobile-coverage-results-table").style.display =
      "none";
    return;
  }

  try {
    const data = { postcode: postcode };
    var formBody = [];
    for (var property in data) {
      var encodedKey = encodeURIComponent(property);
      var encodedValue = encodeURIComponent(data[property]);
      formBody.push(encodedKey + "=" + encodedValue);
    }
    formBody = formBody.join("&");
    const opts = {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: formBody
    };

    const res = await fetch("https://www.signalchecker.co.uk/api", opts);
    const json = await res.json();

    document.getElementById("mobile-coverage-results-table").style.display =
      "table";

    json.json.result.forEach((prov, i) => {
      if (Object.keys(prov).includes(p)) {
        function addTick(element) {
          var tick = document.createElement("img");
          tick.src = TickIcon;
          element.innerHTML = "";
          element.appendChild(tick);
        }
        function addCross(element) {
          var cross = document.createElement("img");
          cross.src = CrossIcon;
          element.innerHTML = "";
          element.appendChild(cross);
        }

        if (
          Object.values(prov)[0].Voice &&
          Object.values(prov)[0].Voice.Indoor.Quality === 1
        ) {
          addTick(document.getElementById("indoor-voice"));
        } else {
          addCross(document.getElementById("indoor-voice"));
        }
        if (
          Object.values(prov)[0]["3G"] &&
          Object.values(prov)[0]["3G"].Indoor.Quality === 1
        ) {
          addTick(document.getElementById("indoor-3g"));
        } else {
          addCross(document.getElementById("indoor-3g"));
        }
        if (
          Object.values(prov)[0]["4G"] &&
          Object.values(prov)[0]["4G"].Indoor.Quality === 1
        ) {
          addTick(document.getElementById("indoor-4g"));
        } else {
          addCross(document.getElementById("indoor-4g"));
        }
        if (
          Object.values(prov)[0]["5G"] &&
          Object.values(prov)[0]["5G"].Indoor.Quality === 1
        ) {
          addTick(document.getElementById("indoor-5g"));
        } else {
          addCross(document.getElementById("indoor-5g"));
        }

        if (
          Object.values(prov)[0].Voice &&
          Object.values(prov)[0].Voice.Outdoor.Quality === 1
        ) {
          addTick(document.getElementById("outdoor-voice"));
        } else {
          addCross(document.getElementById("outdoor-voice"));
        }
        if (
          Object.values(prov)[0]["3G"] &&
          Object.values(prov)[0]["3G"].Outdoor.Quality === 1
        ) {
          addTick(document.getElementById("outdoor-3g"));
        } else {
          addCross(document.getElementById("outdoor-3g"));
        }
        if (
          Object.values(prov)[0]["4G"] &&
          Object.values(prov)[0]["4G"].Outdoor.Quality === 1
        ) {
          addTick(document.getElementById("outdoor-4g"));
        } else {
          addCross(document.getElementById("outdoor-4g"));
        }
        if (
          Object.values(prov)[0]["5G"] &&
          Object.values(prov)[0]["5G"].Outdoor.Quality === 1
        ) {
          addTick(document.getElementById("outdoor-5g"));
        } else {
          addCross(document.getElementById("outdoor-5g"));
        }

        document.getElementById(
          "mobile-coverage-caption"
        ).innerHTML = `Showing ${p} data...`;
      }
    });
  } catch (err) {
    document.getElementById("mobile-coverage-results-table").style.display =
      "none";
    showErrorToast(
      "Failed to get mobile coverage data",
      `There was an error that occurred whilst trying to fetch mobile coverage data. Error: ${err}`
    );
    return;
  }
}

export async function getHandoverAgents() {
  try {
    const data = await request.get(
      `${serverDomain}/api/v1/handover_agents`,
      null,
      "snake"
    );
    return [data, null];
  } catch (err) {
    return [null, err];
  }
}

export async function getCloserAgents() {
  try {
    const data = await request.get(
      `${serverDomain}/api/v1/closing_agents`,
      null,
      "snake"
    );
    return [data, null];
  } catch (err) {
    return [null, err];
  }
}

//list the closing sales agents for the dropdown...
export async function listCloserAgentsDealsheet() {
  const handoverSelect = document.getElementById("css-handover-agent-select");
  const handoverMidwaySelect = document.getElementById(
    "handover-agent-midway-dropdown"
  );

  const closerSelect = document.getElementById("css-closing-agent-select");
  try {
    const data = await request.get(
      `${serverDomain}/api/v1/handover_agents`,
      null,
      "snake"
    );

    console.log("Handover agents: " + data.length);

    for (var i = 0; i < data.length; i++) {
      const agent = data[i];
      const handoverOption = document.createElement("option");
      handoverOption.value = agent.id;
      handoverOption.innerHTML = `${agent.first_name} ${agent.last_name}`;
      handoverSelect.appendChild(handoverOption);

      const midwayHandoverOption = document.createElement("option");
      midwayHandoverOption.value = agent.id;
      midwayHandoverOption.innerHTML = `${agent.first_name} ${agent.last_name}`;
      handoverMidwaySelect.appendChild(midwayHandoverOption);

      const closerOption = document.createElement("option");
      closerOption.value = agent.id;
      closerOption.innerHTML = `${agent.first_name} ${agent.last_name}`;
      closerSelect.appendChild(closerOption);

      if (currentUser.id === agent.id) {
        handoverMidwaySelect.value = agent.id;
        handoverSelect.value = agent.id;
        closerSelect.value = agent.id;
      }
    }
  } catch (err) {
    console.log(err);
    window.alert(
      "An error occurred whilst getting handover agents list. Please try again."
    );
    return;
  }
}

export async function recordHandover(record, closingAgentId) {
  try {
    showLoadingOverlay();

    const data = {
      closing_agent_id: closingAgentId,
      cli: record.cli
    };
    await request.post(
      `${serverDomain}/api/v1/records/${record.id}/handover`,
      data
    );

    hideLoadingOverlay();
    return null;
  } catch (err) {
    console.log(err);
    hideLoadingOverlay();
    showErrorToast(
      "Failed to record handover",
      "Alert sales manager to confirm handover has been recorded."
    );
    return err;
  }
}

export async function getHandover(phone) {
  try {
    showLoadingOverlay();

    const handover = await request.get(
      `${serverDomain}/api/v1/number/${phone}/handover`,
      null,
      "snake"
    );

    // hideLoadingOverlay();
    return [handover, null];
  } catch (err) {
    console.log(err);
    // hideLoadingOverlay();
    return [null, err];
  }
}

//Gets deal stats
export async function getDealStats() {
  try {
    const stats = await request.get(
      `${serverDomain}/api/v1/deal_stats?date=${new Date()
        .toISOString()
        .slice(0, 10)}`,
      null,
      "snake"
    );
    return stats;
  } catch (err) {
    console.log(err);
    return null;
  }
}

export async function openAdditionalLineForm(onSave) {
  const div = document.createElement("div");
  div.id = "modal-div-wrapper";
  document.getElementById("app-content-wrapper").appendChild(div);
  const modal = <AdditionalLineForm onSave={onSave} />;
  const root = createRoot(document.getElementById("modal-div-wrapper"));
  root.render(modal);
}

export async function openRecordHandoverForm(selectedRecord, completion) {
  const div = document.createElement("div");
  div.id = "modal-div-wrapper";
  document.getElementById("app-content-wrapper").appendChild(div);
  const modal = (
    <RecordHandoverForm record={selectedRecord} onCompletion={completion} />
  );
  const root = createRoot(document.getElementById("modal-div-wrapper"));
  root.render(modal);
}

export async function openNewCallbackForm(selectedRecord, completion) {
  const div = document.createElement("div");
  div.id = "modal-div-wrapper";
  document.getElementById("app-content-wrapper").appendChild(div);
  const modal = (
    <NewCallbackForm record={selectedRecord} onCompletion={completion} />
  );
  const root = createRoot(document.getElementById("modal-div-wrapper"));
  root.render(modal);
}

export async function openArchiveRecordForm(selectedRecord, completion) {
  const div = document.createElement("div");
  div.id = "modal-div-wrapper";
  document.getElementById("app-content-wrapper").appendChild(div);
  const modal = (
    <ArchiveRecordForm record={selectedRecord} onCompletion={completion} />
  );
  const root = createRoot(document.getElementById("modal-div-wrapper"));
  root.render(modal);
}
