import React from "react";
import ReactDOM from "react-dom";
import { serverDomain } from "../utils/constants.js";
import { currentUser, fetchCurrentUser } from "../functions/core_functions.js";
import * as req from "../utils/request";
import CallIcon from "../assets/sidebar-icons/OutboundCallsIcon.svg";
import HandIcon from "../assets/sidebar-icons/HandoverSidebarIcon.svg";
import DealsIcon from "../assets/sidebar-icons/CommittedDealsIcon.svg";
import CustomersIcon from "../assets/sidebar-icons/CustomersIcon.svg";
import ProvisioningIcon from "../assets/sidebar-icons/ProvisioningIcon.svg";

import PuntIcon from "../assets/PIcon.svg";
import MissedCallIcon from "../assets/dashboard-stats/MissedCallIcon.svg";
import RecycleIcon from "../assets/RefreshIcon.svg";
import ClockIcon from "../assets/ClockIcon.svg";
import BTIcon from "../assets/break-bar-buttons/BTIcon.svg";
import POAIcon from "../assets/dashboard-stats/POAIcon.svg";
import NotInterestedIcon from "../assets/dashboard-stats/NotInterestedIcon.svg";

import DashboardStat from "../views/Dashboard/DashboardStat.js";
import CallingSessionUserStat from "../views/Dashboard/CallingSessionUserStat.js";
import { abbreviateNumber } from "../utils/helpers.js";

export async function getCounters(companyId) {
  try {
    if (currentUser === null) {
      await fetchCurrentUser();
    }

    const counters = await req.get(
      `${serverDomain}/api/v1/dashboard/counters?company_id=${companyId}`,
      null,
      "snake"
    );
    const callingSessionStats = await req.get(
      `${serverDomain}/api/v1/users/${currentUser.id}/calling_session_stats`,
      null,
      "snake"
    );

    const statsScroll = document.getElementById("dashboard-stats-scroll");
    statsScroll.innerHTML = "";

    var outboundCallsStat;
    var handoversStat;
    var dealsStat;
    var linesProvStat;
    var customersStat;

    const selectedScope = document
      .getElementsByClassName("dash-stats-scope-btn-active")[0]
      .innerHTML.toLowerCase();
    if (selectedScope === "company") {
      outboundCallsStat = counters.company.total_outbound_calls;
      handoversStat = counters.company.total_handovers_completed;
      dealsStat = counters.company.total_deals_completed;
      linesProvStat = counters.company.total_lines_provisioned;
      customersStat = counters.company.total_customers;
    } else if (selectedScope === "individual") {
      outboundCallsStat = counters.individual.total_outbound_calls;
      handoversStat = counters.individual.total_handovers_completed;
      dealsStat = counters.individual.total_deals_completed;
      linesProvStat = counters.individual.total_lines_provisioned;
    }

    if (outboundCallsStat) {
      const outboundCallsDiv = document.createElement("div");
      ReactDOM.render(
        <DashboardStat
          icon={CallIcon}
          count={abbreviateNumber(outboundCallsStat)}
          name={"Outbound Calls"}
        />,
        outboundCallsDiv
      );
      statsScroll.appendChild(outboundCallsDiv);
    }

    if (handoversStat) {
      const handoversDiv = document.createElement("div");
      ReactDOM.render(
        <DashboardStat
          icon={HandIcon}
          count={abbreviateNumber(handoversStat)}
          name={"Handovers Completed"}
        />,
        handoversDiv
      );
      statsScroll.appendChild(handoversDiv);
    }

    if (dealsStat) {
      const dealsDiv = document.createElement("div");
      ReactDOM.render(
        <DashboardStat
          icon={DealsIcon}
          count={abbreviateNumber(dealsStat)}
          name={"Deals Completed"}
        />,
        dealsDiv
      );
      statsScroll.appendChild(dealsDiv);
    }

    if (linesProvStat) {
      const linesProvDiv = document.createElement("div");
      ReactDOM.render(
        <DashboardStat
          icon={ProvisioningIcon}
          count={abbreviateNumber(linesProvStat)}
          name={"Lines Provisioned"}
        />,
        linesProvDiv
      );
      statsScroll.appendChild(linesProvDiv);
    }

    if (customersStat) {
      const customersDiv = document.createElement("div");
      ReactDOM.render(
        <DashboardStat
          icon={CustomersIcon}
          count={abbreviateNumber(customersStat)}
          name={"Customers"}
        />,
        customersDiv
      );
      statsScroll.appendChild(customersDiv);
    }

    const callingSessionStatsScroll = document.getElementById(
      "dashboard-calling-stats-scroll"
    );
    callingSessionStatsScroll.innerHTML = "";

    const stats = {
      punts: parseInt(callingSessionStats["punts"] || 0),
      poas: parseInt(callingSessionStats["poas"] || 0) || 0,
      no_interests: parseInt(callingSessionStats["no_interests"] || 0),
      non_bts: parseInt(callingSessionStats["non_bts"] || 0),
      no_answers: parseInt(callingSessionStats["no_answers"] || 0),
      recycles: parseInt(callingSessionStats["recycles"] || 0),
      scheduled_callbacks: parseInt(
        callingSessionStats["scheduled_callbacks"] || 0
      )
    };

    Object.keys(stats).forEach((key, index) => {
      var title = key.replaceAll("_", " ").replaceAll("bts", "BTs");
      title = title.charAt(0).toUpperCase() + title.slice(1, title.length);
      const div = document.createElement("div");
      div.className = "cs-user-stat-container";
      ReactDOM.render(
        <CallingSessionUserStat
          icon={iconForCallingSessionStat(key)}
          count={stats[key]}
          name={title}
        />,
        div
      );
      callingSessionStatsScroll.appendChild(div);
    });
  } catch (err) {
    console.log(err);
  }
}

function iconForCallingSessionStat(key) {
  switch (key) {
    case "punts":
      return PuntIcon;
    case "no_answers":
      return MissedCallIcon;
    case "recycles":
      return RecycleIcon;
    case "scheduled_callbacks":
      return ClockIcon;
    case "non_bts":
      return BTIcon;
    case "poas":
      return POAIcon;
    case "no_interests":
      return NotInterestedIcon;
  }
}

export async function calculateLeaderboard(id, companyId, timeframe) {
  try {
    const leaderboard = await req.get(
      `${serverDomain}/api/v1/dashboard/leaderboards?company_id=${companyId}&table_id=${id}&timescale=${timeframe}`,
      null,
      "snake"
    );
    return [leaderboard, null];
  } catch (err) {
    console.log(err);
    return [null, err];
  }
}
