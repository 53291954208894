import React from "react";
import { Table } from "../generic/Table";

import "../../styles/Customers/CustomerOverviewTab.css";

export const CustomerOverviewTab = () => {
  return (
    <div className="customer-overview-tab">
      <Table headers={["Action", "Date/Time", "Agent", "Description"]} />
    </div>
  );
};
