import React, { useEffect } from "react";
import Screen from "./Screen";

function WelcomeCalls() {
  useEffect(() => {
    (async () => {})();
  });

  return (
    <Screen>
      <div className="welcome-calls-screen"></div>
    </Screen>
  );
}

export default WelcomeCalls;
