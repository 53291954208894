import React, { useContext, useEffect, useState } from "react";
import Screen from "./Screen";
import { Table } from "../views/generic/Table";

import "../styles/Provisioning/Provisioning.css";
import PrimaryActionButton from "../views/generic/PrimaryActionButton";
import SearchBar from "../views/SearchBar";
import {
  getProvisioningData,
  getProvisioningStat,
  listProvisioningDataLimit,
  openProvisioningDetail,
  sendManagementOverviewText
} from "../functions/provisioning_functions";
import { removeLoadingToast, showLoadingToast } from "../utils/LoadingHandler";
import * as req from "../utils/request";
import { serverDomain } from "../utils/constants";
import {
  getCompanies,
  getUserRoleNames,
  getUserTeamNames
} from "../functions/core_functions";
import { showErrorToast } from "../utils/ErrorHandler";
import { SelectedCompanyContext, UserContext } from "../Main";
import { showSuccessToast } from "../utils/SuccessHandler";
import TableHeaderStat from "../views/generic/TableHeaderStat";

function Provisioning() {
  const [deals, setDeals] = useState([]);
  const [searchInput, setSearchInput] = useState(null);

  const { user, setUser } = useContext(UserContext);
  const { selectedCompany, setSelectedCompany } = useContext(
    SelectedCompanyContext
  );

  const [stopPagination, setStopPagination] = useState(false);

  const [provQueuedStat, setProvQueuedStat] = useState(0);
  const [cancelledStat, setCancelledStat] = useState(0);

  useEffect(() => {
    (async () => {
      if (!selectedCompany) {
        return;
      }

      const [data, err] = await getProvisioningData(searchInput, null, null);
      if (err) {
        showErrorToast("Failed to get deals");
        return;
      } else {
        setDeals(data || []);
      }
    })();
  }, [selectedCompany]);

  useEffect(() => {
    if (deals.length % listProvisioningDataLimit != 0) {
      setStopPagination(true);
    }
  }, [deals]);

  //Get stats
  useEffect(() => {
    // Get queued for provisioning stat.
    (async () => {
      const [stat, err] = await getProvisioningStat("queued_prov");
      if (stat) {
        setProvQueuedStat(stat);
      }
    })();

    (async () => {
      const [stat, err] = await getProvisioningStat("cancelled");
      console.log(err);
      if (stat) {
        console.log(stat);
        setCancelledStat(stat);
      }
    })();
  }, [selectedCompany]);

  async function convertArrayOfObjectsToCSV(args) {
    var result, ctr, keys, columnDelimiter, lineDelimiter, data;

    data = args.data || null;
    if (data === null || !data.length) {
      return null;
    }

    columnDelimiter = args.columnDelimiter || ",";
    lineDelimiter = args.lineDelimiter || "\n";

    keys = Object.keys(data[0]);

    result = "";
    result += keys.join(columnDelimiter);
    result += lineDelimiter;

    data.forEach(function (item) {
      ctr = 0;
      keys.forEach(function (key) {
        if (ctr > 0) result += columnDelimiter;
        var v =
          item[key] !== null && item[key] !== undefined
            ? item[key].toString()
            : null;
        if (v !== null) {
          v = v.replace("paper", "Paper");
          v = v.replace("email", "Email");
          if (key === "D.O.B" || key === "Date" || key === "Live Date") {
            const dateFormatted = new Date(item[key]).toLocaleDateString();
            v = dateFormatted;
          }
        }

        let val = item[key] ? `"${v}"` : item[key];
        result += val;
        ctr++;
      });
      result += lineDelimiter;
    });

    return result;
  }

  function capitalizeSentence(string) {
    if (string === "") {
      return "";
    }
    const words = string.split(" ");
    for (let i = 0; i < words.length; i++) {
      if (words[i] == "") {
        continue;
      }
      words[i] = words[i][0].toUpperCase() + words[i].substr(1);
    }
    return words.join(" ");
  }

  async function exportToCSVButtonAction() {
    try {
      const deals = await req.get(
        `${serverDomain}/api/v1/deals?status=prov`,
        null,
        "snake"
      );

      if (!deals || Object.keys(deals).length === 0) {
        return;
      }

      const companies = await getCompanies();
      if (!companies) {
        showErrorToast(
          "Failed to get companies",
          "There was an issue whilst trying to get the companies details from the database."
        );
        return;
      }

      const colOrderedDeals = [];
      deals.forEach(deal => {
        if (new Date(deal.created_at) < new Date("2023-01-09T00:00:00Z")) {
          return;
        }

        var tariffName = deal.tariff_name;
        companies.forEach(company => {
          if (company.id === deal.company_id) {
            if (company.id === 2) {
              tariffName = tariffName.replaceAll(
                "%COMPANY_NAME%",
                "Transparent"
              );
            } else if (company.id === 3) {
              tariffName = tariffName.replaceAll("%COMPANY_NAME%", "Premier");
            } else if (company.id === 4) {
              tariffName = tariffName.replaceAll("%COMPANY_NAME%", "Expert");
            }
          }
        });

        const orderedDeal = {
          "Phone Provider": deal.phone_provider,
          Date: deal.created_at,
          "Live Date": deal.live_date,
          "Account ID": deal.account_id,
          "Account Name": deal.customer_name,
          "Customer Name": deal.full_name,
          "Additional Account Holder": deal.add_account_holder,
          "House Name or Number": capitalizeSentence(
            `${deal.address1}`.toLowerCase()
          ),
          "Street Name": capitalizeSentence(`${deal.address2}`.toLowerCase()),
          Town: capitalizeSentence(`${deal.address_city}`.toLowerCase()),
          County: capitalizeSentence(`${deal.address_county}`.toLowerCase()),
          Postcode: deal.address_postcode,
          "Landline Number": deal.landline,
          "Mobile Number": deal.mobile_number,
          "Email Address": deal.email_address,
          "Line Rental Charge": deal.line_rental_name,
          "Call Tariffs": tariffName,
          "Call Tariff Price":
            deal.tariff_price || deal.tariff_price == 0
              ? deal.tariff_price.toLocaleString("en-GB", {
                  style: "currency",
                  currency: "GBP"
                })
              : "",
          "Calls Broadband Bundle": deal.call_broadband_bundle_name,
          "Calls & Broadband Bundle Price": deal.call_broadband_bundle_price,
          "Broadband Type": deal.broadband_package_name,
          "Broadband Price": deal.broadband_package_price
            ? deal.broadband_package_price.toLocaleString("en-GB", {
                style: "currency",
                currency: "GBP"
              })
            : "",
          "BT Sport": Object.values(deal.package_add_ons).includes(2),
          "BT Mobile": Object.values(deal.package_add_ons).includes(1),
          "BT Email Address": Object.values(deal.package_add_ons).includes(3),
          "International Call Packages": deal.international_call_package_name,
          "International Call Location": deal.international_call_location,
          "Mobile Package": deal.mobile_package_name,
          "Premium Rate Packages": "-",
          "Billing Type": deal.billing_type,
          "Sort Code": deal.sort_code,
          "Account Number": deal.account_number,
          "Contract Length": `${deal.contract_length_months} Months`,
          "Openreach Reference": "-",
          Agent: deal.handover_agent_name,
          "Closing Agent": deal.closing_agent_name,
          "D.O.B": deal.dob,
          "Broadband Provider": deal.broadband_provider,
          "Invoice Name": deal.invoice_name,
          "Bank Branch": deal.bank_branch,
          "Bank Info Checked On Iban": deal.iban_check,
          "Mobile Number": deal.mobile_number,
          "Soft Facts": deal.soft_facts,
          "If POA - Obtain POA Details": deal.poa_details,
          "If you get sortcode and account number (YES) if you don't get sortcode and account (NO)":
            deal.account_number && deal.sort_code,
          "Does the customer Pay Direct Debit": "-",
          "Call Bolt Ons": "-"
        };
        colOrderedDeals.unshift(orderedDeal);
      });

      var csv = await convertArrayOfObjectsToCSV({ data: colOrderedDeals });
      if (csv === null) return;
      if (!csv.match(/^data:text\/csv/i)) {
        csv = "data:text/csv;charset=ISO-8859-1," + csv;
        csv = csv.replaceAll("FALSE", "NO").replaceAll("false", "NO");
        csv = csv.replaceAll("TRUE", "YES").replaceAll("true", "YES");
        csv = csv.replaceAll("Bt", "BT");
        csv = csv.replaceAll("null", "").replaceAll("Null", "");
      }

      var encodedUri = encodeURI(csv);
      var link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "provisioning_data_export.csv");
      document.body.appendChild(link);
      link.click();
    } catch (err) {
      console.log(err);
      return;
    }
  }

  return (
    <Screen>
      <div className="provisioning-screen">
        <div className="stats-wrapper">
          <div className="stats-scroll">
            <TableHeaderStat
              title={"Queued"}
              description={"Deals awaiting provisioning."}
              value={provQueuedStat}
              label={"Deals"}
            />
            <TableHeaderStat
              title={"Pending"}
              description={"Lines that are pending."}
              value={0}
              label={"Deals"}
            />
            <TableHeaderStat
              title={"Committed"}
              description={"Lines that have been committed."}
              value={0}
              label={"Deals"}
            />
            <TableHeaderStat
              title={"Rejected"}
              description={"Lines that have been rejected."}
              value={0}
              label={"Deals"}
            />
            <TableHeaderStat
              title={"Cancel Other"}
              description={"Lines that have come back as cancelled."}
              value={0}
              label={"Deals"}
            />
            <TableHeaderStat
              title={"Cancelled"}
              description={"Deals that have been cancelled."}
              value={cancelledStat}
              label={"Deals"}
            />
            <TableHeaderStat
              title={"Winbacks"}
              description={"Deals that have been wonback."}
              value={0}
              label={"Deals"}
            />
          </div>
        </div>
        <div className="deals-table-header-wrapper">
          <div className="table-filters">
            <SearchBar
              id={"provisioning-deals-search"}
              placeholder={"Search by CLI, person name, etc..."}
              onSearch={async text => {
                showLoadingToast("Searching deals...");
                setSearchInput(text);
                const [ds, err] = await getProvisioningData(text, null, null);
                removeLoadingToast();
                if (err) {
                  showErrorToast("Failed to search deals");
                  return;
                } else {
                  setDeals(ds || []);
                }
              }}
            />
          </div>
          <div className="action-btns">
            <PrimaryActionButton
              id={"bulk-tps-btn"}
              title={"Bulk TPS Check"}
              onClick={() => {}}
            />
            <PrimaryActionButton
              id={"bulk-provision-btn"}
              title={"Bulk Provision"}
              onClick={() => {}}
            />
            <PrimaryActionButton
              id={"bulk-status-update-btn"}
              title={"Bulk Status Update"}
              onClick={() => {}}
            />
            <PrimaryActionButton
              id={"export-deals-btn"}
              title={"Export Today's Deals"}
              onClick={async () => {
                showLoadingToast();
                await exportToCSVButtonAction();
                removeLoadingToast();
              }}
            />
          </div>
        </div>
        <div className="deals-table-wrapper">
          <Table
            headers={[
              "Date",
              "Deal ID",
              "Name",
              "CLI",
              "Address Line 1",
              "Postcode",
              "Handover",
              "Closing",
              "Prov Status"
            ]}
            rowData={deals.map(deal => {
              const customer = JSON.parse(deal.customer)[0];
              const address = JSON.parse(deal.address)[0];
              const lineRentalOrder = deal.lr_order
                ? JSON.parse(deal.lr_order)[0]
                : null;
              const cancellationRequest = deal.cancellation_req
                ? JSON.parse(deal.cancellation_req)[0]
                : null;

              var status = "PENDING";
              if (cancellationRequest) {
                status = "CANCEL REQ";
              }

              return {
                date: new Date(deal.created_at).toLocaleDateString(),
                id: deal.id,
                full_name: customer.full_name,
                cli: lineRentalOrder ? lineRentalOrder.cli : "N/A",
                address1: address.building_name_number,
                address_postcode: address.postcode,
                handover: deal.handover_agent_name,
                closing: deal.closing_agent_name,
                // recko_status: customer.recko_status
                //   ? capitalizeSentence(customer.recko_status)
                //   : "N/A",
                prov_status: status
              };
            })}
            rowClick={async data => {
              openProvisioningDetail(data.id, selectedCompany);
            }}
            state={deals}
            paginationFunc={async () => {
              if (!stopPagination) {
                showLoadingToast("Loading more deals...");
                const [ds, err] = await getProvisioningData(
                  searchInput,
                  deals.length
                );
                setDeals(deals.concat(ds));
                removeLoadingToast();
              }
            }}
          />
        </div>
      </div>
    </Screen>
  );
}

export default Provisioning;
