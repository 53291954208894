import React, { useEffect, useState } from "react";
import { generateUUID } from "../../functions/core_functions";
import { getLineTakeoverData } from "../../functions/provisioning_functions";
import "../../styles/generic/Table.css";

export const Table = ({
  headers,
  rowData = [],
  rowClick = data => {},
  state = [],
  paginationFunc = () => {}
}) => {
  const uuid = generateUUID();
  const [lastHeight, setLastHeight] = useState(0);

  useEffect(() => {
    const tableWrapper = document.getElementById(uuid);
    tableWrapper.onscroll = () => {
      setLastHeight(tableWrapper.scrollTop + tableWrapper.offsetHeight);

      if (
        tableWrapper.scrollTop + tableWrapper.offsetHeight + 100 >=
          tableWrapper.scrollHeight &&
        tableWrapper.scrollTop + tableWrapper.offsetHeight > lastHeight
      ) {
        paginationFunc();
      }
    };
  }, state);

  return (
    <div className="table-wrapper" id={uuid}>
      <table className="table">
        <thead>
          <tr>
            {headers &&
              headers.length > 0 &&
              headers.map((header, index) => <th key={index}>{header}</th>)}
          </tr>
        </thead>
        <tbody>
          {rowData &&
            rowData.length > 0 &&
            rowData.map((data, index) => (
              <tr
                key={index}
                onClick={async () => {
                  rowClick(data);
                }}
              >
                {Object.values(data).map((value, _) => {
                  if (value === "PENDING") {
                    return <td className="pending">{value}</td>;
                  } else if (value === "CANCEL REQ") {
                    return <td className="cancel">{value}</td>;
                  } else {
                    return <td>{value}</td>;
                  }
                })}
              </tr>
            ))}
        </tbody>
      </table>
    </div>
  );
};
