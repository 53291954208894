import React from "react";
import Modal, {
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalInputGrid,
  ModalRow,
  ModalSection
} from "../generic/modal/Modal";
import { MultiLineInput } from "../generic/MultiLineInput";
import PrimaryActionButton from "../generic/PrimaryActionButton";
import { SingleLineInput } from "../generic/SingleLineInput";

const SendSMSForm = () => {
  return (
    <Modal id={"send-customer-sms-form"}>
      <ModalHeader title={"Send SMS To Customer"} />
      <ModalContent>
        <ModalSection>
          <ModalInputGrid>
            <SingleLineInput
              id={"customer-phone-input"}
              title={"Customer Phone"}
              placeholder={"+447712433835"}
            />
            <div></div>
            <MultiLineInput
              id={"message-input"}
              title={"Message"}
              placeholder={"Write a message to send to the customer..."}
            />
          </ModalInputGrid>
        </ModalSection>
      </ModalContent>
      <ModalFooter>
        <PrimaryActionButton id={"send-button"} title={"Send"} />
      </ModalFooter>
    </Modal>
  );
};

export default SendSMSForm;
