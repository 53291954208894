import React from "react";
import "../../styles/elements/OpenRecordTableRowButton.css";
import RightChevron from "../../assets/RightChevron.svg";

function OpenRecordTableRowButton({ onClick }) {
  return (
    <button className="open-record-button" onClick={onClick}>
      <img src={RightChevron} />
    </button>
  );
}

export default OpenRecordTableRowButton;
