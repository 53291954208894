import React from "react";

// Styles
import "../../styles/generic/LoadingToast.css";

export const LoadingToast = ({
  title = "Loading",
  description = "Please wait a moment whilst we process your request."
}) => {
  return (
    <div className="loading-toast-wrapper">
      <div className="loading-toast">
        <div className="loading-content-wrapper">
          <LoadingWheel />
          <div className="loading-details-wrapper">
            <span className="loading-title-lbl">{title}</span>
            <span className="loading-description-lbl">{description}</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export const LoadingWheel = () => {
  return (
    <div className="loading-wheel">
      <div className="back" />
      <div className="lds-dual-ring"></div>
    </div>
  );
};
