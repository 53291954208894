import React, { useEffect, useState } from "react";

// Styles
import "./styles/Main.css";

// Views
import PageRouter from "./PageRouter";
import { setSwitchedUserID, switchedUserID } from "./utils/constants";

export const SelectedRecordContext = React.createContext();
export const UserContext = React.createContext();
export const SelectedCompanyContext = React.createContext();
export const NavMenuContext = React.createContext();
export const OpenNavMenuDropdownContext = React.createContext();

function Main() {
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [user, setUser] = useState(null);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [selectedNavMenuItem, setSelectedNavMenuItem] = useState(null);
  const [openNavMenuDropdown, setOpenNavMenuDropdown] = useState(null);

  useEffect(() => {
    const switchedUserIdFromStorage = localStorage.getItem(
      "rscrm_switched_user_id"
    );
    if (switchedUserIdFromStorage) {
      setSwitchedUserID(switchedUserIdFromStorage);
    }
  }, [user]);

  return (
    <SelectedRecordContext.Provider
      value={{
        selectedRecord: selectedRecord,
        setSelectedRecord: setSelectedRecord
      }}
    >
      <UserContext.Provider value={{ user: user, setUser: setUser }}>
        <SelectedCompanyContext.Provider
          value={{
            selectedCompany: selectedCompany,
            setSelectedCompany: setSelectedCompany
          }}
        >
          <NavMenuContext.Provider
            value={{
              selectedNavMenuItem: selectedNavMenuItem,
              setSelectedNavMenuItem: setSelectedNavMenuItem
            }}
          >
            <OpenNavMenuDropdownContext.Provider
              value={{
                openNavMenuDropdown: openNavMenuDropdown,
                setOpenNavMenuDropdown: setOpenNavMenuDropdown
              }}
            >
              <PageRouter />
            </OpenNavMenuDropdownContext.Provider>
          </NavMenuContext.Provider>
        </SelectedCompanyContext.Provider>
      </UserContext.Provider>
    </SelectedRecordContext.Provider>
  );
}

export default Main;
