import React from "react";
import { string, func } from "prop-types";
import "../styles/CustomerNotes/CustomerNoteRow.css";
import { showAlert } from "../functions/core_functions.js";

function CustomerNoteRow({dateTime, userName, note}) {
  return (
    <tr className="customer-note-row" onClick={async () => {
      showAlert(`Note from ${userName} on ${dateTime}`, note);
      return;
    }}>
      <td>{dateTime}</td>
      <td>{userName}</td>
      <td>{`${note.substring(0,8)}...`}</td>
    </tr>
  );
}

CustomerNoteRow.propTypes = { dateTime: string, userName: string, note: string };

export default CustomerNoteRow;
